.projectBox {
    height: auto;
    margin: 27px auto 0px;
    width: 900px;
}

.projectBox-header {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    border-right: 1px solid #194368;
    border-left: 1px solid #194368;
    border-top: 1px solid #194368;
    border-bottom: 0;
    color: #f5f5f5;
    font-size: 15px;
    font-weight: bolder;
    height: 25px;
    padding-top: 5px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.projectBox-body {
    border: 1px solid #194368;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    margin-bottom: 40px;
    border-radius: 0 0 5px 5px;
}

.div_form {
    margin: 30px 0 0 -450px;
    position: absolute;
    left: 50%;
    text-align: center;
}

.imageOpacity {
    opacity: 0.70;
}

.imageOpacity:hover {
    opacity: 1;
}

.loading {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50%;
    margin-left: -12px;
    top: 115px;
    z-index: 999;
}

.access-box {
    text-align: center;
    border-right: 1px dotted #d8d8d8;
    border-radius: 3px;
    padding: 8px;
    width: 220px;
}

.access-box-button {
    font-weight: bold;
    margin-top: 15px !important;
}

.online-aura {
    box-shadow: 0 0 0px 2px #269C59 !important;
}

.offline-aura {
    box-shadow: 0 0 0px 2px #E24C38 !important;
}

.favorite-project-link,
.item-project-menu a.favorite-project-link {
    text-decoration: none !important;
    background-color: transparent !important;
    color: #0088cc !important;
    font-size: 15px;
}

.item-project-menu a.favorite-project-link:hover {
    background-color: transparent !important;
}

.type-project-link{
    line-height: 15px;
    font-size: 15px;
}

.projectBoxContent #quota-viewer .fa.fa-info-circle {
    vertical-align: top;
    cursor: help;
}

.projectBoxContent #quota-viewer {
    position: absolute;
    width: 180px;
    height: 20px;
    line-height: 20px;
    right: 150px;
    top: 9px;
}

.projectBoxContent #quota-container {
    display: inline-block;
    box-shadow: 0 0 1px #ccc;
    width: 150px;
    height: 18px;
}

.projectBoxContent #quota-viewer .progress[data-percent]:after {
    line-height: 1.3rem;
    font-size: 0.65rem;
}

.item-project-menu {
    border-bottom: 1px solid #e4ecf3 !important;
    cursor: auto;
    display: block;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 12px;
    height: 20px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    text-align: left;
    text-decoration: none solid rgb(85, 85, 85);
    white-space: nowrap;
    width: 314px;
    padding: 12px 10px;
    letter-spacing: .3px;
}

.item-project-menu a {
    color: #333333 !important;
}

.item-project-menu:hover {
    background: #ffffff;
}

.item-project-menu a:hover, .item-project-menu a:hover:before, .item-project-menu a:hover:after {
    color: #333333 !important;
    background: #f4f9fc !important;
}

.ProjectReplicationDialog .GeneralInformationTab,
.ProjectReplicationDialog .PermissionsTab {
    overflow: auto !important;
    height: 375px !important;
    position: relative !important;
}

.ProjectReplicationDialog .ReplicationConnectionsContainer .DialogFooter,
.ProjectReplicationDialog .ReplicationConnectionsContainer .conection-dialog-division {
    display: none;
}

.ProjectReplicationDialog .ReplicationConnectionsContainer .DialogBody {
    overflow: auto;
    height: 330px;
}