table tr, table tr td, table tr th {
    page-break-inside: avoid;
}

.mdx-tale.table-image-container img {
    opacity: 1 !important;
}

.mdx-table input {
    margin-right: 3px;
}

.mdx-table thead .blank, .mdx-table thead .measure {
    margin: 0 !important;
    padding: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    border-width: 0 !important;
}

.table-theme-branco-azul .mdx-table thead tr:nth-child(odd), .table-theme-branco-azul .mdx-table thead tr:nth-child(odd) th, .table-theme-branco-azul .mdx-table thead tr:nth-child(odd) td, .branco-azul .mdx-table thead tr:nth-child(odd), .branco-azul .mdx-table thead tr:nth-child(odd) th, .branco-azul .mdx-table thead tr:nth-child(odd) td, .table-theme-branco-azul .mdx-table thead tr:nth-child(even), .table-theme-branco-azul .mdx-table thead tr:nth-child(even) th, .table-theme-branco-azul .mdx-table thead tr:nth-child(even) td, .branco-azul .mdx-table thead tr:nth-child(even), .branco-azul .mdx-table thead tr:nth-child(even) th, .branco-azul .mdx-table thead tr:nth-child(even) td {
    border-bottom-width: 0px !important;
    border-left-width: 0px !important;
    border-top: 0px;
}

.mdx-table-border-none .mdx-table tr:nth-child(odd), .mdx-table-border-none .mdx-table tr:nth-child(odd) th, .mdx-table-border-none .mdx-table tr:nth-child(odd) td,
.mdx-table-border-none .mdx-table tr:nth-child(even), .mdx-table-border-none .mdx-table tr:nth-child(even) th, .mdx-table-border-none .mdx-table tr:nth-child(even) td,
.mdx-table-border-none .mdx-table {
    border-style: none !important;
    border-width: 0 !important;
}

.mdx-table-border-solid .mdx-table tr:nth-child(odd), .mdx-table-border-solid .mdx-table tr:nth-child(odd) th, .mdx-table-border-solid .mdx-table tr:nth-child(odd) td,
.mdx-table-border-solid .mdx-table tr:nth-child(even), .mdx-table-border-solid .mdx-table tr:nth-child(even) th, .mdx-table-border-solid .mdx-table tr:nth-child(even) td {
    /*border-style: solid !important;*/
    border-width: 1px;
}

.mdx-table-border-dotted .mdx-table tbody tr:nth-child(odd), .mdx-table-border-dotted .mdx-table tbody tr:nth-child(odd) th, .mdx-table-border-dotted .mdx-table tbody tr:nth-child(odd) td,
.mdx-table-border-dotted .mdx-table tbody tr:nth-child(even), .mdx-table-border-dotted .mdx-table tbody tr:nth-child(even) th, .mdx-table-border-dotted .mdx-table tbody tr:nth-child(even) td {
    border-style: dotted !important;
    border-width: 1px;
}

.mdx-table-border-dashed .mdx-table tbody tr:nth-child(odd), .mdx-table-border-dashed .mdx-table tbody tr:nth-child(odd) th, .mdx-table-border-dashed .mdx-table tbody tr:nth-child(odd) td,
.mdx-table-border-dashed .mdx-table tbody tr:nth-child(even), .mdx-table-border-dashed .mdx-table tbody tr:nth-child(even) th, .mdx-table-border-dashed .mdx-table tbody tr:nth-child(even) td {
    border-style: dashed !important;
    border-width: 1px;
}

.table-row-size-small .mdx-table tr:nth-child(odd), .table-row-size-small .mdx-table tr:nth-child(odd) th, .table-row-size-small .mdx-table tr:nth-child(odd) td,
.table-row-size-small .mdx-table tr:nth-child(even), .table-row-size-small .mdx-table tr:nth-child(even) th, .table-row-size-small .mdx-table tr:nth-child(even) td {
    padding: 2px 5px;
}

.table-row-size-medium .mdx-table tr:nth-child(odd), .table-row-size-medium .mdx-table tr:nth-child(odd) th, .table-row-size-medium .mdx-table tr:nth-child(odd) td,
.table-row-size-medium .mdx-table tr:nth-child(even), .table-row-size-medium .mdx-table tr:nth-child(even) th, .table-row-size-medium .mdx-table tr:nth-child(even) td {
    padding: 5px 7px;
}

.table-row-size-big .mdx-table tr:nth-child(odd), .table-row-size-big .mdx-table tr:nth-child(odd) th, .table-row-size-big .mdx-table tr:nth-child(odd) td,
.table-row-size-big .mdx-table tr:nth-child(even), .table-row-size-big .mdx-table tr:nth-child(even) th, .table-row-size-big .mdx-table tr:nth-child(even) td {
    padding: 10px 12px;
}

.item-content-container .fixed-columns {
    border-right: none !important;
}

.mdx-table thead tr th, .mdx-table thead tr td, .mdx-table thead tr, .mdx-table thead {
    white-space: nowrap;
}

.cell-odd, .cell-even {
    font-weight: normal;
}

.row-heading-red, .column-heading-red, .row-heading-rot, .column-heading-rot, .cell-red {
    background-color: #ff6666 !important;
    border: solid 1px #ffffff;
}

.row-heading-yellow, .column-heading-yellow, .row-heading-gelb, .column-heading-gelb, .cell-yellow {
    background-color: yellow !important;
    border: solid 1px #ffffff;
}

.row-heading-green, .column-heading-green, .row-heading-gruen, .column-heading-gruen, .cell-green {
    background-color: #66ff66 !important;
    border: solid 1px #ffffff;
}

.cell-red, .cell-yellow, .cell-green {
    text-shadow: none !important;
}

.column-heading-even, .column-heading-odd {
    text-shadow: none;
    color: #444444 !important;
}

.column-heading-selected, .column-heading-odd, .column-heading-span, .column-heading-red, .column-heading-green,
.column-heading-yellow, .column-heading-rot, .column-heading-gruen, .column-heading-gelb, .column-heading-even-right,
.row-heading-even-right, .column-heading-odd-right, .column-heading-span-right, .column-heading-even {
    text-align: right !important;
}

#mdx-table-box {
    height: 80%;
    overflow-y: auto;
    overflow-x: visible
}

.table-theme-silver .mdx-table thead tr th, .table-theme-simple-line .mdx-table thead tr th, .table-theme-config .mdx-table thead tr th {
    font-weight: bold !important;
    color: #000000;
}

.table-theme-default .mdx-table tr, .table-theme-default .mdx-table tr th, .table-theme-default .mdx-table tr td {
    border-width: 1px !important;
}

/*****************  thema clean start ****************/

.table-theme-clean table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
}

.table-theme-clean th,
.table-theme-clean td {
    border: 0;
    color: rgba(0, 0, 0, .8);
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-clean .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-clean .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-clean .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #4F7FF2 !important;
    color: White;
}

.table-theme-clean .mdx-table tbody tr.highlight-row th, .table-theme-clean .mdx-table tbody tr.highlight-row td, .table-theme-clean .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #2657CA !important;
    color: #FFFFFF;
}

.table-theme-clean table.mdx-table thead tr td.row-total-col.blank {
    background-color: #eaeaea !important;
    /* border-bottom: 1px solid #cccccc !important;*/
}

.table-theme-clean .row-total-col, .table-theme-clean .col-total-row {
    background: #eeeeee !important;
}

.DashboardTheme-BLACK .table-theme-clean .row-total-col, .DashboardTheme-BLACK .table-theme-clean .col-total-row td{
    background: #e8e8e8 !important;
}

.dashboarditem .mdx-table {
    background: white !important;
}

.DashboardTheme-BLACK .table-theme-clean table {
    background-color: #242424 !important;
}

.DashboardTheme-BLACK .table-theme-modern-6 tr.col-total-row {
    background-color: #272727 !important;
}

.DashboardTheme-BLACK .table-theme-modern-4 tr.col-total-row {
    background-color: #c0c7d5 !important;
}

.DashboardTheme-BLACK .table-theme-modern-5 tr.col-total-row {
    background-color: #353535 !important;
}

.table-theme-clean thead tr .heading-heading {
    background-color: #EAEAEA !important;
}

.table-theme-clean thead tr .corner-heading {
    background-color: #39cdb5 !important;
}

.table-theme-clean thead tr th, .table-theme-clean thead tr td {
    background-color: #EAEAEA !important;
    color: #555555 !important;
}

.table-theme-clean thead tr:nth-child(odd) th, .table-theme-clean thead tr:nth-child(odd) th {
    background-color: #26324b !important;
    color: #EAEAEA !important;
}

.table-theme-clean thead tr:last-child th {
    border-bottom: 1px solid #cccccc !important;
}

.table-theme-clean td .cell-odd {
    background-color: #2C2E30;
}

.table-theme-clean tbody th {
    background-color: #ffffff;
    border-bottom-width: 1px !important;
    border-bottom-color: rgba(0, 0, 0, .1) !important;
}

.table-theme-clean tbody tr:nth-child(odd) .cell-red {
    background-color: #E8CBCB !important;
    font-weight: bolder;
}

.table-theme-clean .cell-red {
    background-color: #D7ACAC !important;
    font-weight: bolder;
}

.table-theme-clean tbody tr:nth-child(odd) .cell-green {
    background-color: #D2E6C6 !important;
    font-weight: bolder;
}

.table-theme-clean .cell-green {
    background-color: #B8D4A6 !important;
    font-weight: bolder;
}

.table-theme-clean tbody tr:nth-child(odd) .cell-yellow {
    background-color: #F1E7AB !important;
    font-weight: bolder;
}

.table-theme-clean .cell-yellow {
    background-color: #E9DB8C !important;
    font-weight: bolder;
}

.table-theme-clean th, .table-theme-clean td {
    border-width: 1px !important;
    border-color: rgba(255, 255, 255, .05) !important;
    border-bottom-color: rgba(0, 0, 0, .1) !important;
}

/*****************  thema clean end ****************/

/*****************  thema blue start ****************/


.table-theme-blue .mdx-table {
    background: transparent;
}

.table-theme-blue .mdx-table thead tr th {
    border-right: 1px solid #FFFFFF;
    background: #2980B9 !important;
}

.table-theme-blue .mdx-table thead tr th {
    background: #2980B9 !important;
}

.table-theme-blue .mdx-table thead tr {
    background: #2980B9 !important;
}

/*****************  thema blue end ****************/


/*****************  thema silver start ****************/

.table-theme-silver .mdx-table {
    background: #f5f5f5;
    color: #333333;
}

.table-theme-silver .mdx-table thead tr th, .table-theme-silver .mdx-table thead tr td, .table-theme-silver .mdx-table thead tr, .table-theme-silver .mdx-table thead {
    border-right: 1px solid #ededed;
    background: #f9f9f9 !important;
    border: none;
    border-width: 0 !important;
    border-top: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    border-top-left: 1px !important;
    color: #333333;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5em;
    height: 1.5em;
}


.table-theme-silver .mdx-table .cell-yellow, .table-theme-silver .mdx-table .cell-green, .table-theme-silver .mdx-table .cell-red, .table-theme-silver .mdx-table thead tr th, .table-theme-silver .mdx-table thead {
    color: #333333 !important;
}


.table-theme-silver .mdx-table tbody tr:nth-child(odd), .table-theme-silver .mdx-table tbody tr:nth-child(odd) th, .table-theme-silver .mdx-table tbody tr:nth-child(odd) td {
    background: #ffffff linear-gradient(to bottom, #ffffff00, #edededa8) repeat-x scroll 0 0;
    border: none;
    border-width: 0 !important;
    border-top: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    border-top-left: 1px !important;
    color: #333333;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 30px;
    height: 30px;
}

.table-theme-silver .mdx-table tbody tr:nth-child(odd) td.cell-green,
.table-theme-silver .mdx-table tbody tr:nth-child(odd) td.cell-yellow,
.table-theme-silver .mdx-table tbody tr:nth-child(odd) td.cell-red {
    background-image: none !important;
}


.table-theme-silver .mdx-table tbody tr:nth-child(even), .table-theme-silver .mdx-table tbody tr:nth-child(even) th, .table-theme-silver .mdx-table tbody tr:nth-child(even) td {
    background-color: #FFFFFF;
    border: none;
    border-width: 0 !important;
    border-top: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    border-right-width: 1px !important;
    border-top-left: 1px !important;
    color: #333333;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 30px;
    height: 30px;
}

/*****************  thema silver end ****************/

/*****************  thema red start ****************/

.table-theme-red .mdx-table {
    background: transparent;
}

.table-theme-red .mdx-table thead tr th {
    border-right: 1px solid #FFFFFF;
    background: #d32f2f !important;
}

.table-theme-red .mdx-table thead tr th {
    background: #d32f2f !important;
}

.table-theme-red .mdx-table thead tr {
    background: #d32f2f !important;
}

/*****************  thema red end ****************/

/*****************  thema green start ****************/

.table-theme-green .mdx-table {
    background: transparent;
}

.table-theme-green .mdx-table thead tr th {
    border-right: 1px solid #FFFFFF;
    background: #388e3c !important;
}

.table-theme-green .mdx-table thead tr th {
    background: #388e3c !important;
}

.table-theme-green .mdx-table thead tr {
    background: #388e3c !important;
}

/*****************  thema gren end ****************/

/*****************  thema dark start ****************/

.table-theme-dark .mdx-table {
    background: transparent;
}

.table-theme-dark .mdx-table thead tr th {
    border-right: 1px solid #FFFFFF;
    background: #404040 !important;
}

.table-theme-dark .mdx-table thead tr th {
    background: #404040 !important;
}

.table-theme-dark .mdx-table thead tr {
    background: #404040 !important;
}

.table-theme-dark .mdx-table .cell-yellow, .table-theme-dark .mdx-table .cell-green, .table-theme-dark .mdx-table .cell-red, .table-theme-dark .mdx-table thead tr th {
    color: #ffffff !important;
}

.table-theme-dark .mdx-table thead tr th {
    background: #404040 !important;
}


.table-theme-dark .mdx-table thead tr th {
    background: #404040 !important;
}

/*****************  thema dark end ****************/


/*****************  thema simple-line start ****************/

.table-theme-simple-line .mdx-table tr:nth-child(odd), .table-theme-simple-line .mdx-table tr:nth-child(odd) th, .table-theme-simple-line .mdx-table tr:nth-child(odd) td, .table-theme-simple-line .mdx-table {
    background-color: #ffffff;
    color: #111111;
    text-shadow: none;
}

.table-theme-simple-line .mdx-table tr:nth-child(even), .table-theme-simple-line .mdx-table tr:nth-child(even) th, .table-theme-simple-line .mdx-table tr:nth-child(even) td, .table-theme-simple-line .mdx-table {
    color: #111111;
    text-shadow: none;
}

.table-theme-simple-line .mdx-table .cell-yellow, .table-theme-simple-line .mdx-table .cell-green, .table-theme-simple-line .mdx-table .cell-red, .table-theme-simple-line .mdx-table thead tr th, .table-theme-simple-line .mdx-table thead {
    color: #111111 !important;
}

/*****************  thema simple-line end ****************/


/**************** css themes general ********************/

/*
.fixed-columns{
    width: calc(100% - 10px);
    border-right: none !important;
}

.fixed-header-cells-container{
    opacity: 0;
}
*/

.publisher-or-mobile .headersArea {
    width: auto !important;
}

.publisher-or-mobile table.mdx-table {
    max-width: 0;
}

.publisher-or-mobile .clusterize-scroll::-webkit-scrollbar {
    background-color: #fff !important;
}

.fixed-columns .mdx-table thead tr .corner-heading.blank {
    visibility: visible;
}

.fixed-columns .mdx-table thead tr .heading-heading.measure {
    visibility: visible;
}


.fixed-columns .mdx-table thead tr .blank, .fixed-columns .mdx-table thead tr .measure, .fixed-columns .mdx-table thead tr .column-heading-even, .fixed-columns .mdx-table thead tr .column-heading-odd {
    visibility: hidden;
}

/* 100% table width into dashboard */
.dashboarditem .mdx-table {
    width: 100%;
}

.dashboarditem .table-theme-modern-5 .mdx-table {
    width: calc(100% - 1px);
}

.table-theme-blue .mdx-table thead tr:first-child, .table-theme-rounded .mdx-table thead tr:first-child, .table-theme-red .mdx-table thead tr:first-child, .table-theme-green .mdx-table thead tr:first-child, .table-theme-dark .mdx-table thead tr:first-child, .table-theme-simple-line .mdx-table thead tr:first-child, .table-theme-clean .mdx-table thead tr:first-child {
    color: #ffffff !important;
    border-width: 0 !important;
    visibility: inherit;
    height: 0px !important;
    line-height: 0px !important;
    font-size: 0px !important;
    border-right: 1px solid #FFFFFF;
}


.table-theme-dark .mdx-table tbody tr td, .table-theme-dark .mdx-table tbody tr th {
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
}

.table-theme-dark .mdx-table .cell-yellow, .table-theme-dark .mdx-table .cell-green, .table-theme-dark .mdx-table .cell-red, .table-theme-dark .mdx-table thead tr th {
    color: #ffffff !important;
}

.table-theme-simple-line .mdx-table tbody tr td, .simple-line .mdx-table tbody tr th {
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
}

.table-theme-simple-line .mdx-table .cell-yellow, .table-theme-simple-line .mdx-table .cell-green, .table-theme-simple-line .mdx-table .cell-red {
    color: #ffffff !important;
}

.table-theme-blue thead .row-total-col.blank {
    background: #2980b9 !important;
}

.table-theme-red thead .row-total-col.blank {
    background: #d32f2f !important;
}

.table-theme-green thead .row-total-col.blank {
    background: #388e3c !important;
}

.table-theme-dark thead .row-total-col.blank {
    background: #404040 !important;
}

.table-theme-simple-line thead .row-total-col.blank {
    background: #ffffff !important;
}

.table-theme-branco-azul .mdx-table tr:nth-child(odd), .table-theme-branco-azul .mdx-table tr:nth-child(odd) th,
.table-theme-branco-azul .mdx-table tr:nth-child(odd) td, .branco-azul .mdx-table tr:nth-child(odd), .branco-azul .mdx-table tr:nth-child(odd) th,
.branco-azul .mdx-table tr:nth-child(odd) td {
    backgound: #ffffff !important;
}

.table-theme-branco-azul .mdx-table tr:nth-child(even), .table-theme-branco-azul .mdx-table tr:nth-child(even) th,
.table-theme-branco-azul .mdx-table tr:nth-child(even) td, .branco-azul .mdx-table tr:nth-child(even), .branco-azul .mdx-table tr:nth-child(even) th,
.branco-azul .mdx-table tr:nth-child(even) td {
    backgound: #eeeeee !important;
}

/******************* geral ******************/

.table-theme-blue .mdx-table tr:nth-child(odd), .table-theme-blue .mdx-table tr:nth-child(odd) th, .table-theme-blue .mdx-table tr:nth-child(odd) td,
.table-theme-red .mdx-table tr:nth-child(odd), .table-theme-red .mdx-table tr:nth-child(odd) th, .table-theme-red .mdx-table tr:nth-child(odd) td,
.table-theme-dark .mdx-table tr:nth-child(odd), .table-theme-dark .mdx-table tr:nth-child(odd) th, .table-theme-dark .mdx-table tr:nth-child(odd) td,
.table-theme-green .mdx-table tr:nth-child(odd), .table-theme-green .mdx-table tr:nth-child(odd) th, .table-theme-green .mdx-table tr:nth-child(odd) td,
.table-theme-simple-line .mdx-table tr:nth-child(odd), .table-theme-simple-line .mdx-table tr:nth-child(odd) th, .table-theme-simple-line .mdx-table tr:nth-child(odd) td {
    background-color: #FFFFFF;
    border: none;
    border-width: 0 !important;
    border-top: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    color: #333333;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5em;
    height: 1.5em;
}

.table-theme-blue .mdx-table tr:nth-child(even), .table-theme-blue .mdx-table tr:nth-child(even) th, .table-theme-blue .mdx-table tr:nth-child(even) td,
.table-theme-green .mdx-table tr:nth-child(even), .table-theme-green .mdx-table tr:nth-child(even) th, .table-theme-green .mdx-table tr:nth-child(even) td,
.table-theme-dark .mdx-table tr:nth-child(even), .table-theme-dark .mdx-table tr:nth-child(even) th, .table-theme-dark .mdx-table tr:nth-child(even) td,
.table-theme-red .mdx-table tr:nth-child(even), .table-theme-red .mdx-table tr:nth-child(even) th, .table-theme-red .mdx-table tr:nth-child(even) td,
.table-theme-simple-line .mdx-table tr:nth-child(even), .table-theme-simple-line .mdx-table tr:nth-child(even) th, .table-theme-simple-line .mdx-table tr:nth-child(even) td {
    background-color: #e8e8e8;
    border: none;
    border-width: 0 !important;
    border-top: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    color: #333333;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5em;
    height: 1.5em;
}

.table-theme-blue .mdx-table thead,
.table-theme-green .mdx-table thead,
.table-theme-dark .mdx-table thead,
.table-theme-red .mdx-table thead,
.table-theme-silver .mdx-table thead,
.table-theme-simple-line .mdx-table thead {
    border-bottom: 1px solid #CCCCCC;
}

.table-theme-blue .mdx-table td.cell-even, .table-theme-blue .mdx-table td.cell-odd,
.table-theme-green .mdx-table td.cell-even, .table-theme-green .mdx-table td.cell-odd,
.table-theme-dark .mdx-table td.cell-even, .table-theme-dark .mdx-table td.cell-odd,
.table-theme-red .mdx-table td.cell-even, .table-theme-red .mdx-table td.cell-odd,
.table-theme-silver .mdx-table td.cell-even, .table-theme-silver .mdx-table td.cell-odd,
.table-theme-simple-line .mdx-table td.cell-even, .table-theme-simple-line .mdx-table td.cell-odd {
    margin-left: 20px;
}

.table-theme-blue .mdx-table .row-heading-even, .table-theme-blue .mdx-table .row-heading-odd, .table-theme-blue .mdx-table .column-heading-even, .table-theme-blue .mdx-table .column-heading-odd,
.table-theme-green .mdx-table .row-heading-even, .table-theme-green .mdx-table .row-heading-odd, .table-theme-green .mdx-table .column-heading-even, .table-theme-green .mdx-table .column-heading-odd,
.table-theme-dark .mdx-table .row-heading-even, .table-theme-dark .mdx-table .row-heading-odd, .table-theme-dark .mdx-table .column-heading-even, .table-theme-dark .mdx-table .column-heading-odd,
.table-theme-red .mdx-table .row-heading-even, .table-theme-red .mdx-table .row-heading-odd, .table-theme-red .mdx-table .column-heading-even, .table-theme-red .mdx-table .column-heading-odd,
.table-theme-silver .mdx-table .row-heading-even, .table-theme-silver .mdx-table .row-heading-odd, .table-theme-silver .mdx-table .column-heading-even, .table-theme-red .mdx-table .column-heading-odd,
.table-theme-simple-line .mdx-table .row-heading-even, .table-theme-simple-line .mdx-table .row-heading-odd, .table-theme-simple-line .mdx-table .column-heading-even, .table-theme-simple-line .mdx-table .column-heading-odd {
    font-weight: bolder;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
}

.table-theme-blue .mdx-table .cell-red,
.table-theme-green .mdx-table .cell-red,
.table-theme-dark .mdx-table .cell-red,
.table-theme-red .mdx-table .cell-red,
.table-theme-silver .mdx-table .cell-red,
.table-theme-simple-line .mdx-table .cell-red {
    background-color: #d32f2f !important;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    padding: 0 6px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #b3b3b3;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
    line-height: 20px;
    margin-bottom: 0;
    padding: 4px 12px;
    text-align: center;
    vertical-align: middle;
    transition: all 0.15s ease 0s;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    margin-bottom: 5px;
    font-weight: bold;
}

.table-theme-blue .mdx-table .cell-green,
.table-theme-green .mdx-table .cell-green,
.table-theme-dark .mdx-table .cell-green,
.table-theme-red .mdx-table .cell-green,
.table-theme-silver .mdx-table .cell-green,
.table-theme-simple-line .mdx-table .cell-green {
    background-color: #388e3c !important;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    padding: 0 6px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #b3b3b3;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
    line-height: 20px;
    margin-bottom: 0;
    padding: 4px 12px;
    text-align: center;
    vertical-align: middle;
    transition: all 0.15s ease 0s;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    margin-bottom: 5px;
    font-weight: bold;
}

.table-theme-blue .mdx-table .cell-yellow,
.table-theme-green .mdx-table .cell-yellow,
.table-theme-dark .mdx-table .cell-yellow,
.table-theme-red .mdx-table .cell-yellow,
.table-theme-silver .mdx-table .cell-yellow,
.table-theme-simple-line .mdx-table .cell-yellow {
    background-color: #ffa000 !important;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    padding: 0 6px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #b3b3b3;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
    line-height: 20px;
    margin-bottom: 0;
    padding: 4px 12px;
    text-align: center;
    vertical-align: middle;
    transition: all 0.15s ease 0s;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    margin-bottom: 5px;
    font-weight: bold;
}

.table-theme-blue .mdx-table .cell-yellow, .table-theme-blue .mdx-table .cell-green, .table-theme-blue .mdx-table .cell-red, .table-theme-blue .mdx-table thead tr th,
.table-theme-green .mdx-table .cell-yellow, .table-theme-green .mdx-table .cell-green, .table-theme-green .mdx-table .cell-red, .table-theme-green .mdx-table thead tr th,
.table-theme-dark .mdx-table .cell-yellow, .table-theme-dark .mdx-table .cell-green, .table-theme-dark .mdx-table .cell-red, .table-theme-dark .mdx-table thead tr th,
.table-theme-red .mdx-table .cell-yellow, .table-theme-red .mdx-table .cell-green, .table-theme-red .mdx-table .cell-red, .table-theme-red .mdx-table thead tr th,
.table-theme-simple-line .mdx-table .cell-yellow, .table-theme-simple-line .mdx-table .cell-green, .table-theme-simple-line .mdx-table .cell-red {
    color: #ffffff !important;
    border-right: 1px solid #FFFFFF;
}

.table-theme-blue .mdx-table thead tr th,
.table-theme-green .mdx-table thead tr th,
.table-theme-dark .mdx-table thead tr th,
.table-theme-red .mdx-table thead tr th {
    height: 30px;
    line-height: 30px;
}

.table-theme-blue .mdx-table thead tr th,
.table-theme-green .mdx-table thead tr th,
.table-theme-dark .mdx-table thead tr th,
.table-theme-red .mdx-table thead tr th {
    color: #fff !important;
    border-left: 1px solid #fff;
    border-width: 1px !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    border-right: 1px solid #FFFFFF;
}

.table-theme-blue .mdx-table tbody tr td, .table-theme-blue .mdx-table tbody tr th,
.table-theme-green .mdx-table tbody tr td, .table-theme-green .mdx-table tbody tr th,
.table-theme-dark .mdx-table tbody tr td, .table-theme-dark .mdx-table tbody tr th,
.table-theme-red .mdx-table tbody tr td, .table-theme-red .mdx-table tbody tr th,
.table-theme-silver .mdx-table tbody tr td, .table-theme-silver .mdx-table tbody tr th,
.table-theme-simple-line .mdx-table tbody tr td, .table-theme-simple-line .mdx-table tbody tr th {
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

.table-theme-blue .mdx-table .cell-yellow, .table-theme-blue .mdx-table .cell-green, .table-theme-blue .mdx-table .cell-red, .table-theme-blue .mdx-table thead tr th,
.table-theme-green .mdx-table .cell-yellow, .table-theme-green .mdx-table .cell-green, .table-theme-green .mdx-table .cell-red, .table-theme-green .mdx-table thead tr th,
.table-theme-dark .mdx-table .cell-yellow, .table-theme-dark .mdx-table .cell-green, .table-theme-dark .mdx-table .cell-red, .table-theme-dark .mdx-table thead tr th,
.table-theme-red .mdx-table .cell-yellow, .table-theme-red .mdx-table .cell-green, .table-theme-red .mdx-table .cell-red, .table-theme-red .mdx-table thead tr th,
.table-theme-simple-line .mdx-table .cell-yellow, .table-theme-simple-line .mdx-table .cell-green, .table-theme-simple-line .mdx-table .cell-red {
    color: #ffffff !important;
    border-right: 1px solid #FFFFFF;
}

.table-theme-clean .mdx-table tr:nth-child(odd), .table-theme-clean .mdx-table tr:nth-child(odd) th, .table-theme-clean .mdx-table tr:nth-child(odd) td {
    color: #111111;
}

.table-theme-clean .mdx-table tr:nth-child(even), .table-theme-clean .mdx-table tr:nth-child(even) th, .table-theme-clean .mdx-table tr:nth-child(even) td {
    color: #111111;
    background: #ffffff;
}

.table-theme-modern-1 .mdx-table-border-none table, .table-theme-modern-1 .mdx-table-border-none table tr, .table-theme-modern-1 .mdx-table-border-none table tr td, .table-theme-modern-1 .mdx-table-border-none table tr th,
.table-theme-modern-2 .mdx-table-border-none table, .table-theme-modern-2 .mdx-table-border-none table tr, .table-theme-modern-2 .mdx-table-border-none table tr td, .table-theme-modern-2 .mdx-table-border-none table tr th,
.table-theme-modern-3 .mdx-table-border-none table, .table-theme-modern-3 .mdx-table-border-none table tr, .table-theme-modern-3 .mdx-table-border-none table tr td, .table-theme-modern-3 .mdx-table-border-none table tr th,
.table-theme-modern-4 .mdx-table-border-none table, .table-theme-modern-4 .mdx-table-border-none table tr, .table-theme-modern-4 .mdx-table-border-none table tr td, .table-theme-modern-4 .mdx-table-border-none table tr th,
.table-theme-modern-5 .mdx-table-border-none table, .table-theme-modern-5 .mdx-table-border-none table tr, .table-theme-modern-5 .mdx-table-border-none table tr td, .table-theme-modern-5 .mdx-table-border-none table tr th,
.table-theme-modern-6 .mdx-table-border-none table, .table-theme-modern-6 .mdx-table-border-none table tr, .table-theme-modern-6 .mdx-table-border-none table tr td, .table-theme-modern-6 .mdx-table-border-none table tr th,
.table-theme-modern-7 .mdx-table-border-none table, .table-theme-modern-7 .mdx-table-border-none table tr, .table-theme-modern-7 .mdx-table-border-none table tr td, .table-theme-modern-7 .mdx-table-border-none table tr th,
.mdx-table-border-none table.mdx-table.initial {
    border: none;
}

table.mdx-table.initial {
    border: none;
    background: transparent;
}

.table-theme-modern-1 .pivot-table div.fixed-columns,
.table-theme-modern-2 .pivot-table div.fixed-columns,
.table-theme-modern-3 .pivot-table div.fixed-columns,
.table-theme-modern-4 .pivot-table div.fixed-columns,
.table-theme-modern-5 .pivot-table div.fixed-columns,
.table-theme-modern-6 .pivot-table div.fixed-columns,
.table-theme-modern-7 .pivot-table div.fixed-columns {
    border-right: none;
}

.mdx-table .table-image-container {
    margin-left: 2px;
    opacity: 1 !important;
    display: inline-block;
}

table.mdx-table img {
    opacity: 1 !important;
}

.BngAppContent .DashGrid table.mdx-table img {
    vertical-align: top;
}

/******************* theme modern 1 ******************/

.table-theme-modern-1 table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
}

.table-theme-modern-1 th,
.table-theme-modern-1 td {
    border: 0;
    color: #C1C1C2;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-modern-1 .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-modern-1 .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-modern-1 .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #315CA0 !important;
}

.table-theme-modern-1 .mdx-table tbody tr.highlight-row th, .table-theme-modern-1 .mdx-table tbody tr.highlight-row td, .table-theme-modern-1 .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #1E498C !important;
}

.table-theme-modern-1 thead .row-total-col.blank {
    border-bottom: 1px solid #161c2a !important;
    background-color: #333333 !important;
}

.table-theme-modern-1 .row-total-col, .table-theme-modern-1 .col-total-row td {
    background: #323232 !important;
    color: #ffffff !important;
}

.table-theme-modern-1 thead tr th, .table-theme-modern-1 thead tr td {
    background-color: #333333 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-1 thead tr:nth-child(odd) th, .table-theme-modern-1 thead tr:nth-child(odd) td {
    background-color: #222222 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-1 thead tr:last-child th {
    border-bottom: 1px solid #161c2a !important;
}

.table-theme-modern-1 tbody th div {
    color: #C1C1C2 !important;
}

.table-theme-modern-1 tbody th {
    background-color: #666666;
    border-bottom-width: 1px !important;
    border-color: #3C3C3C !important;
}

.table-theme-modern-1 tbody tr:nth-child(odd) th {
    background-color: #4C4C4C !important;
}

.table-theme-modern-1 tbody th.row-heading-even {
    background-color: #4C4C4C !important;
}

.table-theme-modern-1 tbody th.row-heading-odd {
    background-color: #444444;
}

.table-theme-modern-1 tbody tr:nth-child(odd) .cell-red {
    background-color: #8B5353 !important;
    color: #ffffff !important;
}

.table-theme-modern-1 .cell-red {
    background-color: #794E4E !important;
    color: #ffffff !important;
}

.table-theme-modern-1 tbody tr:nth-child(odd) .cell-green {
    background-color: #63874A !important;
    color: #ffffff !important;
}

.table-theme-modern-1 .cell-green {
    background-color: #5A7648 !important;
    color: #ffffff !important;
}

.table-theme-modern-1 tbody tr:nth-child(odd) .cell-yellow {
    background-color: #A19445 !important;
    color: #ffffff !important;
}

.table-theme-modern-1 .cell-yellow {
    background-color: #A27F33 !important;
    color: #ffffff !important;
}

.table-theme-modern-1 .cell-even {
    background-color: #777777;
    color: #FFFFFF;
}

.table-theme-modern-1 .cell-odd {
    background-color: #666666;
    color: #EAEAEA;
}

.table-theme-modern-1 th, .table-theme-modern-1 td {
    border-bottom-width: 1px !important;
    border-color: rgba(255, 255, 255, .05) !important;
}

.table-theme-modern-1 tbody th {
    border-right-width: 1px !important;
    border-right-color: rgba(255, 255, 255, .1) !important;

    border-bottom-width: 1px !important;
    border-bottom-color: rgba(0, 0, 0, .3) !important;
}

.table-theme-modern-1 tbody tr td {
    border-bottom: 1px solid rgba(0, 0, 0, .3) !important;
    border-right: 1px solid rgba(0, 0, 0, .1) !important;
}

/******************* end theme modern 1 ******************/

/******************* theme modern 2 ******************/

.table-theme-modern-2 table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
}

.table-theme-modern-2 th,
.table-theme-modern-2 td {
    border: 0;
    color: #000000;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-silver .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    color: #333333 !important;
}

.table-theme-modern-2 .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-modern-2 .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-modern-2 .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #D36A49 !important;
}

.table-theme-modern-2 .mdx-table tbody tr.highlight-row th, .table-theme-modern-2 .mdx-table tbody tr.highlight-row td, .table-theme-modern-2 .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #B44624 !important;
}

.table-theme-modern-2 thead .row-total-col.blank {
    background: #666666 !important;
    border-bottom: 2px solid #444444 !important;
}

.table-theme-modern-2 .row-total-col, .table-theme-modern-2 .col-total-row td {
    background: #777777 !important;
    color: #ffffff !important;
}

.table-theme-modern-2 thead tr .heading-heading {
    background-color: #555555 !important;
}

.table-theme-modern-2 thead tr .corner-heading {
    background-color: #666666 !important;
}

.table-theme-modern-2 thead tr th, .table-theme-modern-2 thead tr td {
    background-color: #666666 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-2 thead tr:nth-child(odd) th, .table-theme-modern-2 thead tr:nth-child(odd) td {
    background-color: #555555 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-2 thead tr:last-child th {
    border-bottom: 2px solid #444444 !important;
}

.table-theme-modern-2 tbody th div {
    color: #C1C1C2 !important;
}

.table-theme-modern-2 .cell-even {
    color: #000000
}

.table-theme-modern-2 .cell-odd {
    color: #000000
}

.table-theme-modern-2 tbody th {
    background-color: #666666;
    border-bottom-width: 1px !important;
    border-bottom-color: rgba(255, 255, 255, .05) !important;
}

.table-theme-modern-2 tbody th.row-heading-even {
    background-color: #777777;
}

.table-theme-modern-2 tbody th.row-heading-odd {
    background-color: #666666;
}

.table-theme-modern-2 .cell-red {
    background-color: #DAA5A5 !important;
}

.table-theme-modern-2 tbody tr:nth-child(odd) .cell-red {
    background-color: #D19D9D !important;
}

.table-theme-modern-2 .cell-green {
    background-color: #C5E2B7 !important;
}

.table-theme-modern-2 tbody tr:nth-child(odd) .cell-green {
    background-color: #BCD9AE !important;
}

.table-theme-modern-2 .cell-yellow {
    background-color: #EEDD7A !important;
}

.table-theme-modern-2 tbody tr:nth-child(odd) .cell-yellow {
    background-color: #E6D471 !important;
}

.table-theme-modern-2 th, .table-theme-modern-2 td {
    border-width: 1px !important;
    border-color: rgba(255, 255, 255, .05) !important;
}

/******************* end theme modern 2 ******************/

/******************* theme modern 3 ******************/

.table-theme-modern-3 table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
}

.table-theme-modern-3 th,
.table-theme-modern-3 td {
    border: 0;
    color: #ccc;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-modern-3 .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-modern-3 .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-modern-3 .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #333D7F !important;
}

.table-theme-modern-3 .mdx-table tbody tr.highlight-row th, .table-theme-modern-3 .mdx-table tbody tr.highlight-row td, .table-theme-modern-3 .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #09134F !important;
}

.table-theme-modern-3 thead .row-total-col.blank {
    background: #666666 !important;
    border-bottom: 2px solid #444444 !important;
}

.table-theme-modern-3 .row-total-col, .table-theme-modern-3 .col-total-row {
    background: #666666 !important;
}

.table-theme-modern-3 thead tr .heading-heading {
    background-color: #555555 !important;
}

.table-theme-modern-3 thead tr .corner-heading {
    background-color: #666666 !important;
}

.table-theme-modern-3 thead tr th, .table-theme-modern-3 thead tr td {
    background-color: #666666 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-3 thead tr:nth-child(odd) th, .table-theme-modern-3 thead tr:nth-child(odd) td {
    background-color: #555555 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-3 thead tr:last-child th {
    border-bottom: 2px solid #444444 !important;
}

.table-theme-modern-3 tbody tr td {
    border-bottom-color: rgba(0, 0, 0, .3) !important;
    border-bottom-width: 1px !important;
    border-top-color: rgba(255, 255, 255, .1) !important;
    border-top-width: 1px !important;
}

.table-theme-modern-3 .cell-even {
    background-color: #333;
}

.table-theme-modern-3 .cell-odd {
    background-color: #222;
}

.table-theme-modern-3 tbody th div {
    color: #C1C1C2 !important;
}

.table-theme-modern-3 tbody th {
    background-color: #777777;
}

.table-theme-modern-3 tbody th.row-heading-even {
    background-color: #555555;
}

.table-theme-modern-3 tbody th.row-heading-odd {
    background-color: #666666;
}

.table-theme-modern-3 .cell-red {
    background-color: #6D2222 !important;
}

.table-theme-modern-3 tbody tr:nth-child(odd) .cell-red {
    background-color: #702828 !important;
}

.table-theme-modern-3 .cell-green {
    background-color: #3D6C1F !important;
}

.table-theme-modern-3 tbody tr:nth-child(odd) .cell-green {
    background-color: #366417 !important;
}

.table-theme-modern-3 .cell-yellow {
    background-color: #8C7B18 !important;
}

.table-theme-modern-3 tbody tr:nth-child(odd) .cell-yellow {
    background-color: #857310 !important;
}


.table-theme-modern-3 th, .table-theme-modern-3 td {
    border: 1px solid rgba(255, 255, 255, .05) !important;
}

.table-theme-modern-3 th, .table-theme-modern-3 td {
    border: 1px solid rgba(255, 255, 255, .05) !important;
}

.table-theme-modern-3 tbody th {
    border-right: rgba(255, 255, 255, .1) !important;
    border-width: 1px !important;
}

.table-theme-modern-3 tbody tr td {
    border-bottom-color: rgba(0, 0, 0, .3) !important;
    border-bottom-width: 1px !important;
    border-right-color: rgba(0, 0, 0, .1) !important;
    border-right-width: 1px !important;
}

/******************* end theme modern 3 ******************/

/******************* theme modern 4 ******************/

.table-theme-modern-4 table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
}

.table-theme-modern-4 th,
.table-theme-modern-4 td {
    border: 0;
    color: #ccc;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-modern-4 .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-modern-4 .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-modern-4 .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #793FE6 !important;
}

.table-theme-modern-4 .mdx-table tbody tr.highlight-row th, .table-theme-modern-4 .mdx-table tbody tr.highlight-row td, .table-theme-modern-4 .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #5117BE !important;
}

.table-theme-modern-4 .row-total-col, .table-theme-modern-4 .col-total-row {
    background: #1f2739 !important;
}

.table-theme-modern-4 thead tr .heading-heading {
    background-color: #161c2a !important;
}

.table-theme-modern-4 thead tr .corner-heading {
    background-color: #1f2739 !important;
}

.table-theme-modern-4 thead tr th, .table-theme-modern-4 thead tr td {
    background-color: #1f2739 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-4 thead tr:nth-child(odd) th, .table-theme-modern-4 thead tr:nth-child(odd) td {
    background-color: #1f2739 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-4 thead tr:last-child th, .table-theme-modern-4 thead tr:last-child td {
    border-bottom: 1px solid #161c2a !important;
}

.table-theme-modern-4 tbody tr td {
    border-bottom-color: rgba(0, 0, 0, .3) !important;
    border-bottom-width: 1px !important;
    border-top-color: rgba(255, 255, 255, .1) !important;
    border-top-width: 1px !important;
}

.table-theme-modern-4 .cell-even {
    background-color: #2c3446;
}

.table-theme-modern-4 .cell-odd {
    background-color: #323c50;
}

.table-theme-modern-4 tbody th div {
    color: #C1C1C2 !important;
}

.table-theme-modern-3 tbody th {
    background-color: #777777;
}

.table-theme-modern-4 tbody th.row-heading-even {
    background-color: #242b3c;
}

.table-theme-modern-4 tbody th.row-heading-odd {
    background-color: #2c3446;
}

.table-theme-modern-4 tbody th div {
    color: #C1C1C2 !important;
}

.table-theme-modern-4 tbody th {
    background-color: #2c3446 !important;
    border-bottom-color: rgba(0, 0, 0, .3) !important;
    border-bottom-width: 1px !important;
}

.table-theme-modern-4 tbody th.row-heading-even {
    background-color: #242b3c !important;
}

.table-theme-modern-4 tbody tr:nth-child(odd) .cell-red {
    background-color: #67323E !important;
    font-weight: bolder;
}

.table-theme-modern-4 .cell-red {
    background-color: #63303C !important;
    font-weight: bolder;
}

.table-theme-modern-4 tbody tr:nth-child(odd) .cell-green {
    background-color: #345533 !important;
    font-weight: bolder;
}

.table-theme-modern-4 .cell-green {
    background-color: #3B6533 !important;
    font-weight: bolder;
}

.table-theme-modern-4 tbody tr:nth-child(odd) .cell-yellow {
    background-color: #8C7B18 !important;
    font-weight: bolder;
}

.table-theme-modern-4 .cell-yellow {
    background-color: #686436 !important;
    font-weight: bolder;
}

.table-theme-modern-4 th, .table-theme-modern-4 td {
    border-width: 1px !important;
    border-color: rgba(255, 255, 255, .05) !important;
}

.table-theme-modern-4 th, .table-theme-modern-4 td {
    border-width: 1px !important;
    border-color: rgba(255, 255, 255, .05) !important;
}

.table-theme-modern-4 tbody th {
    border-right-color: rgba(255, 255, 255, .1) !important;
    border-right-width: 1px !important;
}

.table-theme-modern-4 tbody tr td {
    border-bottom-color: rgba(0, 0, 0, .3) !important;
    border-bottom-width: 1px !important;
    border-right-color: rgba(0, 0, 0, .1) !important;
    border-right-width: 1px !important;
}

.table-theme-modern-4 tbody tr th {
    border-right-color: rgba(0, 0, 0, .3) !important;
    border-right-width: 1px !important;
}

.table-theme-modern-4 tbody th {
    border-bottom-color: rgba(0, 0, 0, .3) !important;
    border-bottom-width: 1px !important;
}

/******************* end theme modern 4 ******************/


/******************* theme modern 5 ******************/

.table-theme-modern-5 table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
    background-color: #ffffff;
}

.table-theme-modern-5 th,
.table-theme-modern-5 td {
    border: 0;
    color: #333;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-modern-5 .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-modern-5 .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-modern-5 .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #4F7FF2 !important;
}

.table-theme-modern-5 .mdx-table tbody tr.highlight-row th, .table-theme-modern-5 .mdx-table tbody tr.highlight-row td, .table-theme-modern-5 .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #2657CB !important;
    color: White;
}

.table-theme-modern-5 thead .row-total-col.blank {
    background: #434343 !important;
    border-bottom: 2px solid #444444 !important;
}

.table-theme-modern-5 .row-total-col, .table-theme-modern-5 .col-total-row {
    background: #dddddd !important;
}

.table-theme-modern-5 thead tr .heading-heading {
    background-color: #333333 !important;
}

.table-theme-modern-5 thead tr .corner-heading {
    background-color: #434343 !important;
}

.table-theme-modern-5 thead tr th, .table-theme-modern-5 thead tr td {
    background-color: #434343 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-5 thead tr:nth-child(odd) th, .table-theme-modern-5 thead tr:nth-child(odd) td {
    background-color: #555555 !important;
    color: #EAEAEA !important;
}

.table-theme-modern-5 thead tr:last-child th {
    border-bottom: 2px solid #444444 !important;
}

.table-theme-modern-5 tbody th.row-heading-even {
    background-color: #eeeeee;
}

.table-theme-modern-5 tbody th.row-heading-odd {
    background-color: #dddddd;
}

.table-theme-modern-5 tbody tr:nth-child(odd) .cell-red {
    background-color: #E8CBCB !important;
}

.table-theme-modern-5 .cell-red {
    background-color: #D7ACAC !important;
}

.table-theme-modern-5 tbody tr:nth-child(odd) .cell-green {
    background-color: #D2E6C6 !important;
}

.table-theme-modern-5 .cell-green {
    background-color: #B8D4A6 !important;
}

.table-theme-modern-5 tbody tr:nth-child(odd) .cell-yellow {
    background-color: #F1E7AB !important;
}

.table-theme-modern-5 .cell-yellow {
    background-color: #E9DB8C !important;
}


.table-theme-modern-5 th, .table-theme-modern-5 td {
    border-width: 1px !important;
    border-color: #444444 !important;
}

/******************* end theme modern 5 ******************/


/******************* theme modern 6 ******************/

.table-theme-modern-6 table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
}

.table-theme-modern-6 th,
.table-theme-modern-6 td {
    border: 0;
    color: rgba(0, 0, 0, .8);
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-modern-6 .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-modern-6 .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-modern-6 .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #4F7FF2 !important;
    color: White;
}

.table-theme-modern-6 .mdx-table tbody tr.highlight-row th, .table-theme-modern-6 .mdx-table tbody tr.highlight-row td, .table-theme-modern-6 .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #2657CA !important;
    color: #ffffff;
}

.table-theme-modern-6 table.mdx-table thead tr td.row-total-col.blank {
    background-color: #39cdb5 !important;
    border-bottom: 1px solid #cccccc !important;
}

.table-theme-modern-6 .row-total-col, .table-theme-modern-6 .col-total-row {
    background: #eeeeee !important;
}

.table-theme-modern-6 thead tr .heading-heading {
    background-color: #26324b !important;
}

.table-theme-modern-6 thead tr .corner-heading {
    background-color: #39cdb5 !important;
}

.table-theme-modern-6 thead tr th, .table-theme-modern-6 thead tr td {
    background-color: #39cdb5 !important;
    color: #EAEAEA !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.table-theme-modern-6 thead tr:nth-child(odd) th, .table-theme-modern-6 thead tr:nth-child(odd) th {
    background-color: #26324b !important;
    color: #EAEAEA !important;
}

.table-theme-modern-6 thead tr:last-child th {
    border-bottom: 1px solid #cccccc !important;
}

.table-theme-modern-6 td .cell-odd {
    background-color: #2C2E30;
}

.table-theme-modern-6 tbody th {
    background-color: #eeeeee;
    border-bottom-width: 1px !important;
    border-bottom-color: rgba(0, 0, 0, .1) !important;
}

.table-theme-modern-6 tbody tr:nth-child(odd) .cell-red {
    background-color: #E8CBCB !important;
    font-weight: bolder;
}

.table-theme-modern-6 .cell-red {
    background-color: #D7ACAC !important;
    font-weight: bolder;
}

.table-theme-modern-6 tbody tr:nth-child(odd) .cell-green {
    background-color: #D2E6C6 !important;
    font-weight: bolder;
}

.table-theme-modern-6 .cell-green {
    background-color: #B8D4A6 !important;
    font-weight: bolder;
}

.table-theme-modern-6 tbody tr:nth-child(odd) .cell-yellow {
    background-color: #F1E7AB !important;
    font-weight: bolder;
}

.table-theme-modern-6 .cell-yellow {
    background-color: #E9DB8C !important;
    font-weight: bolder;
}

.table-theme-modern-6 th, .table-theme-modern-6 td {
    border-width: 1px !important;
    border-color: rgba(255, 255, 255, .05) !important;
    border-bottom-color: rgba(0, 0, 0, .1) !important;
}

/******************* end theme modern 6 ******************/

/******************* theme modern 7 ******************/

.table-theme-modern-7 table {
    display: table;
    border-collapse: collapse;
    border-color: grey;
    background-color: #ffffff;
}

.table-theme-modern-7 th,
.table-theme-modern-7 td {
    border: 0;
    color: #D6D6D6;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
}

.table-theme-modern-7 {
    border: none;
}

.table-theme-modern-7 .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-modern-7 .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-modern-7 .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #DC8923 !important;
}

.table-theme-modern-7 .mdx-table tbody tr.highlight-row th, .table-theme-modern-7 .mdx-table tbody tr.highlight-row td, .table-theme-modern-7 .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #CA8925 !important;
}

.table-theme-modern-7 thead .row-total-col.blank {
    background: #222222 !important;
}

.table-theme-modern-7 .row-total-col, .table-theme-modern-7 .col-total-row {
    background: #943634 !important;
}

.table-theme-modern-7 thead th.corner-heading,
.table-theme-modern-7 thead th.heading-heading {
    background-color: #111;
}

.table-theme-modern-7 thead tr:last-child th:first-child {
    background-color: #222;

}

.table-theme-modern-7 thead tr th, .table-theme-modern-7 thead tr td {
    background-color: #222 !important;
    color: #EAEAEA !important;
    border-width: 0 !important;
}

.table-theme-modern-7 thead tr:last-child th, .table-theme-modern-7 thead tr:last-child td {
    border-bottom: 2px solid #ffffff !important;
}

.table-theme-modern-7 tbody tr, .table-theme-modern-7 tbody tr td {
    background-color: #943634;
}

.table-theme-modern-7 tbody tr:nth-child(even), .table-theme-modern-7 tbody tr:nth-child(even) td {
    background-color: #c0504d;
}

.table-theme-modern-7 tbody tr td {
    border-right-width: 0 !important;
    border-left-width: 0 !important;
    border-bottom-color: rgba(0, 0, 0, .2) !important;
    border-bottom-width: 1px !important;
}

.table-theme-modern-7 tbody th div {
    color: #D6D6D7;
    font-weight: bolder;
}

.table-theme-modern-7 tbody th {
    background-color: #943634;
    border-bottom-color: rgba(0, 0, 0, .1) !important;
    border-bottom-width: 1px !important;
    border-right-color: #ffffff !important;
    border-right-width: 2px !important;
}

.table-theme-modern-7 tbody th.row-heading-even {
    background-color: #943634;
}

.table-theme-modern-7 tbody tr:nth-child(odd) .cell-red {
    background-color: #AB2A29 !important;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.table-theme-modern-7 .cell-red {
    background-color: #B22C2B !important;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.table-theme-modern-7 tbody tr:nth-child(odd) .cell-green {
    background-color: #008D00 !important;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.table-theme-modern-7 .cell-green {
    background-color: #007E00 !important;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.table-theme-modern-7 tbody tr:nth-child(odd) .cell-yellow {
    background-color: #CC9C00 !important;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.table-theme-modern-7 .cell-yellow {
    background-color: #CC8B00 !important;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

/******************* end theme modern 7 ******************/


/****** compatibilidade temas antigos tabela dinamica ****/

.table-theme-branco-azul .mdx-table tr:nth-child(odd), .table-theme-branco-azul .mdx-table tr:nth-child(odd) th, .table-theme-branco-azul .mdx-table tr:nth-child(odd) td, .branco-azul .mdx-table tr:nth-child(odd), .branco-azul .mdx-table tr:nth-child(odd) th, .branco-azul .mdx-table tr:nth-child(odd) td {
    background: #ffffff;
}

.table-theme-branco-azul .mdx-table tr:nth-child(even), .table-theme-branco-azul .mdx-table tr:nth-child(even) th, .table-theme-branco-azul .mdx-table tr:nth-child(even) td, .branco-azul .mdx-table tr:nth-child(even), .branco-azul .mdx-table tr:nth-child(even) th, .branco-azul .mdx-table tr:nth-child(even) td {
    background: #f4f4f4;
}

.table-theme-default .mdx-table tr:nth-child(odd), .table-theme-default .mdx-table tr:nth-child(odd) th, .table-theme-default .mdx-table tr:nth-child(odd) td,
.default .mdx-table tr:nth-child(odd), .default .mdx-table tr:nth-child(odd) th, .default .mdx-table tr:nth-child(odd) td {
    background-color: #EEEEEE;
}

.table-theme-default .mdx-table tr:nth-child(even), .table-theme-default .mdx-table tr:nth-child(even) th, .table-theme-default .mdx-table tr:nth-child(even) td,
.default .mdx-table tr:nth-child(even), .default .mdx-table tr:nth-child(even) th, .default .mdx-table tr:nth-child(even) td {
    background-color: #EFF8EF;
}

.table-theme-branco-verde .mdx-table tr:nth-child(odd), .table-theme-branco-verde .mdx-table tr:nth-child(odd) th, .table-theme-branco-verde .mdx-table tr:nth-child(odd) td,
.branco-verde .mdx-table tr:nth-child(odd), .branco-verde .mdx-table tr:nth-child(odd) th, .branco-verde .mdx-table tr:nth-child(odd) td {
    background-color: #FFFFFF;
}

.table-theme-branco-verde .mdx-table tr:nth-child(even), .table-theme-branco-verde .mdx-table tr:nth-child(even) th, .table-theme-branco-verde .mdx-table tr:nth-child(even) td,
.branco-verde .mdx-table tr:nth-child(even), .branco-verde .mdx-table tr:nth-child(even) th, .branco-verde .mdx-table tr:nth-child(even) td {
    background-color: #C1FFC1;
}

.table-theme-branco-cinza .mdx-table tr:nth-child(odd), .table-theme-branco-cinza .mdx-table tr:nth-child(odd) th, .table-theme-branco-cinza .mdx-table tr:nth-child(odd) td,
.branco-cinza .mdx-table tr:nth-child(odd), .branco-cinza .mdx-table tr:nth-child(odd) th, .branco-cinza .mdx-table tr:nth-child(odd) td {
    background-color: #FFFFFF;
}

.table-theme-branco-cinza .mdx-table tr:nth-child(even), .table-theme-branco-cinza .mdx-table tr:nth-child(even) th, .table-theme-branco-cinza .mdx-table tr:nth-child(even) td,
.branco-cinza .mdx-table tr:nth-child(even), .branco-cinza .mdx-table tr:nth-child(even) th, .branco-cinza .mdx-table tr:nth-child(even) td {
    background-color: #D3D3D3;
}

.table-theme-branco-lavander .mdx-table tr:nth-child(odd), .table-theme-branco-lavander .mdx-table tr:nth-child(odd) th, .table-theme-branco-lavander .mdx-table tr:nth-child(odd) td,
.branco-lavander .mdx-table tr:nth-child(odd), .branco-lavander .mdx-table tr:nth-child(odd) th, .branco-lavander .mdx-table tr:nth-child(odd) td {
    background-color: #FFFFFF;
}

.table-theme-branco-lavander .mdx-table tr:nth-child(even), .table-theme-branco-lavander .mdx-table tr:nth-child(even) th, .table-theme-branco-lavander .mdx-table tr:nth-child(even) td,
.branco-lavander .mdx-table tr:nth-child(even), .branco-lavander .mdx-table tr:nth-child(even) th, .branco-lavander .mdx-table tr:nth-child(even) td {
    background-color: #E6E6FA;
}

.table-theme-default .mdx-table tr:nth-child(odd), .table-theme-default .mdx-table tr:nth-child(odd) th, .table-theme-default .mdx-table tr:nth-child(odd) td,
.default .mdx-table tr:nth-child(odd), .default .mdx-table tr:nth-child(odd) th, .default .mdx-table tr:nth-child(odd) td, .table-theme-default .mdx-table tr:nth-child(even),
.table-theme-default .mdx-table tr:nth-child(even) th, .table-theme-default .mdx-table tr:nth-child(even) td,
.default .mdx-table tr:nth-child(even), .default .mdx-table tr:nth-child(even) th, .default .mdx-table tr:nth-child(even) td, .table-theme-branco-verde .mdx-table tr:nth-child(odd),
.table-theme-branco-verde .mdx-table tr:nth-child(odd) th, .table-theme-branco-verde .mdx-table tr:nth-child(odd) td,
.branco-verde .mdx-table tr:nth-child(odd), .branco-verde .mdx-table tr:nth-child(odd) th, .branco-verde .mdx-table tr:nth-child(odd) td, .table-theme-branco-verde .mdx-table tr:nth-child(even),
.table-theme-branco-verde .mdx-table tr:nth-child(even) th, .table-theme-branco-verde .mdx-table tr:nth-child(even) td,
.branco-verde .mdx-table tr:nth-child(even), .branco-verde .mdx-table tr:nth-child(even) th, .branco-verde .mdx-table tr:nth-child(even) td,
.table-theme-branco-cinza .mdx-table tr:nth-child(odd), .table-theme-branco-cinza .mdx-table tr:nth-child(odd) th, .table-theme-branco-cinza .mdx-table tr:nth-child(odd) td,
.branco-cinza .mdx-table tr:nth-child(odd), .branco-cinza .mdx-table tr:nth-child(odd) th, .branco-cinza .mdx-table tr:nth-child(odd) td, .table-theme-branco-cinza .mdx-table tr:nth-child(even),
.table-theme-branco-cinza .mdx-table tr:nth-child(even) th, .table-theme-branco-cinza .mdx-table tr:nth-child(even) td,
.branco-cinza .mdx-table tr:nth-child(even), .branco-cinza .mdx-table tr:nth-child(even) th, .branco-cinza .mdx-table tr:nth-child(even) td,
.table-theme-branco-lavander .mdx-table tr:nth-child(odd), .table-theme-branco-lavander .mdx-table tr:nth-child(odd) th, .table-theme-branco-lavander .mdx-table tr:nth-child(odd) td,
.branco-lavander .mdx-table tr:nth-child(odd), .branco-lavander .mdx-table tr:nth-child(odd) th, .branco-lavander .mdx-table tr:nth-child(odd) td,
.table-theme-branco-lavander .mdx-table tr:nth-child(even), .table-theme-branco-lavander .mdx-table tr:nth-child(even) th, .table-theme-branco-lavander .mdx-table tr:nth-child(even) td,
.branco-lavander .mdx-table tr:nth-child(even), .branco-lavander .mdx-table tr:nth-child(even) th, .branco-lavander .mdx-table tr:nth-child(even) td {
    border: none;
    border-color: #ccc !important;
    border-width: 1px !important;
    color: #000000;
}

.table-theme-default .mdx-table thead tr, .table-theme-default .mdx-table thead tr th, .table-theme-default .mdx-table thead tr td,
.table-theme-branco-verde .mdx-table thead tr, .table-theme-branco-verde .mdx-table thead tr th, .table-theme-branco-verde .mdx-table thead tr td,
.table-theme-branco-cinza .mdx-table thead tr, .table-theme-branco-cinza .mdx-table thead tr th, .table-theme-branco-cinza .mdx-table thead tr td,
.table-theme-branco-lavander .mdx-table thead tr, .table-theme-branco-lavander .mdx-table thead tr th, .table-theme-branco-lavander .mdx-table thead tr td {
    font-weight: bold !important;
    color: #000000 !important;
}


.table-theme-branco-azul .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-branco-azul .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-branco-azul .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-branco-azul .mdx-table tbody tr.highlight-row th, .table-theme-branco-azul .mdx-table tbody tr.highlight-row td,
.table-theme-default .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-default .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-default .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-default .mdx-table tbody tr.highlight-row th, .table-theme-default .mdx-table tbody tr.highlight-row td,
.table-theme-blue .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-blue .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-blue .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-blue .mdx-table tbody tr.highlight-row th, .table-theme-blue .mdx-table tbody tr.highlight-row td,
.table-theme-green .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-green .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-green .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-green .mdx-table tbody tr.highlight-row th, .table-theme-green .mdx-table tbody tr.highlight-row td,
.table-theme-dark .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-dark .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-dark .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-dark .mdx-table tbody tr.highlight-row th, .table-theme-dark .mdx-table tbody tr.highlight-row td,
.table-theme-red .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-red .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-red .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-red .mdx-table tbody tr.highlight-row th, .table-theme-red .mdx-table tbody tr.highlight-row td {
    background-color: #555555 !important;
    color: #f5f5f5 !important;
}

.table-theme-simple-line .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-simple-line .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-simple-line .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-simple-line .mdx-table tbody tr.highlight-row th, .table-theme-simple-line .mdx-table tbody tr.highlight-row td, .table-theme-simple-line .mdx-table tbody tr.highlight-row th[rowspan="1"],
.table-theme-default .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-default .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-default .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-default .mdx-table tbody tr.highlight-row th, .table-theme-default .mdx-table tbody tr.highlight-row td,
.table-theme-silver .clusterize-scroll .mdx-table tbody tr.highlight-row th, .table-theme-silver .clusterize-scroll .mdx-table tbody tr.highlight-row td, .table-theme-silver .clusterize-scroll .mdx-table tbody tr.highlight-row th[rowspan="1"], .table-theme-silver .mdx-table tbody tr.highlight-row th, .table-theme-silver .mdx-table tbody tr.highlight-row td {
    background-color: #4F7FF2 !important;
    color: #FFFFFF !important;
}

.table-theme-default .mdx-table tbody tr.highlight-row th, .table-theme-default .mdx-table tbody tr.highlight-row td, .table-theme-default .mdx-table tbody tr.highlight-row th[rowspan="1"],
.table-theme-silver .mdx-table tbody tr.highlight-row th, .table-theme-silver .mdx-table tbody tr.highlight-row td, .table-theme-silver .mdx-table tbody tr.highlight-row th[rowspan="1"] {
    background-color: #2657CA !important;
    background-image: -webkit-linear-gradient(top, #2657CA 0, #2657CA 100%) !important;
    background-image: -o-linear-gradient(top, #2657CA 0, #2657CA 100%) !important;
    background-image: linear-gradient(to bottom, #2657CA 0, #2657CA 100%) !important;
    color: #FFFFFF !important;
}