.analysis-table-marker table.mdx-table {
    max-width: 0;
}

.analysis-table-marker table.mdx-table img {
    max-width: none;
}

.mdx-table td, .mdx-table th {
    padding: 2px 5px 2px 5px
}

.slicer-normal {
    font-size: 0.9em;;
}

.slicer-selected {
    background-color: #abe5b5;
    font-size: 0.8em;;
    color: Black;
}

th.corner-heading,
th.heading-heading,
th.column-heading-selected,
th.column-heading-even,
th.column-heading-odd,
th.column-heading-span,
th.column-heading-red,
th.column-heading-green,
th.column-heading-yellow,
th.row-heading-red,
th.row-heading-green,
th.row-heading-yellow,
th.column-heading-rot,
th.column-heading-gruen,
th.column-heading-gelb,
th.row-heading-rot,
th.row-heading-gruen,
th.row-heading-gelb,
th.row-heading-selected,
th.row-heading-even,
th.row-heading-odd,
th.row-heading-span,
th.column-heading-even-right,
th.row-heading-even-right,
th.column-heading-odd-right,
th.row-heading-odd-right,
th.row-heading-span-right,
th.column-heading-span-right,
th.property-heading,
td.property-name {
    font-size: 11px;
    text-align: left;
    vertical-align: top;
    border: solid 1px #ffffff;
}

th.column-heading-even-right,
th.row-heading-even-right,
th.column-heading-odd-right,
th.row-heading-odd-right,
th.column-heading-span-right,
th.row-heading-span-right {
    text-align: right;
    border: solid 1px #ffffff;
}

th.row-heading-selected,
th.column-heading-selected {
    background-color: #abe5b5;
    border: solid 1px #ffffff;
}

th.column-heading-even,
th.column-heading-odd,
th.column-heading-span,
th.corner-heading,
th.row-heading-even,
th.row-heading-span,
th.column-heading-even-right,
th.row-heading-even-right,
th.column-heading-span-right,
th.row-heading-span-right,
th.property-heading {
    background-color: #eeeeee;
    border: solid 1px #ffffff;
}

th.heading-heading,
td.property-name,
th.column-heading-odd-right,
th.row-heading-odd-right,
th.row-heading-odd {
    background-color: #eff8ef;
    border: solid 1px #ffffff;
}

th.row-heading-red,
th.column-heading-red,
th.row-heading-rot,
th.column-heading-rot {
    background-color: #ff6666 !important;
    border: solid 1px #ffffff;
}

th.row-heading-yellow,
th.column-heading-yellow,
th.row-heading-gelb,
th.column-heading-gelb {
    background-color: yellow !important;
    border: solid 1px #ffffff;
}

th.row-heading-green,
th.column-heading-green,
th.row-heading-gruen,
th.column-heading-gruen {
    background-color: #66ff66 !important;
    border: solid 1px #ffffff;
}

td.cell-even,
td.cell-odd,
td.cell-red,
td.cell-yellow,
td.cell-green,
td.cell-rot,
td.cell-gelb,
td.cell-gruen,
td.property-value,
td.property-span,
td.row-total-col,
.col-total-row > td {
    font-size: 11px;
    text-align: right;
    border: solid 1px #ffffff;
}

td.cell-even {
    background-color: #ffffff;
    border: solid 1px #ffffff;
}

td.cell-odd,
td.property-value,
td.property-span {
    background-color: #f4f4f4;
    border: solid 1px #ffffff;
}

td.cell-rot, td.cell-red, span.cell-rot, span.cell-red, .cell-red {
    background-color: #ff6666 !important;
    border: solid 1px #ffffff;
}

td.cell-gelb, td.cell-yellow, span.cell-gelb, span.cell-yellow, .cell-yellow{
    background-color: yellow !important;
    border: solid 1px #ffffff;
}

td.cell-gruen, td.cell-green, span.cell-gruen, span.cell-green, .cell-green{
    background-color: #66ff66 !important;
    border: solid 1px #ffffff;
}

div.table-message {
    font-size: 12pt;
    color: Black;
    font-weight: bold;
    border: solid 1px #ffffff;
}

.popup {
    width: 100%
}

.popup table {
    width: 100%
}

.col-total-row > td {
    font-weight: bold;
}

td.row-total-col,
.col-total-row > td.row-sum-result {
    font-weight: bold;
}

.hide {
    display:none;
}
div.stickyHeader {
    position:fixed;
    _position:absolute;
    top:0;
}