body {
    margin: 0px;
    padding: 0px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 12px;
}

a {
    text-decoration: none !important;
    color: #21618b;
}

a:visited {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:link {
    text-decoration: none;
}

/*
	Wrapper
*/
#wrapper {
    width: 100%;
    padding: 0;
    margin: 0px auto;
}

/*
Body Part
*/
.dashboard-header {
    cursor: move;
}

.popup-body-headertext {
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    color: #000000;
}

.popup-body-normaltext {
    font-size: 11px;
    color: #000000;
}

#field-text {
    font-size: 11px;
    border: 1px solid #cccccc;
    color: #000000;
}

#page-body-content {
    width: 100%;
}

#page-body {
    background-color: #f5f5f5;
    width: 100%;
}

.page-body-left-css {
    position: relative;
    top: 0px;
    left: -8px;
    float: left;
    height: 80%;
    z-index: 100;
    background-color: #f5f5f5;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: -10px;
    padding-top: 0;
    padding-bottom: 10px;
    padding-left: 10px;
}

#page-body-right {
    float: left;
    height: auto;
    width: 100%;
}

.icePnlStk {
    width: 100%;
}

#page-body-right-main {
    width: 100%;
}

#page-body-title-text {
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    text-decoration: none;
    float: left;
    margin-bottom: 12px;
    width: 300px;
    height: 15px;
}

#page-body-title-text-small {
    font-size: 11px;
    font-weight: normal;
    color: #000000;
    text-decoration: none;
}

.page-body-buttons {
    float: right;
    width: 150px;
}

#page-body-inner-block {
    width: 100%;
    float: left;
    vertical-align: top;
}

.page-body-inner-bar {
    background-color: #f5f5f5;
    height: 35px;
    width: 99.5%;
    float: left;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    clear: both;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.page-bar_top {
    background-color: #f5f5f5;
    float: left;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    clear: both;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

form {
    padding: 0;
    margin: 0;
}

.iceGphImg {
    text-decoration: none;
    border: none;
}

.page-body-inner-bar-left-menu {
    float: left;
    margin-left: 10px;
    width: 690px;
}

.page-body-inner-bar-right-menu {
    float: right;
    width: 255px;
}

#page-body-inner-bar-right {
    float: right;
    width: 250px;
    margin-top: 5px;
}

#page-body-tables-chart {
    float: left;
    width: 100%;
}

.hoverStyle {
    opacity: 0.8;
    padding: 2px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.form-table-block {
    background-color: #f5f5f5;
    float: left;
    border: 1px solid #cccccc;
    padding: 10px 20px;
}

.form-left-text {
    text-align: right;
}

.form-right-text {
    text-align: left;
    color: #000000;
}

.page-title {
    font-size: 22px;
    font-weight: bold;
    color: #21618b;
    text-decoration: none;
    margin-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
    border-bottom: 1px solid #cccccc;
}

.subtitle {
    font-weight: bold;
    color: #21618b;
    text-decoration: none;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 50px;
    border-bottom: 1px solid #cccccc;
}

.page-title-dashboard {
    font-size: 22px;
    font-weight: bold;
    color: #21618b;
    text-decoration: none;
    margin-top: 10px;
    margin-left: 15px;
}

.tdNewMap {
    text-align: right;
    line-height: 25px;
    width: 20%;
}

.trNewMap {
    border-bottom: 1px solid #21618b;
    width: 100%;
    line-height: 25px;
}

.configure-dashboard-toplink {
    font-size: 11px;
    font-weight: normal;
    color: #000000;
    margin-top: 0;
    margin-bottom: 7px;
    text-align: right;
}

#dashboardItems-topMargin {
    width: 100%;
    height: 20px;
}

.page-subtitle {
    font-size: 11px;
    font-weight: normal;
    color: #000000;
    text-decoration: none;
    margin-top: 3px;
}

.page-title-big-text {
    font-size: 18px;
    color: #21618b;
    text-decoration: none;
    font-weight: normal;
    padding-bottom: 5px;
}

.page-title-body-big-text {
    font-size: 15px;
    color: #21618b;
    text-decoration: none;
    font-weight: bold;
}

#page-title-small-text a {
    font-size: 12px;
    color: #286ba6;
    text-decoration: none;
    font-weight: normal;
}

#page-title-small-text a:hover {
    color: #21618b;
}

.left-tab-container {
    float: right;
    width: 100%;
    overflow: auto;
    text-align: left;
    border-right: 1px solid #21618b;
    border-bottom: 1px solid #21618b;
    border-left: 1px solid #21618b;
    border-top: 0px none;
    border-radius: 0 0 5px 5px;
}

.right-tab-container {
    width: 100%;
    overflow: auto;
    border-right: 1px solid #21618b;
    border-bottom: 1px solid #21618b;
    border-left: 1px solid #21618b;
    border-top: 0px none;
}

.inner-tab-bars {
    background-color: #f5f5f5;
    width: 99%;
    float: left;
    margin: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.left-tab-container-text {
    font-size: 11px;
    text-align: left;
    vertical-align: middle;
    color: #000000;
    text-decoration: none;
    margin-top: 2px;
    margin-left: 5px;
}

.table-header-background {
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #dddddd 100%);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #cccccc;
    line-height: 20px;
    font-size: 11px;
    font-weight: bold;
    color: #21618b;
}

.table-even-back-colour {
    background-color: #eaeaea;
    font-size: 11px;
    font-weight: normal;
    color: #000000;
    line-height: 20px;
    text-decoration: none;
    border-right: 1px solid #cccccc;
}

.table-odd-back-colour {
    background-color: #f5f5f5;
    font-size: 11px;
    font-weight: normal;
    color: #000000;
    line-height: 20px;
    text-decoration: none;
    border-left: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
}

table#editView {
    background-color: #f5f5f5;
    border: 1px solid #cccccc;
}

/*
Div under page body right Tabs
*/
#page-body-tables-data-container {
    float: left;
    width: 100%;
    overflow: scroll;
}

#page-body-graph-data-container {
    float: left;
    width: 100%;
    overflow: auto;
}

.MidTop {
    height: auto !important;
    font-size: 15px !important;
}

/*
 CSS to control form elements
*/
.form-table-block table {
    color: #21618b;
    text-decoration: none;
    font-size: 12px;
}

.form-table-block td {
    text-decoration: none;
    font-size: 12px;
}

.form-table-block h1 {
    font-size: 15px;
    font-weight: bold;
    color: #21618b;
    text-decoration: none;
    text-align: left;
}

.form-table-block textarea {
    color: #666666;
    text-decoration: none;
    font-size: 12px;
    border: 1px solid #bfbfbf;
    width: 100%;
    background-repeat: repeat-x;
    background-position: bottom;
    background-color: #f5f5f5;
}

.text_area {
    color: #666666;
    text-decoration: none;
    font-size: 12px;
    border: 1px solid #bfbfbf;
    width: 450px;
    height: 150px;
    background-color: #f5f5f5;
    overflow: auto;
}

#page-footer {
    height: 30px;
    width: 100%;
    background-color: #96a58e;
    text-decoration: none;
    text-align: center;
    clear: both;
    padding-top: 10px;
}

#pop-up-box {
    width: 600px;
}

#pop-up-box-header_no_cursor {
    height: 25px;
    width: 600px;
    background-color: #286ba6;
    background-image: linear-gradient(to bottom, #286ba6 0, #194368 100%);
    border-radius: 5px 5px 0 0;
}

#pop-up-box-header {
    height: 25px;
    width: 600px;
    cursor: move;
    background-color: #286ba6;
    background-image: linear-gradient(to bottom, #286ba6 0, #194368 100%);
    border-radius: 5px 5px 0 0;
}

#pop-up-box-header-text {
    font-size: 12px;
    font-weight: bold;
    color: #f5f5f5;
    text-decoration: none;
    text-align: center;
    padding-top: 3px;
    float: left;
    width: 500px;
}

#pop-up-email-send {
    font-size: 11px;
    background: #f5f5f5;
    width: 600px;
    border: 2px solid #21618b;
    border-top: none;
    border-radius: 0 0 5px 5px;
}

#pop-up-analysis-info {
    font-size: 11px;
    background: #f5f5f5;
    width: 600px;
    border: 2px solid #21618b;
}

#pop-up-analysis-info-body {
    font-size: 11px;
    background-color: #f5f5f5;
    width: 100%;
}

#cancel-button {
    text-decoration: none;
    border: none;
    float: right;
    height: 15px;
    width: 20px;
    padding-top: 0px;
    padding-right: 10px;
    margin-top: 0px;
}

#cancel-button .popup-cancel-button {
    display: inline;
    text-decoration: none;
    background-color: transparent;
    background-repeat: no-repeat;
    text-align: center;
    border: none;
    width: 16px;
    height: 16px;
    margin-top: 6px;
}

#button-origin-data {
    border-bottom: 1px solid #21618b !important;
}

.button-origin-data-one {
    border-top: 1px solid #21618b !important;
}

#pop-up-box-body {
    height: 300px;
    width: 100%;
    border: 1px solid #586352;
    background-color: #f5f5f5;
}

#pop-up-box-body-small {
    height: 175px;
    width: 100%;
    border: 1px solid #586352;
    background-color: #f5f5f5;
    font-size: 11px;
    font-weight: normal;
}

#analysis-print {
    height: auto;
    width: 75px;
    float: right;
}

#pop-up-box-body-properties {
    height: 350px;
    width: 96.8%;
    border: 1px solid #586352;
    background-color: #f5f5f5;
    overflow: auto;
    margin: 0px;
    padding: 10px;
}

#pop-up-box-properties {
    height: auto;
    width: 550px;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

#copyright-info {
    font-size: 12px;
    color: #f5f5f5;
}

#copyright-info a {
    color: #f5f5f5;
    font-weight: bold;
    text-decoration: none;
}

#copyright-info a:hover {
    color: #000000;
}

#body-dashboard {
    float: left;
    height: auto;
    width: 97%;
    padding-top: 20px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
}

#body-dashboard-home a img {
    vertical-align: top;
    border: 0px;
}

#body-dashboard-print {
    float: left;
    padding-top: 3px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
    width: 750px;
    height: 960px;
    border: 2px solid #21618b;
    margin-top: 10px;
}

#body-configure-dashboard {
    float: left;
    height: auto;
    width: 97%;
    padding-top: 13px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
}

#body-dashboard-home {
    height: auto;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    position: relative;
}

.drop-down-box {
    width: 200px;
    border: 1px solid #7b7b7b;
    text-decoration: none;
    font-size: 11px;
}

.select_dashboard {
    width: 200px;
    border: 1px solid #ffae00;
    text-decoration: none;
    font-size: 15px;
}

h3 {
    font-size: 10pt;
    margin: 0;
    padding: 0 0 3px;
    color: #f5f5f5;
    text-align: center;
}

table.dashboard {
    background: transparent;
    width: 100%;
    height: 100%;
    border: 2px solid #21618b;
    border-radius: 5px;
}

table.dashboard td.ml {
    border: 0;
    margin: 0;
    width: 6px;
}

table.dashboard td.mm {
    color: #f5f5f5;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    height: 32px;
}

table.dashboard td.mr {
    border: 0;
    margin: 0;
    width: 6px;
}

table.dashboard h3.h-title {
    color: #f5f5f5;
    font-size: 12px;
    margin-left: 10px;
}

.new-analysis {
    width: 480px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #21618b;
    border-radius: 5px;
}

.new-analysis td {
    color: #21618b;
}

.new-analysis input {
    cursor: pointer;
    border: 1px solid #21618b;
    padding: 4px 8px;
    border-radius: 5px;
    margin-top: 15px;
    margin-left: 6px;
    margin-right: 6px;
    color: #f5f5f5;
    background-color: #286ba6;
    background-image: linear-gradient(to bottom, #286ba6 0, #194368 100%);
}

.new-analysis input:hover {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.portlet {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    background: transparent no-repeat;
    text-align: center;
    border: thin;
    width: 17px;
    height: 17px;
    margin-right: 5px;
}

/*header drop shadow*/
div.dashboard {
    float: left;
    margin-right: 10px;
}

div.dashboardContainer {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid black;
    float: left;
}

table.leftPanel tr.header td {
    background-color: #8897aa;
}

/* style class for navigator tree */
.navMenuTree {
    margin-left: 0px;
    font-size: 11px;
    height: auto;
}

.navMenuItem {
    font-size: 11px;
    white-space: nowrap;
    _padding-left: 5px;
    _padding-bottom: 3px;
}

.iceTreeRow {
    margin-top: 1px;
    margin-bottom: 1px;
    white-space: normal;
    text-align: left;
}

.navFolderItem {
    color: #000000;
    font-weight: normal;
    font-size: 8pt;
    padding: 2px 0px;
}

/* Styles for DImentions Tab*/
.style1 {
    font-weight: normal;
    font-size: 11px;
}

.customDimension {
    background: #f5f5f5;
    font-weight: bold;
    color: #333333;
    border: 1px solid #cccccc;
    width: 100%;
    margin-bottom: 5px;
}

.filterHover {
    padding: 4px 2px;
    border-top: 1px solid #333333;
    border-right: 2px dotted #333333;
    border-bottom: 2px dotted #333333;
    border-left: 2px dotted #333333;
}

.customDimensionHover {
    border-bottom: 2px dotted #333333;
    line-height: 2px;
    margin-bottom: 4px;
}

.customDimensionNormal {
    border-bottom: 2px dotted #333333;
    line-height: 2px;
    margin-bottom: 4px;
}

.filterOptionsRegion {
    padding: 4px 2px;
    border-top: 1px solid #333333;
}

/* style class for feedManagement */
span.title {
    color: #21618b;
    font-size: 12px;
}

input.transparentEdit {
    background: transparent;
    border: none;
}

table.tableGrid {
    border-left: 1px solid #cccccc !important;
    border-bottom: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0;
}

table.tableGrid th {
    color: #21618b;
    background-color: #eaeaea;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #dddddd 100%);
    font-size: 12px;
    border-bottom: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    border-right: none;
    text-align: left;
}

tr.trGridRow {
    font-size: 11px;
    height: 25;
}

tr.trGridRow td {
    white-space: nowrap;
    border-right: 0px solid #f5f5f5;
    border-bottom: 0px solid #8897aa;
    background-color: #f5f5f5;
    padding-top: 0px;
    padding-bottom: 1px;
}

tr.trGridAltRow {
    font-size: 11px;
    height: 25px;
}

tr.trGridAltRow td {
    white-space: nowrap;
    background-color: #f5f5f5;
    border-bottom: 0px solid #8897aa;
    border-right: 1px solid #ffffff;
}

tr.trGridAltRow td:last-child {
    border-right: 0px solid #ffffff;
}

tr.trGridRow td a,
tr.trGridAltRow td a {
    text-decoration: underline;
    color: #21618b;
}

tr.trGridRow td a:hover,
tr.trGridAltRow td a:hover {
    text-decoration: none;
}

#projectBox {
    height: auto;
    width: 900px;
    margin-top: 27px;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
}

#projectBox-header {
    background-color: #9dab96;
    height: 25px;
    width: 100%;
    border-right-width: 1px;
    border-left-width: 1px;
    border-right-style: solid;
    border-left-style: solid;
    border-right-color: #afbaa9;
    border-left-color: #afbaa9;
    text-align: center;
    font-size: 15px;
    font-weight: bolder;
    color: #f5f5f5;
    text-decoration: none;
    padding-top: 5px;
}

#projectBox-body {
    border: 1px solid #afbaa9;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.log-info {
    float: right;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 7px;
    width: 300px;
}



.info_content {
    text-align: center;
    padding-top: 7px;
}

.error_content {
    text-align: center;
    padding-top: 7px;
}

.warn_content {
    text-align: center;
    padding-top: 7px;
    font-size: 12px;
    color: #990000;
    font-weight: bold;
    text-decoration: none;
}

.errorMessage {
    font-size: 12px;
    color: #990000;
    font-weight: bold;
    text-decoration: none;
}

.infoMessage {
    font-size: 12px;
    font-weight: bold;
    color: #21618b;
}

.filterHover {
    border-top: 1px solid #333333;
    border-right: 2px dotted #333333;
    border-bottom: 2px dotted #333333;
    border-left: 2px dotted #333333;
}

.filterOption {
    background: #f5f5f5;
    font-weight: bold;
    color: #333333;
    border: 1px solid #cccccc;
    margin: 5px 0;
    width: 100%;
    height: 24px;
    word-break: break-all;
}

.customDimensionHover {
    border-bottom: 2px dotted #333333;
    line-height: 2px;
    margin-bottom: 4px;
}

.customDimensionNormal {
    border-bottom: 2px dotted #333333;
    line-height: 2px;
    margin-bottom: 4px;
}

.filterOptionsRegion {
    padding: 4px 2px;
    border-top: 1px solid #333333;
}

.tab-text {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

#navTab-1-body {
    display: none;
}

#navTab-2-body {
    display: none;
}

#report-image-holder {
    padding: 7px;
    width: 98.5%;
    border: 1px solid #cccccc;
    overflow: auto;
}

.tree-node-odd {
    font-size: 11px;
    letter-spacing: .4px;
}

.tree-node-even {
    font-size: 11px;
    letter-spacing: .4px;
}

.tree-node {
    font-size: 11px;
}

.tree-node-odd div, .tree-node-even div {
    padding: 4px;
}

.popupheader {
    font-size: 12px;
    font-weight: 500;
    padding-left: 20px;
    height: 44px;
    background-color: #f7f8fa;
    letter-spacing: .6px;
}

.popupheader input{
    float: right;
}

.tagged {
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    background-color: #ededed;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    display: inline-block;
    color: #777777;
    font-family: arial;
    font-size: 12px;
    font-weight: normal;
    padding: 4px;
    text-decoration: none;
    text-shadow: 1px 1px 0px #ffffff;
}

.iceOutTxt {
    color: #000000;
    font-size: 11px;
    font-weight: normal;
}

.iceOutTxt.title {
    color: #21618b;
}

.iceCmdLink {
    font-size: 11px;
    text-decoration: underline;
}

.iceSelMnyCb {
    font-size: 11px;
}

/*
CSS classes for analysis print page formatting
*/
.printPageWrapper {
    padding: 13px;
}

.printPageTopMargin {
    height: 17px;
    width: 100%;
    padding-top: 5px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #cccccc;
    font-size: 12px;
    font-weight: bold;
    color: #21618b;
}

.printTableContainer {
    height: 200px;
    width: 100%;
    margin-top: 10px;
}

/* SideBar Settings*/
#sideBar {
    overflow-y: inherit;
}

#sideBarTab {
    float: right;
    width: 25px;
    height: 8%;
}

#sideBarTab img {
    border: 0px solid #ffffff;
}

#sideBarContents {
    border: 0px solid #f0f0ff;
    float: left;
    height: 85%;
    overflow: hidden !important;
    width: 240px;
}

#sideBarContentsInner {
    width: 200px;
}

#reports-table {
    width: auto;
    border-color: #005baf;
    border-width: 1px;
    border-style: solid;
}

/* Tool Tips */
#title2note {
    position: fixed;
    z-index: 2;
    bottom: 0;
    right: 0;
    width: 20em;
    margin: 1em;
    padding: 7px 5px 7px 10px;
    font: 10px, monospace;
    text-align: left;
    color: #111111;
    background-color: #ffffff;
    border: 1px solid #111111;
    font-weight: bold;
}

/* star html hack - IE only */
* html #title2note {
    position: absolute;
}

/* a bet on future IEs */
#title2note[id] {
    position: fixed;
}

/*---------new 08/12/11-------*/
.border_color_new_dashboard {
    border-radius: 5px;
    border: 2px solid #21618b;
    margin: -2px;
}

.icePnlGrd_iceFaces {
    font-size: 11px;
    background: #f5f5f5;
    width: 600;
    border: 2px solid #21618b;
    border-top: none;
    border-radius: 0 0 5px 5px;
    padding: 15px;
}

.color-font-dashboard {
    color: #21618b;
    text-decoration: underline;
    font-size: 11px;
}

#end-config-list {
    border-bottom: 1px solid #21618b;
}

#line-dashboard {
    border-top: 2px solid #21618b;
    height: 2px;
}

#split-menu {
    font-size: 11px;
    text-decoration: none;
    color: #21618b;
}


.previousDash:hover {
    cursor: pointer;
}


.pauseDash:hover {
    cursor: pointer;
}
.nextDash:hover {
    cursor: pointer;
}


.stopDash:hover {
    cursor: pointer;
}

.startDash:hover {
    cursor: pointer;
}

#texto-decoration-dashboard-apresentation {
    text-align: right;
    font-size: 15px;
    color: #21618b;
    padding-right: 20px;
}

/*---------menu lateral------*/
.menu-lateral-line-botton,
.menu-lateral-line-botton2 {
    border-bottom: 1px solid #21618b;
}

.menu-lateral-botao-lefth {
    border-left: 1px solid #21618b;
    border-top: 1px solid #21618b;
    height: 23px;
    width: 6px;
    border-radius: 5px 0 0 0;
}

#navTab-0-bg {
    height: 23px;
    width: 132px;
}

#navTab-1-bg {
    height: 23px;
    width: 102px;
}

#analysisTab-0-bg {
    width: 100px;
    height: 22px;
}

#analysisTab-1-bg {
    height: 22px;
    width: 100px;
}

#analysisTab-2-bg {
    height: 22px;
    width: 100px;
}

/*botoes in_memory e KPI*/
.button_all_aplication {
    border: 1px solid #21618b;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 5px;
    color: #21618b;
    background-color: #dddddd;
    background-image: linear-gradient(to bottom, #dddddd 0, #f5f5f5 100%);
}

.button_all_aplication:hover {
    color: #f5f5f5;
    background-color: #286ba6;
    background-image: linear-gradient(to bottom, #286ba6 0, #194368 100%);
}

.button_all_aplication:active {
    color: #f5f5f5;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.table-comments tr:nth-child(2n+1) {
    background-color: #edeff4;
}

.table-comments tr {
    background-color: #ffffff;
}

.hourComment {
    color: #555555;
    font-size: 10px;
}

.userComment {
    padding: 10px;
    color: #21618b;
    font-size: 12px;
}

.commentContent {
    color: #333333;
    font-size: 12px;
    padding: 10px;
}

#propertieTab-0-bg {
    width: 132px;
    height: 23px;
}

#propertieTab-1-bg {
    height: 23px;
    width: 102px;
}

#propertieTab-2-bg {
    height: 23px;
    width: 102px;
}

#propertieTab-3-bg {
    height: 23px;
    width: 102px;
}

.tabSetCnt {
    padding: 20px;
    border: 1px solid #21618b;
    background-color: whiteSmoke;
}

.pop-up-create-measure {
    border: 2px solid #21618b;
    font-size: 11px;
}

.imageEfect {
    opacity: 0.7;
}

.imageEfect:hover {
    opacity: 1;
}

/*
----------- Data Scroller Component classes
*/
.iceDatPgrScrOut {
    /* the class for the outside table */
    background-color: #e6e9e4;
    margin: 10px;
}

.iceDatPgrScrBtn {
    /* the cells behind the scroll buttons */
    background-color: #e6e9e4;
    padding: 2px;
}

.iceDatPgrTbl {
    /* the container for the pagination*/
    background-color: #e6e9e4;
}

/* set the common attributes for the pagination numbers */
.iceDatPgrScrCol a,
.iceDatPgrCol a {
    text-decoration: none;
    font-weight: normal;
    background-color: #ffffff;
    color: #666666;
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #ffffff;
}

* html .iceDatPgrCol a {
    width: 1%;
}

/* override the attributes for the currently selected page */
.iceDatPgrScrCol a {
    color: #ffffff;
    border: 1px solid #2a6cc2;
    background-color: #2a6cc2;
}

* html .iceDatPgrScrCol a {
    width: 1%;
}

/* set the attributes for the mouseover on none-current pages */
.iceDatPgrCol a:hover {
    color: #000000;
    border: 1px solid #21618b;
}

@media print {
    .noPrint {
        display: none;
    }
}

.custom-box {
    width: 800px;
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    background-color: #ededed;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    display: inline-block;
    color: #777777;
    padding: 5px;
    font-family: arial;
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
    text-shadow: 1px 1px 0px #ffffff;
}

.custom-box > h2 {
    text-align: center;
    margin: 2px;
}

.custom-box > ul {
    margin: 2px;
}

.custom-box > ul > li {
    display: block;
    width: 25%;
    float: left;
}

.spaceUnder > td {
    padding-bottom: 10px;
}

.ui-draggable {
    z-index: 0;
    cursor: move !important;
}

.ui-draggable-dragging {
    z-index: 9999;
    cursor: move !important;
}

.right-margin-5 {
    margin-right: 5px;
}

.icePnlPop {
    border-radius: 5px;
}

.iceOutLbl {
    font-size: 11px;
    color: #21618b;
}

.widget-toolbar a {
    cursor: pointer;
    cursor: hand;
}

.sidebar.menu-min.right-side-sidebar {
    right: 0;
    border-left: 1px solid #cccccc;
}

.sidebar.menu-min.right-side-sidebar.fixed-scroll > .nav.nav-list {
    position: fixed;
}

.sidebar.menu-min.right-side-sidebar .nav-list > li > .submenu {
    left: auto;
    right: 52px;
    margin-top: -50px;
    width: 250px;
    position: fixed;
    padding: 0;
}

.sidebar.menu-min.right-side-sidebar .nav-list > li > a.dropdown-toggle > .menu-text {
    left: auto;
    right: 30px;
}

.field-separator {
    color: #669fc7;
}

.field-separator hr {
    margin: 0 0 8px 0;
}

.iceSelOneRb td,
.iceSelMnyCb td {
    padding-right: 8px;
}

dl dt {
    color: #669fc7;
    font-size: 16px;
    font-weight: normal;
}

dl dd {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.modal {
    z-index: 321050 !important;
}

.modal-backdrop {
    z-index: 321040 !important;
}

.popover {
    z-index: 991040 !important;
}

.btn.select-user-button {
    font-size: 12px;
    border-radius: 2px;
    height: 18px;
    margin: 0;
    padding: 0 3px;
    width: 18px;
}

.fields-list-container .fields-list li {
    line-height: 0;
}