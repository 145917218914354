.grid-stack.grid-stack-24 > .grid-stack-item {
    min-width: 4.16666667%;
}

.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='1'] {
    width: 4.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='1'] {
    left: 4.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='1'] {
    min-width: 4.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='1'] {
    max-width: 4.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='2'] {
    width: 8.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='2'] {
    left: 8.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='2'] {
    min-width: 8.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='2'] {
    max-width: 8.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='3'] {
    width: 12.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='3'] {
    left: 12.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='3'] {
    min-width: 12.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='3'] {
    max-width: 12.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='4'] {
    width: 16.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='4'] {
    left: 16.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='4'] {
    min-width: 16.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='4'] {
    max-width: 16.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='5'] {
    width: 20.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='5'] {
    left: 20.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='5'] {
    min-width: 20.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='5'] {
    max-width: 20.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='6'] {
    width: 25%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='6'] {
    left: 25%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='6'] {
    min-width: 25%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='6'] {
    max-width: 25%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='7'] {
    width: 29.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='7'] {
    left: 29.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='7'] {
    min-width: 29.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='7'] {
    max-width: 29.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='8'] {
    width: 33.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='8'] {
    left: 33.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='8'] {
    min-width: 33.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='8'] {
    max-width: 33.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='9'] {
    width: 37.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='9'] {
    left: 37.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='9'] {
    min-width: 37.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='9'] {
    max-width: 37.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='10'] {
    width: 41.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='10'] {
    left: 41.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='10'] {
    min-width: 41.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='10'] {
    max-width: 41.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='11'] {
    width: 45.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='11'] {
    left: 45.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='11'] {
    min-width: 45.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='11'] {
    max-width: 45.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='12'] {
    width: 50%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='12'] {
    left: 50%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='12'] {
    min-width: 50%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='12'] {
    max-width: 50%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='13'] {
    width: 54.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='13'] {
    left: 54.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='13'] {
    min-width: 54.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='13'] {
    max-width: 54.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='14'] {
    width: 58.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='14'] {
    left: 58.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='14'] {
    min-width: 58.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='14'] {
    max-width: 58.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='15'] {
    width: 62.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='15'] {
    left: 62.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='15'] {
    min-width: 62.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='15'] {
    max-width: 62.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='16'] {
    width: 66.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='16'] {
    left: 66.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='16'] {
    min-width: 66.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='16'] {
    max-width: 66.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='17'] {
    width: 70.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='17'] {
    left: 70.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='17'] {
    min-width: 70.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='17'] {
    max-width: 70.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='18'] {
    width: 75%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='18'] {
    left: 75%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='18'] {
    min-width: 75%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='18'] {
    max-width: 75%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='19'] {
    width: 79.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='19'] {
    left: 79.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='19'] {
    min-width: 79.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='19'] {
    max-width: 79.16666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='20'] {
    width: 83.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='20'] {
    left: 83.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='20'] {
    min-width: 83.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='20'] {
    max-width: 83.33333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='21'] {
    width: 87.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='21'] {
    left: 87.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='21'] {
    min-width: 87.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='21'] {
    max-width: 87.5%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='22'] {
    width: 91.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='22'] {
    left: 91.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='22'] {
    min-width: 91.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='22'] {
    max-width: 91.66666667%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='23'] {
    width: 95.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='23'] {
    left: 95.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='23'] {
    min-width: 95.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='23'] {
    max-width: 95.83333333%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-width='24'] {
    width: 100%;
}
.grid-stack.grid-stack-24 > .grid-stack-item[data-gs-x='24'] {
    left: 100%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-min-width='24'] {
    min-width: 100%;
}
.grid-stack.grid-stack-24 > .grid-stack-item.grid-stack-item[data-gs-max-width='24'] {
    max-width: 100%;
}
