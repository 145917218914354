.BngPanel {
    width: 340px;
    box-shadow: inset 0 0 0 0 #B5B5B5, 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #F7F8FA;
}

.BngFlatPanel {
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    background-color: #fff;
}

.BngPanel.WhiteBg {
    background-color: white;
}

.BngPanel .Panel {
    position: relative;
    min-height: 52px;
    padding: 12px 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
    line-height: 28px;
    background-color: #FFFFFF;
}

.BngPanel .Header {
    box-shadow: inset 0 -1px 0 0 #E7E8EB;
    border-radius: 4px 4px 0 0;
    text-align: left;
}

.BngPanel .Header .Title {
    font-size: 14px;
    font-weight: bold;
}

.BngPanel .Footer {
    box-shadow: inset 0 1px 0 0 #E7E8EB;
}