.KpiGrowth {
    text-align: center;
    margin-left: auto;
}

.KpiGrowth .kpi-comp-val-container {
    font-size: 1em;
    line-height: 1em;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

.KpiGrowth .kpi-comp-val-container i {
    font-size: 1.2em;
    margin-left: 5px;
}

.KpiGrowth .kpi-comp-val-container.red > span {
    color: #f83a22 !important;
}

.KpiGrowth .kpi-comp-val-container.green > span {
    color: #16a765 !important;
}