@font-face {
    font-family: 'Open Sans';
    src: url("../google-font/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff") format('woff'), local('Open Sans Light'), local('OpenSans-Light');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../google-font/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff') format('woff'), local('Open Sans'), local('OpenSans');
    font-style: normal;
    font-weight: 400;
}