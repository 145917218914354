.dash-item-information-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.DashboardItemInformation .LoadingPlaceholder {
    position: absolute;
}