.icePnlCnfBtns {
    padding: 5px !important;
}

.icePnlCnf {
    width: 450px;
}

.icePnlCnfBody {
    height: 50px;
}

.dialog {
    margin: 0;
    padding: 0;
}

.dialog > table:first-child {
    width: 100%;
}

.dialog-header {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    width: inherit;
    line-height: 25px;
    height: 25px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.dialog-body {
    display: block;
    word-break: break-word;
    word-wrap: break-word;
    overflow: auto;
}

/*
 * Fora do padrao pois e uma classe adicionada automaticamente pelo IceFaces
 */
.dialogBody {
    padding: 0;
    margin: 0;
    overflow: auto;
}

.dialog-title {
    color: #f5f5f5;
    font-size: 11px;
    font-weight: bold;
}

.dialog-half-screen {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    height: 50%;
    min-height: 50%;
    max-height: 50%;
}

.close {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: #f5f5f5;
    opacity: 0.8;
    text-decoration: none;
    line-height: 25px;
    height: 25px;
    right: 5px;
}

.close:hover {
    color: white;
    text-decoration: none;
    opacity: 1;
    cursor: pointer;
}

.simplemodal-overlay {
    z-index: 30001 !important;
    background-color: black;
}

.simplemodal-container {
    z-index: 30002 !important;
}

.modal-close {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: #21618b;
    opacity: 0.8;
    text-decoration: none;
    line-height: 25px;
    height: 25px;
    right: 5px;
}

.modal-close-inv {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: #f5f5f5;
    opacity: 0.8;
    text-decoration: none;
    line-height: 25px;
    height: 25px;
    right: 5px;
}

/*
 * Fora do padrao pois e uma classe adicionada automaticamente pelo IceFaces
 */
.icePnlPopHdr {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

/* Tooltips IceFaces */
.icePnlTlTip {
    background: #efefef;
    border: 1px solid #21618b;
    text-align: center;
    border-radius: 5px 5px 0 0;
}

.icePnlTlTipHdr {
    width: 100% !important;
    height: 18px;
    line-height: 18px;
    padding: 2px;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.icePnlTlTipBody {
    text-align: center !important;
    padding: 5px;
}

.file-upload-image-preview {
    max-width: 768px;
    max-height: 480px;
}

.file-upload-image-preview-container {
    width: 300px;
    height: 281px;
    background: #efefef;
    display: none;
    text-align: center;
    border: 1px solid #21618b;
    border-radius: 5px;
}

.file-upload-image-preview-header {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    border-radius: 5px 5px 0 0;
    width: 296px;
    padding: 2px;
    height: 24px;
    line-height: 24px;
    text-align: center;
}

/* End IceFaces *//*

/* Skin */
.tabs-nav {
    list-style: none;
    margin: 0;
    padding-top: 7px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 4px;
}

.tabs-nav:after {
    display: block;
    clear: both;
    content: " ";
}

.tabs-nav li {
    float: left;
    margin: 0 0 0 1px;
    min-width: 84px;
}

.tabs-nav a {
    position: relative;
    top: 1px;
    z-index: 2;
    padding-left: 0;
    color: #21618b;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

.tabs-nav .tabs-selected a {
    color: #000000;
}

.tabs-nav .tabs-selected a,
.tabs-nav a:hover,
.tabs-nav a:focus,
.tabs-nav a:active {
    background-position: 100% -150px;
    outline: 0;
    /* prevent dotted border in Firefox */
}

.tabs-nav a,
.tabs-nav .tabs-disabled a:hover,
.tabs-nav .tabs-disabled a:focus,
.tabs-nav .tabs-disabled a:active {
    background-position: 100% -100px;
}

.tabs-nav a span {
    width: 64px;
    /* IE 6 treats width as min-width */
    min-width: 64px;
    height: 16px;
    /* IE 6 treats height as min-height */
    min-height: 16px;
    padding-top: 6px;
    padding-right: 0;
}

* > .tabs-nav a span {
    /* hide from IE 6 */
    width: auto;
    height: auto;
}

.tabs-nav .tabs-selected a span {
    padding-top: 7px;
}

.tabs-nav .tabs-selected a span,
.tabs-nav a:hover span,
.tabs-nav a:focus span,
.tabs-nav a:active span {
    background-position: 0 -50px;
}

.tabs-nav a span,
.tabs-nav .tabs-disabled a:hover span,
.tabs-nav .tabs-disabled a:focus span,
.tabs-nav .tabs-disabled a:active span {
    background-position: 0 0;
}

.tabs-nav .tabs-selected a:link,
.tabs-nav .tabs-selected a:visited,
.tabs-nav .tabs-disabled a:link,
.tabs-nav .tabs-disabled a:visited {
    /* @ Opera, use pseudo classes otherwise it confuses cursor... */
    cursor: text;
}

.tabs-nav a:hover,
.tabs-nav a:focus,
.tabs-nav a:active {
    /* @ Opera, we need to be explicit again here now... */
    cursor: pointer;
}

.tabs-nav .tabs-disabled {
    opacity: .4;
}

.tabs-container {
    background: #ffffff;
    /* declare background color for container to avoid distorted fonts in IE while fading */
    height: auto;
}

.background-tab {
    border: 1px solid #21618b;
    border-radius: 5px 5px 0 0;
    text-align: center;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #dddddd 100%);
    color: #9b9b9b;
}

.background-tab a {
    color: #9b9b9b;
}

.background-tab-active {
    border-top: 1px solid #21618b;
    border-right: 1px solid #21618b;
    border-left: 1px solid #21618b;
    border-bottom: 1px hidden #21618b;
    border-radius: 5px 5px 0 0;
    color: #21618b;
    text-align: center;
    background-color: #dddddd;
    background-image: linear-gradient(to bottom, #dddddd 0, #f5f5f5 100%);
}

.background-tab-active a {
    color: #21618b;
}

/* Icefaces Tabs */
.baseTab {
    border: 1px solid #21618b;
    border-bottom: 0;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    margin-right: 3px;
}

.icePnlTbOn {
    border: 1px solid #21618b;
    border-bottom: 0;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    margin-right: 3px;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.icePnlTbOn td {
    text-shadow: 0 0 1px;
    color: #f5f5f5;
    font-size: 11px;
    font-weight: bold;
}

.icePnlTbOff {
    border: 1px solid #21618b;
    border-bottom: 0;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    margin-right: 3px;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #dddddd 100%);
}

.icePnlTbOff td {
    color: #21618b;
}

.icePnlTbOver {
    border: 1px solid #21618b;
    border-bottom: 0;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    margin-right: 3px;
    background-color: #dddddd;
    background-image: linear-gradient(to bottom, #dddddd 0, #f5f5f5 100%);
}

.icePnlTbOver td {
    color: #21618b;
    text-shadow: 0 0 1px;
}

.icePnlTbLblLnk {
    outline: none;
}

.icePnlTbLblLnk td {
    outline: none;
    font-size: 11px;
}
