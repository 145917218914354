.tippy-box[data-theme~="bim"] {
  background-color: #393e42 !important;
  box-shadow: 0 0 2px 0 #333 !important;
  font-size: 13px !important;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.tippy-box[data-theme~="bim"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: #393e42 !important;
}

.tippy-box[data-theme~="bim"][data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: #393e42 !important;
}

.tippy-box[data-theme~="bim"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: #393e42 !important;
}

.tippy-box[data-theme~="bim"][data-placement^="right"] > .tippy-arrow::before {
  border-right-color: #393e42 !important;
}