.wizard {
    width: 100%;
    height: 100%;
    background-color: #e5e5e5;
    border-radius: 5px;
}

.wizard-step {
    background-color: #dddddd;
    border-radius: 5px 5px 0 0;
    color: #21618b;
}

.wizard-selected-step {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    color: #f5f5f5;
}

.wizard-data-td {
    padding: 5px;
}
