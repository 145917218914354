.pc-gradient {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.pc-gradient-tab {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.pc-border {
    border: 1px #21618b solid;
    border-radius: 5px;
}

.rem-m-w {
    min-width: 0 !important;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.ta-l {
    text-align: left;
}

.ta-c {
    text-align: center;
}

.ta-r {
    text-align: right;
}

.m-none {
    margin: 0;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline-block-force {
    display: inline-block !important;
}

.d-m {
    margin: 5px;
}

.d-b-m {
    margin-bottom: 5px;
}

.margin-bottom-default {
    margin-bottom: 10px;
}

.d-t-m {
    margin-top: 5px;
}

.d-l-m {
    margin-left: 5px;
}

.d-r-m {
    margin-right: 5px;
}

.d-b-p {
    padding-bottom: 5px;
}

.d-t-p {
    padding-top: 5px;
}

.d-l-p {
    padding-left: 5px;
}

.d-r-p {
    padding-right: 5px;
}

.fill-w {
    width: 100%;
}

.fill-h {
    height: 100%;
}

.d-f-c {
    font-size: 11px;
    color: #21618b;
}

.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-50 {
    width: 50%;
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.pc-font {
    color: #21618b;
}

.base-font-size {
    font-size: 11px;
}

.tab-corners {
    border-radius: 5px 5px 0 0;
}

.bottom-corners {
    border-radius: 0 0 5px 5px;
}

.w-32-px {
    width: 32px;
}

.w-72-px {
    width: 72px;
}

.moment {
    margin-left: 5px;
}

.break-words {
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.btn-fix {
    padding: 1px;
}

.collapsible-panel {
    border: 1px #21618b solid;
    border-radius: 5px;
}

/**
  * Fora do padrao pois e gerada pelo IceFaces
  */
.collapsible-panelHdr {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.collapsible-panel-content {
    padding: 5px;
}

.well h1,
.well h2,
.well h3,
.well h4,
.well h5,
.well h6 {
    margin-top: 0;
}

.well h1,
.well h2,
.well h3 {
    line-height: 36px;
}

.well {
    border-radius: 0;
}

.error-container {
    margin: 20px;
    padding: 0;
    background: #ffffff;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    text-align: left;
}

.blue {
    color: #478fca !important;
}

.bigger-125 {
    font-size: 125%;
}

.lighter {
    font-weight: lighter;
}

h1 {
    font-size: 32px;
    font-weight: normal;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

h1.smaller {
    font-size: 31px;
}

h1.bigger {
    font-size: 33px;
}

h1.block {
    margin-bottom: 16px;
}

h2 {
    font-size: 26px;
    font-weight: normal;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

h2.smaller {
    font-size: 25px;
}

h2.bigger {
    font-size: 27px;
}

h2.block {
    margin-bottom: 16px;
}

h3 {
    font-size: 22px;
    font-weight: normal;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

h3.smaller {
    font-size: 21px;
}

h3.bigger {
    font-size: 23px;
}

h3.block {
    margin-bottom: 16px;
}

h4 {
    font-size: 18px;
    font-weight: normal;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

h4.smaller {
    font-size: 17px;
}

h4.bigger {
    font-size: 19px;
}

h4.block {
    margin-bottom: 16px;
}

h5 {
    font-size: 15px;
    font-weight: normal;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

h5.smaller {
    font-size: 14px;
}

h5.bigger {
    font-size: 16px;
}

h5.block {
    margin-bottom: 16px;
}

h6 {
    font-size: 13px;
    font-weight: normal;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

h6.smaller {
    font-size: 12px;
}

h6.bigger {
    font-size: 14px;
}

h6.block {
    margin-bottom: 16px;
}

/* some list styling */
ul,
ol {
    margin: 0 0 10px 25px;
    padding: 0;
}

ul.margin-5,
ol.margin-5 {
    margin-left: 5px;
}

ul.margin-10,
ol.margin-10 {
    margin-left: 10px;
}

ul.margin-15,
ol.margin-15 {
    margin-left: 15px;
}

ul.margin-20,
ol.margin-20 {
    margin-left: 20px;
}

li > ul,
li > ol {
    margin-left: 18px;
}

.error-page-list {
    list-style: none;
}

.error-page-list li {
    margin-bottom: 5px;
}

.alert {
    font-size: smaller;
}