/*compatibilidade bootstrap*/
.alert {
    padding: 8px 35px 8px 14px !important;
    border-radius: 0px;
}
/*fim compatibilidade*/

/*compatibilidade icefaces*/
.iceFrm iframe {
    background: #c1c1c1 !important;
    position: fixed !important;
    margin-top: 52px;
}
/*fim compatibilidade*/

.infobox-container {
    font-size: 0
}

.infobox {
    display: inline-block;
    width: 220px;
    height: 52px;
    color: #555555;
    box-shadow: none;
    border-radius: 0;
    margin: -1px 0 0 -1px;
    padding: 8px 3px 6px 9px;
    border: 1px dotted;
    border-color: #d8d8d8 !important;
    vertical-align: middle;
    text-align: left;
    position: relative
}

.infobox > .infobox-icon {
    display: inline-block;
    vertical-align: top;
    width: 44px
}

.sidebar > .nav-search.menu-min .nav-search .nav-search-input {
    width: 0 !important;
    max-width: 0 !important;
    opacity: 0 !important;
    filter: alpha(opacity=0) !important
}

.infobox > .infobox-icon > [class*="icon-"] {
    display: inline-block;
    height: 42px;
    margin: 0;
    padding: 1px 1px 0 2px;
    background-color: transparent;
    border: 0;
    text-align: center;
    position: relative;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2)
}

.infobox > .infobox-icon > [class*="icon-"]:before {
    font-size: 24px !important;
    display: block;
    padding: 6px 0 7px;
    width: 40px !important;
    text-align: center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.2);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14)
}

.infobox .infobox-content {
    color: #555555
}

.infobox-description {
    color: #555555;
    font-size: 8px;
    line-height: 8px;
}

.infobox .infobox-content:first-child {
    font-weight: normal
}

.infobox-content{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.infobox > .infobox-data {
    display: inline-block;
    border: 0;
    border-top-width: 0;
    font-size: 13px;
    text-align: left;
    line-height: 21px;
    min-width: 130px;
    padding-left: 14px;
    position: relative;
    top: 0
}

.infobox > .infobox-data > .infobox-data-number {
    display: block;
    font-size: 22px;
    margin: 2px 0 4px;
    position: relative;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15)
}

.infobox > .infobox-data > .infobox-text {
    display: block;
    font-size: 16px;
    margin: 2px 0 4px;
    position: relative;
    text-shadow: none
}

.infobox.no-border {
    border: none !important
}

.infobox-purple {
    color: #6f3cc4;
    border-color: #6f3cc4
}

.infobox-purple > .infobox-icon > [class*="icon-"] {
    background-color: #6f3cc4
}

.infobox-purple.infobox-dark {
    background-color: #6f3cc4;
    border-color: #6f3cc4
}

.infobox-purple2 {
    color: #5f47b0;
    border-color: #5f47b0
}

.infobox-purple2 > .infobox-icon > [class*="icon-"] {
    background-color: #5f47b0
}

.infobox-purple2.infobox-dark {
    background-color: #5f47b0;
    border-color: #5f47b0
}

.infobox-pink {
    color: #cb6fd7;
    border-color: #cb6fd7
}

.infobox-pink > .infobox-icon > [class*="icon-"] {
    background-color: #cb6fd7
}

.infobox-pink.infobox-dark {
    background-color: #cb6fd7;
    border-color: #cb6fd7
}

.infobox-blue {
    color: #6fb3e0;
    border-color: #6fb3e0
}

.infobox-blue > .infobox-icon > [class*="icon-"] {
    background-color: #6fb3e0
}

.infobox-blue.infobox-dark {
    background-color: #6fb3e0;
    border-color: #6fb3e0
}

.infobox-blue2 {
    color: #3983c2;
    border-color: #3983c2
}

.infobox-blue2 > .infobox-icon > [class*="icon-"] {
    background-color: #3983c2
}

.infobox-blue2.infobox-dark {
    background-color: #3983c2;
    border-color: #3983c2
}

.infobox-blue3 {
    color: #1144eb;
    border-color: #1144eb
}

.infobox-blue3 > .infobox-icon > [class*="icon-"] {
    background-color: #1144eb
}

.infobox-blue3.infobox-dark {
    background-color: #1144eb;
    border-color: #1144eb
}

.infobox-red {
    color: #d53f40;
    border-color: #d53f40
}

.infobox-red > .infobox-icon > [class*="icon-"] {
    background-color: #d53f40
}

.infobox-red.infobox-dark {
    background-color: #d53f40;
    border-color: #d53f40
}

.infobox-brown {
    color: #c67a3e;
    border-color: #c67a3e
}

.infobox-brown > .infobox-icon > [class*="icon-"] {
    background-color: #c67a3e
}

.infobox-brown.infobox-dark {
    background-color: #c67a3e;
    border-color: #c67a3e
}

.infobox-wood {
    color: #7b3f25;
    border-color: #7b3f25
}

.infobox-wood > .infobox-icon > [class*="icon-"] {
    background-color: #7b3f25
}

.infobox-wood.infobox-dark {
    background-color: #7b3f25;
    border-color: #7b3f25
}

.infobox-light-brown {
    color: #cebea5;
    border-color: #cebea5
}

.infobox-light-brown > .infobox-icon > [class*="icon-"] {
    background-color: #cebea5
}

.infobox-light-brown.infobox-dark {
    background-color: #cebea5;
    border-color: #cebea5
}

.infobox-orange {
    color: #e8b110;
    border-color: #e8b110
}

.infobox-orange > .infobox-icon > [class*="icon-"] {
    background-color: #e8b110
}

.infobox-orange.infobox-dark {
    background-color: #e8b110;
    border-color: #e8b110
}

.infobox-orange2 {
    color: #f79263;
    border-color: #f79263
}

.infobox-orange2 > .infobox-icon > [class*="icon-"] {
    background-color: #f79263
}

.infobox-orange2.infobox-dark {
    background-color: #f79263;
    border-color: #f79263
}

.infobox-green {
    color: #9abc32;
    border-color: #9abc32
}

.infobox-green > .infobox-icon > [class*="icon-"] {
    background-color: #9abc32;
}

.infobox-green.infobox-dark {
    background-color: #9abc32;
    border-color: #9abc32
}

.infobox-green2 {
    color: #0490a6;
    border-color: #0490a6
}

.infobox-green2 > .infobox-icon > [class*="icon-"] {
    background-color: #0490a6
}

.infobox-green2.infobox-dark {
    background-color: #0490a6;
    border-color: #0490a6
}

.infobox-grey {
    color: #999999;
    border-color: #999999
}

.infobox-grey > .infobox-icon > [class*="icon-"] {
    background-color: #999999
}

.infobox-grey.infobox-dark {
    background-color: #999999;
    border-color: #999999
}

.infobox-black {
    color: #2A2624;
    border-color: #2A2624
}

.infobox-black > .infobox-icon > [class*="icon-"] {
    background-color: #2A2624
}

.infobox-black.infobox-dark {
    background-color: #2A2624;
    border-color: #2A2624
}

.infobox-dark {
    margin: 1px 1px 0 0;
    border-color: transparent !important;
    border: 0;
    color: #ffffff;
    padding: 4px
}

.infobox-dark > .infobox-icon > [class*="icon-"], .infobox-dark > .infobox-icon > [class*="icon-"]:before {
    background-color: transparent;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;
    font-size: 30px
}

.infobox-dark > .infobox-icon > [class*="icon-"]:before {
    opacity: 1;
    filter: alpha(opacity=100)
}

.infobox-dark .infobox-content {
    color: #ffffff
}

.infobox > .infobox-progress {
    padding-top: 0;
    display: inline-block;
    vertical-align: top;
    width: 44px
}

.infobox > .infobox-chart {
    padding-top: 0;
    display: inline-block;
    vertical-align: text-bottom;
    width: 44px;
    text-align: center
}

.infobox > .infobox-chart > .sparkline {
    font-size: 24px
}

.infobox > .infobox-chart canvas {
    vertical-align: middle !important
}

.infobox > .stat {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 11px;
    text-shadow: none;
    color: #abbac3;
    font-size: 13px;
    font-weight: bold;
    padding-right: 18px;
    padding-top: 3px
}

.infobox > .stat:before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 11px;
    background-color: #abbac3;
    position: absolute;
    right: 4px;
    top: 7px
}

.infobox > .stat:after {
    display: inline-block;
    content: "";
    position: absolute;
    right: 1px;
    top: -8px;
    border: 12px solid transparent;
    border-width: 8px 7px;
    border-bottom-color: #abbac3
}

.infobox > .stat.stat-success {
    color: #77c646
}

.infobox > .stat.stat-success:before {
    background-color: #77c646
}

.infobox > .stat.stat-success:after {
    border-bottom-color: #77c646
}

.infobox > .stat.stat-important {
    color: #e4564f
}

.infobox > .stat.stat-important:before {
    background-color: #e4564f;
    top: 3px
}

.infobox > .stat.stat-important:after {
    border-top-color: #e4564f;
    border-bottom-color: transparent;
    bottom: -6px;
    top: auto
}

.infobox.infobox-dark > .stat {
    color: #ffffff
}

.infobox.infobox-dark > .stat:before {
    background-color: #e1e5e8
}

.infobox.infobox-dark > .stat:after {
    border-bottom-color: #e1e5e8
}

.infobox.infobox-dark > .stat.stat-success {
    color: #ffffff
}

.infobox.infobox-dark > .stat.stat-success:before {
    background-color: #d0e29e
}

.infobox.infobox-dark > .stat.stat-success:after {
    border-bottom-color: #d0e29e
}

.infobox.infobox-dark > .stat.stat-important {
    color: #ffffff
}

.infobox.infobox-dark > .stat.stat-important:before {
    background-color: #ff8482;
    top: 3px
}

.infobox.infobox-dark > .stat.stat-important:after {
    border-top-color: #ff8482;
    border-bottom-color: transparent;
    bottom: -6px;
    top: auto
}

.infobox > .badge {
    position: absolute;
    right: 20px;
    top: 11px;
    border-radius: 0;
    text-shadow: none;
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    height: 16px;
    padding: 0 1px
}

.infobox.infobox-dark > .badge {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border: 1px solid #f1f1f1;
    top: 2px;
    right: 2px
}

.infobox.infobox-dark > .badge.badge-success > [class*="icon-"] {
    color: #c6e9a1
}

.infobox.infobox-dark > .badge.badge-important > [class*="icon-"] {
    color: #ecb792
}

.infobox.infobox-dark > .badge.badge-warning > [class*="icon-"] {
    color: #ecb792
}

.infobox-small {
    width: 125px;
    height: 45px;
    text-align: left;
    padding-bottom: 5px
}

.infobox-small > .infobox-icon, .infobox-small > .infobox-chart, .infobox-small > .infobox-progress {
    display: inline-block;
    width: 40px;
    max-width: 40px;
    height: 42px;
    line-height: 38px;
    vertical-align: middle
}

.infobox-small > .infobox-data {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    max-width: 72px;
    min-width: 0
}

.infobox-small > .infobox-chart > .sparkline {
    font-size: 14px;
    margin-left: 2px
}

.percentage {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top
}

.infobox-small .percentage {
    font-size: 13px;
    font-weight: normal;
    margin-top: 2px;
    margin-left: 2px
}

.infobox-circle {
    border-radius: 100% 100% 100% 100%;
    height: 50px;
    width: 50px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
    margin-right: 3px;
}

.infobox-iconcircle {
    margin: 14px 0 0 8px;
    color: #ffffff;
    font-size: 32px;
    line-height: 50px;
}

.infobox > .infobox-icon > [class*="icon-"] {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    border-radius: 100%;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    display: inline-block;
    height: 42px;
    margin: 0;
    padding: 1px 1px 0 2px;
    position: relative;
    text-align: center;
}

.tags-hover, .tags-hover:hover {
    border-color: #f59942;
    outline: 0;
    outline: thin dotted \9

}

.pagination ul > li > a, .pager > li > a {
    border-width: 1px;
    border-radius: 0 !important
}

.pagination ul > li > a, .pager > li > a {
    color: #2283c5;
    background-color: #fafafa;
    margin: 0 -1px 0 0;
    border-color: #e0e8eb
}

.pagination ul > li > a:hover, .pager > li > a:hover {
    background-color: #eaeff2
}

.pagination ul > li.disabled > a, .pagination ul > li.disabled > a:hover, .pager > li.disabled > a, .pager > li.disabled > a:hover {
    background-color: #f9f9f9;
    border-color: #d9d9d9
}

.pagination ul > li.active > a, .pagination ul > li.active > a:hover {
    background-color: #6faed9;
    border-color: #6faed9;
    color: #ffffff;
}

/* inicio das tabelas */

.crud-table th {
    border: 0 !important;
    border-bottom: 1px solid #cccccc !important;
    border-collapse: separate !important;
    color: #707070 !important;
    display: table-cell !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
    font-size: 13px !important;
    font-weight: bold !important;
    height: 24px !important;
    line-height: 24px !important;
    padding: 8px !important;
    text-align: left !important;
    vertical-align: middle !important;
    background: #ffffff !important;
    letter-spacing: .4px;
}

/*.crud-table tbody:before{
    content: '';
    display: block;
    height: 10px;
}*/

.iceDatTblRow .crud-tableRow .crud-grid-formRow .crud-grid-row-odd, .crud-grid-row-odd td,
.iceDatTblRow .crud-tableRow .crud-grid-formRow .crud-grid-row-odd, .crud-grid-row-odd td .iceOutTxt,
.iceDatTblRow .crud-tableRow .crud-grid-formRow .crud-grid-row-even, .crud-grid-row-even td,
.iceDatTblRow .crud-tableRow .crud-grid-formRow .crud-grid-row-even, .crud-grid-row-even td .iceOutTxt{
    border-collapse: separate !important;
    color: #6e6e6e !important;
    display: table-cell !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
    font-size: 13px !important;
    height: 26px !important;
    line-height: 16px !important;
    padding: 4px 8px !important;
    vertical-align: middle !important;
    text-align: left !important;
    font-weight: 400;
    letter-spacing: .4px;
}

td .iceOutTxt {
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
    color: #2A2624 !important;
}

.crud-table tr:hover > td {
    background-color: #f5f5f5 !important;
}

.modal.fade {
    opacity: 1 !important;
}

.box-content {
    height: 480px;
}

.easyPieChart {
    position: relative;
    text-align: center
}

.easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0
}

.profile-info-name, .profile-info-value {
    font-size: 12px;
}

input[type=radio] {
    opacity: 0;
    z-index: 12;
    width: 18px !important;
    height: 18px !important;
}

input[type=checkbox]:checked, input[type=radio]:checked,
input[type=checkbox]:focus, input[type=radio]:focus {
    outline: none !important
}

input[type=checkbox] + .lbl,
input[type=checkbox] + label {
    word-break: normal;
}

input[type=checkbox] + .lbl, input[type=radio] + .lbl,
input[type=checkbox] + label, input[type=radio] + label {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 20px;
    min-height: 14px;
    min-width: 14px;
    font-weight: normal;
}

input[type=radio] + .lbl,
input[type=radio] + label {
    margin-left: 20px;
}

input[type=checkbox] + .lbl.padding-16::before, input[type=radio] + .lbl.padding-16::before {
    margin-right: 16px;
}

input[type=checkbox] + .lbl.padding-12::before, input[type=radio] + .lbl.padding-12::before {
    margin-right: 12px;
}

input[type=checkbox] + .lbl.padding-8::before, input[type=radio] + .lbl.padding-8::before {
    margin-right: 8px;
}

input[type=checkbox] + .lbl.padding-4::before, input[type=radio] + .lbl.padding-4::before {
    margin-right: 4px;
}

input[type=checkbox] + .lbl.padding-0::before, input[type=radio] + .lbl.padding-0::before {
    margin-right: 0;
}

input[type=checkbox] + .lbl::before,
input[type=checkbox] + label::before {
    font-family: 'Material Icons';
    font-weight: bold;
    font-size: 11px;
    color: #32a3ce;
    content: "\a0";
    background-color: #fafafa;
    border: 1px solid #cccccc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: 13px;
    line-height: 15px;
    min-width: 13px;
    margin-right: 8px;
}

input[type=radio]:checked + .lbl::before,
input[type=radio]:checked + label::before {
    font-size: 39px;
}

input[type=checkbox]:checked + .lbl::before, input[type=radio]:checked + .lbl::before,
input[type=checkbox]:checked + label::before, input[type=radio]:checked + label::before{
    display: inline-block;
    content: 'done';
    text-transform: none;
    border-color: #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}

input[type=checkbox]:hover + .lbl::before, input[type=radio]:hover + .lbl::before, input[type=checkbox] + .lbl:hover::before, input[type=radio] + .lbl:hover::before {
    cursor: pointer;
}

input[type=checkbox]:active + .lbl::before, input[type=radio]:active + .lbl::before, input[type=checkbox]:checked:active + .lbl::before, input[type=radio]:checked:active + .lbl::before {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 1px 3px rgba(0, 0, 0, 0.1)
}

input[type=checkbox].ace-checkbox-2 + .lbl::before, input[type=radio].ace-checkbox-2 + .lbl::before {
    box-shadow: none
}

input[type=checkbox].ace-checkbox-2:checked + .lbl::before, input[type=radio].ace-checkbox-2:checked + .lbl::before {
    background-color: #f9a021;
    border-color: #f9a021;
    color: #ffffff
}

span.lbl.iceSelOneRb {
    margin: 0px 10px 0px 22px;
    pointer-events: none;
}

input[type=radio]:disabled + .lbl::before, input[type=radio][disabled] + .lbl::before, input[type=radio].disabled + .lbl::before {
    background-color: #dddddd !important;
    border-color: #cccccc !important;
    box-shadow: none !important;
    color: #bbbbbb
}

input[type=radio] + .lbl::before {
    border-radius: 32px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 36px
}

input[type=radio]:checked + .lbl::before {
    content: "\2022";
    display: none;
}

input[type=checkbox].ace-switch {
    width: 55px;
    height: 20px
}

input[type=checkbox].ace-switch + .lbl {
    margin: 0 4px;
    min-height: 24px
}

input[type=checkbox].ace-switch + .lbl::before {
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    content: "ON\A0\A0\A0\A0\A0\A0\A0\A0\A0OFF";
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 12px;
    background-color: #b5b5b5;
    text-align: left;
    float: left;
    padding: 0;
    width: 50px;
    text-indent: -19px;
    text-indent: -21 px\9;
    margin-right: 0;
    transition: text-indent .4s ease;
}

input[type=checkbox].ace-switch + .lbl::after {
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    content: '|||';
    font-size: 6px;
    color: #696969;
    background-color: #ffffff;
    text-align: center;
    border-radius: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    position: absolute;
    top: 2px;
    left: 2px;
    padding: 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: left .4s ease;
    letter-spacing: 1.5px;
}

input[type=checkbox].ace-switch:checked + .lbl::before {
    text-indent: 9px;
    color: #ffffff;
    background-color: #4A90E2;
    border: 0;
}

input[type=checkbox].ace-switch:checked + .lbl::after {
    left: 34px;
    background-color: #ffffff;
    color: #696969;
}

input[type=checkbox].ace-switch.ace-switch-2 + .lbl::before {
    content: "YES\a0\a0\a0\a0\a0\a0\a0\a0NO"
}

input[type=checkbox].ace-switch.ace-switch-3 + .lbl::after {
    font-family: FontAwesome;
    font-size: 13px;
    line-height: 23px;
    content: "\f00d";
    top: -1px
}

input[type=checkbox].ace-switch.ace-switch-3:checked + .lbl::after {
    content: "\f00c"
}

input[type=checkbox].ace-switch.ace-switch-4 + .lbl::before, input[type=checkbox].ace-switch.ace-switch-5 + .lbl::before {
    content: "ON\a0\a0\a0\a0\a0\a0\a0\a0\a0OFF";
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    line-height: 21 px\9;
    border-radius: 12px;
    background-color: #8b9aa3;
    color: #ffffff;
    width: 52px;
    text-indent: -25px;
    text-indent: -28 px\9;
    display: inline-block;
    position: relative;
    margin-right: 8px;
    box-shadow: none;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease
}

input[type=checkbox].ace-switch.ace-switch-4 + .lbl::after, input[type=checkbox].ace-switch.ace-switch-5 + .lbl::after {
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    content: '|||';
    font-size: 6px;
    font-weight: lighter;
    color: #8b9aa3;
    text-align: center;
    position: absolute;
    border-radius: 16px;
    color: #5b6a73;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: #ffffff;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease
}

input[type=checkbox].ace-switch.ace-switch-4:checked + .lbl::before, input[type=checkbox].ace-switch.ace-switch-5:checked + .lbl::before {
    text-indent: 9px;
    background-color: #468fcc;
    border-color: #468fcc
}

input[type=checkbox].ace-switch.ace-switch-4:checked + .lbl::after, input[type=checkbox].ace-switch.ace-switch-5:checked + .lbl::after {
    left: 36px;
    background-color: #ffffff
}

input[type=checkbox].ace-switch.ace-switch-5 + .lbl::before {
    content: "YES\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0NO"
}

input[type=checkbox].ace-switch.ace-switch-5:checked + .lbl::before {
    text-indent: 8px
}

input[type=checkbox].ace-switch.ace-switch-6 + .lbl {
    position: relative
}

input[type=checkbox].ace-switch.ace-switch-6 + .lbl::before {
    font-family: FontAwesome;
    content: "\f00d";
    box-shadow: none;
    border: 0;
    font-weight: lighter;
    font-size: 16px;
    border-radius: 12px;
    display: inline-block;
    background-color: #888888;
    color: #f2f2f2;
    width: 52px;
    height: 20px;
    line-height: 19px;
    text-indent: 32px;
    margin-right: 8px;
    -webkit-transition: background .1s ease;
    -moz-transition: background .1s ease;
    -o-transition: background .1s ease;
    transition: background .1s ease
}

input[type=checkbox].ace-switch.ace-switch-6 + .lbl::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 12px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    width: 8px;
    height: 8px;
    text-align: center;
    background-color: #f2f2f2;
    border: 4px solid #f2f2f2;
    -webkit-transition: left .2s ease;
    -moz-transition: left .2s ease;
    -o-transition: left .2s ease;
    transition: left .2s ease;
}

input[type=checkbox].ace-switch.ace-switch-6:checked + .lbl::before {
    content: "\f00c";
    text-indent: 6px;
    color: #ffffff;
    border-color: #b7d3e5;
    background-color: #ff893c
}

input[type=checkbox].ace-switch.ace-switch-6:checked + .lbl::after {
    left: 34px;
    background-color: #ffffff;
    border: 4px solid #ffffff;
}

input[type=checkbox].ace-switch.ace-switch-7 {
    width: 75px
}

input[type=checkbox].ace-switch.ace-switch-7 + .lbl {
    position: relative
}

input[type=checkbox].ace-switch.ace-switch-7 + .lbl::before {
    content: "Não\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0Sim";
    font-weight: bolder;
    font-size: 14px;
    line-height: 20px;
    background-color: #ffffff;
    border: 2px solid #aaaaaa;
    border-radius: 0;
    box-shadow: none;
    color: #aaaaaa;
    width: 70px;
    height: 22px;
    line-height: 22px;
    overflow: hidden;
    text-indent: 4px;
    display: inline-block;
    position: relative;
    margin-right: 8px;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease
}

.colorpicker_field > input, .colorpicker_hex > input{ display: none; }

input[type=checkbox].ace-switch.ace-switch-7 + .lbl::after {
    content: '\f00d';
    font-family: FontAwesome;
    font-size: 16px;
    position: absolute;
    top: 3px;
    left: 39px;
    width: 32px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    background-color: #aaaaaa;
    color: #ffffff;
    border-radius: 0;
    box-shadow: none;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

input[type=checkbox].ace-switch.ace-switch-7:checked + .lbl::before {
    color: #468fcc;
    background-color: #ffffff;
    text-indent: -33px;
    border-color: #6fb3e0
}

input[type=checkbox].ace-switch.ace-switch-7:checked + .lbl::after {
    left: 3px;
    content: '\f00c';
    background-color: #468fcc;
    color: #ffffff
}

.nav-tabs .disable a {
    background: #eeeeee;
    cursor: not-allowed;
}

.control-group select, .control-group textarea, .control-group input[type="text"], .control-group input[type="password"], .control-group input[type="datetime"], .control-group input[type="datetime-local"], .control-group input[type="date"], .control-group input[type="month"], .control-group input[type="time"], .control-group input[type="week"], .control-group input[type="number"], .control-group input[type="email"], .control-group input[type="url"], .control-group input[type="search"], .control-group input[type="tel"], .control-group input[type="color"] {
    background: #ffffff
}

.control-group.success input, .control-group.success select, .control-group.success textarea {
    border-color: #92bf65;
    color: #8bad4c;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.control-group.success input:focus, .control-group.success select:focus, .control-group.success textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(130, 188, 58, 0.3);
    -moz-box-shadow: 0 0 0 2px rgba(130, 188, 58, 0.3);
    box-shadow: 0 0 0 2px rgba(130, 188, 58, 0.3);
    color: #778866;
    border-color: #81a85a
}

.control-group.success [class*="icon-"] {
    color: #8bad4c
}

.control-group.success .btn [class*="icon-"] {
    color: inherit
}

.control-group.success .control-label, .control-group.success .help-block, .control-group.success .help-inline {
    color: #7ba065
}

.control-group.info input, .control-group.info select, .control-group.info textarea {
    border-color: #64a6bc;
    color: #4b89aa;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.control-group.info input:focus, .control-group.info select:focus, .control-group.info textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(58, 120, 188, 0.3);
    -moz-box-shadow: 0 0 0 2px rgba(58, 120, 188, 0.3);
    box-shadow: 0 0 0 2px rgba(58, 120, 188, 0.3);
    color: #667788;
    border-color: #5a81a8
}

.control-group.info [class*="icon-"] {
    color: #4b89aa
}

.control-group.info .btn [class*="icon-"] {
    color: inherit
}

.control-group.info .control-label, .control-group.info .help-block, .control-group.info .help-inline {
    color: #657ba0
}

.control-group.error input,
.control-group.error input::placeholder,
.control-group.error select,
.control-group.error textarea,
.control-group.error textarea::placeholder,
.control-group.error .BngSelectSearch,
.control-group.error .BngSelectSearch .OptionPreview label {
    border-color: #f09784;
    color: #d68273;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    z-index: 2;
}

.control-group.error input:focus, .control-group.error select:focus, .control-group.error textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(219, 137, 120, 0.3);
    -moz-box-shadow: 0 0 0 2px rgba(219, 137, 120, 0.3);
    box-shadow: 0 0 0 2px rgba(219, 137, 120, 0.3);
    color: #886666;
    border-color: #db8978;
    z-index: 2;
}

.control-group.error [class*="icon-"] {
    color: #d68273
}

.control-group.error .btn [class*="icon-"] {
    color: inherit
}

.control-group.error .control-label, .control-group.error .help-block, .control-group.error .help-inline, .help-inline.error {
    color: #d16e6c
}

.control-group.warning input, .control-group.warning select, .control-group.warning textarea {
    border-color: #e0c43a;
    color: #d3bd50;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.control-group.warning input:focus, .control-group.warning select:focus, .control-group.warning textarea:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(216, 188, 65, 0.3);
    -moz-box-shadow: 0 0 0 2px rgba(216, 188, 65, 0.3);
    box-shadow: 0 0 0 2px rgba(216, 188, 65, 0.3);
    color: #887755;
    border-color: #d8bc41
}

.control-group.warning [class*="icon-"] {
    color: #d3bd50
}

.control-group.warning .btn [class*="icon-"] {
    color: inherit
}

.control-group.warning .control-label, .control-group.warning .help-block, .control-group.warning .help-inline {
    color: #d19d59
}

.control-group input[disabled], .control-group input:disabled {
    color: #848484 !important;
    background-color: #eeeeee !important
}

.slimScrollBar {
    border-radius: 4px !important;
    width: 6px !important;
}

.slimScrollRail {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important
}

.datepicker td, .daterangepicker td, .datepicker th, .daterangepicker th {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important
}

.datepicker td.active, .daterangepicker td.active, .datepicker td.active:hover, .daterangepicker td.active:hover {
    background: #2283c5 !important
}

.datepicker td.active.disabled, .daterangepicker td.active.disabled, .datepicker td.active.disabled:hover, .daterangepicker td.active.disabled:hover {
    background: #8b9aa3 !important
}

.bootstrap-timepicker-widget table td a:hover {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.datepicker table tr td.day:hover {
    background-color: #7d8893;
    color: #ffffff
}

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    border-width: 1px;
    color: #858585;
    background-color: #ffffff;
    border-color: #d5d5d5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition-duration: .1s;
    -moz-transition-duration: .1s;
    -o-transition-duration: .1s;
    transition-duration: .1s
}

textarea:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="time"]:hover, input[type="week"]:hover, input[type="number"]:hover, input[type="email"]:hover, input[type="url"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="color"]:hover {
    border-color: #b5b5b5
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #696969;
    border-color: #f59942;
    background-color: #ffffff
}

input.block {
    display: block;
    margin-bottom: 9px
}

textarea.autosize-transition {
    -webkit-transition-duration: "height 0.2s";
    -moz-transition-duration: "height 0.2s";
    -o-transition-duration: "height 0.2s";
    transition-duration: "height 0.2s"
}

.limiterBox {
    border: 1px solid #222222;
    border-top: 0;
    background-color: #333333;
    padding: 3px 6px;
    font-size: 12px;
    color: #ffffff;
    margin-top: 6px
}

.limiterBox:after {
    display: none
}

.limiterBox:before {
    display: block;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -8px;
    left: 50%;
    margin-left: -5px;
    border-color: transparent;
    border-style: solid;
    border-bottom-color: #333333;
    border-width: 0 8px 8px;
}

.tooltip.left {
    margin-left: -11px
}

.tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100)
}

.tooltip-inner {
    background-color: #333333;
    color: #ffffff;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 11px;
    font-weight: bold;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.tooltip.top .tooltip-arrow {
    border-top-color: #333333
}

.tooltip.right .tooltip-arrow {
    border-right-color: #333333
}

.tooltip.left .tooltip-arrow {
    border-left-color: #333333
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #333333
}

.tooltip-error + .tooltip > .tooltip-inner {
    background-color: #c94d32;
    color: #ffffff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.tooltip-error + .tooltip.top .tooltip-arrow {
    border-top-color: #c94d32
}

.tooltip-error + .tooltip.right .tooltip-arrow {
    border-right-color: #c94d32
}

.tooltip-error + .tooltip.left .tooltip-arrow {
    border-left-color: #c94d32
}

.tooltip-error + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #c94d32
}

.tooltip-success + .tooltip > .tooltip-inner {
    background-color: #629b58;
    color: #ffffff;
    border-radius: 0
}

.tooltip-success + .tooltip.top .tooltip-arrow {
    border-top-color: #629b58
}

.tooltip-success + .tooltip.right .tooltip-arrow {
    border-right-color: #629b58
}

.tooltip-success + .tooltip.left .tooltip-arrow {
    border-left-color: #629b58
}

.tooltip-success + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #629b58
}

.tooltip-warning + .tooltip > .tooltip-inner {
    background-color: #ed9421;
    color: #ffffff;
    border-radius: 0
}

.tooltip-warning + .tooltip.top .tooltip-arrow {
    border-top-color: #ed9421
}

.tooltip-warning + .tooltip.right .tooltip-arrow {
    border-right-color: #ed9421
}

.tooltip-warning + .tooltip.left .tooltip-arrow {
    border-left-color: #ed9421
}

.tooltip-warning + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #ed9421
}

.tooltip-info + .tooltip > .tooltip-inner {
    background-color: #4b89aa;
    color: #ffffff;
    border-radius: 0
}

.tooltip-info + .tooltip.top .tooltip-arrow {
    border-top-color: #4b89aa
}

.tooltip-info + .tooltip.right .tooltip-arrow {
    border-right-color: #4b89aa
}

.tooltip-info + .tooltip.left .tooltip-arrow {
    border-left-color: #4b89aa
}

.tooltip-info + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #4b89aa
}

.popover {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0;
    border-color: #cccccc;
    border-width: 1px;
    -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    color: #4d6883
}

.popover-title {
    border-radius: 0;
    background-color: #eff3f8;
    color: #555555;
    border-bottom: 1px solid #dfe3e8;
}

.popover.bottom .arrow:after {
    top: 1px;
    margin-left: -10px;
    border-bottom-color: #eff3f8;
    border-top-width: 0
}

.tooltip-error + .popover {
    color: #555555;
    border: 1px solid #f7f0ef
}

.tooltip-error + .popover .popover-title {
    background-color: #f7f0ef;
    border-bottom-color: #e8e0df;
    color: #b75445;
    text-shadow: none
}

.tooltip-error + .popover.top .arrow:after {
    border-top-color: #f7f0ef
}

.tooltip-error + .popover.bottom .arrow:after {
    border-bottom-color: #f7f0ef
}

.tooltip-error + .popover.right .arrow:after {
    border-right-color: #f7f0ef
}

.tooltip-error + .popover.left .arrow:after {
    border-left-color: #f7f0ef
}

.tooltip-warning + .popover {
    color: #555555;
    border: 1px solid #f4eee3
}

.tooltip-warning + .popover .popover-title {
    background-color: #f4eee3;
    border-bottom-color: #e4dcd3;
    color: #d67e31;
    text-shadow: none
}

.tooltip-warning + .popover.top .arrow:after {
    border-top-color: #f4eee3
}

.tooltip-warning + .popover.bottom .arrow:after {
    border-bottom-color: #f4eee3
}

.tooltip-warning + .popover.right .arrow:after {
    border-right-color: #f4eee3
}

.tooltip-warning + .popover.left .arrow:after {
    border-left-color: #f4eee3
}

.tooltip-success + .popover {
    color: #555555;
    border: 1px solid #e8f2e3
}

.tooltip-success + .popover .popover-title {
    background-color: #e8f2e3;
    border-bottom-color: #d8e2d3;
    color: #629b58;
    text-shadow: none
}

.tooltip-success + .popover.top .arrow:after {
    border-top-color: #e8f2e3
}

.tooltip-success + .popover.bottom .arrow:after {
    border-bottom-color: #e8f2e3
}

.tooltip-success + .popover.right .arrow:after {
    border-right-color: #e8f2e3
}

.tooltip-success + .popover.left .arrow:after {
    border-left-color: #e8f2e3
}

.tooltip-info + .popover {
    color: #555555;
    border: 1px solid #e5edf8
}

.tooltip-info + .popover .popover-title {
    background-color: #e5edf8;
    border-bottom-color: #d5dde8;
    color: #3f79b6;
    text-shadow: none
}

.tooltip-info + .popover.top .arrow:after {
    border-top-color: #e5edf8
}

.tooltip-info + .popover.bottom .arrow:after {
    border-bottom-color: #e5edf8
}

.tooltip-info + .popover.right .arrow:after {
    border-right-color: #e5edf8
}

.tooltip-info + .popover.left .arrow:after {
    border-left-color: #e5edf8
}

.popover-notitle + .popover .popover-title {
    display: none
}

.popover-notitle + .popover.top .arrow:after {
    border-top-color: #ffffff
}

.popover-notitle + .popover.bottom .arrow:after {
    border-bottom-color: #ffffff
}

.popover-notitle + .popover.left .arrow:after {
    border-left-color: #ffffff
}

.popover-notitle + .popover.right .arrow:after {
    border-left-color: #ffffff
}

.iceOutLbl {
    text-align: left !important;
}

.dropdown-navbar > li:last-child > a:hover > [class*="icon-"] {
    text-decoration: none
}

.progress {
    border-radius: 0;
    height: 18px;
    box-shadow: none;
    background: #b5b5b5
}

.progress .bar {
    box-shadow: none;
    line-height: 18px
}

.progress[data-percent] {
    position: relative
}

.progress[data-percent]:after {
    display: inline-block;
    content: attr(data-percent);
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: -apple-system, "Roboto", "San Francisco", "Helvetica Neue", "Lato", "Open Sans", Helvetica, Arial;
}

.progress.progress-yellow[data-percent]:after {
    color: #996633
}

.progress.progress-small {
    height: 12px
}

.progress.progress-small .bar {
    line-height: 10px;
    font-size: 11px
}

.progress.progress-small[data-percent]:after {
    line-height: 10px;
    font-size: 11px
}

.progress.progress-mini {
    height: 9px
}

.progress.progress-mini .bar {
    line-height: 8px;
    font-size: 11px
}

.progress.progress-mini[data-percent]:after {
    line-height: 8px;
    font-size: 11px
}

.progress .bar {
    background-image: none;
    background-color: #2a91d8
}

.progress-danger .bar, .progress .bar-danger {
    background-image: none;
    background-color: #ca5952
}

.progress-success .bar, .progress .bar-success {
    background-image: none;
    background-color: #59a84b
}

.progress-warning .bar, .progress .bar-warning {
    background-image: none;
    background-color: #f2bb46
}

.progress-pink .bar, .progress .bar-pink {
    background-image: none;
    background-color: #d6487e
}

.progress-purple .bar, .progress .bar-purple {
    background-image: none;
    background-color: #9585bf
}

.progress-yellow .bar, .progress .bar-yellow {
    background-image: none;
    background-color: #ffd259
}

.progress-inverse .bar, .progress .bar-inverse {
    background-image: none;
    background-color: #404040
}

.progress-grey .bar, .progress .bar-grey {
    background-image: none;
    background-color: #8a8a8a
}

.progress .bar + .bar {
    box-shadow: none
}

.progress-danger.progress-striped .bar, .progress-striped .bar-danger {
    background-color: #cc4942
}

.progress-warning.progress-striped .bar, .progress-striped .bar-warning {
    background-color: #eba450
}

.progress-success.progress-striped .bar, .progress-striped .bar-success {
    background-color: #55b83b
}

.progress-info.progress-striped .bar, .progress-striped .bar-info {
    background-color: #148bcf
}

.progress-pink.progress-striped .bar, .progress-striped .bar-pink {
    background-color: #d6487e;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-purple.progress-striped .bar, .progress-striped .bar-purple {
    background-color: #9585bf;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-yellow.progress-striped .bar, .progress-striped .bar-yellow {
    background-color: #ffd259;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-inverse.progress-striped .bar, .progress-striped .bar-inverse {
    background-color: #404040;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-grey.progress-striped .bar, .progress-striped .bar-grey {
    background-color: #8a8a8a;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress {
    position: relative
}

.progress:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: radial-gradient(9px 9px 0deg, circle farthest-corner, #00ffff 0, rgba(0, 0, 255, 0) 100%, #0000ff 95%)
}

.label-hint .iceOutTxt:hover {
    color: #4d6883 !important;
}

.label-hint:hover {
    color: #4d6883 !important;
}

#page-content-projects {
    margin-left: 8px;
}

.page-content {
    margin-left: 52px;
    margin-right: 2px;
}

.page-content-inner {
    margin-top: 10px;
}

#page-content + .right-side-sidebar {
    margin-right: 50px;
}

.iceOutProgTxt {
    display: none;
}

.modal {
    border-radius: 0
}

.modal-footer {
    border-top-color: #e4e9ee;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #eff3f8
}

.modal-header .close {
    font-size: 32px
}

.show-important {
    display: block !important;
}

.show-important-inline-flex {
    display: inline-flex !important;
}

.page-content-menu-right {
    margin-right: 200px;
}

th, td, .table-bordered {
    border-radius: 0 !important
}

.table thead tr {
    color: #707070;
    font-weight: normal;
    background: #f2f2f2;
    background-color: #f3f3f3;
    background-image: -moz-linear-gradient(top, #f8f8f8, #ececec);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f8f8f8), to(#ececec));
    background-image: -webkit-linear-gradient(top, #f8f8f8, #ececec);
    background-image: -o-linear-gradient(top, #f8f8f8, #ececec);
    background-image: linear-gradient(to bottom, #f8f8f8, #ececec);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0)
}

.table thead tr th {
    border-color: #dddddd;
    font-weight: bold
}

.table thead tr th [class*="icon-"]:first-child {
    margin-right: 2px
}

.table thead tr th:first-child {
    border-left-color: #f1f1f1
}

.table thead tr th:left-child {
    border-right-color: #f1f1f1
}

.table.table-bordered thead tr th {
    vertical-align: middle
}

.table.table-bordered thead tr th:first-child {
    border-left-color: #dddddd
}

.table.table-hover tbody tr:hover td {
    background-color: #f1f1f1
}

th.center, td.center {
    text-align: center
}

th .lbl, td .lbl {
    margin-bottom: 0
}

th .lbl:only-child, td .lbl:only-child {
    vertical-align: top
}

.table-header {
    background-color: #307ecc;
    color: #ffffff;
    font-size: 14px;
    line-height: 38px;
    padding-left: 12px;
    margin-bottom: 1px
}

.table-header .close {
    margin-right: 6px;
    margin-top: 6px
}

.dataTables_length {
    margin-left: 8px
}

.dataTables_length select {
    width: 70px;
    height: 25px;
    padding: 2px 3px
}

.txt-right {
    text-align: right;
}

.dataTables_filter {
    margin-right: 8px;
    text-align: right;
    margin-top: -30px;
}

.dataTables_info {
    font-size: 11px
}

.dataTables_paginate {
    text-align: right
}

.dataTables_paginate.pagination {
    margin: 0 12px
}

.dataTables_wrapper label {
    display: inline-block;
    font-size: 13px
}

.dataTables_wrapper input[type=text], .dataTables_wrapper select {
    margin-bottom: 0 !important;
    margin: 0 4px
}

.dataTables_wrapper .row-fluid:first-child {
    padding: 12px 0 4px;
    background-color: #eff3f8
}

.dataTables_wrapper .row-fluid:first-child + .dataTable {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd
}

.dataTables_wrapper .row-fluid:last-child {
    border-top: 1px solid #dddddd;
    padding: 12px 0 6px;
    background-color: #eff3f8;
    border-bottom: 1px solid #dddddd
}

.dataTable {
    margin-bottom: 0
}

.dataTable th[class*=sort] {
    cursor: pointer
}

.dataTable th[class*=sort] div:after {
    content: "\f0dc";
    display: inline-block;
    color: #555555;
    font-family: FontAwesome;
    font-size: 13px;
    font-weight: normal;
    float: right;
    margin-right: 4px;
    position: relative
}

.dataTable th[class*=sorting_] {
    color: #307ecc
}

.dataTable th.sorting_desc div:after {
    font-family: FontAwesome;
    content: "\f0dd";
    top: -2px
}

.dataTable th.sorting_asc div:after {
    font-family: FontAwesome;
    content: "\f0de";
    top: 4px
}

.dataTable th.sorting_disabled {
    cursor: inherit
}

.dataTable th.sorting_disabled:after {
    display: none
}

.dataTable th.sorting_disabled div:after {
    display: none
}

.paginate_button {
    background-color: #eff3f8;
    border-color: #e0e8eb;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    color: #2283c5;
    cursor: auto;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    height: 20px;
    margin: 0 -1px 0 0;
    padding: 4px 12px;
}

.paginate_active {
    background-color: rgb(111, 174, 217);
    border-bottom-color: rgb(111, 174, 217);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0px;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(111, 174, 217);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(111, 174, 217);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(111, 174, 217);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-style: solid;
    border-top-width: 1px;
    color: rgb(255, 255, 255);
    cursor: default;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    list-style-type: disc;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: -1px;
    margin-top: 0px;
    outline-color: rgb(255, 255, 255);
    outline-style: none;
    outline-width: 0px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    text-align: right;
    text-decoration: none solid rgb(255, 255, 255);
    width: 7px;
}

.input-icon {
    position: relative
}

span.input-icon {
    display: inline-block
}

.input-icon > input {
    padding-left: 24px;
    padding-right: 6px;
    width: 250px;
}

.input-icon.input-icon-right > input {
    padding-left: 6px;
    padding-right: 24px
}

.input-icon > [class*="icon-"] {
    padding: 0 3px;
    z-index: 2;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 3px;
    line-height: 28px;
    display: inline-block;
    color: #909090;
    font-size: 16px
}

.input-icon.input-icon-right > [class*="icon-"] {
    left: auto;
    right: 3px
}

.input-icon > input:focus + [class*="icon-"] {
    color: #557799
}

.input-icon ~ .help-inline {
    padding-left: 8px
}

.nav-search .nav-search-input {
    border-color: #6fb3e0;
    border-width: 1px;
    width: 120px;
    height: 18px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
    font-size: 13px;
    color: #666666 !important;
    z-index: 11;
    -webkit-transition: all ease .15s;
    -moz-transition: all ease .15s;
    -o-transition: all ease .15s;
    transition: all ease .15s
}

.nav-search .nav-search-input + .dropdown-menu {
    min-width: 0;
    left: 0;
    right: 0
}

.nav-search .nav-search-input:focus, .nav-search .nav-search-input:hover {
    border-color: #6fb3e0
}

.nav-search .nav-search-icon {
    color: #6fb3e0 !important;
    font-size: 14px !important;
    line-height: 24px !important
}

.nav-search-icon {
    color: #6fb3e0 !important;
    font-size: 14px !important;
    line-height: 24px !important;
}

.label-datatable-search {
    color: #ffffff;
    cursor: default;
}

.icePnlGrp .text_area {
    background: #ffffff;
}

.input-mini {
    width: 50px !important;
}

.page-header:first-child {
    margin: 0 0 12px;
    border-bottom: 1px dotted #e2e2e2
}

.page-header:first-child h1 {
    padding: 0;
    margin: 0 8px;
    font-size: 24px;
    font-weight: lighter;
    color: #2679b5
}

.page-header:first-child h1 small {
    margin: 0 6px;
    font-size: 14px;
    font-weight: normal;
    color: #8089a0
}

.dialog-text {
    font-size: 14px;
    font-weight: normal;
    color: #8089a0;
}

.page-header {
    background: none;
    border: 0px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    height: 50px;
}

.form-actions {
    background: none;
    margin-top: 0px;
}

.page-content-reports {
    margin-right: 50px !important;
}

.page-content-maps {
    margin-right: 43px !important;
}

.page-content-dashboard {
    margin-right: 40px !important;
}

.right-side-sidebar ul li .iceCmdLnk-dis span.material-icons,
.right-side-sidebar ul li .iceCmdLnk-dis span[class^='icon-bim-'] {
    font-size: 20px;
    padding: 0px 18px 0px 0px;
    line-height: 22px;
}

.right-side-sidebar ul li .iceCmdLnk-dis {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    min-width: 30px;
    text-align: center;
    vertical-align: middle;
    background-color: #efefef;
    color: #6e6e6e;
    height: 24px;
    line-height: 36px;
    text-decoration: none;
    text-shadow: none !important;
    padding: 10px 0px 10px 17px;
    opacity: .6;
}

.right-side-sidebar ul li .iceCmdLnk-dis span.material-icons {
    font-size: 20px;
}

.sidebar.right-side-sidebar .iceCmdLnk-dis span.icon-bim-saveas {
    padding: 0px 18px 0px 0px !important;
    margin-left: -2px;
    font-size: 21px !important;
}

.btn-toolbox, .btn-toolbox:hover {
    font-size: 14px;
    line-height: 24px;
    margin: 0 1px;
    padding-left: 5px;
    color: #aaaaaa;
    text-decoration: none;
}

.btn-toolbox .material-icons {
    font-size: 14px;
}

.menu_sttings:hover {
    background: transparent !important;
}

.submenu_botao {
    background-color: #ecf2f7 !important;
}

.menu_divider {
    border-bottom: 1px solid #f3e4ec !important;
}

.menu_sttings {
    border-bottom: 0px !important;
}

label {
    cursor: default !important;
}

.nav-tabs {
    font-size: 14px;
}

.version_info {
    bottom: 5px;
    font-size: 10px;
    left: 10px;
    position: absolute;
    color: #ffffff;
}

.btn-login, .btn-login:hover {
    width: 340px;
    height: 48px;
    background: #005dff !important;
    font-weight: 500;
    text-shadow: none !important;
    text-transform: uppercase;
    font-size: 13px;
    border: 0;
    border-radius: 0;
}

.container__free-trial-tip {
    height: 2rem;
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #333333;
    background-color: #EFEFEF;
}

.loginInputField input {
    border: 0;
    border-bottom: 1px solid #A9A9A9;
    border-radius: 0;
    font-size: 13px !important;
    color: #000 !important;
    line-height: 30px !important;
}

.loginInputField input:hover, .loginInputField input:focus{
    border-bottom: 1px solid #0005df;
}

span.lbl.loginRememberLabel {
    font-size: 13px;
}

#hierarchy-nav-container {
    padding: 0 0 100px 0;
}

.chk_structure, input[type=checkbox] {
    opacity: 0;
    position: absolute;
    z-index: 9999999;
    margin-top: 3px;
    width: 12px !important;
    height: 12px !important;
}

.iceSelMnyCb .manycheckbox input[type=checkbox], .iceSelMnyCb input[type=checkbox]{
    display: inline-block !important;
}

.dataTableRadio label{
    display: none;
}

.dataTableRadio{
    width: 100%;
}

.dataTableRadio td{
    border: none !important;
    text-align: center !important;
}

/*home academy*/
.home-academy .widget-body {
    height: 100px;
}
/*home academy*/

.widget-box .widget-body .icePnlGrp #indexPageFolderManager .slimScrollDiv .slim-scroll .iceDatTbl thead{
    display: none;
}

.sidebar .submenu .btn-icon-menu{
    color: #616161;
}

.mini-header .widget-header, .widget-box .mini-header .widget-header h4.lighter, .widget-box .mini-header .widget-header .lighter .smaller div,
.widget-box .mini-header .widget-header .widget-toolbar, .widget-box .mini-header .widget-header .widget-toolbar div,
.mini-header > .widget-caption, .mini-header > *:first-child, .mini-header .widget-toolbar,
.mini-header .widget-header > .mini-header .widget-caption, .mini-header .widget-header > *:first-child{
    height: 25px !important;
    min-height: 25px !important;
    line-height: 25px !important;
}

.mini-header .widget-toolbar a [class^="icon-"]:before:hover, .mini-header .widget-toolbar a [class*=" icon-"]:before:hover,
.mini-header .widget-toolbar [class^="icon-"]:before:hover, .mini-header .widget-toolbar [class*=" icon-"]:before:hover,
.mini-header .widget-toolbar [class^="icon-"]:hover, .mini-header .widget-toolbar [class*=" icon-"]:hover,
.mini-header > .widget-toolbar a:hover,
.btn-toolbox, .btn-toolbox:hover,
.mini-header .widget-toolbar a:before:hover, .mini-header .widget-toolbar a:after:hover, .mini-header .widget-toolbar a:hover{
    color: #5e5e5e;
}

.comment-box{
    border-bottom: 1px solid #E4ECF3;
    padding: 10px 2px 10px 2px;
    font-size: 12px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

.comment-box:hover{
    background: #f4f9fc;
}

.action-buttons {
    font-size: 16px;
    margin: 0 2px;
    display: inline-block;
    opacity: .85;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s
}

.action-buttons:hover {
    background: #f4f9fc !important;
    text-decoration: none;
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1
}

.table-comments, .table-comments tr, .table-comments tr td{
    border: none !important;
}

.link_hand:hover{
    cursor: pointer;
}

.demoTag{
    line-height: 13px;
    font-size: 9px;
    position: absolute;
    right: 40px;
    margin-top: 11px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .demoTag{
        line-height: 13px;
        font-size: 9px;
        position: absolute;
        right: 40px;
        margin-top: -25px;
    }
}

.btn-primary.active{
    background: #555555 !important;
}

legend {
    color: #669fc7;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 30px;
}

.termsuse{
    font-size: 9px;
    letter-spacing: 1px;
}

.termsusetitle{
    font-weight: bold;
}

.termsusebox{
    border: 1px solid #cccccc;
    padding: 5px;
}

.igree,.igree .middle{
    text-align: right;
    font-weight: bold;
}

.igree .middle input[type="checkbox"] + .lbl:before, input[type="radio"] + .lbl:before{
    border: 1px solid #000000;
}

.master-label {
    position: absolute;
    bottom: -27px;
    left: -3px;
}

.mobile-dasactivated .icon-mobile-phone, .icon-dasactivated .icon-external-link-sign, .icon-dasactivated .icon-envelope, .icon-dasactivated .icon-mail-forward{
    color:  #999999;
}

#xmlaNav\.membernav\.selectNone,
#xmlaNav\.membernav\.disableGrouping,
#xmlaNav\.membernav\.ok,
#xmlaNav\.membernav\.cancel,
#xmlaNav\.membernav\.enableGrouping,
#xmlaNav\.membernav\.selectVisible {
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 10.5px;
    border-radius: 2px;
    color: #fff;
    background-color: #006dcc;
    border: none;
    font-weight: 500;
    letter-spacing: .6px;
    text-transform: uppercase;
    padding: 2px 8px;
}

#xmlaNav\.membernav\.selectNone:hover, #xmlaNav\.membernav\.disableGrouping:hover, #xmlaNav\.membernav\.ok:hover, #xmlaNav\.membernav\.cancel:hover, #xmlaNav\.membernav\.enableGrouping:hover, #xmlaNav\.membernav\.selectVisible:hover{
    color: #fff;
    background-color: #04c;
    *background-color: #003bb3
}

#xmlaNav\.membernav\.disableGrouping {
    background-color: #faa732;
}

#xmlaNav\.membernav\.disableGrouping:hover, #xmlaNav\.membernav\.disableGrouping:focus, #xmlaNav\.membernav\.disableGrouping:active, #xmlaNav\.membernav\.disableGrouping.active, #xmlaNav\.membernav\.disableGrouping.disabled, #xmlaNav\.membernav\.disableGrouping[disabled] {
    color: #fff;
    background-color: #f89406;
    *background-color: #df8505
}

#xmlaNav\.membernav\.disableGrouping:active, #xmlaNav\.membernav\.disableGrouping.active {
    background-color: #c67605  \9
}

#xmlaNav\.membernav\.cancel {
    background-color: #da4f49;
}

#xmlaNav\.membernav\.cancel:hover, #xmlaNav\.membernav\.cancel:focus, #xmlaNav\.membernav\.cancel:active, #xmlaNav\.membernav\.cancel.active, #xmlaNav\.membernav\.cancel.disabled, #xmlaNav\.membernav\.cancel[disabled] {
    color: #fff;
    background-color: #bd362f;
    *background-color: #a9302a
}

#xmlaNav\.membernav\.cancel:active, #xmlaNav\.membernav\.cancel.active {
    background-color: #942a25  \9
}

#xmlaNav\.membernav\.ok {
    background-color: #5bb75b;
}

#xmlaNav\.membernav\.ok:hover, #xmlaNav\.membernav\.ok:focus, #xmlaNav\.membernav\.ok:active, #xmlaNav\.membernav\.ok.active, #xmlaNav\.membernav\.ok.disabled, #xmlaNav\.membernav\.ok[disabled] {
    color: #fff;
    background-color: #51a351;
    *background-color: #499249
}

#xmlaNav\.membernav\.ok:active, #xmlaNav\.membernav\.ok.active {
    background-color: #408140  \9
}

#analysisTable{
    width: 10px;
}

input[type="image"]#xmlaNav\.membernav\.cancel{
    width: 5px;
    height: 5px;
    padding: 5px;
    float: right;
}

.chartOption,
.tableThemeOption {
    display: inline-block;
    width: 440px;
}

li.chartOption>.chartOptionTitle, li.tableThemeOption>.chartOptionTitle{
    background: #eaeaea;
}

.chartOptionTitle{
    font-size: 12px;
    color: #000000;
    letter-spacing: 1px;
}

.chartOption td, .tableThemeOption td{
    width: 220px;
}

.tablethemereplication .chartOption td, .tableThemeOption td{
    width: 80px;
}

.tableThemeOption a, .tableThemeOption a:hover{
    font-size: 11px;
    line-height: 18px;
    display: inline-flex;
    color: #555555;
    background: transparent;
}

.tableThemeOption a, .tableThemeOption a:hover{
    color: #555555 !important;
    background: transparent !important;
    display: block !important;
}

.chartOption a{
    font-size: 12px;
    line-height: 18px;
    display: inline-flex;
}

.chartOptionImg, .tableThemeOption .chartOptionImg, .tableThemeOption .chartOptionImg:hover{
    margin: 0px 10px 0px 0px;
    float:left;
    width: 130px;
    height: 100px;
}

.chartOptionClose{
    float: right;
    margin: 5px 5px 0 0;
}

.dropdown-menu li a .chartOptionClose:hover{
    color: #fff;
    background-color: #bd362f !important;
    *background-color: #a9302a !important;
}

.chartOptionDesc{
    color: #333333;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 11px;
    text-align: justify
}

.analysis__charts-fragment__beta-component {
    box-sizing: border-box;
    border: 1px solid #d15b47;
    border-radius: 8px;
    display: flex;
    padding: 3px 3px;
    color: #d15b47;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0.4px;
    white-space: nowrap;
    margin-left: 5px;
}

.analysis__charts-fragment__new-component {
    box-sizing: border-box;
    border: 1px solid #629b58;
    border-radius: 8px;
    display: flex;
    padding: 3px 3px;
    color: #629b58;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0.4px;
    white-space: nowrap;
    margin-left: 5px;
}

.chartOption a i {
    margin-right: 4px;
}

.chartOption a:hover, .chartOption table tr td a:hover {
    display: inline-flex;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    background: transparent !important;
    color: #0088CC;
}

.chartOption td{
    padding-left: 25px;
}

.tableThemeOption .active .img-rounded,
.imageTheme.active img{
    border: 3px solid #6495ed;
    box-sizing: border-box;
}

.imageTheme img {
    width: 100px;
    height: 90px;
    margin: 5px;
}

.tablethemereplication .imageTheme img{
    width: 80px;
    height: 70px;
    margin: 5px;
    float: left;
}

.tableThemeOption .active{
    font-weight: 900;
}

.tableThemeOption td{
    text-align: center;
    padding: 0px 5px;
}

.dashboarditem h4{
    width: 99%;
}

.dashboarditem h4 div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}

.btn-group{
    display: inline-flex;
}

.btn-group-vertical {
    display: inline-block !important;
}

.dropdown-colorpicker > .dropdown-menu.pull-right{
    height: 120px;
}

.btn.jumbo {
    font-size: 20px;
    font-weight: normal;
    padding: 14px 24px;
    margin-right: 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.wysiwyg-editor {
    background-color: #f7f8fa;
    border: 1px solid #bbc0ca;
    border-collapse: separate;
    box-sizing: content-box;
    height: 100px;
    max-height: 250px;
    outline: 0 none;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 4px;
}

.btn-editor-noshow{
    display: none !important;
}

.btn-editor{
    display: block !important;
}

.btn-colorpicker{
    display: block !important;
}

.bootstrap-wysihtml5-insert-image-modal.modal.fade{
    opacity: 0 !important;
}

.bootstrap-wysihtml5-insert-image-modal.modal.fade.in{
    opacity: 1 !important;
}

.dialog-title{
    border-bottom: 1px solid #e4e9ee;
    font-size: 20px;
    font-weight: normal;
    line-height: 40px;
    margin: 0 0 10px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
    width: 100%;
    color: #000000;
    text-shadow: none;
}

option[disabled] { background-color: #dddddd; }

.btn-grey .analysis_icon, .btn-grey .analysis_icon:hover{
    background: #9F9F9F;
}

.analysis_icon{
    margin: 0 0 0 4px;
    opacity: 0.95;
    border-radius: 0;
    background: #2183C4;
    float: left;
}

.report-options-buttons .inline.position-relative,
.report-options-buttons form span{
    float: left;
    margin-left: 4px;
}

.analysis_icon:hover{
    opacity: 1;
}

.template4{
    border: none;
}

.infobox-data{
    white-space: nowrap;
}

.menu-text, .menu_option, .menutd {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 100%;
    text-align: left;
    letter-spacing: .3px;
    padding-left: 10px;
}

.menutd{
    height: 32px;
    padding: 0 3px;
}

.menu-min .menu-text{
    display: none;
}

.menutd i{font-size: 20px;}

.popover-content {
    padding: 9px 14px 18px;
}

.chosen-container {
    text-align: left;
}

.useBands td{
    vertical-align: middle;
}

.select2-container{
    width: 100%;
}

.iceSelOneMnu {
    white-space: nowrap;
}

/*
 start social icons
*/
.social ul {
    margin: 0 !important;
}
.soc_list {
    list-style: none outside none;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative;
}
.soc_list li {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 6px;
    text-align: center;
}
.soc_list li a {
    display: block;
    height: 60px;
    overflow: hidden;
    position: relative;
    width: 60px;
}
.soc_list li a img {
    left: 0;
    position: absolute;
    top: 0;
}
.soc_list li a:hover img {
    top: -60px;
}


.support-text-field{
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    font-size: 13px;
    height: 25px;
    width: 95%;
}


/*
 end social icons
*/

.typeaheadtext{
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
}

.tab-pane.active.mdx-table{width:0;}


.item-filter-config-container .iceSelMnyCb-dis td,
.item-filter-config-container .iceSelMnyCb td {
    border: none !important;
}

.select2-input {
    width: 100% !important;
}

.select2-search-field {
    width: 100%;
}

.dashboarditem .infobox tr td {
    vertical-align: middle;
}

#left-menu-container #hierarchy-nav-container .errorMessage {display: none}

#hierarchy-nav-container {
    position: relative;
}

.right-menu-container-report.open{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    min-width: 342px;
}

.analysis-dimension-menu-title {
    text-transform: uppercase;
    position: fixed;
    margin-top: -31px;
    margin-left: -1px;
    background: #EFEFEF;
    height: 32px;
    width: 20%;
    font-weight: 500;
    font-size: 13px;
    padding-left: 11px;
    letter-spacing: 0.4px;
    line-height: 32px;
    color: #191919;
    min-width: 309px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    z-index: 1;
}

.right-menu-container-report .analysis-dimension-menu-title{
    display: none;
}

.right-menu-container-report.open .analysis-dimension-menu-title{
    display: block;
}

.right-menu-container-report{
    position: absolute;

    /*habilitar para mover o menu para a direita*/
    right: 50px;
    /*top: 94px;*/

    /*retirar para mover o menu para a direita*/
    top: 118px;

    width: 0;
    z-index: 49;
    height: calc(100% - 118px);
    background: #e7e7e7;
    transition: all .4s ease;

    overflow: auto;
}

.right-menu-container-report-title{
    height: 46px;
    line-height: 46px;
    padding: 0 14px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-top: 1px solid rgba(0,0,0,.1);
    font-size: 13px;
    font-weight: bold;
    color: #565657;
}

.right-menu-container-report-options{
    padding: 14px;
}

.right-menu-container-footer-actions{
    width: 400px;
    background-color: #EFEFEF;
    height: 56px;
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.right-menu-container-footer-actions-inner{
    padding: 14px;
    text-align: right;
}

.right-menu-container-close-button span.material-icons {
    font-size: 30px;
    color: #565657;
}

.right-side-sidebar .submenu li .menu-text {
    height: 48px;
    font-size: 14px;
    text-align: center;
    line-height: 56px;
    background: #f7f8fa;
    color: #585858;
    font-weight: 500;
    border-bottom: 1px solid #e2e2e2;
}

.sidebar.menu-min.right-side-sidebar .nav-list > li > .submenu > li {
    padding: 0;
}

ul.submenu.submenu-right-side-dropdown{
    border-radius: 4px 0px 4px 4px;
    border: 1px solid #efefef;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,0.07);
}

.right-side-sidebar .submenu li .lbl {
    font-size: 13px !important;
    color: #6e6e6e;
    font-weight: 500;
    padding: 0 !important;
}

.dash-item-add-icon i {
    color: #6e6e6e;
    padding: 0 !important;
}

.dash-item-add-name {
    color: #6e6e6e;
    font-size: 13px;
    padding: 4px 0px 12px 0px;
}

.dash-item-add-icon {
    padding-top: 12px;
}

div#analysisReportView {
    margin-left: 0;
}

.dashboarditem .widget-toolbar{
    display: none;
}

.dash-item-menu {
    position: absolute;
    right: -1px;
    top: -1px;
    width: 14px;
}

.dash-tem-menu {
    margin-left: -33px;
    width: 20px;
}

.dash-menu-box {
    width: 100%;
    background-color: #ffffff;
    padding: 5px 5px 0;
    box-shadow: 1px 1px 10px #cccccc;
    text-align: center;
}

.dash-menu-box .btn-toolbox,
.dash-menu-box div,
.dash-menu-box .btn-toolbox:hover {
    display: block;
    padding: 0;
    margin: 0 0 5px;
}

.notificationsTable tr:first-child {
    border-top: none;
}

.notificationsTable tr:last-child {
    border-bottom: 1px solid #ECECEC;
}

.notificationsTable tr {
    border-top: 1px solid #ECECEC;
}

.node .stack-container .stack li{
    text-align: center;
    border-radius: 8px;
}

.node .stack-container .stack{
    border-radius: 8px;
    box-shadow: 4px 4px 6px #888888;
}

.htmlEditorTextVarsLabel{
    font-size: 12px;
    margin: 5px;
    font-weight: bold;
}

.htmlEditorTextVars button{
    margin: 0 3px;
}

.ui-resizable-handle{background:transparent !important;color: #111 !important;}

.text-overflow-ellipsis-remove{white-space: nowrap;}

.zoom-target{
    transform-origin: left top;
    -webkit-transform-origin: 0px 0px;
}

.itemFound td{
    background-color: #74abd7 !important;
}

.schedulerrunning{
    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}


.grid-stack > .grid-stack-item.ui-draggable-dragging > .grid-stack-item-content,
.grid-stack > .grid-stack-item.ui-resizable-resizing > .grid-stack-item-content {
    box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
    opacity: .5 !important;
}

.grid-stack .grid-stack-placeholder > .placeholder-content {
    border: 2px dashed #000000 !important;
}

div:not(.focus):not(:hover)::-webkit-scrollbar-thumb {
    transition: 1s all ease;
    background: rgba(0,0,0,0);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    transition: 1s all ease;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(108, 110, 113, 0.7);
    border-radius: 6px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
/* fixed columns table adjust */
.table-theme-modern-1 .clusterize-scroll::-webkit-scrollbar {
    background: #777777;
}

.table-theme-modern-3 .clusterize-scroll::-webkit-scrollbar {
    background: #333333;
}

.table-theme-modern-3 .clusterize-scroll::-webkit-scrollbar {
    background: #2c3446;
}

.table-theme-modern-7 .clusterize-scroll::-webkit-scrollbar {
    background: #c0504d;
}

.clusterize-scroll::-webkit-scrollbar {
    background: #fff;
}

.clusterize-scroll::-webkit-scrollbar-corner {
    background: #fff;
}
/* end fixed columns table adjust */

.checkbox-react-form{
    display: block !important;
    opacity: 1 !important;
    position: relative !important;
}

.imageDashOriginalSize {
    background-repeat: no-repeat !important;
}

.imageDashProportionalSize {
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

.imageDashResizeSize{
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}

.dashboard-item-links li a {
    border: none !important;
}

#dashboard-wrapper #body-wrapper {
    padding: 0;
    /*overflow: -webkit-paged-x;*/
}

.cockpit-item-panel .dashboard-wrapper-with-filters.filter-top-fixed #body-wrapper {
    padding: 14px 4px 2px 4px;
}

.cockpit-item-panel #body-wrapper {
    padding: 2px 4px;
}

#body-wrapper {
    padding: 16px 20px;
}

.AppPageWrapper {
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
}

body{
    /*overflow: hidden;*/
    font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-smooth: always !important;
    -webkit-font-smoothing: antialiased !important;
}

.mdl-textfield input {
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.12) !important;
    font-size: 16px !important;
    border-radius: 0;
    padding-left: 0px;
}

.login-box .mdl-textfield, .forgot-box .mdl-textfield {
    width: 335px;
}

.navbar .nav>li>a{
    padding: 0;
}

.permissionUsersList{
    border: 1px solid #cccccc;
    border-radius: 4px;
}

.main-container {
    background: #F6F6F6;
}

#wrapper.main-container {
    min-height: calc(48px - 100vh);
    transition: min-height var(--expand-header-transition);
}

#wrapper .main-container-inner {
    height: calc(100vh - 48px);
}

#render-edit-object.floating-edit-object.floating-edit-object-analysis {
    display: none;
}

.cockpit-header-hidden #wrapper.main-container,
.cockpit-header-hidden #wrapper .main-container-inner,
.cockpit-header-hidden #wrapper .main-content,
.cockpit-header-hidden #wrapper .main-container-inner {
    height: 100vh !important;
}

.widget-body-inner{
    background: #F6F6F6;
}

.chartOption a.chartOptionClose:hover{
    background-color: #d15b47 !important;
}

button.btn-only-icon:hover {
    background: transparent !important;
    color: #555555 !important;
}

button.btn-only-icon {
    background: transparent !important;
    color: #6e6e6e !important;
    padding: 0px !important;
    margin: 0px 4px !important;
    width: 16px;
}

button.btn-only-icon i.material-icons {
    font-size: 16px;
}

.btn-only-icon.disable,
.btn-only-icon.disable:hover {
    background: transparent !important;
    color: #8d8e8f !important;
}

.btn.btn-mini.btn-table {
    height: 24px;
    width: 24px;
    margin: 0 0 0 2px;
    border-radius: 2px;
    border: 0;
    text-shadow: none !important;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-mini.btn-table [class*="icon-"] {
    margin: 0;
}

.btn.btn-mini.btn-table .material-icons {
    font-size: 13px;
}

.btn.btn-mini.btn-table span{
    margin: 0
}

/* nova info do template */
.info-top-bar {
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #2481E2;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
    position: absolute;
    top: 52px;
    width: 100%;
    z-index: 999;
}

.info-top-bar a {
    color: #ffffff;
}

span.info-top-bar-close-button {
    position: absolute;
    right: 10px;
    top: 12px;
}

.info-top-bar-close-button i.material-icons {
    font-size: 16px;
}

.btnnmodalbtclose, .btnmodal.btnnmodalbtexpand {
    color: #565657;
    border-radius: 100%;
    padding: 5px;
    margin-right: -5px;
    margin-top: -5px;
    transition: background .3s linear;
}

.btnnmodalbtclose:before {
    content: '';
    position: absolute;
    top: 17px;
    left: 33px;
    width: 2px;
    height: 2px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

@keyframes ripple {
    0% {
        opacity: 1;
        transform: scale(0, 0);
    }
    50% {
        opacity: 1;
        transform: scale(8, 8);
    }
    100% {
        opacity: 1;
        transform: scale(16, 16);
    }
}

/* analise */

.report-options-buttons{
    position:absolute;
    z-index: 20;
    display: inline;
    margin-top: 6px;
    margin-left: 75px;
}

.report-options-buttons .btn.square-mini-btn{
    padding: 2px 8px;
    font-size: 12px;
    letter-spacing: .4px;
}

.report-options-buttons span.material-icons,
.report-options-buttons i.material-icons {
    font-size: 16px;
    padding: 2px;
    margin: 0px;
}

.report-options-buttons [class^="icon-bim-"] {
    font-size: 20px;
    margin: 0;
}

.report-options-buttons.show-chart{
    margin-left: 145px;
}

.TableLayoutsDropdown > li,
.ChartTypesDropdown > li,
.ChartStylesPopper > li {
    padding: 4px 14px;
}

.btn-uppercase{
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .4px;
    padding: 2px 12px;
}

/* fim nova info do template */

.colorPalleteSchemePreviewItem {
    width: 30px;
    height: 30px;
    float: left;
}

.projectColorPalleteItem {
    width: 36px;
    height: 36px;
    margin: 2px;
    border-radius: 100%;
    float: left;
    border: 2px solid rgba(0,0,0,0.2);
}

/* inicio cometarios dashboard */

.comment-box {
    padding: 14px 20px 20px 20px !important;
}

.msg-body.comment-box-user-msg-body {
    text-align: justify;
    width: 400px;
    letter-spacing: .4px;
}

.comment-box-user-avatar {
    width: 32px;
    margin: 0 !important;
    float: left;
}

span.comment-box-user-name {
    font-weight: 500;
    color: #000;
}

.comment-box-user-msg-mention {
    padding: 10px 0 0 0 !important;
}

.comment-box-actions {
    height: 14px;
}

.comment-box-actions {
    height: 14px;
}

span.msg-time.comment-box-user-msg-time .iceCmdLnk span.material-icons{
    font-size: 16px;
}

span.msg-time.comment-box-user-msg-time table {
    float: right;
}

/* fim cometarios dashboard */

.list-item-box.folder {
    background: #f8d775;
    font-weight: 500;
    clear: both;
}

.list-item-box.object {
    float:left
}

.list-item-box{
    box-sizing: border-box;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 4px 14px 4px 14px;
    margin: 0px 6px 14px 6px;
    font-size: 13px;
    letter-spacing: .5px;
}

.list-item-box-itens{
    background-color: #f7f8fa;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    padding: 14px;
    min-height: 140px;
}

.activities-search .profile-activity {
    background: transparent;
    padding: 0;
}

.FolderManagementActions {
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FolderManagementActions > * {
    margin-right: 5px !important;
}

.google-button:hover div {
    background-color: #1669F2 !important;
}

.google-button div, .google-button-dis div {
    display: inline-flex;
    background-color: #4285f4;
    border-radius: 2px;
}

.google-button div div, .google-button-dis div div {
    align-self: center;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: 'Roboto';
    padding-left: 6px;
    padding-right: 6px;
}

.general__config-checkbox-opt {
    width: 180px;
    height: 30px;
    float: left;
    margin-bottom: 10px;
}

.general__config-inputNumber-opt {
    width: 180px;
    height: 62px;
    float: left;
    margin-bottom: 10px;
    margin-right: 30px;
}
