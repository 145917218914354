.sweet-alert p {
    overflow: auto;
    max-height: 350px;
    max-height: 50vh;
}

.sweet-alert p > pre {
    overflow: visible !important;
}

div.sweet-alert > h2 {
    font-size: 20px;
    margin: 15px auto;
}

.sweet-alert div.sa-icon {
    margin: 0 auto;
}