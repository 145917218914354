.grid-stack-item > .icon-remove {
    position: absolute;
    top: 2px;
    right:  12px;
    cursor: pointer;
    display: none;
}

.grid-stack-item > .icon-gear {
    position: absolute;
    top: 2px;
    right:  28px;
    cursor: pointer;
    display: none;
}

.grid-stack>.grid-stack-item>.grid-stack-item-content {
    left: 0;
    right: 0;
    overflow: hidden !important;
}

.grid-stack>.grid-stack-item>.grid-stack-item-content.type-html {
    overflow: auto !important;
}

.grid-stack .grid-stack-item.ui-draggable-disabled {
    cursor: inherit !important;
}

.grid-stack > :not(.ui-resizable-disabled) > .grid-stack-item-content {
    overflow: hidden;
    background-color: white;
}

.grid-stack[data-edit-mode='true']>.grid-stack-item>.ui-resizable-handle {
    display: none !important;
}

.grid-stack[data-edit-mode='true']>.grid-stack-item:hover >.ui-resizable-handle {
    display: inline-block !important;
}

.grid-stack>.grid-stack-item>.ui-resizable-handle:before {
    font-family: FontAwesome;
    font-size: 12px;
    display: inline-block;
}

.grid-stack>.grid-stack-item>.ui-resizable-se {
    cursor: se-resize;
    width: 14px;
    height: 14px;
    right: -7px;
    bottom: -7px;
    background-color: white;
}

.grid-stack>.grid-stack-item>.ui-resizable-ne {
    cursor: ne-resize;
    width: 14px;
    height: 14px;
    right: -7px;
    top: -7px;
    background-color: white;
}

.grid-stack>.grid-stack-item>.ui-resizable-ne:before {
    content: "\f065";
    transform: rotate(180deg);
}


.grid-stack>.grid-stack-item>.ui-resizable-sw {
    cursor: sw-resize;
    width: 14px;
    height: 14px;
    left: -7px;
    bottom: -7px;
    background-color: white;
}

.grid-stack>.grid-stack-item>.ui-resizable-nw {
    cursor: nw-resize;
    width: 14px;
    height: 14px;
    left: -7px;
    top: -7px;
    background-color: white;
}

.grid-stack>.grid-stack-item>.ui-resizable-nw:before {
    content: "\f065";
    transform: rotate(90deg);
}

.grid-stack > :not(.ui-resizable-disabled) > .grid-stack-item-content .dash-item-menu {
    display: none !important;
}