.BngClickOutsideOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    cursor: default;
}

.BngClickOutsideOverlay.CockpitManagementSettings.Overlay {
    z-index: 100 !important;
    background: none !important;
}