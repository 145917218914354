.grid-stack.grid-stack-48 > .grid-stack-item {
    min-width: 2.08333333%;
}

.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='1'] {
    width: 2.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='1'] {
    left: 2.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='1'] {
    min-width: 2.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='1'] {
    max-width: 2.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='2'] {
    width: 4.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='2'] {
    left: 4.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='2'] {
    min-width: 4.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='2'] {
    max-width: 4.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='3'] {
    width: 6.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='3'] {
    left: 6.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='3'] {
    min-width: 6.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='3'] {
    max-width: 6.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='4'] {
    width: 8.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='4'] {
    left: 8.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='4'] {
    min-width: 8.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='4'] {
    max-width: 8.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='5'] {
    width: 10.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='5'] {
    left: 10.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='5'] {
    min-width: 10.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='5'] {
    max-width: 10.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='6'] {
    width: 12.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='6'] {
    left: 12.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='6'] {
    min-width: 12.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='6'] {
    max-width: 12.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='7'] {
    width: 14.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='7'] {
    left: 14.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='7'] {
    min-width: 14.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='7'] {
    max-width: 14.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='8'] {
    width: 16.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='8'] {
    left: 16.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='8'] {
    min-width: 16.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='8'] {
    max-width: 16.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='9'] {
    width: 18.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='9'] {
    left: 18.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='9'] {
    min-width: 18.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='9'] {
    max-width: 18.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='10'] {
    width: 20.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='10'] {
    left: 20.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='10'] {
    min-width: 20.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='10'] {
    max-width: 20.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='11'] {
    width: 22.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='11'] {
    left: 22.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='11'] {
    min-width: 22.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='11'] {
    max-width: 22.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='12'] {
    width: 25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='12'] {
    left: 25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='12'] {
    min-width: 25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='12'] {
    max-width: 25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='13'] {
    width: 27.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='13'] {
    left: 27.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='13'] {
    min-width: 27.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='13'] {
    max-width: 27.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='14'] {
    width: 29.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='14'] {
    left: 29.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='14'] {
    min-width: 29.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='14'] {
    max-width: 29.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='15'] {
    width: 31.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='15'] {
    left: 31.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='15'] {
    min-width: 31.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='15'] {
    max-width: 31.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='16'] {
    width: 33.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='16'] {
    left: 33.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='16'] {
    min-width: 33.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='16'] {
    max-width: 33.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='17'] {
    width: 35.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='17'] {
    left: 35.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='17'] {
    min-width: 35.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='17'] {
    max-width: 35.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='18'] {
    width: 37.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='18'] {
    left: 37.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='18'] {
    min-width: 37.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='18'] {
    max-width: 37.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='19'] {
    width: 39.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='19'] {
    left: 39.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='19'] {
    min-width: 39.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='19'] {
    max-width: 39.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='20'] {
    width: 41.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='20'] {
    left: 41.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='20'] {
    min-width: 41.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='20'] {
    max-width: 41.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='21'] {
    width: 43.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='21'] {
    left: 43.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='21'] {
    min-width: 43.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='21'] {
    max-width: 43.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='22'] {
    width: 45.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='22'] {
    left: 45.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='22'] {
    min-width: 45.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='22'] {
    max-width: 45.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='23'] {
    width: 47.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='23'] {
    left: 47.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='23'] {
    min-width: 47.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='23'] {
    max-width: 47.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='24'] {
    width: 50%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='24'] {
    left: 50%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='24'] {
    min-width: 50%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='24'] {
    max-width: 50%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='25'] {
    width: 52.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='25'] {
    left: 52.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='25'] {
    min-width: 52.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='25'] {
    max-width: 52.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='26'] {
    width: 54.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='26'] {
    left: 54.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='26'] {
    min-width: 54.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='26'] {
    max-width: 54.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='27'] {
    width: 56.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='27'] {
    left: 56.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='27'] {
    min-width: 56.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='27'] {
    max-width: 56.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='28'] {
    width: 58.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='28'] {
    left: 58.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='28'] {
    min-width: 58.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='28'] {
    max-width: 58.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='29'] {
    width: 60.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='29'] {
    left: 60.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='29'] {
    min-width: 60.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='29'] {
    max-width: 60.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='30'] {
    width: 62.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='30'] {
    left: 62.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='30'] {
    min-width: 62.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='30'] {
    max-width: 62.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='31'] {
    width: 64.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='31'] {
    left: 64.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='31'] {
    min-width: 64.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='31'] {
    max-width: 64.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='32'] {
    width: 66.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='32'] {
    left: 66.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='32'] {
    min-width: 66.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='32'] {
    max-width: 66.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='33'] {
    width: 68.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='33'] {
    left: 68.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='33'] {
    min-width: 68.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='33'] {
    max-width: 68.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='34'] {
    width: 70.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='34'] {
    left: 70.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='34'] {
    min-width: 70.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='34'] {
    max-width: 70.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='35'] {
    width: 72.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='35'] {
    left: 72.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='35'] {
    min-width: 72.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='35'] {
    max-width: 72.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='36'] {
    width: 75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='36'] {
    left: 75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='36'] {
    min-width: 75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='36'] {
    max-width: 75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='37'] {
    width: 77.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='37'] {
    left: 77.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='37'] {
    min-width: 77.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='37'] {
    max-width: 77.08333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='38'] {
    width: 79.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='38'] {
    left: 79.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='38'] {
    min-width: 79.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='38'] {
    max-width: 79.16666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='39'] {
    width: 81.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='39'] {
    left: 81.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='39'] {
    min-width: 81.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='39'] {
    max-width: 81.25%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='40'] {
    width: 83.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='40'] {
    left: 83.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='40'] {
    min-width: 83.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='40'] {
    max-width: 83.33333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='41'] {
    width: 85.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='41'] {
    left: 85.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='41'] {
    min-width: 85.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='41'] {
    max-width: 85.41666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='42'] {
    width: 87.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='42'] {
    left: 87.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='42'] {
    min-width: 87.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='42'] {
    max-width: 87.5%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='43'] {
    width: 89.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='43'] {
    left: 89.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='43'] {
    min-width: 89.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='43'] {
    max-width: 89.58333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='44'] {
    width: 91.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='44'] {
    left: 91.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='44'] {
    min-width: 91.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='44'] {
    max-width: 91.66666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='45'] {
    width: 93.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='45'] {
    left: 93.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='45'] {
    min-width: 93.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='45'] {
    max-width: 93.75%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='46'] {
    width: 95.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='46'] {
    left: 95.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='46'] {
    min-width: 95.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='46'] {
    max-width: 95.83333333%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='47'] {
    width: 97.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='47'] {
    left: 97.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='47'] {
    min-width: 97.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='47'] {
    max-width: 97.91666667%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-width='48'] {
    width: 100%;
}
.grid-stack.grid-stack-48 > .grid-stack-item[data-gs-x='48'] {
    left: 100%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-min-width='48'] {
    min-width: 100%;
}
.grid-stack.grid-stack-48 > .grid-stack-item.grid-stack-item[data-gs-max-width='48'] {
    max-width: 100%;
}
