.Kpi-render-item {
    font-family: "Roboto" !important;
    display: flex;
    align-items: center;
    text-align: center;
    width: 96%;
    height: 100%;
    overflow: unset !important;
    margin-left: 2%;
    margin-right: 2%;
    justify-content: center;
}

.NewKpiRender {
    overflow: hidden !important;
    display: flex;
    align-items: center;
}

.Kpi-render-item table {
    max-width: unset !important;
}


.KpiBody {
    display: grid !important;
    grid-template-columns: auto auto;
    grid-gap: 0;
}

.KpiType-PIZZA .KpiBody,
.KpiType-DEFAULT .KpiBody {
    grid-gap: 2%;
}

.kpi-left .KpiBody {
    display: flex !important;
}

.Kpi-render-item > div {
    width: 100%;
}

.KpiType-SQUARE.Kpi-render-item,
.KpiType-SQUAREPERCENT.Kpi-render-item {
    margin: 0 !important;
    width: 100% !important;
}

.KpiBody.Qplot .KpiInternalBody > *:first-child {
    margin: auto 10px auto auto;
}

.Kpi-render-item .kpi-default-image {
    border-radius: 100%;
    width: 100%;
    height: inherit;
    box-sizing: border-box;
}

.Kpi-render-item .kpi-default-avatar {
    border-radius: 100%;
    width: 100%;
    box-sizing: border-box;
}

.Kpi-render-item .kpititle {
    white-space: nowrap;
}

.KpiRightFragment {
    height: auto;
}

.kpi-body-values {
    width: 100%;
}


.kpi-left .KpiJustGauge svg,
.kpi-left .KpiJustGauge .KpiGrowth {
    width: 100% !important;
}


.KpiType-PIZZAPERCENT .percentage {
    display: flex;
    align-items: center;
    justify-content: center;
}


.kpi-left .kpi-percent,
.kpi-left .KpiGrowth {
    padding-left: 0 !important;
}


.Kpi-render-item.kpi-left > div > table {
    margin-left: 0 !important;
}

.Kpi-render-item.kpi-left .KpiJustGauge svg {
    padding-left: 0 !important;
}

.Kpi-render-item.kpi-left {
    margin-left: 2%;
    justify-content: unset;
}


.Kpi-render-item.kpi-left div[class^="KpiBody"] {
    margin: 0 !important;
    padding: 0 !important;
}

.KpiType-PIZZA .KpiInternalBody {
    text-align: right;
}

.td-pizza-percent {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Kpi-render-item .kpititle,
.Kpi-render-item .kpidescription {
    font-weight: bold !important;
}

