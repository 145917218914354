.page-header {
    position: relative;
    height: 65px;
    vertical-align: top;
    margin-top: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #286ba6;
    border-top-width: 10px;
    border-top-style: solid;
    border-top-color: #286ba6;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #dddddd 100%);
}

.page-header_top {
    height: 25px;
    vertical-align: top;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #286ba6;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    font-size: 12px;
    color: #f5f5f5;
}

p.break-words {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 10px;
    margin: 0;
}