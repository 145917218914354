.map-loader {
    width: 100%;
    height: 100%;
    position: relative;
}

.map-loader.map-error,
.map-loader.updating {
    background-color: transparent;
}

.map-loader.updating .load-icon {
    position: absolute;
    color: #333;
    top: 5px;
    right: 5px;
}

.map-loader.updating .legacy-map {
    opacity: 0.2;
}

.leaflet-control-attribution {
    display: none;
}

.legacy-map,
.legacy-map * {
    z-index: 1;
}

.legacy-map .map-container {
    width: 100%;
}

.map-loader .alert {
    padding: 15px;
    margin: 0;
}

.map-loader .alert .span6 {
    line-height: 30px;
}

.leaflet-popup-content-wrapper {
    text-decoration: none;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    color: #000;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 2px;
}
.leaflet-popup-content {
    width: auto !important;
}
.leaflet-popup-content-wrapper img {
    max-width: none;
}

.map,
.map-container {
    text-align: initial;
}

.leaflet-div-icon {
    border: none;
    background-color: transparent;
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }

    60% {
        opacity: 1;
        transform: translateY(30px)
    }

    80% {
        transform: translateY(-10px)
    }

    100% {
        transform: translateY(0)
    }
}

.LeafletMap {
    position: relative;
}

.LeafletMap .MapControls {
    position: absolute;
    top: 10px;
    left: 44px;
}

.LeafletMap .MapControls .leaflet-bar a {
    cursor: pointer;
}

.leaflet-touch .leaflet-bar a{
    width: 25px;
    height: 25px;
    line-height: 25px;
}
.LeafletMap .MapControls .icon {
    line-height: 30px;
    font-size: 16px;
    cursor: pointer;
}

.LeafletMapTitleContainer {
    font-size: 11px;
}

.LeafletMapTitleContainer .table td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}