.crud-table {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0;
}

.crud-search {
    margin: 5px 5px 0 5px;
    cursor: auto;
}

.crud-table-default-actions-column {
    text-align: center;
    min-width: 82px;
    width: 82px;
    max-width: 82px;
}

.crud-table-header {
    height: 35px;
    line-height: 35px;
    padding: 0 5px 0 5px;
}

.crud-table tr {
    line-height: 20px;
}

.crud-table > tfoot > tr > td:first-child {
    color: #21618b;
    line-height: 35px;
    border-bottom: 1px solid #cccccc;
    border-radius: 0 3px 0 3px;
    background-color: #dddddd;
    background-image: linear-gradient(to bottom, #dddddd 0, #f5f5f5 100%);
    font-size: 11px;
}

.crud-grid-form {
    width: 100%;
    margin: 0 auto;
}

.crud-table th {
    color: #21618b;
    background-color: #dddddd;
    background-image: linear-gradient(to bottom, #dddddd 0, #f5f5f5 100%);
    font-size: 11px;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    text-align: center;
}

.crud-table th:last-child {
    border-right: none;
}

.crud-table tbody tr:hover {
    background-color: #e5e5e5;
}

.crud-grid-row-odd {
    background-color: #ffffff;
}

.crud-grid-row-even {
    background-color: #ffffff;
}

.crud-grid-row-odd td {
    word-break: break-all;
    border-bottom: 1px solid #e4e4e4;
    padding: 0 5px 0 5px;
}

.crud-grid-row-odd td:hover {
    padding: 0 5px 0 5px;
}

.crud-grid-row-odd td:last-child {
    border-right: 0 solid #cccccc;
}

.crud-grid-row-even td {
    word-break: break-all;
    border-bottom: 1px solid #e4e4e4;
    padding: 0 5px 0 5px;
}

.crud-grid-row-even td:hover {
    padding: 0 5px 0 5px;
}

.crud-grid-row-even td:last-child {
    border-right: 0 solid #cccccc;
}

.iceMsgError {
    color: red;
    text-shadow: 0 0 1px;
    font-size: 10px;
}

.error {
    color: red;
    text-shadow: 0 0 1px;
    font-size: 0.85em
}

.draggable-cursor {
    cursor: move;
}

.title-button {
    float: right;
}

.button-margin {
    margin: 5px;
}

.crud-title {
    font-size: 18px;
    font-weight: normal;
    color: #21618b;
    text-decoration: none;
    margin: 10px 0 12px;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
}

.crud-title-pagination-rows {
    min-width: 0;
    height: 24px;
    margin: 5px;
    padding: 2px;
}

.font-normal-size {
    font-size: 12px;
}

.two-column-panel-grid {
    width: 99%;
}

.two-column-panel-grid td {
    min-width: 120px;
    font-size: 11px;
    color: #21618b;
}

.two-column-panel-grid tr {
    width: 100%;
}

.two-column-panel-grid tr td:first-child {
    width: 15%;
    text-align: left;
}

.two-column-panel-grid tr td:last-child {
    width: 85%;
}

.two-column-panel-grid tr td:last-child input {
    width: 100%;
}

.image-button {
    width: 24px;
    height: 24px;
    margin: 5px;
    min-width: 0;
}

.crud-paginator {
    background-color: transparent;
}

.crud-paginator tbody tr {
    background-color: transparent;
}

.crud-paginator tbody tr:hover {
    background-color: transparent;
}

.crud-paginator tbody tr td {
    background-color: transparent;
}

.crud-paginatorTbl {
    background-color: transparent;
}

.crud-paginator .iceDatPgrScrCol {
    background-color: transparent;
}

.crud-paginator .iceDatPgrScrBtn span {
    display: block;
    padding: 0;
    margin: 0;
    width: 22px;
    height: 22px;
    background-color: #f5f5f5;
    color: #21618b;
    border-radius: 5px;
}

.crud-paginator .iceDatPgrScrBtn span:hover {
    background-color: #286ba6;
    color: #f5f5f5;
}

.crud-paginator .iceDatPgrScrBtn a:hover span {
    background-color: #286ba6;
    color: #f5f5f5;
}

.crud-paginator .iceDatPgrScrCol a {
    border-radius: 5px;
    background-color: #286ba6;
    color: #f5f5f5;
}

.crud-paginator .iceDatPgrCol a {
    border-radius: 5px;
    border: 1px solid #21618b;
    background-color: #f5f5f5;
    color: #21618b;
}

.crud-paginator .iceDatPgrCol a:hover {
    background-color: #21618b;
    color: #f5f5f5;
}

.iceDatPgr {
    float: right;
}

.selected-row td {
    background-color: #f5f5f5;
    box-shadow: inset 0 0 10px #21618b;
    cursor: pointer !important;
}

.iceRowSelSelectedMouseOver td {
    background-color: #286ba6;
    box-shadow: inset 0 0 15px #cccccc;
    cursor: pointer !important;
}

.iceRowSelMouseOver td {
    box-shadow: inset 0 0 15px #cccccc;
    cursor: pointer !important;
}

.link {
    text-decoration: none !important;
    color: #21618b !important;
}
