.er {
    margin: 0 auto;
    list-style: none;
}

.er > li {
    float: left;
    margin-bottom: 20px;
    margin-left: 20px;
}

.er > li.three-col {
    width: 30%;
}

.er > li.two-col {
    width: 40%;
}

.er-box {
    font-size: 0.8em;
    background-color: #EDF2F6;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #CFCFCF;
    font-family: "Open sans", monospace, sans-serif, serif;
    font-weight: 200;
    min-height: 256px;
}

.er-table-name{
    border-bottom: 1px dashed #D3D3D3;
    text-align: center;
    font-weight: bold;
    padding: 0 0 5px 0;
}

.er-columns{
    margin-top: 10px;
    height: 200px;
    overflow: auto;
}

.er-column {
    border-bottom: 1px solid #E9E9E9;
    min-height: 0;
}

.er-column > .span9,
.er-column > .span3 {
    min-height: 0;
    padding: 2px 0;
}

.er-column:last-child {
    border-bottom: none;
}