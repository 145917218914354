.user-filters-table-container {
    width: 100%;
    height: 450px;
    overflow: auto;
}

.user-filter-default-height {
    height: 350px;
}

td .user-filter-member {
    display: inline-block;
    width: 105px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 5px;
}

.user-filter-config-icon,
.user-filter-move-icon {
    top: 4px;
    background-color: white;
    font-size: 16px;
    position: relative;
}

.dashboard-filter-icon-expand i.icon.icon-bim-menuexpand,
.dashboard-filter-icon-expand i.icon.icon-bim-menucontracted {
    cursor: pointer;
}

.user-filter-move-icon {
    top: 7px;
    padding: 0 5px;
}

.filter-information-edit-mode-explorer .user-filter-move-icon {
    margin-left: 0 !important;
}

.filter-bottom-right .dashboard-filter-icon-expand, .filter-top-right .dashboard-filter-icon-expand {
    padding-top: 12px;
}

.filter-container .block-ui {
    min-height: unset;
}