@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,900');

@import "fonts/material-icons/local-md-icons.css";

@import "crud.css";
@import "dialogs.css";
@import "default-green.css";
@import "header.css";
@import "header-menu.css";
@import "project-selection.css";
@import "tabs.css";
@import "utils.css";
@import "panels.css";
@import "connection-status.css";
@import "dashboard.css";
@import "toolbar.css";
@import "toolbar-mods.css";
@import "wizard.css";
@import "analysis-tables.css";
@import "analysis.css";
@import "breadcrumb.css";
@import "ace-skins-fixes.css";
@import "er.css";
@import "print.css";
@import "cockpit.css";
@import "ace-container.css";
@import "user-filters.css";
@import "sweetalert-mods.css";
@import "popper.css";
@import "in-memory.css";
@import "fixes-new-theme.css";
@import "fonts/bim-icons/bim-icon-font.css";
@import "kpi.css";
@import "scheduling.css";

*,
*:focus,
*:hover {
    outline: none;
}