.grid-stack.grid-stack-96 > .grid-stack-item {
    min-width: 1.04166667%;
}

.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='1'] {
    width: 1.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='1'] {
    left: 1.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='1'] {
    min-width: 1.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='1'] {
    max-width: 1.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='2'] {
    width: 2.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='2'] {
    left: 2.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='2'] {
    min-width: 2.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='2'] {
    max-width: 2.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='3'] {
    width: 3.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='3'] {
    left: 3.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='3'] {
    min-width: 3.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='3'] {
    max-width: 3.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='4'] {
    width: 4.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='4'] {
    left: 4.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='4'] {
    min-width: 4.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='4'] {
    max-width: 4.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='5'] {
    width: 5.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='5'] {
    left: 5.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='5'] {
    min-width: 5.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='5'] {
    max-width: 5.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='6'] {
    width: 6.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='6'] {
    left: 6.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='6'] {
    min-width: 6.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='6'] {
    max-width: 6.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='7'] {
    width: 7.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='7'] {
    left: 7.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='7'] {
    min-width: 7.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='7'] {
    max-width: 7.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='8'] {
    width: 8.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='8'] {
    left: 8.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='8'] {
    min-width: 8.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='8'] {
    max-width: 8.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='9'] {
    width: 9.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='9'] {
    left: 9.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='9'] {
    min-width: 9.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='9'] {
    max-width: 9.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='10'] {
    width: 10.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='10'] {
    left: 10.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='10'] {
    min-width: 10.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='10'] {
    max-width: 10.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='11'] {
    width: 11.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='11'] {
    left: 11.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='11'] {
    min-width: 11.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='11'] {
    max-width: 11.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='12'] {
    width: 12.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='12'] {
    left: 12.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='12'] {
    min-width: 12.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='12'] {
    max-width: 12.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='13'] {
    width: 13.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='13'] {
    left: 13.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='13'] {
    min-width: 13.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='13'] {
    max-width: 13.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='14'] {
    width: 14.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='14'] {
    left: 14.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='14'] {
    min-width: 14.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='14'] {
    max-width: 14.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='15'] {
    width: 15.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='15'] {
    left: 15.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='15'] {
    min-width: 15.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='15'] {
    max-width: 15.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='16'] {
    width: 16.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='16'] {
    left: 16.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='16'] {
    min-width: 16.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='16'] {
    max-width: 16.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='17'] {
    width: 17.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='17'] {
    left: 17.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='17'] {
    min-width: 17.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='17'] {
    max-width: 17.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='18'] {
    width: 18.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='18'] {
    left: 18.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='18'] {
    min-width: 18.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='18'] {
    max-width: 18.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='19'] {
    width: 19.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='19'] {
    left: 19.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='19'] {
    min-width: 19.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='19'] {
    max-width: 19.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='20'] {
    width: 20.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='20'] {
    left: 20.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='20'] {
    min-width: 20.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='20'] {
    max-width: 20.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='21'] {
    width: 21.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='21'] {
    left: 21.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='21'] {
    min-width: 21.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='21'] {
    max-width: 21.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='22'] {
    width: 22.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='22'] {
    left: 22.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='22'] {
    min-width: 22.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='22'] {
    max-width: 22.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='23'] {
    width: 23.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='23'] {
    left: 23.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='23'] {
    min-width: 23.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='23'] {
    max-width: 23.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='24'] {
    width: 25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='24'] {
    left: 25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='24'] {
    min-width: 25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='24'] {
    max-width: 25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='25'] {
    width: 26.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='25'] {
    left: 26.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='25'] {
    min-width: 26.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='25'] {
    max-width: 26.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='26'] {
    width: 27.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='26'] {
    left: 27.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='26'] {
    min-width: 27.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='26'] {
    max-width: 27.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='27'] {
    width: 28.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='27'] {
    left: 28.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='27'] {
    min-width: 28.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='27'] {
    max-width: 28.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='28'] {
    width: 29.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='28'] {
    left: 29.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='28'] {
    min-width: 29.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='28'] {
    max-width: 29.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='29'] {
    width: 30.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='29'] {
    left: 30.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='29'] {
    min-width: 30.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='29'] {
    max-width: 30.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='30'] {
    width: 31.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='30'] {
    left: 31.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='30'] {
    min-width: 31.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='30'] {
    max-width: 31.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='31'] {
    width: 32.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='31'] {
    left: 32.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='31'] {
    min-width: 32.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='31'] {
    max-width: 32.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='32'] {
    width: 33.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='32'] {
    left: 33.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='32'] {
    min-width: 33.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='32'] {
    max-width: 33.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='33'] {
    width: 34.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='33'] {
    left: 34.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='33'] {
    min-width: 34.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='33'] {
    max-width: 34.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='34'] {
    width: 35.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='34'] {
    left: 35.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='34'] {
    min-width: 35.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='34'] {
    max-width: 35.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='35'] {
    width: 36.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='35'] {
    left: 36.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='35'] {
    min-width: 36.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='35'] {
    max-width: 36.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='36'] {
    width: 37.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='36'] {
    left: 37.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='36'] {
    min-width: 37.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='36'] {
    max-width: 37.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='37'] {
    width: 38.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='37'] {
    left: 38.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='37'] {
    min-width: 38.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='37'] {
    max-width: 38.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='38'] {
    width: 39.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='38'] {
    left: 39.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='38'] {
    min-width: 39.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='38'] {
    max-width: 39.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='39'] {
    width: 40.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='39'] {
    left: 40.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='39'] {
    min-width: 40.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='39'] {
    max-width: 40.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='40'] {
    width: 41.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='40'] {
    left: 41.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='40'] {
    min-width: 41.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='40'] {
    max-width: 41.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='41'] {
    width: 42.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='41'] {
    left: 42.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='41'] {
    min-width: 42.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='41'] {
    max-width: 42.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='42'] {
    width: 43.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='42'] {
    left: 43.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='42'] {
    min-width: 43.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='42'] {
    max-width: 43.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='43'] {
    width: 44.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='43'] {
    left: 44.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='43'] {
    min-width: 44.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='43'] {
    max-width: 44.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='44'] {
    width: 45.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='44'] {
    left: 45.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='44'] {
    min-width: 45.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='44'] {
    max-width: 45.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='45'] {
    width: 46.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='45'] {
    left: 46.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='45'] {
    min-width: 46.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='45'] {
    max-width: 46.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='46'] {
    width: 47.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='46'] {
    left: 47.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='46'] {
    min-width: 47.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='46'] {
    max-width: 47.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='47'] {
    width: 48.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='47'] {
    left: 48.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='47'] {
    min-width: 48.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='47'] {
    max-width: 48.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='48'] {
    width: 50%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='48'] {
    left: 50%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='48'] {
    min-width: 50%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='48'] {
    max-width: 50%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='49'] {
    width: 51.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='49'] {
    left: 51.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='49'] {
    min-width: 51.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='49'] {
    max-width: 51.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='50'] {
    width: 52.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='50'] {
    left: 52.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='50'] {
    min-width: 52.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='50'] {
    max-width: 52.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='51'] {
    width: 53.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='51'] {
    left: 53.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='51'] {
    min-width: 53.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='51'] {
    max-width: 53.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='52'] {
    width: 54.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='52'] {
    left: 54.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='52'] {
    min-width: 54.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='52'] {
    max-width: 54.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='53'] {
    width: 55.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='53'] {
    left: 55.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='53'] {
    min-width: 55.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='53'] {
    max-width: 55.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='54'] {
    width: 56.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='54'] {
    left: 56.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='54'] {
    min-width: 56.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='54'] {
    max-width: 56.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='55'] {
    width: 57.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='55'] {
    left: 57.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='55'] {
    min-width: 57.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='55'] {
    max-width: 57.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='56'] {
    width: 58.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='56'] {
    left: 58.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='56'] {
    min-width: 58.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='56'] {
    max-width: 58.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='57'] {
    width: 59.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='57'] {
    left: 59.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='57'] {
    min-width: 59.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='57'] {
    max-width: 59.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='58'] {
    width: 60.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='58'] {
    left: 60.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='58'] {
    min-width: 60.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='58'] {
    max-width: 60.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='59'] {
    width: 61.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='59'] {
    left: 61.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='59'] {
    min-width: 61.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='59'] {
    max-width: 61.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='60'] {
    width: 62.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='60'] {
    left: 62.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='60'] {
    min-width: 62.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='60'] {
    max-width: 62.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='61'] {
    width: 63.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='61'] {
    left: 63.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='61'] {
    min-width: 63.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='61'] {
    max-width: 63.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='62'] {
    width: 64.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='62'] {
    left: 64.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='62'] {
    min-width: 64.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='62'] {
    max-width: 64.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='63'] {
    width: 65.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='63'] {
    left: 65.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='63'] {
    min-width: 65.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='63'] {
    max-width: 65.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='64'] {
    width: 66.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='64'] {
    left: 66.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='64'] {
    min-width: 66.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='64'] {
    max-width: 66.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='65'] {
    width: 67.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='65'] {
    left: 67.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='65'] {
    min-width: 67.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='65'] {
    max-width: 67.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='66'] {
    width: 68.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='66'] {
    left: 68.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='66'] {
    min-width: 68.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='66'] {
    max-width: 68.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='67'] {
    width: 69.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='67'] {
    left: 69.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='67'] {
    min-width: 69.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='67'] {
    max-width: 69.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='68'] {
    width: 70.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='68'] {
    left: 70.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='68'] {
    min-width: 70.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='68'] {
    max-width: 70.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='69'] {
    width: 71.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='69'] {
    left: 71.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='69'] {
    min-width: 71.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='69'] {
    max-width: 71.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='70'] {
    width: 72.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='70'] {
    left: 72.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='70'] {
    min-width: 72.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='70'] {
    max-width: 72.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='71'] {
    width: 73.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='71'] {
    left: 73.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='71'] {
    min-width: 73.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='71'] {
    max-width: 73.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='72'] {
    width: 75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='72'] {
    left: 75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='72'] {
    min-width: 75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='72'] {
    max-width: 75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='73'] {
    width: 76.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='73'] {
    left: 76.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='73'] {
    min-width: 76.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='73'] {
    max-width: 76.04166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='74'] {
    width: 77.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='74'] {
    left: 77.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='74'] {
    min-width: 77.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='74'] {
    max-width: 77.08333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='75'] {
    width: 78.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='75'] {
    left: 78.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='75'] {
    min-width: 78.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='75'] {
    max-width: 78.125%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='76'] {
    width: 79.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='76'] {
    left: 79.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='76'] {
    min-width: 79.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='76'] {
    max-width: 79.16666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='77'] {
    width: 80.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='77'] {
    left: 80.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='77'] {
    min-width: 80.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='77'] {
    max-width: 80.20833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='78'] {
    width: 81.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='78'] {
    left: 81.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='78'] {
    min-width: 81.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='78'] {
    max-width: 81.25%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='79'] {
    width: 82.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='79'] {
    left: 82.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='79'] {
    min-width: 82.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='79'] {
    max-width: 82.29166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='80'] {
    width: 83.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='80'] {
    left: 83.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='80'] {
    min-width: 83.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='80'] {
    max-width: 83.33333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='81'] {
    width: 84.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='81'] {
    left: 84.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='81'] {
    min-width: 84.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='81'] {
    max-width: 84.375%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='82'] {
    width: 85.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='82'] {
    left: 85.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='82'] {
    min-width: 85.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='82'] {
    max-width: 85.41666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='83'] {
    width: 86.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='83'] {
    left: 86.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='83'] {
    min-width: 86.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='83'] {
    max-width: 86.45833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='84'] {
    width: 87.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='84'] {
    left: 87.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='84'] {
    min-width: 87.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='84'] {
    max-width: 87.5%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='85'] {
    width: 88.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='85'] {
    left: 88.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='85'] {
    min-width: 88.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='85'] {
    max-width: 88.54166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='86'] {
    width: 89.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='86'] {
    left: 89.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='86'] {
    min-width: 89.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='86'] {
    max-width: 89.58333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='87'] {
    width: 90.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='87'] {
    left: 90.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='87'] {
    min-width: 90.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='87'] {
    max-width: 90.625%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='88'] {
    width: 91.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='88'] {
    left: 91.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='88'] {
    min-width: 91.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='88'] {
    max-width: 91.66666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='89'] {
    width: 92.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='89'] {
    left: 92.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='89'] {
    min-width: 92.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='89'] {
    max-width: 92.70833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='90'] {
    width: 93.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='90'] {
    left: 93.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='90'] {
    min-width: 93.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='90'] {
    max-width: 93.75%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='91'] {
    width: 94.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='91'] {
    left: 94.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='91'] {
    min-width: 94.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='91'] {
    max-width: 94.79166667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='92'] {
    width: 95.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='92'] {
    left: 95.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='92'] {
    min-width: 95.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='92'] {
    max-width: 95.83333333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='93'] {
    width: 96.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='93'] {
    left: 96.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='93'] {
    min-width: 96.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='93'] {
    max-width: 96.875%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='94'] {
    width: 97.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='94'] {
    left: 97.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='94'] {
    min-width: 97.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='94'] {
    max-width: 97.91666667%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='95'] {
    width: 98.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='95'] {
    left: 98.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='95'] {
    min-width: 98.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='95'] {
    max-width: 98.95833333%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-width='96'] {
    width: 100%;
}
.grid-stack.grid-stack-96 > .grid-stack-item[data-gs-x='96'] {
    left: 100%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-min-width='96'] {
    min-width: 100%;
}
.grid-stack.grid-stack-96 > .grid-stack-item.grid-stack-item[data-gs-max-width='96'] {
    max-width: 100%;
}
