.table-square-bands {
    color: #f2f2f2;
    font-weight: bold;
    white-space: nowrap;
    line-height: 0;
}

.indicator-round {
    color: #f2f2f2;
    box-shadow: 0 0 0px 2px #eee;
    border-radius: 100%;
}

.table-square-bands .icon-chevron-left,
.table-square-bands .icon-chevron-right {
    margin-left: 6px;
    margin-right: 6px;
}