@keyframes pulsate {
    0% {
        transform: scale(.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

.iceOutConStat {
    position: fixed;
    width: 0;
    height: 0;
    border: none;
    font-size: .5em;
    right: 65px;
    bottom: 65px;
    z-index: 30000;
}

.iceOutConStatNoMargin {
    position: absolute;
    top: 2px;
    right: 50%;
    margin: 0px !important;
    margin-left: -16px;
    border: none;
    width: 32px;
    height: 32px;
    font-size: .5em;
}

.iceOutConStatActv {
    border: 5px solid #333;
    border-radius: 30px;
    height: 30px;
    left: 50%;
    margin: -15px 0 0 -15px;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 30px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
}

body > div:last-child {
    cursor: inherit !important;
}