.dash-header-panel-group {
    width: 85%;
    float: left;
    cursor: move;
}

.dash-header-icons {
    width: 80px;
    float: right;
    text-align: right;
    margin-right: 5px;
}

.dashboard {
    width: 100%;
    vertical-align: top;
}

.dashboard .widget-header {
    cursor: move;
}

.dashboard-table {
    table-layout: fixed;
    width: 100%;
}

.dashboard-table td {
    vertical-align: top;
}

.dashboard-header * {
    vertical-align: top;
}

.dashboarditem {
    margin: 2px 0 0 0 !important;
}

ul.dashboard-item-links {
    width: 200px;
    max-height: 350px;
    overflow-y: auto;
}

ul.dashboard-item-links a > i,
ul.dashboard-item-links span > i {
    float: left;
}

.dashboard-item-links span [class^="icon-"],
.dashboard-item-links span [class*=" icon-"] {
    display: inline;
}

ul.dashboard-item-links li {
    position: relative;
}

ul.dashboard-item-links li > i {
    left: 5px !important;
    top: 10px !important;
}

ul.dashboard-item-links li > span {
    display: block;
}

ul.dashboard-item-links .menu_option {
    width: 140px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}

ul.dropdown-menu.dashboard-item-links > li {
    padding: 0;
}

ul.dropdown-menu.dashboard-item-links > li > a,
ul.dropdown-menu.dashboard-item-links > li > span {
    background-image: none;
    margin: 0 !important;
    padding: 5px 0 5px 30px !important;
}

ul.dropdown-menu.dashboard-item-links > li > a:hover,
ul.dropdown-menu.dashboard-item-links > li > span:hover,
ul.dropdown-menu.dashboard-item-links > li > a:hover > i,
ul.dropdown-menu.dashboard-item-links > li > span:hover > i,
ul.dropdown-menu.dashboard-item-links > li > span:hover > div,
ul.dropdown-menu.dashboard-item-links > li > a:hover > div {
    color: #4F99C6 !important;
}

#dashTable, .fixedtableHeader table {
    width: 100%;
}

/* Scope */
.widget-box.dashboard-item {
    border: none;
}

.dashboard-item .widget-toolbar {
    background-color: white;
    margin-right: 15px;
    padding: 0 5px;
}

.dashboard-item .widget-toolbar:before {
    border: none;
}

/* SIMPLE */
.border-simple .widget-body,
.border-simple {
    border: 1px solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/* THICK */
.border-thick .widget-body, .border-thick {
    border: 3px solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/* DOUBLE_LINE */
.border-double-line > .widget-body, .border-double-line {
    border: 3px double;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/* DOTTED */
.border-dotted .widget-body, .border-dotted {
    border: 1px dotted;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

#dashboard-exp .border-double-line {
    border-width: 4px;
}

#dashboard-exp .grid-stack-item-content .widget-box {
    box-shadow: none;
}

.dashboarditem-export .widget-header {
    display: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-left: 3px;
    margin-top: 3px;
}

.border-shadow .widget-body, .border-shadow {
    border-style: solid;
    border-width: 1px 1px 2px 1px;
    border-radius: 4px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
}

.border-postfix,
.border-postfix.dashboard-item {
    border: 1px solid;
}

.border-postfix > .widget-body {
    border: none;
    box-shadow: 1px 3px 4px #777;
}

.border-postfix {
    position: relative;
    z-index: 1;
}

.border-postfix:before {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 4px;
    left: 3px;
    width: 77%;
    top: 80%;
    max-width: 300px;
    background: #777;
    -webkit-box-shadow: 0 10px 10px #777;
    -moz-box-shadow: 0 10px 10px #777;
    box-shadow: 0 10px 10px #777;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

#dashboard-exp .border-double-line {
    border-width: 4px;
}

.dashboarditem-export .widget-header {
    display: none;
}

.smallsquare .widget-body div {
    overflow: hidden;
}

.bigsquare .widget-body div {
    overflow: hidden;
}

.dashboarditem .widget-header {
    display: none;
}

.dashboarditem[class*=with-header] .widget-header {
    display: block;
}

.dashboard-item-html-component {
    position: relative;
}

.dashboard-item-html-component .HtmlDragOverlay {
    height: 100%;
    position: absolute;
    width: 100%;
}

.filter-container {
    padding-bottom: 3px;
    position: relative;
    background: #ffffff;
    margin-left: 3px;
    z-index: 0;
}

.dashboard-item .mdx-table {
    width: 100%;
}

.mobile-order-btn {
    padding: 0;
}

.mobile-order-btn .icon-sort, .mobile-order-btn .icon-bim-move {
    font-size: 15px;
    position: absolute;
    left: -9px;
}

.mobile-order-btn .icon-mobile-phone {
    font-size: 30px;
    position: absolute;
    right: 12px;
}

.free-style-first-dashboard-load,
.dashboard-item-timeout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
}

.free-style-first-dashboard-load.editing {
    border: none;
}

.free-style-first-dashboard-load .item-loader-icon {
    position: absolute;
    top: 5px;
    right: 10px
}

.item-loader-icon.fa-remove {
    color: red;
}

.item-loader-icon.fa-check {
    color: green;
    display: none;
}

.free-style-first-dashboard-load img {
    opacity: 0.2;
    max-width: 75%;
    max-height: 75%;
}

.restrict-members-fieldset .select-items-component-items-container {
    max-height: 175px;
}

.infobox td {
    padding: 0px;
}

.dashboard-item {
    margin: 0 !important;
}

#body-dashboard-home .widget-body {
    margin: 0 !important;
}

.dashboard-item .widget-body {
    border-radius: 0;
}

div[data-edit-mode='false'] .itemsize {
    display: none;
}

div[data-edit-mode='true'] .itemsize {
    display: inline-block;
}

.grid-stack-item .itemsize {
    padding: 1px 8px;
    color: #222222;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    font-size: 10px;
    letter-spacing: 0.4px;
    line-height: 16px;
    bottom: 0;
    left: 0;
}

.grid-stack-item .item-highlight .itemsize {
    background-color: #000;
}

.grid-stack-item-content {
    background-color: transparent;
    padding: 0;
}

.dashboardEditDialog ul.wizard-steps,
.dashboardEditDialog .folder-management-container {
    display: none;
}

.Flex-Center-Aligner {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* tema do preview do template */
.dashboard-item-preview {
    width: 104px;
    height: 55px;
    margin: 4px;
    float: left;
    border-radius: 4px;
}

.background-preview-WHITE .dashboard-item-preview {
    background-color: rgb(255, 255, 255);
}

.background-preview-BLACK .dashboard-item-preview {
    background-color: rgb(53, 53, 53);
    opacity: .4;
}

.background-preview-CORPORATIVE .dashboard-item-preview {
    background-color: rgb(0, 0, 0);
    opacity: .3;
}

.item-content-container.ImageRender .zoom-target-disable {
    margin: 0 !important;
}

.analysisScrollHorizontalFix {
    display: inline-table;
    width: calc(100% - 25px);
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.analysisScrollHorizontalFix div{
    overflow: hidden !important;
}
#DashGridComponent:not(.DashboardTheme-NONE) .react-grid-item.grid-stack-item-content.ContainerChild.Item.cssTransforms {
    background: rgba(255, 255, 255, 0) !important;
}
