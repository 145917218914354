.maximize .scheduler-pan{
    height: 100% !important;
    width: 100% !important;
}

.scheduler-dialog.maximize .widget-body{
    height: 100% !important;
}

.scheduler-dialog.maximize .widget-main{
    height: calc(100% - 80px) !important;
}

.scheduler-dialog.maximize .CodeMirror{
    height: calc(100vh - 420px) !important;
    width: calc(100vw - 250px) !important;
    margin-left: 0px !important;
}

.scheduler-dialog.maximize .final-step-scheduler .CodeMirror{
    height: calc(100vh - 600px) !important;
    width: calc(100vw - 120px) !important;
    margin-left: 0px !important;
}

.scheduler-dialog.maximize .final-step-scheduler .icePnlGrp{
    height: calc(100% - 80px) !important;
    padding: 10px !important;
}

.scheduler-dialog .CodeMirror{
    width: 750px !important;
    height: 160px !important;
}

.scheduler-dialog.maximize .widget-main .btn-fix{
    width: calc(100% - 45px);
}

.scheduler-dialog.maximize .widget-main .btn-fix .skipValidation{
    margin-left: 110px !important;
    position: fixed !important;
}

.scheduler-dialog.maximize .widget-main .span2{
    width: 6%;
}

.scheduler-dialog .scheduler-dialogBody .step-content {
    overflow-y: scroll;
    overflow-x: clip;
}

.scheduler-dialog:not(.maximize) .scheduler-dialogBody .step-content {
    max-height: 398px;
}

.scheduler-dialog.maximize .scheduler-dialogBody .step-content {
    max-height: 100%;
}

.scheduler-dialog .scheduler-dialogBody .AbsoluteButtons {
    position: inherit;
}