.loading-image-box {
    text-align: center;
    padding: 80px 0;
}

.center-spinner {
    top: 40%;
    left: calc(50% - 64px);
    z-index: 35650;
    position: fixed;
}

.logo-spinner {
    cursor: unset !important;
    border-radius: 100%;
    border: 3px solid #CACFD3;
    font-size: 60px;
    color: #CACFD3;
    margin: auto;
    position: absolute;
    display: flex;
    align-items: center;
}

.logo-spinner,
.loading-image {
    width: 128px;
    height: 128px;
}

.loading-image {
    margin: auto;
}

.logo-spinner > span {
    margin: auto;
    width: 60px;
    height: 60px;
}

.spinner {
    animation: rotator 1.4s linear infinite;
    width: 134px;
    height: 134px;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: #4A90E2;
    animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
    0% { stroke-dashoffset: 187; }
    50% {
        stroke-dashoffset: 46;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform:rotate(450deg);
    }
}

.linear-wipe:before {
    display: block;
    padding: 10px;
}

h1.linear-wipe  {
    display: block;
    vertical-align: middle;
    margin: 0;
}

.linear-wipe {
    text-align: center;
    background: linear-gradient(to right, #d2d2d2 20%, #bbbbbb 40%, #bbbbbb 60%, #d2d2d2 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 1s linear infinite;
    animation: shine 1s linear infinite;
}

@keyframes shine {
    to {
        background-position: -200% center;
    }
}

.loading-ease-out {
    opacity: 1;
    animation: loading-ease-out 1s linear forwards;
}

@keyframes loading-ease-out {
    to {
        opacity: 0;
    }
}

.background-loading-opacity {
    height: 100vh;
    height: max(100vh, 100%);
    width: 100%;
    background: #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    z-index: 1;
}