.AppPageWrapper .cockpit-item-panel {
    height: calc(100% - 34px);
}

.cockpit-item-panel.o-visible {
    overflow: visible;
}

@supports (-webkit-overflow-scrolling: touch) {
    .cockpit-item-panel.o-visible {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        top: 52px;
        -webkit-overflow-scrolling: touch !important;
        overflow: auto !important;
    }
}

@supports not (-webkit-overflow-scrolling: touch) {
    .cockpit-item-panel.o-visible {
        overflow: visible;
        height: 100%;
    }
}

.cockpit-toolbar-container {
    position: relative;
    height: 35px;
}

@supports not (-webkit-overflow-scrolling: touch) {
    .cockpit-item-panel.o-visible {
        overflow: visible;
        height: 100%;
    }
}

.cockpit-header-panel-ul-container {
    margin-left: 40px;
}

.cockpit-header-panel-ul-container.expand {
    margin-right: 30px;
}

.cockpit-header-project-info {
    float: left;
}

.btn.btn-link.cockpit-index-anchor-button {
    padding: 10px 0 0 15px !important;
    color: #222222 !important;
}

.btn.btn-link.cockpit-index-anchor-button:hover {
    text-decoration: none !important;
    color: #000000 !important;
}

.cockpit-header-project-info > img {
    height: 20px;
    margin-top: 10px;
    width: auto;
}

.cockpit-header-controls--more-painels {
    width: 10px;
    position: absolute;
    top: 12px;
    right: 20px;
}

.cockpit-header-controls-container.hide-controls .cockpit-header-scroll-left,
.cockpit-header-controls-container.hide-controls .cockpit-header-position-on-active,
.cockpit-header-controls-container.hide-controls .cockpit-header-scroll-right {
    display: none;
}

.cockpit-header-panel-ul {
    list-style: none;
    margin: 0 2px;
    white-space: nowrap;
}

.cockpit-control-button {
    color: rgb(0, 136, 204);
    width: 30px;
}

.cockpit-header-panel-ul > li.active{
    background: linear-gradient(90deg, #FCFCFC 0%, #FAFAFA 100%);
}

.cockpit-header-panel-ul > li {
    vertical-align: middle;
    display: table-cell;
    font-size: 10px;
    position: relative;
    border-right: 1px solid #E7E8EB;
    background: #f7f8fa;
}

.cockpit-config-icon {
    position: absolute;
    right: 8px;
    top: 10px;
    color: #fff;
}

.cockpit-header-panel-ul > .cockpit-toolbar-dropdown-option > .cpt-btn-item > a.btn.btn-link {
    color: #ffffff !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    padding: 2px 25px 0px 2px !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2) !important;
    font-size: bold !important;
}

.cockpit-header-panel-ul > .cockpit-toolbar-dropdown-option > .cpt-btn-item > a.btn.btn-link > span,
.cockpit-header-panel-ul > .cockpit-toolbar-dropdown-option > .cpt-btn-item > a.btn.btn-link > i {
    padding: 0 !important;
    color: #ffffff !important;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.2) !important;
}

.cockpit-toolbar-btn-group {
    padding: 8px 0 8px 0;
    background: #ffffff;
}

.cockpit-header-panel{
    background: #EFEFEF;
}

.cpt-btn-item a{
    font-size: 12px !important;
}

.cockpit-toolbar-dropdown-options{
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
}

.cockpit-toolbar-dropdown > .cockpit-toolbar-dropdown-option > a.cockpit-dropdown-fav:hover {
    background: #f4f9fc !important;
}

.cockpit-toolbar-dropdown > .cockpit-toolbar-dropdown-option > a.cockpit-dropdown-fav:hover > span {
    color: #0081c2;
    background: #f4f9fc !important;
}

.cockpit-toolbar-dropdown > .cockpit-toolbar-dropdown-option > a.cockpit-dropdown-access-link span {
    font-size: 20px !important;
    margin-right: 5px !important;
}

.cockpit-toolbar-dropdown-option {
    font-size: 12px;
    line-height: 44px;
    font-weight: 500;
    letter-spacing: .5px;
    border-bottom: 1px solid #ededed;
    display: flex;
}

.cockpit-toolbar-dropdown-option:nth-child(5n+1){
    border-left: 4px solid #269c59;
}

.cockpit-toolbar-dropdown-option:nth-child(5n+2){
    border-left: 4px solid #4a90e2;
}

.cockpit-toolbar-dropdown-option:nth-child(5n+3){
    border-left: 4px solid #727272;
}

.cockpit-toolbar-dropdown-option:nth-child(5n+4){
    border-left: 4px solid #da9f1a;
}

.cockpit-toolbar-dropdown-option:nth-child(5n+5){
    border-left: 4px solid #e23d3d;
}

.cockpit-toolbar-dropdown-option a span {
    font-size: 20px;
    padding-right: 14px;
}

.cockpit-toolbar-dropdown-option:hover {
    background-color: #ffffff !important;
    background-image: none;
    color: rgba(0,0,0,.75) !important;
}

.cockpit-toolbar-dropdown > .cockpit-toolbar-dropdown-option > a.cockpit-dropdown-fav {
    position: absolute;
    right: 0;
    top: 0;
    height: 10px;
    border: 0px !important;
}

.cockpit-toolbar-dropdown > .cockpit-toolbar-dropdown-option > a.cockpit-dropdown-fav > span {
    color: rgb(102, 159, 199);
}

.cockpit-toolbar-dropdown-option a {
    color: #7e7e7e;
    padding-left: 20px;
}

.cockpit-toolbar-dropdown-option a:hover {
    background: #fff !important;
    color: #7e7e7e !important;
}

.cockpit-index-page-ol {
    -moz-column-count: 3;
    -moz-column-gap: 10px;
    -webkit-column-count: 3;
    -webkit-column-gap: 10px;
    column-count: 3;
    column-gap: 10px;
    list-style: decimal-leading-zero inside none;
    margin-top: 20px;
}

.cockpit-index-page-ol li {
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
}

.cockpit-index-page-ol li a.iceCmdLnk {
    color: #6c6c6c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 16px;
}

.cockpit-index-page-ol li a.iceCmdLnk:hover {
    color: #0088cc !important;
}

.cockpit-index-page-container > h1 {
    color: #669fc7;
    font-weight: normal;
    text-align: center;
    font-size: 10px;
}

.cockpit-index-page-content-panel {
    max-height: 40%;
    overflow: auto;
    text-align: justify;
}

.user-cockpit-config {
    height: 530px;
    overflow: auto;
}

.no-outline,
a.no-outline {
    outline: 0 !important;
}

.cockpit-view .breadcrumbs {
    margin-left: 0;
}

.cockpit-view.with-right-menu div.page-content {
    margin-right: 10px;
    position: relative;
    top: 36px;
}

.cockpit-view .page-content,
.cockpit-view .page-content.page-content-expand {
    margin-left: 10px;
}

.cockpit-view .floating-object-actions {
    right: 10px;
}

.cpt-item-view-more {
    width: 150px;
    float: left;
    text-align: center;
    line-height: 10px;
}

.cpt-item-view-more-icon {
    font-size: 24px;
}

.cpt-item-view-more-name {
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
    color: #65655d !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.widget-toolbar > .dropdown > .cpt-menu::after, .cpt-menu.dropdown-caret::after {
    border-bottom: 6px solid #000000 !important;
}

.cpt-menu {
    border-top: 2px solid #000000 !important;
}

.cockpit-header-controls--more-painels a {
    color: #65655d;
}

.cockpit-header-controls--more-painels a:hover, .cockpit-header-controls--more-painels a:focus, .cockpit-header-controls--more-painels a.btn.btn-link:hover, .cockpit-header-controls--more-painels a.btn.btn-link:focus {
    color: #000000 !important;
}

.cpt-btn-item-corner-left {
    width: 0;
    height: 0;
    border-bottom: 25px solid red;
    border-left: 25px solid transparent;
}

.cpt-btn-item-corner-right {
    width: 0;
    height: 0;
    border-bottom: 25px solid red;
    border-right: 25px solid transparent;
}

.view-more-painels-title {
    color: #fffffd;
    background: #222222;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
}

.view-more-painels-desc {
    color: #222222;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    white-space: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    margin: 4px;
}

.view-more-painels {
    text-align: center;
    margin-top: -5px;
}

.cpt-index-title {
    color: #555555;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    text-align: left;
    font-size: 22px;
    margin: 20px;
}

.cockpit-index-page-content-container{
    border-top: 1px solid #ededde;
}

.cockpit-index-search-div {
    margin-left: 10px;
    margin-top: 20px !important;
}
.cockpit-index-search{
    margin-bottom: 0 !important;
    border: 1px solid #dddddd;
    width: 300px !important;
}

.cockpit-index-page .scroll-element.scroll-y .scroll-element_outer {
    visibility: visible !important;
}

.filter-container .scrollbar-inner,
.filter-container .scrollbar-outer,
.filter-container .scrollbar-macosx {
    white-space: nowrap;
    margin-bottom: 0;
}

.cockpit-book-btn:hover,
.cockpit-book-btn:active,
.cockpit-book-btn:visited,
.cockpit-book-btn:focus {
    background: transparent !important;
}

.cockpit-book-btn.refresh {
    right: 10px;
}

.cockpit-book-btn.share {
    right: 30px;
}

.CockpitNavFilterDrop {
    left: auto !important;
    right: 8px !important;
    top: 38px !important;
    text-align: left;
    width: 434px;
}

.CockpitNavFilterDrop .filter-block span {
    margin: unset;
}

.CockpitNavFilterDrop .filter-block .remove-filter {
    margin-right: 0;
}

.CockpitNavFilterDrop .filter-block .icon-angle-down {
    visibility: hidden;
}

.CockpitNavFilterDrop .filter-block {
    display: inline-flex;
    width: 100%;
    margin: 5px 0;
}

.CockpitNavFilterDrop .filter-block .truncate-text {
    width: calc(100% - 20px);
    max-width: unset;
}

.CockpitNavFilterDrop .filter-block .truncate-text.disabled .dashboard-filter-information-icon {
    background-color: transparent;
}

.CockpitNavFilterDrop .filter-block .truncate-text.disabled {
    cursor: not-allowed;
}

.CockpitNavFilterDrop .filter-container .remove-filter {
    padding-right: 5px;
}

.CockpitNavFilterDrop .filter-container {
    box-shadow: none;
    border-radius: 0;
    background-color: #f7f8fa;
    margin: 0;
}

.CockpitNavFilterDrop .nav-header.disabled-header .icon-angle-down {
    position: absolute;
    right: 0;
    top: 10px;
}

.CockpitNavFilterDrop .nav-header.disabled-header div {
    cursor: pointer;
    position: relative;
}

.CockpitNavFilterDrop .filter-container {
    max-height: 230px;
    overflow-y: auto;
}

.CockpitNavFilterDrop .filter-container.disabled {
    max-height: 300px;
    overflow-y: auto;
}

.cockpit-index-list-item-link {
    font-size: 14px;
    margin-left: 4px;
}

.cockpit-index-page-summary .widget-body{
    border-radius: 0 0 4px 4px;
}

.menu-mobile-filter-container .CockpitNavFilterDrop .dashboard-filter-information.filtered {
    background: #575bd8;
}

.menu-mobile-filter-container .CockpitNavFilterDrop .filter-block .truncate-text{
    width: 384px;
}

.CockpitNavFilterDrop .expand-filter {
    display: none;
}

.CockpitNavFilterDrop #disabled-filter-container .remove-filter {
    display: none;
}

.CockpitNavFilterDrop #disabled-filter-container .dashboard-filter-information > a {
    opacity: 1;
}