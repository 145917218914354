.kpilabelspan {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
}

.kpititle,
.kpidescription {
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap !important;
}

.kpi-left .KpiRightFragment {
    text-overflow: unset !important;
    overflow: unset !important;
}


.KpiValueSpan {
    font-weight: bold !important;
}

.KpiRightFragment {
    display: flex;
    align-items: center;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap !important;
}


