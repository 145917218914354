.lista {
    height: 25px;
    padding: 0;
    margin: 0;
    width: 100%;
}

.lista li {
    float: right;
    list-style: none;
    height: 25px;
    line-height: 25px;
    vertical-align: middle;
    padding: 0 3px;
}

.lista li a {
    padding: 0 5px;
    float: right;
}

.lista li a:hover {
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #dddddd 100%);
    margin-top: 1px;
    color: #21618b;
}

.link_hover {
    background-color: #dddddd;
    background-image: linear-gradient(to bottom, #dddddd 0, #f5f5f5 100%);
    margin-top: 1px;
    color: #21618b !important;
    border-radius: 5px 5px 0 0;
}

.main-menu {
    padding: 0;
    font-size: 12px;
    font-weight: normal;
    vertical-align: bottom;
    margin: 0 auto;
}

.main-menu li {
    margin: 0;
    padding: 0;
    list-style: none;
    float: left;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
}

.main-menu li a {
    display: block;
    color: #21618b;
    text-decoration: none;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    margin: 0 5px 0 0;
    height: 28px;
    width: 100px;
    line-height: 28px;
    vertical-align: middle;
    padding: 0;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #dddddd 100%);
    border: 1px solid #21618b;
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
}

.main-menu li a:hover {
    color: #f5f5f5;
    background-color: #286ba6;
    background-image: linear-gradient(to bottom, #286ba6 0, #194368 100%);
}

.main-menu li a:active {
    color: #f5f5f5;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.activeModule {
    color: #f5f5f5 !important;
    background-color: #194368 !important;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%) !important;
}

.main-menu div {
    padding: 0;
    margin-top: 1px;
    font-size: 11px;
    font-weight: normal;
    text-decoration: none;
    position: absolute;
    float: left;
    visibility: hidden;
    z-index: 999;
}

.main-menu div a {
    display: block;
    white-space: nowrap;
    text-align: left;
    text-decoration: none;
    color: #21618b;
    height: 28px;
    line-height: 28px;
    padding: 0 0 0 10px;
    font-size: 11px;
    font-weight: normal;
    width: 160px;
    clear: left;
    background-color: #f5f5f5;
    margin: 0px;
    border-bottom: 1px solid #21618b;
    border-left: 1px solid #21618b;
    border-right: 1px solid #21618b;
    border-top: 0;
    border-radius: 0;
}

.main-menu div a:hover {
    color: #f5f5f5;
    font-weight: bold;
}

#menu {
    position: absolute;
    margin-top: -7px;
}

#menu li a {
    display: block;
    white-space: nowrap;
    text-align: center;
    padding-top: 4px;
    text-decoration: none;
    font-size: 11px;
    font-weight: normal;
    width: 80px;
    height: 17px;
    clear: left;
    float: left;
    margin: 0;
}

.menu_sttings {
    display: block;
    color: #21618b;
    width: 290px;
    height: 25px;
    line-height: 25px;
    vertical-align: middle;
    font-size: 11px;
    text-align: left;
    margin: 0;
    padding: 0 0 0 10px;
    border-top: 0;
    border-bottom: 1px solid #21618b;
}

.menu_sttings:hover {
    border-top: 0 !important;
    color: #f5f5f5;
    background-color: #286ba6;
    background-image: linear-gradient(to bottom, #286ba6 0, #194368 100%);
}

.menu_sttings:active {
    border-top: 0 !important;
    color: #f5f5f5;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

#menu ul {
    width: 80px;
    float: left;
    margin: 0;
    padding: 2px;
    list-style: none;
}

.clear {
    overflow: hidden;
    width: 100%;
    clear: both;
    height: 10px;
}

.header-login-info,
.lista-ajuda,
.lista-idioma-a,
.lista-idioma,
.lista-temas,
.lista-killsession,
.lista-biportal,
.lista-config {
    height: 25px;
    font-size: 12px;
    color: #f5f5f5;
    text-decoration: none;
    text-align: right;
}

.header-login-info a {
    text-decoration: none;
}

#header-menu {
    position: absolute;
    left: 140px;
    width: 780px;
    bottom: 0px;
    margin: 0px;
    border: 0px;
}

div.header-menu-right {
    position: absolute;
    right: 5px;
    bottom: 5px;
    margin: 0px;
    border: 0px;
    height: 48px;
    background-color: #f5f5f5;
    border: 1px solid #21618b;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

div.user-info {
    line-height: 24px;
    color: #21618b;
    background-color: #f5f5f5;
    font-size: 12px;
}

div.user-info span {
    vertical-align: top;
    margin-left: 5px;
}

div.user-info img {
    margin: 4px;
    border: 1px solid #21618b;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

div.icon {
    height: 24px;
    width: 32px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    margin-left: 52px;
}

div.icon div.pointerDown {
    width: 6px;
    height: 6px;
    border: solid #21618b;
    border-width: 5px 5px 0 0;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    margin: 2px 0 0 6px;
}

div.icon div.pointerDown:hover {
    width: 6px;
    height: 6px;
    border: solid #21618b;
    border-width: 5px 5px 0 0;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    margin: 2px 0 0 6px;
}

div.icon2 {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-right: 10px;
}

div.icon2 div.pointerTop {
    width: 24px;
    height: 32px;
    border: solid #21648e;
    border-width: 1px 1px 0 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin: 6px 0 0 6px;
    background-color: #f5f5f5;
}

.logoutBtn {
    font-size: 12px;
    border: 1px solid #21648e;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
    background: #f5f5f5;
    /* Old browsers */
    background: -moz-linear-gradient(top, #f5f5f5 0%, #dddddd 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f5f5f5), color-stop(100%, #dddddd));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f5f5f5 0%, #dddddd 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f5f5f5 0%, #dddddd 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f5f5f5 0%, #dddddd 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #f5f5f5 0%, #dddddd 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#dddddd', GradientType=0);
    /* IE6-9 */
}

.logoutBtn:hover {
    background: #dddddd;
    /* Old browsers */
    background: -moz-linear-gradient(top, #dddddd 0%, #f5f5f5 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #dddddd), color-stop(100%, #f5f5f5));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #dddddd 0%, #f5f5f5 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #dddddd 0%, #f5f5f5 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #dddddd 0%, #f5f5f5 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #dddddd 0%, #f5f5f5 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dddddd', endColorstr='#f5f5f5', GradientType=0);
    /* IE6-9 */
    -webkit-box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.55);
    -moz-box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.55);
    box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.55);
}

.noticicationBtn {
    font-size: 11px;
    width: 24px;
    height: 24px;
    color: #ffffff;
    background: #ff9b9b;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ff9b9b 0%, #ff1b1b 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff9b9b), color-stop(100%, #ff1b1b));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ff9b9b 0%, #ff1b1b 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ff9b9b 0%, #ff1b1b 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ff9b9b 0%, #ff1b1b 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ff9b9b 0%, #ff1b1b 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9B9B', endColorstr='#FF1B1B', GradientType=0);
    /* IE6-9 */
    border: 1px solid #ff0000;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    padding: 2px;
    cursor: pointer;
    font-weight: bold;
}

.noticicationBtn:hover {
    background: #ff1b1b;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ff1b1b 0%, #ff9b9b 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff1b1b), color-stop(100%, #ff9b9b));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ff1b1b 0%, #ff9b9b 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ff1b1b 0%, #ff9b9b 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ff1b1b 0%, #ff9b9b 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ff1b1b 0%, #ff9b9b 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF1B1B', endColorstr='#FF9B9B', GradientType=0);
    /* IE6-9 */
    -webkit-box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.55);
    -moz-box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.55);
    box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.55);
}

.perfilSettings {
    border: 1px solid #21618b;
    border-bottom: none;
    height: 100px;
    background-color: #f5f5f5;
    margin-top: -21px;
}