.kpiSquareTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #f2f2f2;
    margin-left: 3%;
    margin-right: 3%;
}

.kpiSquareValue {
    text-align: center;
    font-weight: 500;
    margin-left: 3%;
    white-space: nowrap;
    margin-right: 3%;
}

.tr-target-square {
    position: absolute;
    border: 0;
    height: 1px;
    border-top: 2px solid rgb(255, 255, 255);
    margin-bottom: 0;
    top: 60%;
    left: 42.5%;
    white-space: nowrap;
}

.div-indicator-square {
    border-right: 2px solid #ffffff;
    vertical-align: inherit;
    text-align: center;
}

.square-target-text {
    color: #f2f2f2;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
}

.square-bands-indicator {
    float: left;
    border-right: 2px solid #ffffff;
    vertical-align: inherit;
    text-align: center;
    white-space: nowrap;
}

.kpiSquareTitle.target {
    position: absolute;
    top: 5%;
}

.kpiSquareTitle.targetdescription {
    position: absolute;
    top: 22%;
}
.kpiSquareTitle.notarget,
.kpiSquareValue.notarget{
    margin-left: 4% !important;
    margin-right: 4% !important;
}


