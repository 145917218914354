/**
 * Fixes for themes in ace-skin.min.css
 **/

.breadcrumbs {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

