.row-fluid .span9.mdx-table-span {
    margin-left: 0;
    width: 76.59574468085105%;
}

#analysisReportView.with-filters {
    padding-top: 48px !important;
}

.fixed-members-mdx {
    border: 1px solid #CCCCCC;
    padding: 5px;
    overflow: auto;
    font-size: 12px;
    color: #333;
    white-space: nowrap;
    line-height: 20px;
    background-color: #F9F9F9;
    height: 150px;
}

.properties-tab {
    width: 600px;
    height: 300px;
    overflow: auto;
}

.maximize .properties-tab{
    height: 100% !important;
    width: 100%;
}

.maximize.properties-maximizable{
    height: 100vh !important;
    width: 100vw !important;
}

.maximize.properties-maximizable .widget-body{
    height: 100% !important;
}

.maximize.properties-maximizable .widget-main{
    height: calc(100% - 10px) !important;
}

.maximize.properties-maximizable .dialog-body{
    height: calc(100% - 80px ) !important;
}

.maximize .properties-tab-size-fix .clearfix .tabbable .tab-content{
    height: calc(100% - 180px);
}

.properties-tab .span12 .CodeMirror{
    margin-left: 0px !important;
    width: calc(100% - 5px) !important;
}

.maximize .properties-tab .span12 .CodeMirror{
    margin-left: 0px !important;
    height: calc(100vh - 255px) !important;
    width: 94vw !important;
}

.properties-tab.extended {
    height: 300px;
}

.analysis-toolbar-button {
    height: 25px;
    margin-left: 3px;
    width: 11px;
    border-radius: 0 !important;
}

.analysis-toolbar-button [class*='icon-'] {
    margin-left: -7px;
}

.analysis-toolbar-button .icon-align-justify {
    font-size: 14px;
}


/* Analysis chart tooltips style*/

.analysis-title {
    text-align: left;
}

.analysis-title hr {
    margin: 5px 0;
}

.analysis-title .row-name {
    margin-bottom: 5px;
}

.analysis-title .measures .measure {
    margin-bottom: 2px;
}

.analysis-title .measure .value {
    font-weight:bold;
    color: #0de0e6;
}

/* Chart visualization dropdown */
.chart-visualization-dropdown label {
    cursor: pointer !important;
}

.chart-visualization-dropdown .divider {
    margin: 5px 1px;
}

.chart-visualization-dropdown .divider.first {
    margin-top: 0;
}

.chart-visualization-dropdown .nav-header {
    padding: 3px 5px
}
.chart-visualization-list {
    max-height: 800px;
    max-height: calc(80vh - 30px);
    min-height: 294px;
    width: 500px;
    overflow: auto;
    position: relative;
}

.scroll-wrapper.chart-visualization-list.scrollbar-ff-fix{
    overflow: clip !important;
}

.chart-visualization-list .truncate-text:hover {
    background-color: #EEE;
}

.chart-visualization-table.crud-table th {
    padding: 0 4px !important;
}

.chart-visualization-table * {
    font-size: 11px !important;
}

.chart-visualization-table .order-input {
    width: 30px;
    margin: 0;
    padding: 0 2px;
}

.chart-visualization-table .droppable.drag-active {
    background-color: #DFDFDF;
}
.chart-visualization-table .droppable.highlight {
    background-color: #caeaf5;
}

tr.label-config {
    border-top: 2px solid #CCC;
}

.reduce-analysis-table-size {
    width: calc(100vw - 450px);
}