.kpi-left .KpiBandsTable {
    margin: 0;
}

.KpiBandsTable {
    margin: auto;
}

.KpiBandsTable tr {
    white-space: nowrap;
}

.KpiBandsTable .KpiBandColor .Icon {
    font-size: 17px;
}

.KpiBandsTable .KpiBandNext {
    font-size: 7px;
}

.KpiBandsTable .KpiBandsValue {
    font-size: 11px;
}