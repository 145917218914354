@media print {

    .no-print,
    .right-side-sidebar {
        display: none !important;
    }

    #page-content,
    #breadcrumbs {
        margin: 0 !important;
    }

}