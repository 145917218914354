.map-legend {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    max-height: 120px;
    overflow-y: hidden;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    background: rgba(255, 255, 255, .35);
    transition: max-height ease-in-out 0.3s;
}

.map-legend.export {
    max-height: 999px;
}

.map-legend table {
    width: 100%;
}

.map-legend.closed {
    max-height: 27px;
}

.map-legend.opened {
    max-height: 126px;
}

.map-legend div.legendArrow {
    display: flex;
}

.map-legend span.legendButtonLabel {
    line-height: 24px;
    margin-left: 4px;
}

.map-legend i#total-displayed-markers {
    font-style: normal;
}

.tdLegendMap .checkbox {
    padding: 0;
}

.tdLegendMap {
    font-size: 12px;
    border-bottom: 1px solid #d2d2d2;
}

tr:last-child .tdLegendMap {
    border-bottom: none;
}

.tdLegendMapHeader {
    font-size: 12px;
    padding: 0 7px;
    letter-spacing: .2px;
    font-weight: 500;
}

.legendMapTotal {
    float: right;
    text-align: right;
    line-height: 28px;
    color: #444;
    line-height: 13px;
}

i.material-icons.view-in-map {
    position: absolute;
    margin-top: -10px;
    font-size: 18px;
}

i.material-icons.view-in-map.view-in-map-disabled {
    color: #b1b1b1;
}

.ReactVirtualized__Table__row.tdLegendMap {
    background: #fff;
    border-style: inset;
}

.ReactVirtualized__Table__row.tdLegendMap {
    background: rgba(255, 255, 255, 0.7);;
    border-style: inset;
    border-top: 1px solid #e4e4e4;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    outline: 0;
}

.legendMapFilterPoints {
    font-weight: 400;
}