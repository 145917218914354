.ace-settings-container {
    position: absolute;
    right: 0;
    top: auto;
    z-index: 12;
}

.btn.btn-app.ace-settings-btn.btn-xs {
    width: 42px;
    height: 24px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn.btn-app.ace-settings-btn {
    float: left;
    display: block;
    text-align: center;
    border-radius: 6px 0 0 6px;
    opacity: .55;
    vertical-align: top;
    margin: 0;
}

.btn.btn-app.btn-xs {
    width: 64px;
    font-size: 15px;
    border-radius: 8px;
    padding-bottom: 7px;
    padding-top: 8px;
    line-height: 1.45;
}

.btn-app.btn-warning, .btn-app.btn-warning.no-hover:hover, .btn-app.btn-warning.disabled:hover {
    background: #ffb44b!important;
    background-image: -webkit-linear-gradient(top,#ffbf66 0,#ffa830 100%)!important;
    background-image: -o-linear-gradient(top,#ffbf66 0,#ffa830 100%)!important;
    background-image: linear-gradient(to bottom,#ffbf66 0,#ffa830 100%)!important;
    background-repeat: repeat-x!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffbf66', endColorstr='#ffffa830', GradientType=0)!important;
}

.btn.btn-app {
    display: inline-block;
    width: 100px;
    font-size: 18px;
    font-weight: 400;
    color: #FFF;
    text-align: center;
    text-shadow: 0 -1px -1px rgba(0,0,0,.2)!important;
    border: none;
    border-radius: 12px;
    padding: 12px 0 8px;
    margin: 2px;
    line-height: 1.7;
    position: relative;
}

.btn-app, .btn-app.btn-default, .btn-app.no-hover:hover, .btn-app.btn-default.no-hover:hover, .btn-app.disabled:hover, .btn-app.btn-default.disabled:hover {
    background: #b4c2cc!important;
    background-image: -webkit-linear-gradient(top,#bcc9d5 0,#abbac3 100%)!important;
    background-image: -o-linear-gradient(top,#bcc9d5 0,#abbac3 100%)!important;
    background-image: linear-gradient(to bottom,#bcc9d5 0,#abbac3 100%)!important;
    background-repeat: repeat-x!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbcc9d5', endColorstr='#ffabbac3', GradientType=0)!important;
}

.btn-xs, .btn-group-xs>.btn {
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 3px;
}

.btn-xs, .btn-group-xs>.btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.ace-settings-box {
    display: block;
    float: left;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    -moz-transform: translate(0,0);
    -webkit-transform: translate(0,0);
    -o-transform: translate(0,0);
    -ms-transform: translate(0,0);
    transform: translate(0,0);
    background-color: #FFF;
    border: 0 solid #ffb34b;
    -webkit-transition: max-width .25s linear 0s,max-height 0s linear .25s,padding 0s linear .25s,border-width 0s linear .25s;
    -o-transition: max-width .25s linear 0s,max-height 0s linear .25s,padding 0s linear .25s,border-width 0s linear .25s;
    transition: max-width .25s linear 0s,max-height 0s linear .25s,padding 0s linear .25s,border-width 0s linear .25s;
}

.ace-settings-box.open {
    max-width: 320px;
    min-height: 35px;
    max-height: 1000px;
    padding: 0 14px;
    border-width: 2px;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}
.btn.btn-app.ace-settings-btn:hover, .btn.btn-app.ace-settings-btn.open {
    opacity: 1;
}

.btn.btn-app.ace-settings-btn > i {
    font-size: 19.5px;
    line-height: 25px;
}