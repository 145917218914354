.pc-gradient {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.pc-gradient-tab {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.pc-border {
    border: 1px #21618b solid;
    border-radius: 5px;
}

.rem-m-w {
    min-width: 0 !important;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.ta-l {
    text-align: left;
}

.ta-c {
    text-align: center;
}

.ta-r {
    text-align: right !important;
}

.m-none, .m-0 {
    margin: 0 !important;
}

.m-none-force, .m-0-force {
    margin: 0 !important;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.force-inline-block {
    display: inline-block !important;
}

.d-m {
    margin: 5px;
}

.d-b-m {
    margin-bottom: 5px;
}

.margin-bottom-default {
    margin-bottom: 10px;
}

.d-t-m {
    margin-top: 5px;
}

.d-l-m {
    margin-left: 5px;
}

.d-r-m {
    margin-right: 5px;
}

.d-b-p {
    padding-bottom: 5px;
}

.d-t-p {
    padding-top: 5px;
}

.d-l-p {
    padding-left: 5px;
}

.d-r-p {
    padding-right: 5px;
}

.fill-w {
    width: 100%;
}

.fill-w-49 {
    width: 49%;
}

.fill-h {
    height: 100%;
}

.d-f-c {
    font-size: 11px;
    color: #21618b;
}

.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-12 {
    width: 12%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-50 {
    width: 50%;
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100%;
}

.pointer {
    cursor: pointer;
}

.pc-font {
    color: #21618b;
}

.base-font-size {
    font-size: 11px;
}

.tab-corners {
    border-radius: 5px 5px 0 0;
}

.bottom-corners {
    border-radius: 0 0 5px 5px;
}

.w-32-px {
    width: 32px;
}

.w-72-px {
    width: 72px;
}

.moment {
    margin-left: 5px;
}

.break-words {
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.ul-no-decoration {
    margin: 0;
    list-style: none;
}

.btn-fix {
    padding: 1px;
}

.font-12 .nav-tabs {
    font-size: 12px;
}

.CodeMirror {
    border: 1px #cccccc solid;
}

.CodeMirror-hints {
    z-index: 25500 !important;
}

.iceCmdBtn-dis {
    cursor: not-allowed;
}

.position-relative {
    position: relative;
}

.bootstrap-timepicker-widget input {
    padding: 4px !important;
}

.bootstrap-timepicker-widget {
    z-index: 45000 !important;
}

.task-manager-label {
    width: 75px;
    text-align: center;
}

.iceOutLbl {
    font-size: 12px;
    color: #669FC7;
}

.scrollable {
    overflow: auto;
}

.text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.infobox-purple {
    color: #6f3cc4;
    border-color: #6f3cc4;
}

.infobox-purple > .infobox-icon > .ace-icon {
    background-color: #6f3cc4;
}

.infobox-purple.infobox-dark {
    background-color: #6f3cc4;
    border-color: #6f3cc4;
}

.infobox-purple2 {
    color: #5f47b0;
    border-color: #5f47b0;
}

.infobox-purple2 > .infobox-icon > .ace-icon {
    background-color: #5f47b0;
}

.infobox-purple2.infobox-dark {
    background-color: #5f47b0;
    border-color: #5f47b0;
}

.infobox-pink {
    color: #cb6fd7;
    border-color: #cb6fd7;
}

.infobox-pink > .infobox-icon > .ace-icon {
    background-color: #cb6fd7;
}

.infobox-pink.infobox-dark {
    background-color: #cb6fd7;
    border-color: #cb6fd7;
}

.infobox-blue {
    color: #6fb3e0;
    border-color: #6fb3e0;
}

.infobox-blue > .infobox-icon > .ace-icon {
    background-color: #6fb3e0;
}

.infobox-blue.infobox-dark {
    background-color: #6fb3e0;
    border-color: #6fb3e0;
}

.infobox-blue2 {
    color: #3983c2;
    border-color: #3983c2;
}

.infobox-blue2 > .infobox-icon > .ace-icon {
    background-color: #3983c2;
}

.infobox-blue2.infobox-dark {
    background-color: #3983c2;
    border-color: #3983c2;
}

.infobox-blue3 {
    color: #1144eb;
    border-color: #1144eb;
}

.infobox-blue3 > .infobox-icon > .ace-icon {
    background-color: #1144eb;
}

.infobox-blue3.infobox-dark {
    background-color: #1144eb;
    border-color: #1144eb;
}

.infobox-red {
    color: #d53f40;
    border-color: #d53f40;
}

.infobox-red > .infobox-icon > .ace-icon {
    background-color: #d53f40;
}

.infobox-red.infobox-dark {
    background-color: #d53f40;
    border-color: #d53f40;
}

.infobox-brown {
    color: #c67a3e;
    border-color: #c67a3e;
}

.infobox-brown > .infobox-icon > .ace-icon {
    background-color: #c67a3e;
}

.infobox-brown.infobox-dark {
    background-color: #c67a3e;
    border-color: #c67a3e;
}

.infobox-wood {
    color: #7b3f25;
    border-color: #7b3f25;
}

.infobox-wood > .infobox-icon > .ace-icon {
    background-color: #7b3f25;
}

.infobox-wood.infobox-dark {
    background-color: #7b3f25;
    border-color: #7b3f25;
}

.infobox-light-brown {
    color: #cebea5;
    border-color: #cebea5;
}

.infobox-light-brown > .infobox-icon > .ace-icon {
    background-color: #cebea5;
}

.infobox-light-brown.infobox-dark {
    background-color: #cebea5;
    border-color: #cebea5;
}

.infobox-orange {
    color: #e8b110;
    border-color: #e8b110;
}

.infobox-orange > .infobox-icon > .ace-icon {
    background-color: #e8b110;
}

.infobox-orange.infobox-dark {
    background-color: #e8b110;
    border-color: #e8b110;
}

.infobox-orange2 {
    color: #f79263;
    border-color: #f79263;
}

.infobox-orange2 > .infobox-icon > .ace-icon {
    background-color: #f79263;
}

.infobox-orange2.infobox-dark {
    background-color: #f79263;
    border-color: #f79263;
}

.infobox-green {
    color: #9abc32;
    border-color: #9abc32;
}

.infobox-green > .infobox-icon > .ace-icon {
    background-color: #9abc32;
}

.infobox-green.infobox-dark {
    background-color: #9abc32;
    border-color: #9abc32;
}

.infobox-green2 {
    color: #0490a6;
    border-color: #0490a6;
}

.infobox-green2 > .infobox-icon > .ace-icon {
    background-color: #0490a6;
}

.infobox-green2.infobox-dark {
    background-color: #0490a6;
    border-color: #0490a6;
}

.infobox-grey {
    color: #999999;
    border-color: #999999;
}

.infobox-grey > .infobox-icon > .ace-icon {
    background-color: #999999;
}

.infobox-grey.infobox-dark {
    background-color: #999999;
    border-color: #999999;
}

.infobox-black {
    color: #2A2624;
    border-color: #2A2624;
}

.infobox-black > .infobox-icon > .ace-icon {
    background-color: #2A2624;
}

.infobox-black.infobox-dark {
    background-color: #2A2624;
    border-color: #2A2624;
}

.infobox-dark {
    margin: 1px 1px 0 0;
    border-color: transparent !important;
    border-width: 0;
    color: #FFF;
    padding: 4px;
}

.infobox-dark > .infobox-icon > .ace-icon,
.infobox-dark > .infobox-icon > .ace-icon:before {
    background-color: transparent;
    box-shadow: none !important;
    text-shadow: none;
    border-radius: 0;
    font-size: 30px;
}

.infobox-dark > .infobox-icon > .ace-icon:before {
    opacity: 1;
    filter: alpha(opacity=100);
}

.infobox-dark .infobox-content {
    color: #FFF;
}

.icon-05x {
    font-size: 0.5em;
}


.filter-block {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.filter-block.filter-required {
    border-radius: 4px;
    border: 1px solid #e24c38;
}

.filter-container .filter-required .label {
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.filter-block > .label {
    height: 20px;
    line-height: 18px;
    vertical-align: top;
}

.filter-block > .label > i {
    line-height: 22px;
}

.filter-block > .label:first-child {
    color: #FFF;
}

.filter-block > .label.filtered {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: 0;
}

.filter-block > a.remove-filter {
    margin-right: 2px;
    margin-left: -10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.filter-block > a.expand-filter.disabled-true {
    margin-right: 0;
    margin-left: -10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.filter-block > a.expand-filter.disabled-false {
    margin-right: 0;
    margin-left: -10px;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filter-limit-width {
    max-width: 220px;
}

.select-many-fill-container {
    table-layout: fixed;
    width: 100%;
}

.select-many-fill-container label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.iceCmdLnk span.material-icons {
    font-size: 18px;
    margin-right: 6px;
}

.strip-margin .input-append {
    margin: 0;
}

.pagination.unstyled > li {
    float: left;
}

.clear-both {
    clear: both;
}

.v-align-middle {
    vertical-align: middle;
}

.reduced-select-size .iceSelOneMnu {
    font-size: 11px;
}

.reduced-select-size .iceInpTxt {
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 4px !important;
}

.reduced-select-size .input-append .add-on {
    height: 16px;
}

.reduce-margin-to-5 {
    margin: 5px 0;
}

.migrate-to-data-object-list {
    height: 200px;
    overflow: auto;
}

.migrate-to-data-object-list ul li i {
    margin-right: 5px;
}

table.hide-header {
    margin: 0;
    border: none !important;
}

.hide-header thead {
    display: none;
}

.vhidden {
    visibility: hidden !important;
    height: 0 !important;
    line-height: 0 !important;
    min-height: 0 !important;
    max-height: 0 !important;
    width: 0 !important;
    min-width: 0 !important;
    max-width: 0 !important;
}

.inm-bim-origin-floating-actions {
    position: absolute;
    bottom: -50px;
    z-index: 1;
}

input:not([type="image"]), textarea {
    box-sizing: border-box;
}

.sweet-alert h2 {
    max-height: 200px;
    max-height: 50vh;
    overflow: auto;
}

hr.m-10 {
    margin: 10px 0;
}

div.select2-drop-mask {
    z-index: 30000;
}

div.select2-drop {
    z-index: 30001;
}

.hide-scrollbar {
    overflow: hidden !important;
}

.va-top {
    vertical-align: top;
}

.label-beta {
    background-color: #d15b47 !important;
    font-size: 8px !important;
    font-family: monospace !important;
    height: 11px !important;
    margin: 3px 0 0 0 !important;
    padding: 0 4px 2px !important;
}

.form-horizontal .help-block {
    margin-left: 160px;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.no-wrap {
    white-space: nowrap;
}

a.disabled,
.disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
}

.no-resize {
    resize: none;
}

.help-block {
    font-size: 12px;
}

.with-bg-overlay.open:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    content: " ";
    background-color: transparent;
}

.help-block {
    font-size: 12px;
}

.dropdown-menu.open {
    display: inline-block;
}

.flex-center-items {
    display: flex !important;
    align-items: center !important;
    align-items: safe center !important;
}

.flex-direction-column {
    flex-direction: column;
}

.right-menu-icon-glow {
    position: relative;
}

.right-menu-icon-glow > .blink {
    pointer-events: none;
    position: absolute;
    right: 12px;
    bottom: 12px;
    background-color: #E24C38;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    text-decoration: blink;
    animation: blinker 0.6s ease-in-out infinite alternate;
}

@-webkit-keyframes blinker {
    from {
        box-shadow: 0 0 2px 1px red;
    }
    to {
        box-shadow: 0 0 10px 0 red;
    }
}

.export-icon-glow > .material-icons {
    animation: right-menu-icon-glow-effect 1s ease-in-out infinite alternate;
}

.disable-li-export {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
}


@keyframes right-menu-icon-glow-effect {
    from {
        opacity: 1.5;
    }
    to {
        opacity: 0.3;
    }
}

.btn.btn-link:hover {
    text-decoration: none;
}

.d-flex {
    display: flex !important;
}

.flex-grow-1 {
    flex-grow: 1;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 5px !important;
}

.mb-2 {
    margin-bottom: 10px !important;
}

.mb-3 {
    margin-bottom: 15px !important;
}

.mb-4 {
    margin-bottom: 20px !important;
}

.mb-5 {
    margin-bottom: 25px !important;
}

.mb-6 {
    margin-bottom: 30px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 5px !important;
}

.mt-2 {
    margin-top: 10px !important;
}

.mt-3 {
    margin-top: 15px !important;
}

.mt-4 {
    margin-top: 20px !important;
}

.mt-5 {
    margin-top: 25px !important;
}

.mt-6 {
    margin-top: 30px !important;
}

.mt-7 {
    margin-top: 35px !important;
}

.mt-8 {
    margin-top: 40px !important;
}

.mt-9 {
    margin-top: 45px !important;
}

.mt-10 {
    margin-top: 50px !important;
}

.mr-1 {
    margin-right: 5px !important;
}

.mr-2 {
    margin-right: 10px !important;
}

.mr-3 {
    margin-right: 15px !important;
}

.mr-4 {
    margin-right: 20px !important;
}

.mr-5 {
    margin-right: 25px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 5px !important;
}

.ml-2 {
    margin-left: 10px !important;
}

.ml-3 {
    margin-left: 15px !important;
}

.ml-4 {
    margin-left: 20px !important;
}

.ml-5 {
    margin-left: 25px !important;
}

.ml-6 {
    margin-left: 30px !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 5px !important;
}

.p-2 {
    padding: 10px !important;
}

.p-3 {
    padding: 15px !important;
}

.p-4 {
    padding: 20px !important;
}

.p-5 {
    padding: 25px !important;
}

.p-6 {
    padding: 30px !important;
}

.p-7 {
    padding: 35px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 5px !important;
}

.pl-2 {
    padding-left: 10px !important;
}

.pl-3 {
    padding-left: 15px !important;
}

.pl-4 {
    padding-left: 20px !important;
}

.pl-5 {
    padding-left: 25px !important;
}

.pl-6 {
    padding-left: 30px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 5px !important;
}

.pr-2 {
    padding-right: 10px !important;
}

.pr-3 {
    padding-right: 15px !important;
}

.pr-4 {
    padding-right: 20px !important;
}

.pr-5 {
    padding-right: 25px !important;
}

.pr-6 {
    padding-right: 30px !important;
}

.pl-7 {
    padding-left: 35px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 5px !important;
}

.pb-2 {
    padding-bottom: 10px !important;
}

.pb-3 {
    padding-bottom: 15px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 5px !important;
}

.pt-2 {
    padding-top: 10px !important;
}

.pt-3 {
    padding-top: 15px !important;
}

.pt-4 {
    padding-top: 20px !important;
}

.pt-5 {
    padding-top: 25px !important;
}

.pt-6 {
    padding-top: 30px !important;
}

.row-fluid.noMinHeight [class*="span"] {
    min-height: unset;
}

.iceCmdLnk-dis,
.iceCmdLnk-dis:hover {
    opacity: 0.7;
    cursor: not-allowed !important;
}

.btn.disabled, .btn[disabled] {
    cursor: not-allowed !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-flex-end {
    align-items: flex-end !important;
}

.spin-element {
    animation-name: utils-spin-animation;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes utils-spin-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.box-shadow-none {
    box-shadow: none !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.justify-content-flex-end {
    justify-content: flex-end !important;
}

.jc-center {
    justify-content: center !important;
}

.jc-end {
    justify-content: end !important;
}

.cursor-help {
    cursor: help !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.quick-highlight {
    animation: quick-highlight-effect 2.5s ease-in-out;
}

@keyframes quick-highlight-effect {
    0% {
        border-color: #005dff;
    }
    75% {
        border-color: #005dff;
    }
    100% {
        border-color: transparent;
    }
}

.badge.badge-alert {
    background-color: #FF7F50 !important;
}

.btn.no-margin > [class*="icon-"] {
    margin: 0;
}

.JsfHiddenInput {
    visibility: collapse !important;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
}

.gap-0 {
    gap: 0
}

.gap-1 {
    gap: 5px
}

.gap-2 {
    gap: 10px
}

.gap-3 {
    gap: 15px
}

.gap-4 {
    gap: 20px
}

.gap-5 {
    gap: 25px
}

.jc-sa {
    justify-content: space-around;
}

.jc-sb {
    justify-content: space-between;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.d-none {
    display: none;
}

.text-xs {
    font-size: 0.75rem !important;
}

.text-sm {
    font-size: 0.875rem !important;
}

.text-base {
    font-size: 1rem !important;
}

.text-lg {
    font-size: 1.125rem !important;
}

.text-xl {
    font-size: 1.25rem !important;
}

.text-2xl {
    font-size: 1.5rem !important;
}

.text-3xl {
    font-size: 1.875rem !important;
}

.text-4xl {
    font-size: 2.25rem !important;
}

.text-5xl {
    font-size: 3rem !important;
}

.fo-0 {
    order: 0;
}

.fo-1 {
    order: 1;
}

.bg-white {
    background-color: white;
}

.rounded {
    border-radius: 4px;
}

.rounded-lg {
    border-radius: 8px;
}

.inline-flex {
    display: inline-flex !important;
}