@charset "UTF-8";

@font-face {
  font-family: "bim-icon-font";
  src:url("bim-icon-font.eot");
  src:url("bim-icon-font.eot?#iefix") format("embedded-opentype"),
    url("bim-icon-font.woff") format("woff"),
    url("bim-icon-font.ttf") format("truetype"),
    url("bim-icon-font.svg#bim-icon-font") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-bim-"]:before,
[class*=" icon-bim-"]:before {
  font-family: "bim-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bim-wallet:before {
  content: "\61";
}
.icon-bim-wand:before {
  content: "\62";
}
.icon-bim-place:before {
  content: "\63";
}
.icon-bim-people:before {
  content: "\64";
}
.icon-bim-favourite:before {
  content: "\65";
}
.icon-bim-favourited:before {
  content: "\66";
}
.icon-bim-add:before {
  content: "\67";
}
.icon-bim-abc:before {
  content: "\68";
}
.icon-bim-filter:before {
  content: "\69";
}
.icon-bim-agile:before {
  content: "\6a";
}
.icon-bim-rename:before {
  content: "\6b";
}
.icon-bim-s:before {
  content: "\6c";
}
.icon-bim-focus:before {
  content: "\6d";
}
.icon-bim-analysis:before {
  content: "\6e";
}
.icon-bim-analysis-1:before {
  content: "\6f";
}
.icon-bim-graph:before {
  content: "\70";
}
.icon-bim-saveas:before {
  content: "\71";
}
.icon-bim-store:before {
  content: "\72";
}
.icon-bim-graphforbiden:before {
  content: "\73";
}
.icon-bim-analytics:before {
  content: "\74";
}
.icon-bim-book:before {
  content: "\75";
}
.icon-bim-logo:before {
  content: "\76";
}
.icon-bim-tabbledown:before {
  content: "\77";
}
.icon-bim-tabblezero:before {
  content: "\78";
}
.icon-bim-m:before {
  content: "\79";
}
.icon-bim-component:before {
  content: "\7a";
}
.icon-bim-dashboard:before {
  content: "\41";
}
.icon-bim-materials:before {
  content: "\42";
}
.icon-bim-table:before {
  content: "\43";
}
.icon-bim-tablerows:before {
  content: "\44";
}
.icon-bim-menucontracted:before {
  content: "\45";
}
.icon-bim-desenvolvidos:before {
  content: "\46";
}
.icon-bim-dotdown:before {
  content: "\47";
}
.icon-bim-menuexpand:before {
  content: "\48";
}
.icon-bim-talk:before {
  content: "\49";
}
.icon-bim-u:before {
  content: "\4a";
}
.icon-bim-money:before {
  content: "\4b";
}
.icon-bim-doublearrow:before {
  content: "\4c";
}
.icon-bim-dropdown:before {
  content: "\4d";
}
.icon-bim-move:before {
  content: "\4e";
}
.icon-bim-university:before {
  content: "\4f";
}
.icon-bim-cards:before {
  content: "\50";
}
.icon-bim-check-list:before {
  content: "\52";
}
.icon-bim-favourites:before {
  content: "\53";
}
.icon-bim-html:before {
  content: "\55";
}
.icon-bim-kpi-horizon:before {
  content: "\56";
}
.icon-bim-map:before {
  content: "\57";
}
.icon-bim-kpi-vertical:before {
  content: "\58";
}
.icon-bim-table-1:before {
  content: "\59";
}
.icon-bim-flow:before {
  content: "\54";
}
.icon-bim-chart:before {
  content: "\51";
}
