.cockpit-enabled .breadcrumbs {
    margin: 0;
    top: 48px;
}

.AppPageWrapper.cockpit-header-hideable-tabs .cockpit-enabled .navbar {
    max-height: 50px;
}

.AppPageWrapper.cockpit-header-hideable-breadcrumb.cockpit-header-hidden .breadcrumbs {
    width: 100%;
}

.AppPageWrapper.cockpit-header-hideable-breadcrumb .breadcrumbs {
    width: 100%;
}

.AppPageWrapper.cockpit-header-hideable-breadcrumb .breadcrumbs,
.AppPageWrapper.cockpit-header-hideable-breadcrumb #floating-object-container {
    transition: top var(--expand-header-transition);
    position: fixed;
}

.AppPageWrapper.cockpit-header-hideable-breadcrumb.cockpit-header-hidden .breadcrumbs,
.AppPageWrapper.cockpit-header-hideable-breadcrumb.cockpit-header-hidden #floating-object-container {
    position: fixed;
    top: 0;
}

.cockpit-enabled .navbar {
    max-height: 84px;
    transition: max-height var(--expand-header-transition);
    position: relative;
    overflow: hidden;
}

.cockpit-header-hidden .cockpit-enabled .navbar {
    max-height: 0 !important;
}

.breadcrumbs.fixed,
.breadcrumbs.breadcrumbs-fixed {
    position: fixed;
    right: 0;
    left: 190px;
    top: 45px;
    z-index: 1028;
}

.breadcrumb > li > a {
    display: inline-block;
    color: #6e6e6e;
}

.breadcrumb > li + li:before {
    font-family: FontAwesome;
    font-size: 14px;
    content: "\f105";
    color: #6e6e6e;
    margin-right: 2px;
    padding: 0 5px 0 2px;
    position: relative;
    top: 1px;
}

.breadcrumb .home-icon {
    margin-left: 4px;
    margin-right: 2px;
    font-size: 20px;
    position: relative;
    top: 2px;
}

.breadcrumb > li {
    vertical-align: middle;
}

@media only screen and (max-width: 991px) {
    .breadcrumb {
        margin-left: 90px;
    }

    .breadcrumbs.fixed,
    .breadcrumbs.breadcrumbs-fixed {
        position: relative !important;
        left: auto !important;
        right: auto !important;
        top: auto !important;
        z-index: auto !important;
    }

    body.breadcrumbs-fixed .ace-settings-container {
        top: 50px;
    }
}

@media only screen and (max-width: 480px) {
    .breadcrumb > li > a {
        padding: 0 1px;
    }
}

@media only screen and (max-width: 320px) {
    .breadcrumb {
        margin-left: 36px;
    }
}

.container.main-container .breadcrumbs.breadcrumbs-fixed {
    left: auto !important;
    right: auto !important;
}

@media (min-width: 768px) {
    .container.main-container .breadcrumbs.breadcrumbs-fixed {
        width: 554px;
    }

    .container.main-container .menu-min + .main-content .breadcrumbs.breadcrumbs-fixed {
        width: 701px;
    }
}

@media (min-width: 992px) {
    .container.main-container .breadcrumbs.breadcrumbs-fixed {
        width: 774px;
    }

    .container.main-container .menu-min + .main-content .breadcrumbs.breadcrumbs-fixed {
        width: 921px;
    }
}

@media (min-width: 1200px) {
    .container.main-container .breadcrumbs.breadcrumbs-fixed {
        width: 974px;
    }

    .container.main-container .menu-min + .main-content .breadcrumbs.breadcrumbs-fixed {
        width: 1121px;
    }
}