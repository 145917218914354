.IceDialogFrame,
.IceDialogBackdrop {
    position: fixed !important;
    visibility: visible !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
}

.icePnlCnfBtns {
    padding: 5px !important;
}

.icePnlCnf {
    width: 450px;
}

.icePnlCnfBody {
    height: 50px;
}

.dialog {
    margin: 0;
    padding: 0;
}

.widget-box .widget-header{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.widget-box .widget-body{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.home-folder-managment .widget-header,
.home-folder-managment .widget-box {
    border-radius: 4px;
}

.folder-management-data-table {
    background: white;
}

.home-folder-managment .folder-management-data-table td div > div {
    margin: 0px 5px;
}

.dialog > table:first-child {
    width: 100%;
}

.dialog-header {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    width: inherit;
    line-height: 25px;
    height: 25px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.dialog-body {
    display: block;
    word-break: break-word;
    word-wrap: break-word;
    overflow: auto;
}

.dialog-body fieldset {
    word-break: normal;
    word-wrap: normal;
}

.dialogBody .widget-toolbar {
    display: inline-flex;
    padding: 0 10px;
    position: absolute;
    right: 10px;
    top: 20px;
}

.dialogBody .widget-body{
    background: #ffffff;
    padding: 0px 18px 12px 18px;
}

.dialogBody .widget-header {
    padding: 14px 50px 0px 28px;
}

/*
 * Fora do padrao pois e uma classe adicionada automaticamente pelo IceFaces
 */
.dialogBody {
    padding: 0;
    margin: 0;
    overflow: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.dialog-title {
    color: #f5f5f5;
    font-size: 11px;
    font-weight: bold;
}

.dialog-half-screen {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    height: 50%;
    min-height: 50%;
    max-height: 50%;
}

.close {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: #f5f5f5;
    opacity: 0.8;
    text-decoration: none;
    line-height: 25px;
    height: 25px;
    right: 5px;
}

.close:hover {
    color: white;
    text-decoration: none;
    opacity: 1;
    cursor: pointer;
}

.simplemodal-overlay {
    z-index: 30001 !important;
    background-color: black;
}

.simplemodal-container {
    z-index: 30002 !important;
}

.modal-close {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: #21618b;
    opacity: 0.8;
    text-decoration: none;
    line-height: 25px;
    height: 25px;
    right: 5px;
}

.modal-close-inv {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: #f5f5f5;
    opacity: 0.8;
    text-decoration: none;
    line-height: 25px;
    height: 25px;
    right: 5px;
}

/*
 * Fora do padrao pois e uma classe adicionada automaticamente pelo IceFaces
 */
.icePnlPopHdr {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

/* Tooltips IceFaces */
.icePnlTlTip {
    background: #efefef;
    border: 1px solid #21618b;
    text-align: center;
    border-radius: 5px 5px 0 0;
}

.icePnlTlTipHdr {
    width: 100% !important;
    height: 18px;
    line-height: 18px;
    padding: 2px;
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
}

.icePnlTlTipBody {
    text-align: center !important;
    padding: 5px;
}

.file-upload-image-preview {
    max-width: 768px;
    max-height: 480px;
}

.file-upload-image-preview-container {
    width: 300px;
    height: 281px;
    background: #efefef;
    display: none;
    text-align: center;
    border: 1px solid #21618b;
    border-radius: 5px;
}

.file-upload-image-preview-header {
    background-color: #194368;
    background-image: linear-gradient(to bottom, #194368 0, #286ba6 100%);
    border-radius: 5px 5px 0 0;
    width: 296px;
    padding: 2px;
    height: 24px;
    line-height: 24px;
    text-align: center;
}

/* End IceFaces */

.icon-padding-zero {
    padding: 0 !important;
}
