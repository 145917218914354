.kpi-default-percent.regular .infobox-progress {
    padding: 0 10px 10px 0;
}

.kpi-pie.regular .pie-holder {
    padding: 0 10px 10px 0;
}

.useBands, .useTarget{
    color:#555555;
    white-space: nowrap;
}

.infobox-data-label{
    font-weight: normal;
    white-space:nowrap;
    color: #555555;
}