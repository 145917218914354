.cleditorPopup {
    z-index: 420000
}

.cle-editor-full-screen {
    z-index: 410000;
    width: 90%;
    height: 90%;
    position: absolute;
    top: 25px;
    left: 50px;
}