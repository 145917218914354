.UiBlocker.Loading .BngEmpty {
    visibility: hidden;
}
.BngEmpty {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    margin: 0;
}

.BngEmpty .Container {
    display: inline-block;
    width: 250px;
}

.BngEmpty .Image {
    height: 100px;
}

.BngEmpty .Title {
    padding: 16px 0 8px 0;
    font-size: 16px;
    color: #292C31;
    font-weight: bold;
    letter-spacing: 0.5px;
    word-break: normal;
}

.BngEmpty .Message {
    line-height: 16px;
    word-break: normal;
}