.BngInputColor {
    display: inline-flex;
    position: relative;
    align-items: center;
}

.BngInputColorList {
    display: flex;
    gap: 10px;
    cursor: default;
    justify-content: space-between;
}

.BngInputColorList > .ColorContainer {
    height: 40px;
    width: 34px;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
}

.BngInputColor.Disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.BngInputColor.xxs .ColorCircle {
    width: 8px !important;
    height: 8px !important;
}

.BngInputColor.xs .ColorCircle {
    width: 12px !important;
    height: 12px !important;
}

.BngInputColor.sm .ColorCircle {
    width: 18px !important;
    height: 18px !important;
}

.BngInputColor.md .ColorCircle {
    width: 24px !important;
    height: 24px !important;
}

.ColorCircle,
.chrome-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div {
    height: 32px !important;
    width: 32px !important;
    border: 2px solid rgba(0, 0, 0, 0.11) !important;
    border-radius: 100% !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease 300ms;
}

.ColorCircle:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.BngInputColor.Disabled .ColorCircle:hover {
    opacity: initial;
    transform: initial;
}

.ColorCircle.Selected {
    transform: scale(1.25);
    border: 4px solid rgba(0, 0, 0, 0.25) !important;
    height: 28px !important;
    width: 28px !important;
}

.ColorCircle .material-icons {
    font-size: 36px;
    transform: scale(0.6);
    color: #979797;
}

.BngField .ColorCircle {
    width: 28px !important;
    height: 28px !important;
}

.BngInputColorPopper {
    width: auto;
    display: flex;
    z-index: 322001;
}

.BngInputColorPopperOverlay {
    z-index: 322000;
}

.BngInputColorPopper .BngPanel {
    width: 324px;
    height: 344px;
    display: inline-block;
}

.BngInputColorPopper .Content {
    padding: 20px 20px 0 20px;
    height: 272px;
}

.BngInputColorPopper .Content > .ColorContainer {
    cursor: pointer;
    width: 20%;
    display: inline-flex;
    justify-content: center;
    height: 60px;
}

.BngInputColorPopper .PickerPanel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 52px);
}

.BngInputColorPopper .PickerPanel > div {
    width: 281px;
    height: 263px;
    position: relative;
}

.chrome-picker {
    width: 280px !important;
    box-shadow: none !important;
    font-family: unset !important;
}

.chrome-picker > div:first-child {
    height: 182px !important;
    padding: 0 !important;
    width: 280px !important;
    border: 1px solid #E7E8EB !important;
    border-radius: 4px !important;
}

.chrome-picker > div:nth-child(2) > div:nth-child(2) > div.flexbox-fix > div > div > span,
.chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
    display: none !important;
}

.chrome-picker > div:nth-child(2) {
    padding: 0 !important;
    height: 15px !important;
    margin: 8px 0;
}

.chrome-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
    margin: 0 !important;
}

.chrome-picker > div:nth-child(2) > div:nth-child(1) {
    height: 15px !important;
}

.chrome-picker .hue-horizontal {
    height: 15px !important;
    width: 280px !important;
    border-radius: 7px !important;
    box-sizing: border-box;
}

.chrome-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div > div > div > div {
    height: 9px !important;
    width: 9px !important;
    border: 1px solid #E7E8EB !important;
    border-radius: 100% !important;
    background-color: #fff !important;
    margin-top: 3px;
}

.chrome-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    position: absolute;
    bottom: 8px;
    left: 8px;
    height: 33px;
}


.chrome-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div > div {
    box-shadow: none !important;
    border: 2px solid rgba(0, 0, 0, 0.11) !important;
    border-radius: 100% !important;
}

.chrome-picker > div:nth-child(2) > div:nth-child(2) {
    padding-top: 8px !important;
}

.chrome-picker > div:nth-child(2) > div:nth-child(2) > div.flexbox-fix > div > div > input {
    margin-left: 0 !important;
    height: 48px !important;
    border: 1px solid #E7E8EB !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    color: #292C31 !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    text-align: left !important;
    padding-left: 50px;
    box-shadow: none !important;
}

.BngInputColorPopper .Actions {
    position: absolute;
    top: 5px;
    right: 15px;
    left: 15px;
}

.BngInputColorDrop.asList {
    width: 100%;
}