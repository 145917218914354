.BngDropdown {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.BngDropdownSeparator {
  white-space: nowrap;
  padding: 5px 16px !important;
  box-shadow: none !important;
  cursor: unset !important;
  background: #fff !important;
}

.bng-dropdown-parent {
  width: 200px;
  z-index: 5;
}

.bng-dropdown,
ul.bng-dropdown {
  display: block;
  margin: 5px 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  border: 1px;
  border-radius: 4px;
  text-align: left;
  right: 8px;
  z-index: 1;
}

.bng-dropdown li,
.bng-dropdown .item {
  display: flex;
  align-items: center;
  padding: 11px 15px;
  font-weight: 500;
  color: #6e6e6e;
  box-shadow: inset 0 -1px 0 0 #e7e8eb;
  cursor: pointer;
  line-height: 30px;
}

.bng-dropdown-parent.dropdown-links .item,
.bng-dropdown-parent.dropdown-links li {
  padding: 0;
}

.bng-dropdown-parent.dropdown-links .item > a,
.bng-dropdown-parent.dropdown-links li > a {
  padding: 11px 15px;
  width: 100%;
}

.bng-dropdown li button .material-icons {
  margin-right: 5px;
}

.bng-dropdown .item.compact {
  padding: 5px 15px;
}

.bng-dropdown .item.no-border {
  border: none;
}

.bng-dropdown li:last-child,
.bng-dropdown .item:last-child {
  border-bottom: 0;
}

.bng-dropdown li:not(.disabled):hover,
.bng-dropdown .item:not(.disabled):hover {
  background: #efefef;
}

.bng-dropdown li .material-icons,
.bng-dropdown .item .material-icons {
  vertical-align: middle;
}

.bng-dropdown li .Icon,
.bng-dropdown .item .Icon {
  margin-right: 10px;
}

.bng-dropdown hr {
  margin: 0;
}

.bng-dropdown .material-icons {
  font-size: 16px;
  width: 16px;
}

.bng-dropdown li > * {
  margin-right: 8px;
}

.bng-dropdown li > *:last-child {
  margin-right: 0;
}

/*styled options*/

.BngOptions > .bng-dropdown {
  background: unset;
}

.BngOptions > .bng-dropdown li,
.BngOptions > .bng-dropdown .item {
  background: #f7f8fa;
}

.BngOptions > .bng-dropdown li:hover,
.BngOptions > .bng-dropdown .item:hover {
  background: #efefef;
}

.BngOptions > .bng-dropdown li:first-child,
.BngOptions > .bng-dropdown .item:first-child,
.BngOptions > .bng-dropdown li.bng-dropdown-header,
.BngOptions > .bng-dropdown .item.bng-dropdown-header {
  cursor: unset;
  background: #fff;
  border-radius: 4px 4px 0 0;
}

.BngOptions > .bng-dropdown li:last-child,
.BngOptions > .bng-dropdown .item:last-child {
  border-radius: 0 0 4px 4px;
}

.bng-dropdown .GroupOption:not(.GroupFirst, .GroupLast) {
  padding-top: 6px;
  padding-bottom: 6px;
}

.bng-dropdown .GroupOption.GroupFirst {
  padding-bottom: 6px;
}

.bng-dropdown .GroupOption.GroupLast {
  padding-top: 6px;
}

.BngDropdown-OVERDIALOG {
  z-index: 321052 !important;
}

.BngDropdown-OVERDIALOGOverlay.BngClickOutsideOverlay {
  z-index: 321051 !important;
}

.bng-dropdown > li.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.BngDropdownPopperArrow {
  background: #fff;
  display: block;
  height: 16px;
  position: absolute;
  transform: rotate(45deg);
  width: 16px;
}