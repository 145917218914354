html {
    min-height: 100%;
    position: relative;
}

body {
    padding-bottom: 0;
    background-color: #e4e6e9;
    min-height: 100%;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    font-size: 13px;
    color: #2A2624;
}

body:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #ffffff;
}

.commandLink {
    text-decoration: none;
    border-bottom-color: #e4ecf3;
    padding: 10px 2px;
    margin: 0;
    border-bottom: 1px solid;
    font-size: 12px;
    line-height: 16px;
    color: #777777 !important;
    list-style: none;
    outline: 0;
    clear: both;
    white-space: nowrap;
    display: block;
}

.textReadNotification {
    font-weight: bold;
}

body.navbar-fixed {
    padding-top: 45px;
}

[class*=" icon-"],
[class^="icon-"] {
    display: inline-block;
    text-align: center;
}

a:focus,
a:active {
    text-decoration: none;
}

li.divider {
    margin-top: 3px;
    margin-bottom: 3px;
    height: 0;
    font-size: 0;
}

.lighter {
    font-weight: lighter;
}

.bolder {
    font-weight: bolder;
}

.inline {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.center,
.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.middle {
    vertical-align: middle;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.blue {
    color: #478fca !important;
}

.navbar {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.navbar .navbar-inner {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    min-height: 48px;
    height: 48px;
    position: relative;
    background: #575BD8;
}

.navbar .navbar-text,
.navbar .navbar-link {
    color: #ffffff;
}

.navbar .brand {
    color: #ffffff;
    font-size: 24px;
    text-shadow: none;
    padding: 2px 0px 0px 20px !important;
}

.navbar .nav > li > a,
.navbar .nav > li > a:hover,
.navbar .nav > li > a:focus {
    font-size: 14px;
    text-shadow: none;
    color: #ffffff;
}

.ace-nav {
    height: 100%;
}

.ace-nav > li:first-child {
    border-left: none;
}

.badge {
    text-shadow: none;
    font-size: 12px;
    padding-top: 1px;
    padding-bottom: 3px;
    font-weight: normal;
    line-height: 15px;
    background-color: #abbac3 !important;
}

.label-success,
.badge-success {
    background-color: #82af6f !important;
}

.label-important,
.badge-important {
    background-color: #d15b47 !important;
}

.ace-nav > li.grey {
    background: #555555;
}

.ace-nav > li.purple {
    background: #892e65;
}

.ace-nav > li.green {
    background: #2e8965;
}

.ace-nav > li.light-blue {
    background: #62a8d1;
}

.ace-nav > li.light-blue2 {
    background: #42a8e1;
}

.ace-nav > li.red {
    background: #b74635;
}

.ace-nav > li.light-green {
    background: #9abc32;
}

.ace-nav > li.light-purple {
    background: #cb6fd7;
}

.ace-nav > li.light-orange {
    background: #f79263;
}

.ace-nav > li.light-pink {
    background: #f4dae5;
}

.ace-nav > li.dark {
    background: #404040;
}

.ace-nav > li.white-opaque {
    background: rgba(255, 255, 255, 0.8);
}

.ace-nav > li.dark-opaque {
    background: rgba(0, 0, 0, 0.2);
}

.ace-nav > li.no-border {
    border: 0;
}

.ace-nav > li.margin-4 {
    margin-left: 4px;
}

.ace-nav > li.margin-3 {
    margin-left: 3px;
}

.ace-nav > li.margin-2 {
    margin-left: 2px;
}

.ace-nav > li.margin-1 {
    margin-left: 1px;
}

.ace-nav > li .dropdown-menu {
    z-index: 1031;
}

.ace-nav .nav-user-photo {
    margin: -4px 8px 0 0;
    border-radius: 24px;
    border: 2px solid #ffffff;
    max-width: 36px !important;
}

.ace-nav li:last-child a [class^="icon-"] {
    display: inline-block;
    text-align: center;
}

li [class^="icon-"],
li [class*=" icon-"],
.nav-list li [class^="icon-"],
.nav-list li [class*=" icon-"] {
    width: auto;
}

.btn,
.iceCmdBtn .button,
.iceCmdBtn,
.button {
    display: inline-block;
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-image: none !important;
    border: 5px solid;
    border-radius: 0;
    box-shadow: none !important;
    -webkit-transition: all ease .15s;
    -moz-transition: all ease .15s;
    -o-transition: all ease .15s;
    transition: all ease .15s;
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
    position: relative;
    padding: 0 12px 1px;
    line-height: 32px;
    font-size: 14px;
}

.btn-mini {
    padding: 0 5px;
    line-height: 22px;
    border-width: 2px;
    font-size: 12px;
}

.btn-table,
.btn-table-dis {
    height: 25px;
    width: 25px;
    font-size: 16px;
}

button.btn.btn-primary.toggled {
    top: 1px;
    left: 1px;
    border: 1px inset #1C6CA1;
    background-color: #1C6CA1 !important;
}

.btn,
.btn-default {
    background-color: #abbac3 !important;
    border-color: #abbac3;
}

.btn:hover,
.btn-default:hover {
    background-color: #8b9aa3 !important;
}

.btn.no-border:hover,
.btn-default.no-border:hover {
    border-color: #8b9aa3;
}

.btn.no-hover:hover,
.btn-default.no-hover:hover {
    background-color: #abbac3 !important;
}

.btn.active,
.btn-default.active {
    background-color: #9baab3 !important;
    border-color: #8a9ba6;
}

.btn.no-border.active,
.btn-default.no-border.active {
    background-color: #92a3ac !important;
    border-color: #92a3ac;
}

.btn.disabled,
.btn-default.disabled,
.btn[disabled],
.btn-default[disabled] {
    background-color: #abbac3 !important;
}

.btn-primary,
.iceCmdBtn .button,
.iceCmdBtn,
.button {
    background-color: #2283c5 !important;
    border-color: #2283c5;
}

.btn-primary:hover {
    background-color: #045e9f !important;
}

.btn-primary.no-border:hover {
    border-color: #045e9f;
}

.btn-primary.no-hover:hover {
    background-color: #2283c5 !important;
}

.btn-primary.active {
    background-color: #1371b2 !important;
    border-color: #105f96;
}

.btn-primary.no-border.active {
    background-color: #1268a4 !important;
    border-color: #1268a4;
}

.btn-primary.disabled,
.btn-primary[disabled] {
    background-color: #2283c5 !important;
}

.btn-success.disabled,
.btn-success[disabled] {
    background-color: #87b87f !important;
}

.btn-warning {
    background-color: #ffb752 !important;
    border-color: #ffb752;
}

.btn-warning:hover {
    background-color: #e59729 !important;
}

.btn-warning.no-border:hover {
    border-color: #e59729;
}

.btn-warning.no-hover:hover {
    background-color: #ffb752 !important;
}

.btn-warning.active {
    background-color: #f2a73e !important;
    border-color: #f09a21;
}

.btn-warning.no-border.active {
    background-color: #f1a02f !important;
    border-color: #f1a02f;
}

.btn-warning.disabled,
.btn-warning[disabled] {
    background-color: #ffb752 !important;
}

.btn-danger {
    background-color: #d15b47 !important;
    border-color: #d15b47;
}

.btn-danger:hover {
    background-color: #b74635 !important;
}

.btn-danger.no-border:hover {
    border-color: #b74635;
}

.btn-danger.no-hover:hover {
    background-color: #d15b47 !important;
}

.btn-danger.active {
    background-color: #c4513e !important;
    border-color: #ae4635;
}

.btn-danger.no-border.active {
    background-color: #ba4b39 !important;
    border-color: #ba4b39;
}

.btn-danger.disabled,
.btn-danger[disabled] {
    background-color: #d15b47 !important;
}

.btn-inverse {
    background-color: #555555 !important;
    border-color: #555555;
}

.btn-inverse:hover {
    background-color: #303030 !important;
}

.btn-inverse.no-border:hover {
    border-color: #303030;
}

.btn-inverse.no-hover:hover {
    background-color: #555555 !important;
}

.btn-inverse.active {
    background-color: #434343 !important;
    border-color: #333333;
}

.btn-inverse.no-border.active {
    background-color: #3b3b3b !important;
    border-color: #3b3b3b;
}

.btn-inverse.disabled,
.btn-inverse[disabled] {
    background-color: #555555 !important;
}

.btn-pink {
    background-color: #d6487e !important;
    border-color: #d6487e;
}

.btn-pink:hover {
    background-color: #b73766 !important;
}

.btn-pink.no-border:hover {
    border-color: #b73766;
}

.btn-pink.no-hover:hover {
    background-color: #d6487e !important;
}

.btn-pink.active {
    background-color: #c74072 !important;
    border-color: #b33564;
}

.btn-pink.no-border.active {
    background-color: #be386a !important;
    border-color: #be386a;
}

.btn-pink.disabled,
.btn-pink[disabled] {
    background-color: #d6487e !important;
}

.btn-purple {
    background-color: #9585bf !important;
    border-color: #9585bf;
}

.btn-purple:hover {
    background-color: #7461aa !important;
}

.btn-purple.no-border:hover {
    border-color: #7461aa;
}

.btn-purple.no-hover:hover {
    background-color: #9585bf !important;
}

.btn-purple.active {
    background-color: #8573b5 !important;
    border-color: #735faa;
}

.btn-purple.no-border.active {
    background-color: #7c69af !important;
    border-color: #7c69af;
}

.btn-purple.disabled,
.btn-purple[disabled] {
    background-color: #9585bf !important;
}

.btn-grey {
    background-color: #a0a0a0 !important;
    border-color: #a0a0a0;
}

.btn-grey:hover {
    background-color: #888888 !important;
}

.btn-grey.no-border:hover {
    border-color: #888888;
}

.btn-grey.no-hover:hover {
    background-color: #a0a0a0 !important;
}

.btn-grey.active {
    background-color: #949494 !important;
    border-color: #858585;
}

.btn-grey.no-border.active {
    background-color: #8c8c8c !important;
    border-color: #8c8c8c;
}

.btn-grey.disabled,
.btn-grey[disabled] {
    background-color: #a0a0a0 !important;
}

.btn-yellow {
    background-color: #fee188 !important;
    border-color: #fee188;
    color: #996633 !important;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
}

.btn-yellow:hover {
    background-color: #f7d05b !important;
}

.btn-yellow.no-border:hover {
    border-color: #f7d05b;
}

.btn-yellow.no-hover:hover {
    background-color: #fee188 !important;
}

.btn-yellow.active {
    background-color: #fbd972 !important;
    border-color: #fad054;
}

.btn-yellow.no-border.active {
    background-color: #fad463 !important;
    border-color: #fad463;
}

.btn-yellow.disabled,
.btn-yellow[disabled] {
    background-color: #fee188 !important;
}

.btn-light {
    background-color: #e7e7e7 !important;
    border-color: #e7e7e7;
    color: #888888 !important;
    text-shadow: 0 -1px 0 rgba(250, 250, 250, 0.25) !important;
}

.btn-light:hover {
    background-color: #d9d9d9 !important;
}

.btn-light.no-border:hover {
    border-color: #d9d9d9;
}

.btn-light.no-hover:hover {
    background-color: #e7e7e7 !important;
}

.btn-light.active {
    background-color: #e0e0e0 !important;
    border-color: #d1d1d1;
}

.btn-light.no-border.active {
    background-color: #d8d8d8 !important;
    border-color: #d8d8d8;
}

.btn-light.disabled,
.btn-light[disabled] {
    background-color: #e7e7e7 !important;
}

.btn-light.btn-mini:after {
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
}

.btn-light.btn-small:after {
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;
}

.btn-light.btn-large:after {
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
}

.btn.disabled.active,
.btn[disabled].active,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled:active,
.btn[disabled]:active {
    outline: 0;
}

.btn.disabled:active,
.btn[disabled]:active {
    top: 0;
    left: 0;
}

.btn.active {
    color: #efe5b5;
}

.btn.active:after {
    display: inline-block;
    content: "";
    position: absolute;
    border-bottom: 1px solid #efe5b5;
    left: -4px;
    right: -4px;
    bottom: -4px;
}

.btn.active.btn-small:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-bottom-width: 1px;
}

.btn.active.btn-large:after {
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-bottom-width: 1px;
}

.btn.active.btn-mini:after,
.btn.active.btn-minier:after {
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-bottom-width: 1px;
}

.btn.active.btn-yellow:after {
    border-bottom-color: #c96338;
}

.btn.active.btn-light {
    color: #515151;
}

.btn.active.btn-light:after {
    border-bottom-color: #b5b5b5;
}

.btn > [class*="icon-"] {
    display: inline;
    margin-right: 4px;
}

.btn > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 4px;
}

.btn > .icon-only[class*="icon-"] {
    margin: 0;
    vertical-align: middle;
    text-align: center;
    padding: 0;
}

.btn-right {
    float: right;
    margin-right: 20px;
}

.btn-margin-top {
    margin-top: 10px;
}

.btn-margin-bottom {
    margin-bottom: 10px;
}

.btn-large > [class*="icon-"] {
    margin-right: 6px;
}

.btn-large > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 6px;
}

.btn-small > [class*="icon-"] {
    margin-right: 3px;
}

.btn-small > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 3px;
}

.btn-mini > [class*="icon-"],
.btn-minier > [class*="icon-"] {
    margin-right: 2px;
}

.btn-mini > [class*="icon-"].icon-on-right,
.btn-minier > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 2px;
}

.btn.btn-link {
    border: none !important;
    background: transparent none !important;
    color: #0088cc !important;
    text-shadow: none !important;
    padding: 4px 12px !important;
    line-height: 20px !important;
}

.btn.btn-link:hover {
    background: none !important;
    text-shadow: none !important;
}

.btn.btn-link.active {
    background: none !important;
    text-decoration: underline;
    color: #009ceb !important;
}

.btn.btn-link.active:after {
    display: none;
}

.btn.btn-link.disabled,
.btn.btn-link[disabled] {
    background: 0;
    opacity: 0.65;
}

.btn.btn-link.disabled:hover,
.btn.btn-link[disabled]:hover {
    background: none !important;
    text-decoration: none !important;
}

.btn-group > .btn,
.btn-group > .btn + .btn {
    margin: 0 1px 0 0;
}

.btn-group > .btn:first-child {
    margin: 0 1px 0 0;
}

.btn-group > .btn:first-child,
.btn-group > .btn:last-child {
    border-radius: 0;
}

.btn-group > .btn > .caret {
    margin-top: 15px;
    margin-left: 1px;
    border-width: 5px;
    border-top-color: #ffffff;
}

.btn-group > .btn.btn-small > .caret {
    margin-top: 10px;
    border-width: 4px;
}

.btn-group > .btn.btn-large > .caret {
    margin-top: 18px;
    border-width: 6px;
}

.btn-group > .btn.btn-mini > .caret {
    margin-top: 9px;
    border-width: 4px;
}

.btn-group > .btn.btn-minier > .caret {
    margin-top: 7px;
    border-width: 3px;
}

.btn-group > .btn + .btn.dropdown-toggle {
    padding-right: 3px;
    padding-left: 3px;
}

.btn-group > .btn + .btn-large.dropdown-toggle {
    padding-right: 4px;
    padding-left: 4px;
}

.btn-group .dropdown-toggle {
    border-radius: 0;
}

.btn-group > .btn,
.btn-group + .btn {
    margin: 0 1px 0 0;
    border-width: 3px !important;
}

.btn-group > .btn.active:after,
.btn-group + .btn.active:after {
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-bottom-width: 1px;
}

.btn-group > .btn-large,
.btn-group + .btn-large {
    border-width: 4px !important;
}

.btn-group > .btn-large.active:after,
.btn-group + .btn-large.active:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-bottom-width: 1px;
}

.btn-group > .btn-small,
.btn-group + .btn-small {
    border-width: 2px !important;
}

.btn-group > .btn-small.active:after,
.btn-group + .btn-small.active:after {
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-bottom-width: 1px;
}

.btn-group > .btn-mini,
.btn-group + .btn-mini {
    border-width: 1px !important;
}

.btn-group > .btn-mini.active:after,
.btn-group + .btn-mini.active:after {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-width: 1px;
}

.btn-group > .btn-minier,
.btn-group + .btn-minier {
    border-width: 0 !important;
}

.btn-group > .btn-minier.active:after,
.btn-group + .btn-minier.active:after {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-width: 1px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn + .btn {
    margin: 1px 0 0;
}

.btn-group-vertical > .btn:first-child {
    margin-right: 0;
}

.label {
    border-radius: 0;
    text-shadow: none;
    font-size: 11px;
    font-weight: normal;
    padding: 1px 5px 3px;
    background-color: #abbac3;
}

.label[class*="span"][class*="arrow"] {
    min-height: 0;
}

.icon-white,
.nav-pills > .active > a > [class^="icon-"],
.nav-pills > .active > a > [class*=" icon-"],
.nav-list > .active > a > [class^="icon-"],
.nav-list > .active > a > [class*=" icon-"],
.navbar-inverse .nav > .active > a > [class^="icon-"],
.navbar-inverse .nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:focus > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > li > a:focus > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"],
.dropdown-submenu:hover > a > [class^="icon-"],
.dropdown-submenu:focus > a > [class^="icon-"],
.dropdown-submenu:hover > a > [class*=" icon-"],
.dropdown-submenu:focus > a > [class*=" icon-"] {
    background-image: none;
}

.dropdown-left {
    float: left !important;
    right: 0;
    left: auto !important;
    text-align: left;
}

.dropdown-menu > li > a {
    font-size: 13px;
    padding-left: 11px;
    padding-right: 11px;
    margin-bottom: 1px;
    margin-top: 1px;
}

.dropdown-menu.dropdown-icon-only {
    min-width: 0;
}

.dropdown-menu.dropdown-icon-only > li > a [class*="icon-"],
.dropdown-menu.dropdown-icon-only > li > form [class*="icon-"] {
    width: 18px;
    display: inline-block;
}

.dropdown-menu.dropdown-icon-only > li > a .icon-2x,
.dropdown-menu.dropdown-icon-only > li > form .icon-2x {
    width: 36px;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active,
.dropdown-menu li.active a,
.dropdown-menu li.active a:hover,
.dropdown-menu .dropdown-submenu:hover > a,
.nav-tabs .dropdown-menu li > a:focus {
    background: #4f99c6;
    color: #ffffff;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active,
.dropdown-menu li.active a,
.dropdown-menu li.active a:hover {
    background: #4f99c6;
    color: #ffffff;
}

.dropdown-form a {
    color: #555555 !important;
}

.purple li .iceOutLnk:hover {
    color: #0088cc !important;
    background-color: #fcf4f9 !important;
    text-decoration: underline !important;
}

.purple .dropdown-menu li a:hover {
    color: #0088cc;
    background-color: #fcf4f9;
    text-decoration: underline;
}

.purple form .icon-arrow-right:hover,
.purple form .icon-arrow-right {
    text-decoration: none !important;
}

.purple .icon-arrow-right:hover,
.purple .icon-arrow-right {
    text-decoration: none !important;
}

.dropdown-form a:hover {
    color: #555555 !important;
    text-decoration: none !important;
    background-color: #fcf4f9 !important;
}

.dropdown-default li a:hover,
.dropdown-default li a:focus,
.dropdown-default li a:active,
.dropdown-default li.active a,
.dropdown-default li.active a:hover,
.dropdown-default .dropdown-submenu:hover > a,
.nav-tabs .dropdown-default li > a:focus {
    background: #abbac3;
    color: #ffffff;
}

.dropdown-info li a:hover,
.dropdown-info li a:focus,
.dropdown-info li a:active,
.dropdown-info li.active a,
.dropdown-info li.active a:hover,
.dropdown-info .dropdown-submenu:hover > a,
.nav-tabs .dropdown-info li > a:focus {
    background: #6fb3e0;
    color: #ffffff;
}

.dropdown-primary li a:hover,
.dropdown-primary li a:focus,
.dropdown-primary li a:active,
.dropdown-primary li.active a,
.dropdown-primary li.active a:hover,
.dropdown-primary .dropdown-submenu:hover > a,
.nav-tabs .dropdown-primary li > a:focus {
    background: #2283c5;
    color: #ffffff;
}

.dropdown-success li a:hover,
.dropdown-success li a:focus,
.dropdown-success li a:active,
.dropdown-success li.active a,
.dropdown-success li.active a:hover,
.dropdown-success .dropdown-submenu:hover > a,
.nav-tabs .dropdown-success li > a:focus {
    background: #87b87f;
    color: #ffffff;
}

.dropdown-warning li a:hover,
.dropdown-warning li a:focus,
.dropdown-warning li a:active,
.dropdown-warning li.active a,
.dropdown-warning li.active a:hover,
.dropdown-warning .dropdown-submenu:hover > a,
.nav-tabs .dropdown-warning li > a:focus {
    background: #ffa24d;
    color: #ffffff;
}

.dropdown-danger li a:hover,
.dropdown-danger li a:focus,
.dropdown-danger li a:active,
.dropdown-danger li.active a,
.dropdown-danger li.active a:hover,
.dropdown-danger .dropdown-submenu:hover > a,
.nav-tabs .dropdown-danger li > a:focus {
    background: #d15b47;
    color: #ffffff;
}

.dropdown-inverse li a:hover,
.dropdown-inverse li a:focus,
.dropdown-inverse li a:active,
.dropdown-inverse li.active a,
.dropdown-inverse li.active a:hover,
.dropdown-inverse .dropdown-submenu:hover > a,
.nav-tabs .dropdown-inverse li > a:focus {
    background: #555555;
    color: #ffffff;
}

.dropdown-purple li a:hover,
.dropdown-purple li a:focus,
.dropdown-purple li a:active,
.dropdown-purple li.active a,
.dropdown-purple li.active a:hover,
.dropdown-purple .dropdown-submenu:hover > a,
.nav-tabs .dropdown-purple li > a:focus {
    background: #9585bf;
    color: #ffffff;
}

.dropdown-pink li a:hover,
.dropdown-pink li a:focus,
.dropdown-pink li a:active,
.dropdown-pink li.active a,
.dropdown-pink li.active a:hover,
.dropdown-pink .dropdown-submenu:hover > a,
.nav-tabs .dropdown-pink li > a:focus {
    background: #d6487e;
    color: #ffffff;
}

.dropdown-grey li a:hover,
.dropdown-grey li a:focus,
.dropdown-grey li a:active,
.dropdown-grey li.active a,
.dropdown-grey li.active a:hover,
.dropdown-grey .dropdown-submenu:hover > a,
.nav-tabs .dropdown-grey li > a:focus {
    background: #a0a0a0;
    color: #ffffff;
}

.dropdown-light li a:hover,
.dropdown-light li a:focus,
.dropdown-light li a:active,
.dropdown-light li.active a,
.dropdown-light li.active a:hover,
.dropdown-light .dropdown-submenu:hover > a,
.nav-tabs .dropdown-light li > a:focus {
    background: #e7e7e7;
    color: #333333;
}

.dropdown-lighter li a:hover,
.dropdown-lighter li a:focus,
.dropdown-lighter li a:active,
.dropdown-lighter li.active a,
.dropdown-lighter li.active a:hover,
.dropdown-lighter .dropdown-submenu:hover > a,
.nav-tabs .dropdown-lighter li > a:focus {
    background: #f3f3f3;
    color: #444444;
}

.dropdown-yellow li a:hover,
.dropdown-yellow li a:focus,
.dropdown-yellow li a:active,
.dropdown-yellow li.active a,
.dropdown-yellow li.active a:hover,
.dropdown-yellow .dropdown-submenu:hover > a,
.nav-tabs .dropdown-yellow li > a:focus {
    background: #fee188;
    color: #444444;
}

.dropdown-light .dropdown-submenu:hover > a:after,
.dropdown-lighter .dropdown-submenu:hover > a:after {
    border-left-color: #444444;
}

.dropdown-menu.dropdown-close {
    top: 92%;
    left: -5px;
}

.dropdown-menu.dropdown-close.pull-right {
    left: auto;
    right: -5px;
}

.dropdown-menu.dropdown-closer {
    top: 80%;
    left: -10px;
}

.dropdown-menu.dropdown-closer.pull-right {
    right: -10px;
    left: auto;
}

.dropdown-submenu > .dropdown-menu {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.dropdown-submenu > a:after {
    margin-right: -5px;
}

.dropdown-colorpicker > .dropdown-menu {
    top: 80%;
    left: -7px;
    padding: 4px;
    min-width: 120px;
    max-width: 120px;
}

.dropdown-colorpicker > .dropdown-menu.pull-right {
    right: -7px;
    left: auto;
}

.dropdown-colorpicker > .dropdown-menu > li {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
}

.dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    position: relative;
    -webkit-transition: all ease .1s;
    -moz-transition: all ease .1s;
    -o-transition: all ease .1s;
    transition: all ease 0.1s;
}

.dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn:hover {
    text-decoration: none;
    opacity: .8;
    filter: alpha(opacity=80);
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
    -o-transform: scale(1.08);
    transform: scale(1.08);
}

.dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn.selected:after {
    content: "\f00c";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 11px;
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px;
}

.btn-colorpicker {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #dddddd;
    vertical-align: middle;
    border-radius: 0;
}

.dropdown-navbar {
    padding: 0;
    width: 340px;
    -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    -moz-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    border-color: #bcd4e5;
}

.dropdown-navbar > li {
    padding: 0 8px;
    background-color: #ffffff;
}

.dropdown-navbar > li.nav-header {
    text-shadow: none;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
    border-bottom: 1px solid;
}

.dropdown-navbar > li > [class*="icon-"],
.dropdown-navbar > li > a > [class*="icon-"],
.dropdown-navbar > li > span > [class*="icon-"]
.dropdown-navbar > li > form > a > [class*="icon-"] {
    margin-right: 5px !important;
    color: #555555;
    font-size: 14px;
}

.dropdown-navbar [class*="btn"][class*="icon-"] {
    border: 0 none;
    border-radius: 0 0 0 0;
    display: inline-block;
    height: 20px;
    margin: 0 5px 0 0;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 24px;
}

.dropdown-navbar > li > a,
.dropdown-navbar > li > span,
.dropdown-navbar > li > form {
    padding: 10px 2px !important;
    margin: 0 !important;
    border-bottom: 1px solid !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #555555 !important;
}

.dropdown-navbar > li > a:active,
.dropdown-navbar > li > a:hover,
.dropdown-navbar > li > a:focus,
.dropdown-navbar > li > span:active,
.dropdown-navbar > li > span:hover,
.dropdown-navbar > li > span:focus,
.dropdown-form > li > a:active,
.dropdown-form > li > a:hover,
.dropdown-form > li > a:focus {
    background-color: transparent !important;
    color: #555555;
}

.dropdown-navbar > li > a .progress {
    margin-bottom: 0;
    margin-top: 4px;
}

.dropdown-navbar > li > a .badge,
.dropdown-navbar > li > form > a .badge {
    line-height: 16px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
}

.dropdown-navbar > li:last-child > a {
    border-bottom: 0 solid #dddddd;
    border-top: 1px dotted transparent;
    color: #4f99c6;
    text-align: center;
    font-size: 13px;
}

.dropdown-navbar > li:last-child > a:hover {
    background-color: #ffffff;
    color: #4f99c6;
    text-decoration: underline;
}

.dropdown-navbar > li:hover {
    background-color: #f4f9fc !important;
}

.dropdown-navbar > li:hover {
    background-color: #f4f9fc !important;
}

.dropdown-menu .divider:hover {
    background-color: #e5e5e5 !important;
}

.dropdown-navbar > li.nav-header {
    background-color: #ecf2f700 !important;
    color: #555555 !important;
    border-bottom-color: #bcd4e5;
    border-bottom: 1px solid #dddddd;

}

.dropdown-navbar > li.nav-header > [class*="icon-"] {
    color: #8090a0;
}

.dropdown-navbar > li > a,
.dropdown-navbar > li > span,
.dropdown-navbar > li > form {
    border-bottom-color: #e4ecf3 !important;
}

.dropdown-navbar.navbar-pink {
    border-color: #e5bcd4;
}

.dropdown-navbar.navbar-pink > li:hover,
.dropdown-navbar.navbar-pink > form:hover {
    background-color: #fcf4f9 !important;
}

.dropdown-navbar.navbar-pink > li.nav-header,
.dropdown-navbar.navbar-pink > form.nav-header {
    background-color: #f7ecf2 !important;
    color: #b471a0;
    border-bottom-color: #e5bcd4 !important;
}

.dropdown-navbar.navbar-pink > li.nav-header > [class*="icon-"],
.dropdown-navbar.navbar-pink > form.nav-header > [class*="icon-"] {
    color: #c06090;
}

.dropdown-navbar.navbar-pink > li > a,
.dropdown-navbar.navbar-pink > form {
    border-bottom-color: #f3e4ec !important;
}

.dropdown-navbar.navbar-grey {
    border-color: #e5e5e5;
}

.dropdown-navbar.navbar-grey > li:hover {
    background-color: #f8f8f8 !important;
}

.dropdown-navbar.navbar-grey > li.nav-header {
    background-color: #f2f2f2 !important;
    color: #3a87ad;
    border-bottom-color: #e5e5e5 !important;
}

.dropdown-navbar.navbar-grey > li.nav-header > [class*="icon-"] {
    color: #3a87ad;
}

.dropdown-navbar.navbar-grey > li > a {
    border-bottom-color: #eeeeee !important;
}

.dropdown-navbar.navbar-green {
    border-color: #b4d5ac;
}

.dropdown-navbar.navbar-green > li:hover {
    background-color: #f4f9ef !important;
}

.dropdown-navbar.navbar-green > li.nav-header {
    background-color: #ebf7e4 !important;
    color: #88aa66;
    border-bottom-color: #b4d5ac !important;
}

.dropdown-navbar.navbar-green > li.nav-header > [class*="icon-"] {
    color: #90c060;
}

.dropdown-navbar.navbar-green > li > a {
    border-bottom-color: #ecf3e4 !important;
}

.dropdown-navbar [class*="btn"][class*="icon-"] {
    display: inline-block;
    border: 0;
    margin: 0 5px 0 0;
    width: 24px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.dropdown-navbar .msg-photo {
    margin-right: 6px;
    max-width: 42px;
}

.dropdown-navbar .msg-body {
    display: inline-block;
    line-height: 20px;
    white-space: normal;
    vertical-align: middle;
    max-width: 410px;
}

.dropdown-navbar .msg-title {
    display: inline-block;
    line-height: 14px;
}

.dropdown-navbar .msg-time {
    display: block;
    font-size: 11px;
    color: #777777;
}

.dropdown-navbar .msg-time > [class*="icon-"] {
    font-size: 14px;
    color: #555555;
}

.user-menu > li > a {
    padding: 4px 12px;
}

.user-menu > li > a > [class*="icon-"] {
    margin-right: 6px;
    font-size: 120%;
}

.user-info {
    max-width: 100px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
    line-height: 15px;
    position: relative;
    top: 6px;
}

.user-info small {
    display: block;
}

.icon-animated-bell {
    display: inline-block;
    -moz-animation: ringing 2.0s 5 ease 1.0s;
    -webkit-animation: ringing 2.0s 5 ease 1.0s;
    -o-animation: ringing 2.0s 5 ease 1.0s;
    -ms-animation: ringing 2.0s 5 ease 1.0s;
    animation: ringing 2.0s 5 ease 1.0s;
    -moz-transform-origin: 50% 0;
    -webkit-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
}

@-moz-keyframes ringing {
    0% {
        -moz-transform: rotate(-15deg);
    }
    2% {
        -moz-transform: rotate(15deg);
    }
    4% {
        -moz-transform: rotate(-18deg);
    }
    6% {
        -moz-transform: rotate(18deg);
    }
    8% {
        -moz-transform: rotate(-22deg);
    }
    10% {
        -moz-transform: rotate(22deg);
    }
    12% {
        -moz-transform: rotate(-18deg);
    }
    14% {
        -moz-transform: rotate(18deg);
    }
    16% {
        -moz-transform: rotate(-12deg);
    }
    18% {
        -moz-transform: rotate(12deg);
    }
    20% {
        -moz-transform: rotate(0deg);
    }
}

@-webkit-keyframes ringing {
    0% {
        -webkit-transform: rotate(-15deg);
    }
    2% {
        -webkit-transform: rotate(15deg);
    }
    4% {
        -webkit-transform: rotate(-18deg);
    }
    6% {
        -webkit-transform: rotate(18deg);
    }
    8% {
        -webkit-transform: rotate(-22deg);
    }
    10% {
        -webkit-transform: rotate(22deg);
    }
    12% {
        -webkit-transform: rotate(-18deg);
    }
    14% {
        -webkit-transform: rotate(18deg);
    }
    16% {
        -webkit-transform: rotate(-12deg);
    }
    18% {
        -webkit-transform: rotate(12deg);
    }
    20% {
        -webkit-transform: rotate(0deg);
    }
}

@-ms-keyframes ringing {
    0% {
        -ms-transform: rotate(-15deg);
    }
    2% {
        -ms-transform: rotate(15deg);
    }
    4% {
        -ms-transform: rotate(-18deg);
    }
    6% {
        -ms-transform: rotate(18deg);
    }
    8% {
        -ms-transform: rotate(-22deg);
    }
    10% {
        -ms-transform: rotate(22deg);
    }
    12% {
        -ms-transform: rotate(-18deg);
    }
    14% {
        -ms-transform: rotate(18deg);
    }
    16% {
        -ms-transform: rotate(-12deg);
    }
    18% {
        -ms-transform: rotate(12deg);
    }
    20% {
        -ms-transform: rotate(0deg);
    }
}

@keyframes ringing {
    0% {
        transform: rotate(-15deg);
    }
    2% {
        transform: rotate(15deg);
    }
    4% {
        transform: rotate(-18deg);
    }
    6% {
        transform: rotate(18deg);
    }
    8% {
        transform: rotate(-22deg);
    }
    10% {
        transform: rotate(22deg);
    }
    12% {
        transform: rotate(-18deg);
    }
    14% {
        transform: rotate(18deg);
    }
    16% {
        transform: rotate(-12deg);
    }
    18% {
        transform: rotate(12deg);
    }
    20% {
        transform: rotate(0deg);
    }
}

.icon-animated-vertical {
    display: inline-block;
    -moz-animation: vertical 2.0s 5 ease 2.0s;
    -webkit-animation: vertical 2.0s 5 ease 2.0s;
    -o-animation: vertical 2.0s 5 ease 2.0s;
    -ms-animation: vertical 2.0s 5 ease 2.0s;
    animation: vertical 2s 5 ease 2s;
}

@-moz-keyframes vertical {
    0% {
        -moz-transform: translate(0, -3px);
    }
    4% {
        -moz-transform: translate(0, 3px);
    }
    8% {
        -moz-transform: translate(0, -3px);
    }
    12% {
        -moz-transform: translate(0, 3px);
    }
    16% {
        -moz-transform: translate(0, -3px);
    }
    20% {
        -moz-transform: translate(0, 3px);
    }
    22% {
        -moz-transform: translate(0, 0);
    }
}

@-webkit-keyframes vertical {
    0% {
        -webkit-transform: translate(0, -3px);
    }
    4% {
        -webkit-transform: translate(0, 3px);
    }
    8% {
        -webkit-transform: translate(0, -3px);
    }
    12% {
        -webkit-transform: translate(0, 3px);
    }
    16% {
        -webkit-transform: translate(0, -3px);
    }
    20% {
        -webkit-transform: translate(0, 3px);
    }
    22% {
        -webkit-transform: translate(0, 0);
    }
}

@-ms-keyframes vertical {
    0% {
        -ms-transform: translate(0, -3px);
    }
    4% {
        -ms-transform: translate(0, 3px);
    }
    8% {
        -ms-transform: translate(0, -3px);
    }
    12% {
        -ms-transform: translate(0, 3px);
    }
    16% {
        -ms-transform: translate(0, -3px);
    }
    20% {
        -ms-transform: translate(0, 3px);
    }
    22% {
        -ms-transform: translate(0, 0);
    }
}

@keyframes vertical {
    0% {
        transform: translate(0, -3px);
    }
    4% {
        transform: translate(0, 3px);
    }
    8% {
        transform: translate(0, -3px);
    }
    12% {
        transform: translate(0, 3px);
    }
    16% {
        transform: translate(0, -3px);
    }
    20% {
        transform: translate(0, 3px);
    }
    22% {
        transform: translate(0, 0);
    }
}

.icon-animated-hand-pointer {
    display: inline-block;
    -moz-animation: hand-pointer 2s 4 ease 2s;
    -webkit-animation: hand-pointer 2s 4 ease 2s;
    -o-animation: hand-pointer 2s 4 ease 2s;
    -ms-animation: hand-pointer 2s 4 ease 2s;
    animation: hand-pointer 2s 4 ease 2s;
}

@-moz-keyframes hand-pointer {
    0% {
        -moz-transform: translate(0, 0);
    }
    6% {
        -moz-transform: translate(5px, 0);
    }
    12% {
        -moz-transform: translate(0, 0);
    }
    18% {
        -moz-transform: translate(5px, 0);
    }
    24% {
        -moz-transform: translate(0, 0);
    }
    30% {
        -moz-transform: translate(5px, 0);
    }
    36% {
        -moz-transform: translate(0, 0);
    }
}

.icon-animated-wrench {
    display: inline-block;
    -moz-animation: wrenching 2.5s 4 ease;
    -webkit-animation: wrenching 2.5s 4 ease;
    -o-animation: wrenching 2.5s 4 ease;
    -ms-animation: wrenching 2.5s 4 ease;
    animation: wrenching 2.5s 4 ease;
    -moz-transform-origin: 90% 35%;
    -webkit-transform-origin: 90% 35%;
    -o-transform-origin: 90% 35%;
    -ms-transform-origin: 90% 35%;
    transform-origin: 90% 35%;
}

@-moz-keyframes wrenching {
    0% {
        -moz-transform: rotate(-12deg);
    }
    8% {
        -moz-transform: rotate(12deg);
    }
    10% {
        -moz-transform: rotate(24deg);
    }
    18% {
        -moz-transform: rotate(-24deg);
    }
    20% {
        -moz-transform: rotate(-24deg);
    }
    28% {
        -moz-transform: rotate(24deg);
    }
    30% {
        -moz-transform: rotate(24deg);
    }
    38% {
        -moz-transform: rotate(-24deg);
    }
    40% {
        -moz-transform: rotate(-24deg);
    }
    48% {
        -moz-transform: rotate(24deg);
    }
    50% {
        -moz-transform: rotate(24deg);
    }
    58% {
        -moz-transform: rotate(-24deg);
    }
    60% {
        -moz-transform: rotate(-24deg);
    }
    68% {
        -moz-transform: rotate(24deg);
    }
    75% {
        -moz-transform: rotate(0deg);
    }
}

@-webkit-keyframes wrenching {
    0% {
        -webkit-transform: rotate(-12deg);
    }
    8% {
        -webkit-transform: rotate(12deg);
    }
    10% {
        -webkit-transform: rotate(24deg);
    }
    18% {
        -webkit-transform: rotate(-24deg);
    }
    20% {
        -webkit-transform: rotate(-24deg);
    }
    28% {
        -webkit-transform: rotate(24deg);
    }
    30% {
        -webkit-transform: rotate(24deg);
    }
    38% {
        -webkit-transform: rotate(-24deg);
    }
    40% {
        -webkit-transform: rotate(-24deg);
    }
    48% {
        -webkit-transform: rotate(24deg);
    }
    50% {
        -webkit-transform: rotate(24deg);
    }
    58% {
        -webkit-transform: rotate(-24deg);
    }
    60% {
        -webkit-transform: rotate(-24deg);
    }
    68% {
        -webkit-transform: rotate(24deg);
    }
    75% {
        -webkit-transform: rotate(0deg);
    }
}

@-o-keyframes wrenching {
    0% {
        -o-transform: rotate(-12deg);
    }
    8% {
        -o-transform: rotate(12deg);
    }
    10% {
        -o-transform: rotate(24deg);
    }
    18% {
        -o-transform: rotate(-24deg);
    }
    20% {
        -o-transform: rotate(-24deg);
    }
    28% {
        -o-transform: rotate(24deg);
    }
    30% {
        -o-transform: rotate(24deg);
    }
    38% {
        -o-transform: rotate(-24deg);
    }
    40% {
        -o-transform: rotate(-24deg);
    }
    48% {
        -o-transform: rotate(24deg);
    }
    50% {
        -o-transform: rotate(24deg);
    }
    58% {
        -o-transform: rotate(-24deg);
    }
    60% {
        -o-transform: rotate(-24deg);
    }
    68% {
        -o-transform: rotate(24deg);
    }
    75% {
        -o-transform: rotate(0deg);
    }
}

@-ms-keyframes wrenching {
    0% {
        -ms-transform: rotate(-12deg);
    }
    8% {
        -ms-transform: rotate(12deg);
    }
    10% {
        -ms-transform: rotate(24deg);
    }
    18% {
        -ms-transform: rotate(-24deg);
    }
    20% {
        -ms-transform: rotate(-24deg);
    }
    28% {
        -ms-transform: rotate(24deg);
    }
    30% {
        -ms-transform: rotate(24deg);
    }
    38% {
        -ms-transform: rotate(-24deg);
    }
    40% {
        -ms-transform: rotate(-24deg);
    }
    48% {
        -ms-transform: rotate(24deg);
    }
    50% {
        -ms-transform: rotate(24deg);
    }
    58% {
        -ms-transform: rotate(-24deg);
    }
    60% {
        -ms-transform: rotate(-24deg);
    }
    68% {
        -ms-transform: rotate(24deg);
    }
    75% {
        -ms-transform: rotate(0deg);
    }
}

@keyframes wrenching {
    0% {
        transform: rotate(-12deg);
    }
    8% {
        transform: rotate(12deg);
    }
    10% {
        transform: rotate(24deg);
    }
    18% {
        transform: rotate(-24deg);
    }
    20% {
        transform: rotate(-24deg);
    }
    28% {
        transform: rotate(24deg);
    }
    30% {
        transform: rotate(24deg);
    }
    38% {
        transform: rotate(-24deg);
    }
    40% {
        transform: rotate(-24deg);
    }
    48% {
        transform: rotate(24deg);
    }
    50% {
        transform: rotate(24deg);
    }
    58% {
        transform: rotate(-24deg);
    }
    60% {
        transform: rotate(-24deg);
    }
    68% {
        transform: rotate(24deg);
    }
    75% {
        transform: rotate(0deg);
    }
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
    color: #ffffff !important;
}

.menu_sttings {
    border-bottom-color: #f3e4ec !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    clear: both !important;
    color: #6e6e6e !important;
    cursor: auto !important;
    display: block !important;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial !important;
    font-size: 12px !important;
    font-weight: normal !important;
    height: 20px !important;
    line-height: 20px !important;
    list-style-image: none !important;
    list-style-position: outside !important;
    list-style-type: none !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    padding-bottom: 10px !important;
    padding-left: 0 !important;
    padding-right: 2px !important;
    padding-top: 10px !important;
    text-align: left !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    width: 220px !important;
}

.page-header {
    border-bottom: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

form {
    margin: 0 !important;
}

.dropdown-navbar > li.nav-header {
    background: #dddddd !important;
    color: #21618b !important;
}

btn-client {
    background: #21618b !important;
}

.label[class*="span"][class*="arrow"] {
    min-height: 0;
}

.badge {
    text-shadow: none;
    font-size: 12px;
    padding-top: 1px;
    padding-bottom: 3px;
    font-weight: normal;
    line-height: 15px;
    background-color: #abbac3 !important;
}

.label-transparent,
.badge-transparent {
    background-color: transparent !important;
}

.label-grey,
.badge-grey {
    background-color: #a0a0a0 !important;
}

.label-info,
.badge-info {
    background-color: #3a87ad !important;
}

.label-primary,
.badge-primary {
    background-color: #2283c5 !important;
}

.label-success,
.badge-success {
    background-color: #82af6f !important;
}

.label-important,
.badge-important {
    background-color: #d15b47 !important;
}

.label-inverse,
.badge-inverse {
    background-color: #333333 !important;
}

.label-warning,
.badge-warning {
    background-color: #f89406 !important;
}

.label-pink,
.badge-pink {
    background-color: #d6487e !important;
}

.label-purple,
.badge-purple {
    background-color: #9585bf !important;
}

.label-yellow,
.badge-yellow {
    background-color: #fee188 !important;
}

.label-light,
.badge-light {
    background-color: #e7e7e7 !important;
}

.badge-yellow,
.label-yellow {
    color: #996633 !important;
    border-color: #fee188;
}

.badge-light,
.label-light {
    color: #888888 !important;
}

.label.arrowed,
.label.arrowed-in {
    position: relative;
    margin-left: 9px;
}

.label.arrowed:before,
.label.arrowed-in:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 9px 7px;
    border-right-color: #abbac3;
}

.label.arrowed-in:before {
    border-color: #abbac3;
    border-left-color: transparent !important;
    left: -9px;
}

.label.arrowed-right,
.label.arrowed-in-right {
    position: relative;
    margin-right: 9px;
}

.label.arrowed-right:after,
.label.arrowed-in-right:after {
    display: inline-block;
    content: "";
    position: absolute;
    right: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 9px 7px;
    border-left-color: #abbac3;
}

.label.arrowed-in-right:after {
    border-color: #abbac3;
    border-right-color: transparent !important;
    right: -9px;
}

.label-info.arrowed:before {
    border-right-color: #3a87ad;
}

.label-info.arrowed-in:before {
    border-color: #3a87ad;
}

.label-info.arrowed-right:after {
    border-left-color: #3a87ad;
}

.label-info.arrowed-in-right:after {
    border-color: #3a87ad;
}

.label-primary.arrowed:before {
    border-right-color: #2283c5;
}

.label-primary.arrowed-in:before {
    border-color: #2283c5;
}

.label-primary.arrowed-right:after {
    border-left-color: #2283c5;
}

.label-primary.arrowed-in-right:after {
    border-color: #2283c5;
}

.label-success.arrowed:before {
    border-right-color: #82af6f;
}

.label-success.arrowed-in:before {
    border-color: #82af6f;
}

.label-success.arrowed-right:after {
    border-left-color: #82af6f;
}

.label-success.arrowed-in-right:after {
    border-color: #82af6f;
}

.label-warning.arrowed:before {
    border-right-color: #f89406;
}

.label-warning.arrowed-in:before {
    border-color: #f89406;
}

.label-warning.arrowed-right:after {
    border-left-color: #f89406;
}

.label-warning.arrowed-in-right:after {
    border-color: #f89406;
}

.label-important.arrowed:before {
    border-right-color: #d15b47;
}

.label-important.arrowed-in:before {
    border-color: #d15b47;
}

.label-important.arrowed-right:after {
    border-left-color: #d15b47;
}

.label-important.arrowed-in-right:after {
    border-color: #d15b47;
}

.label-inverse.arrowed:before {
    border-right-color: #333333;
}

.label-inverse.arrowed-in:before {
    border-color: #333333;
}

.label-inverse.arrowed-right:after {
    border-left-color: #333333;
}

.label-inverse.arrowed-in-right:after {
    border-color: #333333;
}

.label-pink.arrowed:before {
    border-right-color: #d6487e;
}

.label-pink.arrowed-in:before {
    border-color: #d6487e;
}

.label-pink.arrowed-right:after {
    border-left-color: #d6487e;
}

.label-pink.arrowed-in-right:after {
    border-color: #d6487e;
}

.label-purple.arrowed:before {
    border-right-color: #9585bf;
}

.label-purple.arrowed-in:before {
    border-color: #9585bf;
}

.label-purple.arrowed-right:after {
    border-left-color: #9585bf;
}

.label-purple.arrowed-in-right:after {
    border-color: #9585bf;
}

.label-yellow.arrowed:before {
    border-right-color: #fee188;
}

.label-yellow.arrowed-in:before {
    border-color: #fee188;
}

.label-yellow.arrowed-right:after {
    border-left-color: #fee188;
}

.label-yellow.arrowed-in-right:after {
    border-color: #fee188;
}

.label-light.arrowed:before {
    border-right-color: #e7e7e7;
}

.label-light.arrowed-in:before {
    border-color: #e7e7e7;
}

.label-light.arrowed-right:after {
    border-left-color: #e7e7e7;
}

.label-light.arrowed-in-right:after {
    border-color: #e7e7e7;
}

.label-grey.arrowed:before {
    border-right-color: #a0a0a0;
}

.label-grey.arrowed-in:before {
    border-color: #a0a0a0;
}

.label-grey.arrowed-right:after {
    border-left-color: #a0a0a0;
}

.label-grey.arrowed-in-right:after {
    border-color: #a0a0a0;
}

.label-large {
    font-size: 13px;
    padding: 3px 8px 5px;
}

.label-large.arrowed,
.label-large.arrowed-in {
    margin-left: 12px;
}

.label-large.arrowed:before,
.label-large.arrowed-in:before {
    left: -16px;
    border-width: 11px 8px;
}

.label-large.arrowed-in:before {
    left: -12px;
}

.label-large.arrowed-right,
.label-large.arrowed-in-right {
    margin-right: 11px;
}

.label-large.arrowed-right:after,
.label-large.arrowed-in-right:after {
    right: -16px;
    border-width: 11px 8px;
}

.label-large.arrowed-in-right:after {
    right: -12px;
}

.widget-box {
    padding: 0;
    margin: 3px 0;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,0.07);
}

.widget-header {
    position: relative;
    color: #383838;
    border: 1px solid #E4E4E8;
    border-bottom: 0;
    padding-left: 12px;
    background: #FFFFFF;
    line-height: 46px;
    font-size: 16px;
    letter-spacing: .5px;
}

.widget-footer {
    height: 34px;
    background: #FFFFFF;
    border: 1px solid #E4E4E8;
    border-top: 0px;
    border-radius: 0px 0px 4px 4px;
}

.widget-footer-pg-buttons{
    display: flex;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    margin: auto;
}

.widget-footer-pg-button{
    height: 8px;
    width: 8px;
    border-radius: 6px;
    background-color: #D8D8D8;
    margin: 12px 3px;
}

.widget-footer-pg-button.active{
    background-color: #005DFF;
}


.collapsed .widget-header {
    border-bottom-width: 0;
}

.collapsed .widget-body {
    display: none;
}

.widget-header-large {
    min-height: 49px;
    padding-left: 18px;
}

.widget-header-small {
    min-height: 31px;
    padding-left: 10px;
}

.widget-header > .widget-caption,
.widget-header > :first-child {
    color: #555555;
    line-height: 36px;
    padding: 0;
    margin: 0;
    float: left;
    text-align: left;
    font-size: 18px;
}

.limit-dialog-title-header .widget-header > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 50px);
}

.widget-header > .widget-caption > [class*="icon-"],
.widget-header > :first-child > [class*="icon-"] {
    margin-right: 5px;
    font-weight: normal;
    display: inline-block;
}

.widget-header-large > .widget-caption,
.widget-header-large > :first-child {
    line-height: 48px;
}

.widget-header-small > .widget-caption,
.widget-header-small > :first-child {
    line-height: 30px;
}

.widget-toolbar {
    display: inline-flex;
    padding: 0 10px;
    line-height: 37px;
    position: relative;
    text-align: left;
}

.widget-collapse-chevron{
    margin-top: 10px !important;
}

.widget-header-large > .widget-toolbar {
    line-height: 48px;
}

.widget-header-small > .widget-toolbar {
    line-height: 29px;
}

.widget-toolbar.no-padding {
    padding: 0;
}

.widget-toolbar.padding-5 {
    padding: 0 5px;
}

.widget-header-large > .widget-toolbar:before {
    top: 6px;
    bottom: 6px;
}

[class*="header-color-"] > .widget-toolbar:before {
    border-color: #eeeeee;
}

.header-color-orange > .widget-toolbar:before {
    border-color: #ffeeaa;
}

.header-color-dark > .widget-toolbar:before {
    border-color: #222222;
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.2), inset 1px 0 0 rgba(255, 255, 255, 0.1);
}

.widget-toolbar.no-border:before {
    display: none;
}

.widget-toolbar label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
}

.widget-toolbar > a {
    font-size: 14px;
    margin: 0 1px;
    padding-left: 5px;
    line-height: 24px;
}

.widget-toolbar > a:hover {
    text-decoration: none;
}

.widget-header-large > .widget-toolbar > a {
    font-size: 15px;
    margin: 0 1px;
}

.widget-toolbar > .btn {
    line-height: 27px;
    margin-top: -2px;
}

.widget-toolbar > .btn.smaller {
    line-height: 26px;
}

.widget-toolbar > .btn.bigger {
    line-height: 28px;
}

.widget-toolbar > .btn-small {
    line-height: 24px;
}

.widget-toolbar > .btn-small.smaller {
    line-height: 23px;
}

.widget-toolbar > .btn-small.bigger {
    line-height: 25px;
}

.widget-toolbar > .btn-mini {
    line-height: 22px;
}

.widget-toolbar > .btn-mini.smaller {
    line-height: 21px;
}

.widget-toolbar > .btn-mini.bigger {
    line-height: 23px;
}

.widget-toolbar > .btn-minier {
    line-height: 18px;
}

.widget-toolbar > .btn-minier.smaller {
    line-height: 17px;
}

.widget-toolbar > .btn-minier.bigger {
    line-height: 19px;
}

.widget-toolbar > .btn-large {
    line-height: 36px;
}

.widget-toolbar > .btn-large.smaller {
    line-height: 34px;
}

.widget-toolbar > .btn-large.bigger {
    line-height: 38px;
}

.widget-toolbar-dark {
    background: #444444;
}

.widget-toolbar-light {
    background: rgba(255, 255, 255, 0.85);
}

.widget-toolbar > [data-action] > [class*="icon-"] {
    margin-right: 0;
}

.widget-toolbar > [data-action]:focus {
    text-decoration: none;
}

[class*="header-color-"] > .widget-toolbar > [data-action="settings"] {
    color: #d3e4ed;
}

[class*="header-color-"] > .widget-toolbar > [data-action="reload"] {
    color: #deead3;
}

[class*="header-color-"] > .widget-toolbar > [data-action="collapse"] {
    color: #e2e2e2;
}

[class*="header-color-"] > .widget-toolbar > [data-action="close"] {
    color: #ffd9d5;
}

.header-color-orange > .widget-toolbar > [data-action] {
    text-shadow: none;
}

.header-color-orange > .widget-toolbar > [data-action="settings"] {
    color: #559aab;
}

.header-color-orange > .widget-toolbar > [data-action="reload"] {
    color: #7ca362;
}

.header-color-orange > .widget-toolbar > [data-action="collapse"] {
    color: #777777;
}

.header-color-orange > .widget-toolbar > [data-action="close"] {
    color: #a05656;
}

.widget-toolbar > [data-action="settings"],
.header-color-dark > .widget-toolbar > [data-action="settings"] {
    color: #99cadb;
}

.widget-toolbar > [data-action="reload"],
.header-color-dark > .widget-toolbar > [data-action="reload"] {
    color: #acd392;
}

.widget-toolbar > [data-action="collapse"],
.header-color-dark > .widget-toolbar > [data-action="collapse"] {
    color: #aaaaaa;
}

.widget-toolbar > [data-action="close"],
.header-color-dark > .widget-toolbar > [data-action="close"] {
    color: #e09e96;
}

.widget-body {
    border: 1px solid #E4E4E8;
    border-top: 0;
    background-color: #f6f6f6;
}

.widget-main {
    padding: 12px;
}

.widget-main.padding-32 {
    padding: 32px;
}

.widget-main.padding-30 {
    padding: 30px;
}

.widget-main.padding-28 {
    padding: 28px;
}

.widget-main.padding-26 {
    padding: 26px;
}

.widget-main.padding-24 {
    padding: 24px;
}

.widget-main.padding-22 {
    padding: 22px;
}

.widget-main.padding-20 {
    padding: 20px;
}

.widget-main.padding-18 {
    padding: 18px;
}

.widget-main.padding-16 {
    padding: 16px;
}

.widget-main.padding-14 {
    padding: 14px;
}

.widget-main.padding-12 {
    padding: 12px;
}

.widget-main.padding-10 {
    padding: 10px;
}

.widget-main.padding-8 {
    padding: 8px;
}

.widget-main.padding-6 {
    padding: 6px;
}

.widget-main.padding-4 {
    padding: 4px;
}

.widget-main.padding-2 {
    padding: 2px;
}

.widget-main.padding-0 {
    padding: 0;
}

.widget-main.no-padding {
    padding: 0;
}

.widget-toolbar .progress {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
}

.widget-toolbar > .dropdown,
.widget-toolbar > .dropup {
    display: inline-block;
}

.widget-toolbar > .dropdown > .dropdown-menu:before,
.dropdown-menu.dropdown-caret:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    left: 9px;
    position: absolute;
    top: -7px;
}

.widget-toolbar > .dropdown > .dropdown-menu:after,
.dropdown-menu.dropdown-caret:after {
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    left: 10px;
    position: absolute;
    top: -6px;
}

.widget-toolbar > .dropdown > .dropdown-menu.pull-right:before,
.dropdown-menu.pull-right.dropdown-caret:before {
    left: auto;
    right: 9px;
}

.widget-toolbar > .dropdown > .dropdown-menu.pull-right:after,
.dropdown-menu.pull-right.dropdown-caret:after {
    left: auto;
    right: 10px;
}

.widget-header[class*="header-color-"] {
    color: #ffffff;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.header-color-blue {
    background: #307ecc;
    border-color: #307ecc;
}

.header-color-blue + .widget-body {
    border-color: #307ecc;
}

.header-color-blue2 {
    background: #575BD8;
    border-color: #575BD8;
}

.header-color-blue2 + .widget-body {
    border-color: #575BD8;
}

.header-color-blue3 {
    background: #6379aa;
    border-color: #6379aa;
}

.header-color-blue3 + .widget-body {
    border-color: #6379aa;
}

.header-color-green {
    background: #82af6f;
    border-color: #82af6f;
}

.header-color-green + .widget-body {
    border-color: #82af6f;
}

.header-color-green2 {
    background: #2e8965;
    border-color: #2e8965;
}

.header-color-green2 + .widget-body {
    border-color: #2e8965;
}

.header-color-green3 {
    background: #4ebc30;
    border-color: #4ebc30;
}

.header-color-green3 + .widget-body {
    border-color: #4ebc30;
}

.header-color-red {
    background: #e2755f;
    border-color: #e2755f;
}

.header-color-red + .widget-body {
    border-color: #e2755f;
}

.header-color-red2 {
    background: #e04141;
    border-color: #e04141;
}

.header-color-red2 + .widget-body {
    border-color: #e04141;
}

.header-color-red3 {
    background: #d15b47;
    border-color: #d15b47;
}

.header-color-red3 + .widget-body {
    border-color: #d15b47;
}

.header-color-purple {
    background: #7e6eb0;
    border-color: #7e6eb0;
}

.header-color-purple + .widget-body {
    border-color: #7e6eb0;
}

.header-color-pink {
    background: #ce6f9e;
    border-color: #ce6f9e;
}

.header-color-pink + .widget-body {
    border-color: #ce6f9e;
}

.header-color-orange {
    background: #ffc657;
    border-color: #e8b10d;
    color: #855d10 !important;
}

.header-color-orange + .widget-body {
    border-color: #e8b10d;
}

.header-color-dark {
    background: #404040;
    border-color: #454545;
}

.header-color-dark + .widget-body {
    border-color: #666666;
}

.header-color-grey {
    background: #848484;
    border-color: #989898;
}

.header-color-grey + .widget-body {
    border-color: #aaaaaa;
}

.widget-box.light-border > [class*="header-color-"] + .widget-body {
    border-color: #d6d6d6 !important;
}

.widget-box.no-border {
    border-bottom: 0;
}

.widget-box.no-border > .widget-body {
    border: 0;
}

.widget-box.transparent > .widget-header {
    background: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    border: 0;
    border-bottom: 1px solid #dce8f1;
    color: #4383b4;
    padding-left: 3px;
}

.widget-box.transparent > .widget-header-large {
    padding-left: 5px;
}

.widget-box.transparent > .widget-header-small {
    padding-left: 1px;
}

.widget-box.transparent .widget-body {
    border: 0;
    background-color: transparent;
}

.widget-box.transparent .widget-main.no-padding-left {
    padding-left: 0;
}

.widget-box.transparent .widget-main.no-padding-right {
    padding-right: 0;
}

.widget-box.transparent .widget-main.no-padding-top {
    padding-top: 0;
}

.widget-box.transparent .widget-main.no-padding-bottom {
    padding-bottom: 0;
}

.widget-body .table {
    border-top: 1px solid #e5e5e5;
}

.widget-body .table thead:first-child tr {
    background: #ffffff;
}

[class*="header-color-"] + .widget-body .table thead:first-child tr {
    background: #f2f2f2;
    background-color: #f3f3f3;
    background-image: -moz-linear-gradient(top, #f8f8f8, #ececec);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f8f8f8), to(#ececec));
    background-image: -webkit-linear-gradient(top, #f8f8f8, #ececec);
    background-image: -o-linear-gradient(top, #f8f8f8, #ececec);
    background-image: linear-gradient(to bottom, #f8f8f8, #ececec);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
}

.widget-body .table.table-bordered thead:first-child > tr {
    border-top: 0;
}

.widget-main.no-padding .table,
.widget-main.padding-0 .table {
    margin-bottom: 0;
    border: 0;
}

.widget-main.no-padding .table-bordered th:first-child,
.widget-main.padding-0 .table-bordered th:first-child,
.widget-main.no-padding .table-bordered td:first-child,
.widget-main.padding-0 .table-bordered td:first-child {
    border-left-width: 0;
}

.widget-body .alert:last-child {
    margin-bottom: 0;
}

.widget-main .tab-content {
    font-size: 14px;
}

.widget-toolbar > .nav-tabs {
    border-bottom: 0;
    margin-bottom: 0;
    position: relative;
    top: 5px;
}

.widget-toolbar > .nav-tabs > li > a {
    box-shadow: none;
}

.widget-toolbar > .nav-tabs > li:not(.active) > a {
    border-color: transparent;
    background-color: transparent;
}

.widget-toolbar > .nav-tabs > li:not(.active) > a:hover {
    background-color: transparent;
}

.widget-toolbar > .nav-tabs > li.active > a {
    background-color: #ffffff;
    border-bottom-color: transparent;
    box-shadow: none;
}

.widget-header-small > .widget-toolbar > .nav-tabs {
    top: 5px;
}

.widget-header-small > .widget-toolbar > .nav-tabs > li > a {
    line-height: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.widget-header-small > .widget-toolbar > .nav-tabs > li.active > a {
    border-top-width: 2px;
    margin-top: -1px;
}

.widget-header-large > .widget-toolbar > .nav-tabs {
    top: 8px;
}

.widget-header-large > .widget-toolbar > .nav-tabs > li > a {
    line-height: 22px;
    padding-top: 9px;
    padding-bottom: 9px;
}

[class*="header-color-"] > .widget-toolbar > .nav-tabs > li > a {
    border-color: transparent;
    background-color: transparent;
    color: #ffffff;
    margin-right: 1px;
}

[class*="header-color-"] > .widget-toolbar > .nav-tabs > li > a:hover {
    background-color: #ffffff;
    color: #555555;
    border-top-color: #ffffff;
}

[class*="header-color-"] > .widget-toolbar > .nav-tabs > li.active > a {
    background-color: #ffffff;
    color: #555555;
    border-top-width: 1px;
    margin-top: 0;
}

.header-color-orange > .widget-toolbar > .nav-tabs > li > a {
    color: #855d10;
}

.transparent .widget-toolbar > .nav-tabs > li > a {
    color: #555555;
    background-color: transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
}

.transparent .widget-toolbar > .nav-tabs > li.active > a {
    border-top-color: #4c8fbd;
    border-right: 1px solid #c5d0dc;
    border-left: 1px solid #c5d0dc;
    background-color: #ffffff;
    box-shadow: none;
}

.widget-toolbox {
    background-color: #eeeeee;
}

.widget-toolbox:first-child {
    padding: 2px;
    border-bottom: 1px solid #cccccc;
}

.widget-toolbox:last-child {
    padding: 2px;
    border-top: 1px solid #cccccc;
}

.transparent .widget-toolbox:last-child {
    border: 0;
    border-top: 1px solid #cccccc;
}

.widget-toolbox > .btn-toolbar {
    margin: 0;
    padding: 0;
}

.widget-toolbox.center {
    text-align: center;
}

.widget-toolbox.padding-16 {
    padding: 16px;
}

.widget-toolbox.padding-14 {
    padding: 14px;
}

.widget-toolbox.padding-12 {
    padding: 12px;
}

.widget-toolbox.padding-10 {
    padding: 10px;
}

.widget-toolbox.padding-8 {
    padding: 8px;
}

.widget-toolbox.padding-6 {
    padding: 6px;
}

.widget-toolbox.padding-4 {
    padding: 4px;
}

.widget-toolbox.padding-2 {
    padding: 2px;
}

.widget-toolbox.padding-0 {
    padding: 0;
}

.widget-box-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
}

.widget-box-layer > [class*="icon-"] {
    position: relative;
    top: 35%;
}

.widget-box-layer > .icon-spin {
    -moz-animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    -o-animation-duration: 1.2s;
    -ms-animation-duration: 1.2s;
    animation-duration: 1.2s;
}

.widget-main > form {
    margin-bottom: 0;
}

.widget-main > form .input-append,
.widget-main > form .input-prepend {
    margin-bottom: 0;
}

.widget-main.no-padding > form > fieldset,
.widget-main.padding-0 > form > fieldset {
    padding: 16px;
}

.widget-main.no-padding > form > fieldset + .form-actions,
.widget-main.padding-0 > form > fieldset + .form-actions {
    padding: 10px 0 12px;
}

.widget-main.no-padding > form > .form-actions,
.widget-main.padding-0 > form > .form-actions {
    margin: 0;
    padding: 10px 12px 12px;
}

.widget-placeholder {
    border: 2px dashed #d9d9d9;
}

.dropdown-navbar > li.nav-header {
    background: #dddddd !important;
    color: #21618b !important;
}

widget-header h5 {
    color: #21618b !important;
}

div.widget-box div.widget-header div.widget-toolbar a {
    margin-top: 6px;
}

.iceOutLbl {
    color: #2A2624 !important;
    font-size: 14px !important;
    text-align: right !important;
    margin-right: 10px !important;
}

.sidebar > .nav-search {
    position: static;
    background-color: #fafafa;
    border-bottom: 1px solid #dddddd;
    text-align: center;
    height: 35px;
    padding-top: 5px;
}

.sidebar > .nav-search .nav-search-input {
    width: 130px !important;
    border-radius: 0 !important;
    max-width: 130px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.sidebar > .nav-search .nav-search-input + .dropdown-menu {
    text-align: left;
}

.sidebar > .nav-search .nav-search-icon {
    border: 0;
    border-radius: 0;
    padding: 0 3px;
}

.sidebar > .nav-search.menu-min .nav-search .form-search {
    position: absolute;
    left: 5px;
    z-index: 14;
}

.sidebar > .nav-search.menu-min .nav-search .nav-search-input:hover,
.sidebar > .nav-search.menu-min .nav-search .nav-search-input:focus,
.sidebar > .nav-search.menu-min .nav-search .nav-search-input:active {
    width: 130px !important;
    max-width: 130px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.sidebar > .nav-search.menu-min .nav-search .nav-search-input:hover ~ #nav-search-icon,
.sidebar > .nav-search.menu-min .nav-search .nav-search-input:focus ~ #nav-search-icon,
.sidebar > .nav-search.menu-min .nav-search .nav-search-input:active ~ #nav-search-icon {
    border: 0;
    border-radius: 0;
    padding: 0 3px;
}

.sidebar > .nav-search.menu-min .nav-search:hover .nav-search-input {
    width: 130px !important;
    max-width: 130px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.sidebar > .nav-search.menu-min .nav-search:hover .nav-search-input ~ .nav-search-icon {
    border: 0;
    border-radius: 0;
    padding: 0 3px;
}

.sidebar > .nav-search.menu-min .nav-search .nav-search-icon {
    border: 1px solid;
    border-radius: 32px;
    background-color: #ffffff;
    padding: 0 5px;
}

.sidebar {
    width: 260px;
    position: absolute;
    top: 84px;
    bottom: 0;
    border-right: 1px solid #cccccc;
    background-color: #EFEFEF;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 50;
}

.sidebar:before {
    content: "";
    display: block;
    position: fixed;
    bottom: 0;
    top: 84px;
    z-index: -1;
    background-color: #efefef;
}

.sidebar.right-side-sidebar span.icon-bim-saveas {
    font-size: 23px !important;
    padding: 11px 14px !important;
}


.sidebar.right-side-sidebar .nav.nav-list li a i, .sidebar.right-side-sidebar .nav.nav-list li a span {
    font-size: 20px;
    padding: 14px 15px;
}

.sidebar.right-side-sidebar .nav.nav-list li a.active{
    background: #eeeeee;
}

.right-side-sidebar::before{
    border-right: none;
}

.sidebar.fixed {
    position: fixed;
    z-index: 1029;
    top: 45px;
    left: 0;
}

.sidebar.fixed:before {
    left: 0;
    right: auto;
}

li [class^="icon-"],
li [class*=" icon-"],
.nav-list li [class^="icon-"],
.nav-list li [class*=" icon-"] {
    width: auto;
}

.nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-list > li > a,
.nav-list .nav-header {
    margin: 0;
}

.nav-list > li > a {
    display: block;
    line-height: 48px;
    color: #6e6e6e;
    text-shadow: none !important;
}

.nav-list > li > a > [class*="icon-"]:first-child {
    display: inline-block;
    vertical-align: middle;
    min-width: 30px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
}

.Menu .nav-list > li > a > [class*="material-icons"]:first-child {
    line-height: 42px;
    font-size: 20px;
}

.nav-list > li > a:focus {
    background-color: #f9f9f9;
    color: #1963aa;
}

.nav-list > li > a:hover {
    background-color: #ffffff;
    color: #1963aa;
}

.nav-list > li a > .arrow {
    display: inline-block;
    width: 14px !important;
    height: 14px;
    line-height: 14px;
    text-shadow: none;
    font-size: 18px;
    position: absolute;
    right: 9px;
    top: 11px;
    padding: 0;
    color: #666666;
}

.nav-list > li a:hover > .arrow,
.nav-list > li.active > a > .arrow,
.nav-list > li.open > a > .arrow {
    color: #1963aa;
}

.nav-list > li.separator {
    height: 3px;
    background-color: transparent;
    position: static;
    margin: 1px 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.nav-list > li.open > a {
    background-color: #f7f8fa;
    border-top: solid 1px #e2e2e2;
    border-bottom: solid 1px #e2e2e2;
}

.nav-list > li.active {
    background-color: #ffffff;
}

.nav-list > li.active > a,
.nav-list > li.active > a:hover,
.nav-list > li.active > a:focus,
.nav-list > li.active > a:active {
    background-color: #ffffff;
    color: #575bd8;
    font-weight: bold;
    font-size: 13px;
}

.nav-list > li.active > a > [class*="icon-"] {
    font-weight: normal;
}

.nav-list > li.active > a:hover:before {
    display: none;
}

.nav-list > li.open {
    border-bottom-color: #e5e5e5;
    background-color: #fafafa;
    margin-top: -1px;
}

.nav-list > li.active .submenu {
    display: block;
}

.nav-list > li .submenu {
    display: none;
    list-style: none;
    margin: 0;
    background-color: #fafafa;
}

.nav-list > li .submenu > li {
    margin-left: 0;
    position: relative;
}

.nav-list > li .submenu > li > a {
    display: block;
    position: relative;
    color: #616161;
    padding: 7px 0 8px 15px;
    margin: 0;
    font-size: 12px;
}

.nav-list > li .submenu > li > * {
    padding: 7px 0 0 0;
    margin: 0;
}

.nav-list > li .submenu > li > a:focus {
    text-decoration: none;
}

.nav-list > li .submenu > li.active > a {
    color: #575bd8;
}

.nav-list > li .submenu > li a > [class*="icon-"]:first-child {
    font-size: 12px;
    font-weight: normal;
    width: 18px;
    height: auto;
    text-align: center;
    position: absolute;
}

.nav-list > li .submenu > li.active > a > [class*="icon-"]:first-child,
.nav-list > li .submenu > li:hover > a > [class*="icon-"]:first-child {
    display: inline-block;
}

.nav-list > li .submenu > li.active > a > [class*="icon-"]:first-child {
    color: #c86139;
}

.nav-list > li.active > .submenu > li:before {
    border-top-color: #bccfe0;
}

.nav-list > li.active > .submenu:before {
    border-left-color: #bccfe0;
}

.nav-list li.open > a:after {
    display: none;
    font-size: 12px;
}

.nav-list li.active.open > .submenu > li.active.open > a.dropdown-toggle:after {
    display: none;
}

.nav-list li.active > .submenu > li.active > a:after {
    display: none;
}

.nav-list li.active.open > .submenu > li.active > a:after {
    display: block;
}

.menu-min .nav-list li.active.open > .submenu > li.active > a:after {
    display: none;
}

.nav-list li.active.no-active-child > a:after {
    display: inline-block !important;
}

.nav-list a .badge,
.nav-list a .label {
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
    position: absolute;
    top: 9px;
    right: 11px;
    opacity: 0.88;
}

.nav-list a .badge [class*="icon-"],
.nav-list a .label [class*="icon-"] {
    vertical-align: middle;
    margin: 0;
}

.nav-list a.dropdown-toggle .badge,
.nav-list a.dropdown-toggle .label {
    right: 28px;
}

.nav-list a:hover .badge,
.nav-list a:hover .label {
    opacity: 1;
}

.menu-min .nav-list a .badge,
.menu-min .nav-list a .label {
    position: relative;
    top: -1px;
    right: auto;
    left: 4px;
}

.nav-list .submenu .submenu a .badge,
.nav-list .submenu .submenu a .label {
    top: 6px;
}

.menu-min .nav-list .submenu .submenu a .badge,
.menu-min .nav-list .submenu .submenu a .label {
    top: 0;
}

.menu-min .sidebar-collapse {
    width: 50px;
}

.sidebar-collapse {
    text-align: center;
    height: 36px;
    background-color: #FAFAFA;
    padding: 10px 0px 0px 0px;
    position: fixed;
    width: 260px;
    bottom: 0;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    border-top: solid 1px #e2e2e2;
    cursor: pointer;
}

.sidebar-collapse > [class*="icon-"] {
    display: inline-block;
    cursor: pointer;
    font-size: 25px;
    color: #6e6e6e;
    line-height: 18px;
    position: relative;
}

.sidebar-collapse:hover > [class*="icon-"] {
    display: inline-block;
    cursor: pointer;
    font-size: 25px;
    color: #575bd8;
    line-height: 18px;
    position: relative;
}

ul.nav.nav-list {
    background: #efefef;
}

.sidebar.menu-min:before {
    width: 50px;
}

.sidebar.menu-min + .main-content {
    margin-left: 51px;
}

.sidebar.menu-min + .main-content .breadcrumbs.fixed {
    left: 51px;
}

.menu-min .nav-list > li > a {
    position: relative;
}

.menu-min .nav-list > li > a > .menu-text {
    display: none;
    position: absolute;
    left: 49px;
    top: -2px;
    width: 158px;
    height: 56px;
    line-height: 56px;
    background-color: #fafafa;
    z-index: 121;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #cccccc;
    padding-left: 12px;
}

.menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 0;
    top: 0px;
    left: 50px;
    width: 388px;
    color: #585858;
    font-weight: 500;
}

.menu-min .nav-list > li > a .arrow {
    display: none;
}

.menu-min .nav-list > li.open > a > .menu-text,
.menu-min li.open .menu-text {
    display: block;
}


.menu-min .nav-list > li.active > a > .menu-text {
    border-left-color: #575bd8;
}

.menu-min .nav-list > li.open > a {
    background-color: #f7f8fa;
    color: #585858;
    border-bottom: 1px solid #e2e2e2;
}

.menu-min .nav-list > li.open.active > a {
    background-color: #ffffff;
}

.menu-min .nav-list > li.open:hover > a {
    color: #575bd8;
}

.menu-min .nav-list > li.active > a {
    color: #575bd8;
}
.menu-min .nav-list > li.active.open > a:after {
    display: block;
}

.menu-min .nav-list > li.active.open li.active > a:after {
    display: none;
}

.menu-min .nav-list > li > .submenu {
    background: #fafafa;
    position: absolute;
    z-index: 120;
    left: 50px;
    width: 400px;
    display: none !important;
}

.menu-min .nav-list > li > .submenu:before {
    display: none;
}

.menu-min .nav-list > li > .submenu li:before {
    display: none;
}

.menu-min .nav-list > li > .submenu li > a {
    margin-left: 0;
    padding-left: 24px;
}

.menu-min .nav-list > li > .submenu li > a > [class*="icon-"]:first-child {
    left: 4px;
}

.menu-min .nav-list > li.open > .submenu {
    display: block !important;
}

.menu-min .nav-list > li.active > .submenu {
    border-left-color: #575bd8;
}

.menu-min .sidebar-collapse:before {
    left: 5px;
    right: 5px;
}

.nav-list > li > .submenu li > .submenu {
    border-top: 0;
    background-color: transparent;
    display: none;
    border-bottom: 0;
}

.nav-list > li > .submenu li.active > .submenu {
    display: block;
}

.nav-list > li > .submenu a > .arrow {
    right: 11px;
    top: 10px;
    font-size: 16px;
    color: #6b828e;
}

.nav-list > li > .submenu li > .submenu > li > a > .arrow {
    right: 12px;
    top: 9px;
}

.nav-list > li > .submenu li > .submenu > li {
    line-height: 18px;
}

.nav-list > li > .submenu li > .submenu > li:before {
    display: none;
}

.nav-list > li > .submenu li > .submenu > li > .submenu > li > a > div{
    word-wrap: break-word;
}

.nav-list > li > .submenu li > .submenu > li a > [class*="icon-"]:first-child {
    display: inline-block;
    color: inherit;
    font-size: 14px;
    position: static;
    background-color: transparent;
}

.nav-list > li > .submenu li > .submenu > li a {
    font-size: 12px;
    color: #777777;
    margin-right: 20px;
    display: flex;
}

.nav-list > li > .submenu li > .submenu > li a:hover {
    color: #575bd8;
    text-decoration: underline;
}

.nav-list > li > .submenu li > .submenu > li a:hover [class*="icon-"] {
    text-decoration: none;
}

.nav-list > li > .submenu li.open > a {
    color: #6e6e6e;
}

.nav-list > li > .submenu li.open > a > [class*="icon-"]:first-child {
    display: inline-block;
}

/*minha pagina*/
.menu-home:hover > i.material-icons,
.menu-home:hover > a > i.material-icons,
.menu-home:hover .menutd-icon-home i.material-icons,
.menu-home:hover .menu_option i.material-icons {
    color: #575bd8;
}

/*sidebar menu*/
.menu-home td.menutd.menutd-text.menutd-text-home:hover .menu_option,
.menu-home td.menutd.menutd-text.menutd-text-home:hover i.material-icons
{
    color: #575bd8;
}

.nav-list > li > .submenu li.open > a .arrow {
    color: #575bd8;
}

.nav-list > li > .submenu li > .submenu li.open > a {
    color: #575bd8;
}

.nav-list > li > .submenu li > .submenu li.open > a > [class*="icon-"]:first-child {
    display: inline-block;
}

.nav-list > li > .submenu li > .submenu li.open > a .arrow {
    color: #575bd8;
}

.nav-list > li > .submenu li > .submenu li.active > a {
    color: #136bb4;
}

.nav-list > li > .submenu li > .submenu li.active > a > [class*="icon-"]:first-child {
    display: inline-block;
}

.nav-list > li.active.open li.active > a:after {
    top: 2px;
    border-width: 14px 8px;
}

.nav-list > li.active.open li.active.open li.active > a:after {
    top: 0;
}

.menu-min .nav-list > li > .submenu li > .submenu > li > a {
    margin-left: 0;
    padding-left: 30px;
}

.menu-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
    margin-left: 0;
    padding-left: 45px;
}

.menu-min .nav-list > li > .submenu li.active > a:after {
    display: none;
}

.menu-toggler {
    display: none;
}

.btn {
    display: inline-block;
    color: #ffffff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
    background-image: none !important;
    border: 5px solid;
    border-radius: 0;
    box-shadow: none !important;
    -webkit-transition: all ease .15s;
    -moz-transition: all ease .15s;
    -o-transition: all ease .15s;
    transition: all ease .15s;
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
    position: relative;
    padding: 0 12px 1px;
    line-height: 32px;
    font-size: 14px;
}

.btn-large {
    padding: 0 14px 1px;
    line-height: 38px;
    border-width: 6px;
    font-size: 16px;
}

.btn-small {
    padding: 0 8px;
    line-height: 24px;
    border-width: 4px;
    font-size: 13px;
}

.btn-mini {
    padding: 0 5px;
    line-height: 22px;
    border-width: 2px;
    font-size: 12px;
}

.btn-minier {
    padding: 0 4px;
    line-height: 18px;
    border-width: 1px;
    font-size: 11px;
}
.btn,
.btn-default {
    background-color: #abbac3 !important;
    border-color: #abbac3;
}

.btn:hover,
.btn-default:hover {
    background-color: #8b9aa3 !important;
}

.btn.no-border:hover,
.btn-default.no-border:hover {
    border-color: #8b9aa3;
}

.btn.no-hover:hover,
.btn-default.no-hover:hover {
    background-color: #abbac3 !important;
}

.btn.active,
.btn-default.active {
    background-color: #9baab3 !important;
    border-color: #8a9ba6;
}

.btn.no-border.active,
.btn-default.no-border.active {
    background-color: #92a3ac !important;
    border-color: #92a3ac;
}

.btn.disabled,
.btn-default.disabled,
.btn[disabled],
.btn-default[disabled] {
    background-color: #abbac3 !important;
}

.btn-primary {
    background-color: #2283c5 !important;
    border-color: #2283c5;
}

.btn-primary:hover {
    background-color: #045e9f !important;
}

.btn-primary.no-border:hover {
    border-color: #045e9f;
}

.btn-primary.no-hover:hover {
    background-color: #2283c5 !important;
}

.btn-primary.active {
    background-color: #1371b2 !important;
    border-color: #105f96;
}

.btn-primary.no-border.active {
    background-color: #1268a4 !important;
    border-color: #1268a4;
}

.btn-primary.disabled,
.btn-primary[disabled] {
    background-color: #2283c5 !important;
}

.btn-info {
    background-color: #6fb3e0 !important;
    border-color: #6fb3e0;
}

.btn-info:hover {
    background-color: #4f99c6 !important;
}

.btn-info.no-border:hover {
    border-color: #4f99c6;
}

.btn-info.no-hover:hover {
    background-color: #6fb3e0 !important;
}

.btn-info.active {
    background-color: #5fa6d3 !important;
    border-color: #4799cc;
}

.btn-info.no-border.active {
    background-color: #539fd0 !important;
    border-color: #539fd0;
}

.btn-info.disabled,
.btn-info[disabled] {
    background-color: #6fb3e0 !important;
}

.btn-success {
    background-color: #87b87f !important;
    border-color: #87b87f;
}

.btn-success:hover {
    background-color: #629b58 !important;
}

.btn-success.no-border:hover {
    border-color: #629b58;
}

.btn-success.no-hover:hover {
    background-color: #87b87f !important;
}

.btn-success.active {
    background-color: #75aa6c !important;
    border-color: #649c5a;
}

.btn-success.no-border.active {
    background-color: #6ba462 !important;
    border-color: #6ba462;
}

.btn-success.disabled,
.btn-success[disabled] {
    background-color: #87b87f !important;
}

.btn-warning {
    background-color: #ffb752 !important;
    border-color: #ffb752;
}

.btn-warning:hover {
    background-color: #e59729 !important;
}

.btn-warning.no-border:hover {
    border-color: #e59729;
}

.btn-warning.no-hover:hover {
    background-color: #ffb752 !important;
}

.btn-warning.active {
    background-color: #f2a73e !important;
    border-color: #f09a21;
}

.btn-warning.no-border.active {
    background-color: #f1a02f !important;
    border-color: #f1a02f;
}

.btn-warning.disabled,
.btn-warning[disabled] {
    background-color: #ffb752 !important;
}

.btn-danger {
    background-color: #d15b47 !important;
    border-color: #d15b47;
}

.btn-danger:hover {
    background-color: #b74635 !important;
}

.btn-danger.no-border:hover {
    border-color: #b74635;
}

.btn-danger.no-hover:hover {
    background-color: #d15b47 !important;
}

.btn-danger.active {
    background-color: #c4513e !important;
    border-color: #ae4635;
}

.btn-danger.no-border.active {
    background-color: #ba4b39 !important;
    border-color: #ba4b39;
}

.btn-danger.disabled,
.btn-danger[disabled] {
    background-color: #d15b47 !important;
}

.btn-inverse {
    background-color: #555555 !important;
    border-color: #555555;
}

.btn-inverse:hover {
    background-color: #303030 !important;
}

.btn-inverse.no-border:hover {
    border-color: #303030;
}

.btn-inverse.no-hover:hover {
    background-color: #555555 !important;
}

.btn-inverse.active {
    background-color: #434343 !important;
    border-color: #333333;
}

.btn-inverse.no-border.active {
    background-color: #3b3b3b !important;
    border-color: #3b3b3b;
}

.btn-inverse.disabled,
.btn-inverse[disabled] {
    background-color: #555555 !important;
}

.btn-pink {
    background-color: #d6487e !important;
    border-color: #d6487e;
}

.btn-pink:hover {
    background-color: #b73766 !important;
}

.btn-pink.no-border:hover {
    border-color: #b73766;
}

.btn-pink.no-hover:hover {
    background-color: #d6487e !important;
}

.btn-pink.active {
    background-color: #c74072 !important;
    border-color: #b33564;
}

.btn-pink.no-border.active {
    background-color: #be386a !important;
    border-color: #be386a;
}

.btn-pink.disabled,
.btn-pink[disabled] {
    background-color: #d6487e !important;
}

.btn-purple {
    background-color: #9585bf !important;
    border-color: #9585bf;
}

.btn-purple:hover {
    background-color: #7461aa !important;
}

.btn-purple.no-border:hover {
    border-color: #7461aa;
}

.btn-purple.no-hover:hover {
    background-color: #9585bf !important;
}

.btn-purple.active {
    background-color: #8573b5 !important;
    border-color: #735faa;
}

.btn-purple.no-border.active {
    background-color: #7c69af !important;
    border-color: #7c69af;
}

.btn-purple.disabled,
.btn-purple[disabled] {
    background-color: #9585bf !important;
}

.btn-grey {
    background-color: #a0a0a0 !important;
    border-color: #a0a0a0;
}

.btn-grey:hover {
    background-color: #888888 !important;
}

.btn-grey.no-border:hover {
    border-color: #888888;
}

.btn-grey.no-hover:hover {
    background-color: #a0a0a0 !important;
}

.btn-grey.active {
    background-color: #949494 !important;
    border-color: #858585;
}

.btn-grey.no-border.active {
    background-color: #8c8c8c !important;
    border-color: #8c8c8c;
}

.btn-grey.disabled,
.btn-grey[disabled] {
    background-color: #a0a0a0 !important;
}

.btn-yellow {
    background-color: #fee188 !important;
    border-color: #fee188;
    color: #996633 !important;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
}

.btn-yellow:hover {
    background-color: #f7d05b !important;
}

.btn-yellow.no-border:hover {
    border-color: #f7d05b;
}

.btn-yellow.no-hover:hover {
    background-color: #fee188 !important;
}

.btn-yellow.active {
    background-color: #fbd972 !important;
    border-color: #fad054;
}

.btn-yellow.no-border.active {
    background-color: #fad463 !important;
    border-color: #fad463;
}

.btn-yellow.disabled,
.btn-yellow[disabled] {
    background-color: #fee188 !important;
}





.btn-white {
    background-color: #ffffff !important;
    border-color: #e3e3e7;
    color: #888888 !important;
    text-shadow: 0 -1px 0 rgba(250, 250, 250, 0.25) !important;
}

.btn-white:hover {
    background-color: #f5f5f4 !important;
    color: #777 !important;
}

.btn-white.no-border:hover {
    border-color: #d9d9d9;
}

.btn-white.no-hover:hover {
    background-color: #f8f8f7 !important;
}

.btn-white.active {
    background-color: #e0e0e0 !important;
    border-color: #d1d1d1;
}

.btn-white.no-border.active {
    background-color: #d8d8d8 !important;
    border-color: #d8d8d8;
}

.btn-white.disabled,
.btn-white[disabled] {
    background-color: #ffffff !important;
}





.btn-light {
    background-color: #e7e7e7 !important;
    border-color: #e7e7e7;
    color: #888888 !important;
    text-shadow: 0 -1px 0 rgba(250, 250, 250, 0.25) !important;
}

.btn-light:hover {
    background-color: #d9d9d9 !important;
}

.btn-light.no-border:hover {
    border-color: #d9d9d9;
}

.btn-light.no-hover:hover {
    background-color: #e7e7e7 !important;
}

.btn-light.active {
    background-color: #e0e0e0 !important;
    border-color: #d1d1d1;
}

.btn-light.no-border.active {
    background-color: #d8d8d8 !important;
    border-color: #d8d8d8;
}

.btn-light.disabled,
.btn-light[disabled] {
    background-color: #e7e7e7 !important;
}

.btn-light.btn-mini:after {
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
}

.btn-light.btn-small:after {
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;
}

.btn-light.btn-large:after {
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
}

.btn.disabled.active,
.btn[disabled].active,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled:active,
.btn[disabled]:active {
    outline: 0;
}

.btn.disabled:active,
.btn[disabled]:active {
    top: 0;
    left: 0;
}

.btn.active {
    color: #efe5b5;
}

.btn.active:after {
    display: inline-block;
    content: "";
    position: absolute;
    border-bottom: 1px solid #efe5b5;
    left: -4px;
    right: -4px;
    bottom: -4px;
}

.btn.active.btn-small:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-bottom-width: 1px;
}

.btn.active.btn-large:after {
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-bottom-width: 1px;
}

.btn.active.btn-mini:after,
.btn.active.btn-minier:after {
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-bottom-width: 1px;
}

.btn.active.btn-yellow:after {
    border-bottom-color: #c96338;
}

.btn.active.btn-light {
    color: #515151;
}

.btn.active.btn-light:after {
    border-bottom-color: #b5b5b5;
}

.btn > [class*="icon-"] {
    display: inline;
    margin-right: 4px;
}

.btn > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 4px;
}

.btn > .icon-only[class*="icon-"] {
    margin: 0;
    vertical-align: middle;
    text-align: center;
    padding: 0;
}

.btn-large > [class*="icon-"] {
    margin-right: 6px;
}

.btn-large > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 6px;
}

.btn-small > [class*="icon-"] {
    margin-right: 3px;
}

.btn-small > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 3px;
}

.btn-mini > [class*="icon-"],
.btn-minier > [class*="icon-"] {
    margin-right: 2px;
}

.btn-mini > [class*="icon-"].icon-on-right,
.btn-minier > [class*="icon-"].icon-on-right {
    margin-right: 0;
    margin-left: 2px;
}

.btn.btn-link {
    border: none !important;
    background: transparent none !important;
    color: #0088cc !important;
    text-shadow: none !important;
    padding: 4px 12px !important;
    line-height: 20px !important;
}

.btn.btn-link:hover {
    background: none !important;
    text-shadow: none !important;
}

.btn.btn-link.active {
    background: none !important;
    text-decoration: underline;
    color: #009ceb !important;
}

.btn.btn-link.active:after {
    display: none;
}

.btn.btn-link.disabled,
.btn.btn-link[disabled] {
    background: 0;
    opacity: 0.65;
}

.btn.btn-link.disabled:hover,
.btn.btn-link[disabled]:hover {
    background: none !important;
    text-decoration: none !important;
}

.btn-group > .btn,
.btn-group > .btn + .btn {
    margin: 0 1px 0 0;
}

.btn-group > .btn:first-child {
    margin: 0 1px 0 0;
}

.btn-group > .btn:first-child,
.btn-group > .btn:last-child {
    border-radius: 0;
}

.btn-group > .btn > .caret {
    margin-top: 15px;
    margin-left: 1px;
    border-width: 5px;
    border-top-color: #ffffff;
}

.btn-group > .btn.btn-small > .caret {
    margin-top: 10px;
    border-width: 4px;
}

.btn-group > .btn.btn-large > .caret {
    margin-top: 18px;
    border-width: 6px;
}

.btn-group > .btn.btn-mini > .caret {
    margin-top: 9px;
    border-width: 4px;
}

.btn-group > .btn.btn-minier > .caret {
    margin-top: 7px;
    border-width: 3px;
}

.btn-group > .btn + .btn.dropdown-toggle {
    padding-right: 3px;
    padding-left: 3px;
}

.btn-group > .btn + .btn-large.dropdown-toggle {
    padding-right: 4px;
    padding-left: 4px;
}

.btn-group .dropdown-toggle {
    border-radius: 0;
}

.btn-group > .btn,
.btn-group + .btn {
    margin: 0 1px 0 0;
    border-width: 3px !important;
}

.btn-group > .btn.active:after,
.btn-group + .btn.active:after {
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-bottom-width: 1px;
}

.btn-group > .btn-large,
.btn-group + .btn-large {
    border-width: 4px !important;
}

.btn-group > .btn-large.active:after,
.btn-group + .btn-large.active:after {
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-bottom-width: 1px;
}

.btn-group > .btn-small,
.btn-group + .btn-small {
    border-width: 2px !important;
}

.btn-group > .btn-small.active:after,
.btn-group + .btn-small.active:after {
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-bottom-width: 1px;
}

.btn-group > .btn-mini,
.btn-group + .btn-mini {
    border-width: 1px !important;
}

.btn-group > .btn-mini.active:after,
.btn-group + .btn-mini.active:after {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-width: 1px;
}

.btn-group > .btn-minier,
.btn-group + .btn-minier {
    border-width: 0 !important;
}

.btn-group > .btn-minier.active:after,
.btn-group + .btn-minier.active:after {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-width: 1px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn + .btn {
    margin: 1px 0 0;
}

.btn-group-vertical > .btn:first-child {
    margin-right: 0;
}

.btn.btn-app {
    display: inline-block;
    width: 100px;
    margin: 2px;
    position: relative;
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 -1px -1px rgba(0, 0, 0, 0.2) !important;
    border: 0;
    border-radius: 12px;
    padding: 12px 0 8px;
}

.btn-app,
.btn-app.btn-default,
.btn-app.no-hover:hover,
.btn-app.btn-default.no-hover:hover,
.btn-app.disabled:hover,
.btn-app.btn-default.disabled:hover {
    background: #b4c2cc !important;
    background-color: #b5c3ce !important;
    background-image: -moz-linear-gradient(top, #bcc9d5, #abbac3) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#bcc9d5), to(#abbac3)) !important;
    background-image: -webkit-linear-gradient(top, #bcc9d5, #abbac3) !important;
    background-image: -o-linear-gradient(top, #bcc9d5, #abbac3) !important;
    background-image: linear-gradient(to bottom, #bcc9d5, #abbac3) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbcc9d5', endColorstr='#ffabbac3', GradientType=0) !important;
}

.btn-app:hover,
.btn-app.btn-default:hover {
    background: #9baebc !important;
    background-color: #9dafbe !important;
    background-image: -moz-linear-gradient(top, #a3b5c5, #93a6b2) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#a3b5c5), to(#93a6b2)) !important;
    background-image: -webkit-linear-gradient(top, #a3b5c5, #93a6b2) !important;
    background-image: -o-linear-gradient(top, #a3b5c5, #93a6b2) !important;
    background-image: linear-gradient(to bottom, #a3b5c5, #93a6b2) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa3b5c5', endColorstr='#ff93a6b2', GradientType=0) !important;
}

.btn-app.btn-primary,
.btn-app.btn-primary.no-hover:hover,
.btn-app.btn-primary.disabled:hover {
    background: #2a8bcc !important;
    background-color: #2d8ece !important;
    background-image: -moz-linear-gradient(top, #3b98d6, #197ec1) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#3b98d6), to(#197ec1)) !important;
    background-image: -webkit-linear-gradient(top, #3b98d6, #197ec1) !important;
    background-image: -o-linear-gradient(top, #3b98d6, #197ec1) !important;
    background-image: linear-gradient(to bottom, #3b98d6, #197ec1) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3b98d6', endColorstr='#ff197ec1', GradientType=0) !important;
}

.btn-app.btn-primary:hover {
    background: #1d6fa6 !important;
    background-color: #1f72a9 !important;
    background-image: -moz-linear-gradient(top, #267eb8, #136194) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#267eb8), to(#136194)) !important;
    background-image: -webkit-linear-gradient(top, #267eb8, #136194) !important;
    background-image: -o-linear-gradient(top, #267eb8, #136194) !important;
    background-image: linear-gradient(to bottom, #267eb8, #136194) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff267eb8', endColorstr='#ff136194', GradientType=0) !important;
}

.btn-app.btn-info,
.btn-app.btn-info.no-hover:hover,
.btn-app.btn-info.disabled:hover {
    background: #68adde !important;
    background-color: #6baedf !important;
    background-image: -moz-linear-gradient(top, #75b5e6, #5ba4d5) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#75b5e6), to(#5ba4d5)) !important;
    background-image: -webkit-linear-gradient(top, #75b5e6, #5ba4d5) !important;
    background-image: -o-linear-gradient(top, #75b5e6, #5ba4d5) !important;
    background-image: linear-gradient(to bottom, #75b5e6, #5ba4d5) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff75b5e6', endColorstr='#ff5ba4d5', GradientType=0) !important;
}

.btn-app.btn-info:hover {
    background: #3f96d4 !important;
    background-color: #4197d6 !important;
    background-image: -moz-linear-gradient(top, #4a9ede, #348dc9) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4a9ede), to(#348dc9)) !important;
    background-image: -webkit-linear-gradient(top, #4a9ede, #348dc9) !important;
    background-image: -o-linear-gradient(top, #4a9ede, #348dc9) !important;
    background-image: linear-gradient(to bottom, #4a9ede, #348dc9) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4a9ede', endColorstr='#ff348dc9', GradientType=0) !important;
}

.btn-app.btn-success,
.btn-app.btn-success.no-hover:hover,
.btn-app.btn-success.disabled:hover {
    background: #86b558 !important;
    background-color: #87b75a !important;
    background-image: -moz-linear-gradient(top, #8ebf60, #7daa50) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#8ebf60), to(#7daa50)) !important;
    background-image: -webkit-linear-gradient(top, #8ebf60, #7daa50) !important;
    background-image: -o-linear-gradient(top, #8ebf60, #7daa50) !important;
    background-image: linear-gradient(to bottom, #8ebf60, #7daa50) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8ebf60', endColorstr='#ff7daa50', GradientType=0) !important;
}

.btn-app.btn-success:hover {
    background: #6c9842 !important;
    background-color: #6e9b42 !important;
    background-image: -moz-linear-gradient(top, #74a844, #648740) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#74a844), to(#648740)) !important;
    background-image: -webkit-linear-gradient(top, #74a844, #648740) !important;
    background-image: -o-linear-gradient(top, #74a844, #648740) !important;
    background-image: linear-gradient(to bottom, #74a844, #648740) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff74a844', endColorstr='#ff648740', GradientType=0) !important;
}

.btn-app.btn-danger,
.btn-app.btn-danger.no-hover:hover,
.btn-app.btn-danger.disabled:hover {
    background: #d3413b !important;
    background-color: #d3463f !important;
    background-image: -moz-linear-gradient(top, #d55b52, #d12723) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#d55b52), to(#d12723)) !important;
    background-image: -webkit-linear-gradient(top, #d55b52, #d12723) !important;
    background-image: -o-linear-gradient(top, #d55b52, #d12723) !important;
    background-image: linear-gradient(to bottom, #d55b52, #d12723) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd55b52', endColorstr='#ffd12723', GradientType=0) !important;
}

.btn-app.btn-danger:hover {
    background: #b52c26 !important;
    background-color: #b82f28 !important;
    background-image: -moz-linear-gradient(top, #c43a30, #a51f1c) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#c43a30), to(#a51f1c)) !important;
    background-image: -webkit-linear-gradient(top, #c43a30, #a51f1c) !important;
    background-image: -o-linear-gradient(top, #c43a30, #a51f1c) !important;
    background-image: linear-gradient(to bottom, #c43a30, #a51f1c) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc43a30', endColorstr='#ffa51f1c', GradientType=0) !important;
}

.btn-app.btn-warning,
.btn-app.btn-warning.no-hover:hover,
.btn-app.btn-warning.disabled:hover {
    background: #ffb44b !important;
    background-color: #ffb650 !important;
    background-image: -moz-linear-gradient(top, #ffbf66, #ffa830) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffbf66), to(#ffa830)) !important;
    background-image: -webkit-linear-gradient(top, #ffbf66, #ffa830) !important;
    background-image: -o-linear-gradient(top, #ffbf66, #ffa830) !important;
    background-image: linear-gradient(to bottom, #ffbf66, #ffa830) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffbf66', endColorstr='#ffffa830', GradientType=0) !important;
}

.btn-app.btn-warning:hover {
    background: #fe9e19 !important;
    background-color: #fea01f !important;
    background-image: -moz-linear-gradient(top, #ffaa33, #fc9200) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffaa33), to(#fc9200)) !important;
    background-image: -webkit-linear-gradient(top, #ffaa33, #fc9200) !important;
    background-image: -o-linear-gradient(top, #ffaa33, #fc9200) !important;
    background-image: linear-gradient(to bottom, #ffaa33, #fc9200) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffaa33', endColorstr='#fffc9200', GradientType=0) !important;
}

.btn-app.btn-purple,
.btn-app.btn-purple.no-hover:hover,
.btn-app.btn-purple.disabled:hover {
    background: #9889c1 !important;
    background-color: #9b8cc4 !important;
    background-image: -moz-linear-gradient(top, #a696ce, #8a7cb4) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#a696ce), to(#8a7cb4)) !important;
    background-image: -webkit-linear-gradient(top, #a696ce, #8a7cb4) !important;
    background-image: -o-linear-gradient(top, #a696ce, #8a7cb4) !important;
    background-image: linear-gradient(to bottom, #a696ce, #8a7cb4) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa696ce', endColorstr='#ff8a7cb4', GradientType=0) !important;
}

.btn-app.btn-purple:hover {
    background: #7b68af !important;
    background-color: #7e6ab2 !important;
    background-image: -moz-linear-gradient(top, #8973be, #6d5ca1) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#8973be), to(#6d5ca1)) !important;
    background-image: -webkit-linear-gradient(top, #8973be, #6d5ca1) !important;
    background-image: -o-linear-gradient(top, #8973be, #6d5ca1) !important;
    background-image: linear-gradient(to bottom, #8973be, #6d5ca1) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8973be', endColorstr='#ff6d5ca1', GradientType=0) !important;
}

.btn-app.btn-pink,
.btn-app.btn-pink.no-hover:hover,
.btn-app.btn-pink.disabled:hover {
    background: #d54c7e !important;
    background-color: #d64f81 !important;
    background-image: -moz-linear-gradient(top, #db5e8c, #ce3970) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#db5e8c), to(#ce3970)) !important;
    background-image: -webkit-linear-gradient(top, #db5e8c, #ce3970) !important;
    background-image: -o-linear-gradient(top, #db5e8c, #ce3970) !important;
    background-image: linear-gradient(to bottom, #db5e8c, #ce3970) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdb5e8c', endColorstr='#ffce3970', GradientType=0) !important;
}

.btn-app.btn-pink:hover {
    background: #be2f64 !important;
    background-color: #c23066 !important;
    background-image: -moz-linear-gradient(top, #d2346e, #aa2a59) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#d2346e), to(#aa2a59)) !important;
    background-image: -webkit-linear-gradient(top, #d2346e, #aa2a59) !important;
    background-image: -o-linear-gradient(top, #d2346e, #aa2a59) !important;
    background-image: linear-gradient(to bottom, #d2346e, #aa2a59) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd2346e', endColorstr='#ffaa2a59', GradientType=0) !important;
}

.btn-app.btn-inverse,
.btn-app.btn-inverse.no-hover:hover,
.btn-app.btn-inverse.disabled:hover {
    background: #444444 !important;
    background-color: #474747 !important;
    background-image: -moz-linear-gradient(top, #555555, #333333) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#555555), to(#333333)) !important;
    background-image: -webkit-linear-gradient(top, #555555, #333333) !important;
    background-image: -o-linear-gradient(top, #555555, #333333) !important;
    background-image: linear-gradient(to bottom, #555555, #333333) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555555', endColorstr='#ff333333', GradientType=0) !important;
}

.btn-app.btn-inverse:hover {
    background: #2b2b2b !important;
    background-color: #2e2e2e !important;
    background-image: -moz-linear-gradient(top, #3b3b3b, #1a1a1a) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#3b3b3b), to(#1a1a1a)) !important;
    background-image: -webkit-linear-gradient(top, #3b3b3b, #1a1a1a) !important;
    background-image: -o-linear-gradient(top, #3b3b3b, #1a1a1a) !important;
    background-image: linear-gradient(to bottom, #3b3b3b, #1a1a1a) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3b3b3b', endColorstr='#ff1a1a1a', GradientType=0) !important;
}

.btn-app.btn-grey,
.btn-app.btn-grey.no-hover:hover,
.btn-app.btn-grey.disabled:hover {
    background: #797979 !important;
    background-color: #7c7c7c !important;
    background-image: -moz-linear-gradient(top, #898989, #696969) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#898989), to(#696969)) !important;
    background-image: -webkit-linear-gradient(top, #898989, #696969) !important;
    background-image: -o-linear-gradient(top, #898989, #696969) !important;
    background-image: linear-gradient(to bottom, #898989, #696969) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff898989', endColorstr='#ff696969', GradientType=0) !important;
}

.btn-app.btn-grey:hover {
    background: #6c6c6c !important;
    background-color: #6f6f6f !important;
    background-image: -moz-linear-gradient(top, #7c7c7c, #5c5c5c) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7c7c7c), to(#5c5c5c)) !important;
    background-image: -webkit-linear-gradient(top, #7c7c7c, #5c5c5c) !important;
    background-image: -o-linear-gradient(top, #7c7c7c, #5c5c5c) !important;
    background-image: linear-gradient(to bottom, #7c7c7c, #5c5c5c) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7c7c7c', endColorstr='#ff5c5c5c', GradientType=0) !important;
}

.btn-app.btn-light {
    color: #5a5a5a !important;
    text-shadow: 0 1px 1px #eeeeee !important;
}

.btn-app.btn-light,
.btn-app.btn-light.no-hover:hover,
.btn-app.btn-light.disabled:hover {
    background: #ededed !important;
    background-color: #eeeeee !important;
    background-image: -moz-linear-gradient(top, #f4f4f4, #e6e6e6) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f4f4f4), to(#e6e6e6)) !important;
    background-image: -webkit-linear-gradient(top, #f4f4f4, #e6e6e6) !important;
    background-image: -o-linear-gradient(top, #f4f4f4, #e6e6e6) !important;
    background-image: linear-gradient(to bottom, #f4f4f4, #e6e6e6) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff4f4f4', endColorstr='#ffe6e6e6', GradientType=0) !important;
}

.btn-app.btn-light:hover {
    background: #e0e0e0 !important;
    background-color: #e2e2e2 !important;
    background-image: -moz-linear-gradient(top, #e7e7e7, #d9d9d9) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#e7e7e7), to(#d9d9d9)) !important;
    background-image: -webkit-linear-gradient(top, #e7e7e7, #d9d9d9) !important;
    background-image: -o-linear-gradient(top, #e7e7e7, #d9d9d9) !important;
    background-image: linear-gradient(to bottom, #e7e7e7, #d9d9d9) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe7e7e7', endColorstr='#ffd9d9d9', GradientType=0) !important;
}

.btn-app.btn-light,
.btn-app.btn-light.no-hover:hover {
    border: 2px solid #dddddd;
}

.btn-app.btn-light.btn-mini {
    width: 64px;
    padding-bottom: 6px;
}

.btn-app.btn-light:hover {
    border-color: #d8d8d8;
}

.btn-app.btn-yellow {
    color: #996633 !important;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
    border: 2px solid #fee188;
}

.btn-app.btn-yellow,
.btn-app.btn-yellow.no-hover:hover,
.btn-app.btn-yellow.disabled:hover {
    background: #fee088 !important;
    background-color: #fee18d !important;
    background-image: -moz-linear-gradient(top, #ffe8a5, #fcd76a) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffe8a5), to(#fcd76a)) !important;
    background-image: -webkit-linear-gradient(top, #ffe8a5, #fcd76a) !important;
    background-image: -o-linear-gradient(top, #ffe8a5, #fcd76a) !important;
    background-image: linear-gradient(to bottom, #ffe8a5, #fcd76a) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffe8a5', endColorstr='#fffcd76a', GradientType=0) !important;
}

.btn-app.btn-yellow:hover {
    background: #fdd96e !important;
    background-color: #fedb74 !important;
    background-image: -moz-linear-gradient(top, #ffe18b, #fbd051) !important;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffe18b), to(#fbd051)) !important;
    background-image: -webkit-linear-gradient(top, #ffe18b, #fbd051) !important;
    background-image: -o-linear-gradient(top, #ffe18b, #fbd051) !important;
    background-image: linear-gradient(to bottom, #ffe18b, #fbd051) !important;
    background-repeat: repeat-x !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffe18b', endColorstr='#fffbd051', GradientType=0) !important;
}

.btn-app.btn-yellow,
.btn-app.btn-yellow.no-hover:hover {
    border: 2px solid #fee188;
}

.btn-app.btn-yellow:hover {
    border-color: #fed760;
}

.btn.btn-app.btn-small {
    width: 80px;
    font-size: 16px;
    border-radius: 10px;
    padding-bottom: 9px;
}

.btn.btn-app.btn-mini {
    width: 64px;
    font-size: 15px;
    border-radius: 8px;
    padding-bottom: 7px;
    padding-top: 8px;
}

.btn.btn-app > [class*=icon] {
    display: block;
    font-size: 42px;
    margin: 0 0 4px;
    line-height: 36px;
    min-width: 0;
    padding: 0;
}

.btn.btn-app.btn-small > [class*=icon] {
    display: block;
    font-size: 32px;
    line-height: 30px;
    margin: 0 0 3px;
}

.btn.btn-app.btn-mini > [class*=icon] {
    display: block;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
}

.btn.btn-app.no-radius {
    border-radius: 0;
}

.btn.btn-app.radius-4 {
    border-radius: 4px;
}

.btn.btn-app > .badge,
.btn.btn-app > .label {
    position: absolute !important;
    top: -2px;
    right: -2px;
    padding: 1px 3px;
    text-align: center;
    font-size: 12px;
}

.btn > .badge {
    position: absolute !important;
    top: -3px !important;
    right: -3px;
    padding: 0 2px;
    text-align: center;
    font-size: 9px;
}

.btn.btn-app > .badge.badge-right,
.btn.btn-app > .label.badge-right,
.btn.btn-app > .badge.label-right,
.btn.btn-app > .label.label-right {
    right: auto;
    left: -2px;
}

.btn > .badge.badge-right {
    right: auto;
    left: -3px;
    top: -3px;
}

.btn.btn-app > .label {
    padding: 1px 6px 3px;
    font-size: 13px;
}

.btn.btn-app.radius-4 > .badge,
.btn > .badge,
.btn.btn-app.no-radius > .badge {
    border-radius: 3px;
}

.btn.btn-app.radius-4 > .badge.no-radius,
.btn.btn-app.no-radius > .badge.no-radius {
    border-radius: 0;
}

.btn.btn-app.active {
    color: #ffffff;
}

.btn.btn-app.active:after {
    display: none;
}

.btn.btn-app.active.btn-yellow {
    color: #996633;
    border-color: #fee188;
}

.btn.btn-app.active.btn-light {
    color: #515151;
}

.label[class*="span"][class*="arrow"] {
    min-height: 0;
}

.label-transparent,
.badge-transparent {
    background-color: transparent !important;
}

.label-grey,
.badge-grey {
    background-color: #a0a0a0 !important;
}

.label-info,
.badge-info {
    background-color: #3a87ad !important;
}

.label-primary,
.badge-primary {
    background-color: #2283c5 !important;
}

.label-success,
.badge-success {
    background-color: #82af6f !important;
}

.label-important,
.badge-important {
    background-color: #d15b47 !important;
}

.label-inverse,
.badge-inverse {
    background-color: #333333 !important;
}

.label-warning,
.badge-warning {
    background-color: #f89406 !important;
}

.label-pink,
.badge-pink {
    background-color: #d6487e !important;
}

.label-purple,
.badge-purple {
    background-color: #9585bf !important;
}

.label-yellow,
.badge-yellow {
    background-color: #fee188 !important;
}

.label-light,
.badge-light {
    background-color: #e7e7e7 !important;
}

.badge-yellow,
.label-yellow {
    color: #996633 !important;
    border-color: #fee188;
}

.badge-light,
.label-light {
    color: #888888 !important;
}

.label.arrowed,
.label.arrowed-in {
    position: relative;
    margin-left: 9px;
}

.label.arrowed:before,
.label.arrowed-in:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 9px 7px;
    border-right-color: #abbac3;
}

.label.arrowed-in:before {
    border-color: #abbac3;
    border-left-color: transparent !important;
    left: -9px;
}

.label.arrowed-right,
.label.arrowed-in-right {
    position: relative;
    margin-right: 9px;
}

.label.arrowed-right:after,
.label.arrowed-in-right:after {
    display: inline-block;
    content: "";
    position: absolute;
    right: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 9px 7px;
    border-left-color: #abbac3;
}

.label.arrowed-in-right:after {
    border-color: #abbac3;
    border-right-color: transparent !important;
    right: -9px;
}

.label-info.arrowed:before {
    border-right-color: #3a87ad;
}

.label-info.arrowed-in:before {
    border-color: #3a87ad;
}

.label-info.arrowed-right:after {
    border-left-color: #3a87ad;
}

.label-info.arrowed-in-right:after {
    border-color: #3a87ad;
}

.label-primary.arrowed:before {
    border-right-color: #2283c5;
}

.label-primary.arrowed-in:before {
    border-color: #2283c5;
}

.label-primary.arrowed-right:after {
    border-left-color: #2283c5;
}

.label-primary.arrowed-in-right:after {
    border-color: #2283c5;
}

.label-success.arrowed:before {
    border-right-color: #82af6f;
}

.label-success.arrowed-in:before {
    border-color: #82af6f;
}

.label-success.arrowed-right:after {
    border-left-color: #82af6f;
}

.label-success.arrowed-in-right:after {
    border-color: #82af6f;
}

.label-warning.arrowed:before {
    border-right-color: #f89406;
}

.label-warning.arrowed-in:before {
    border-color: #f89406;
}

.label-warning.arrowed-right:after {
    border-left-color: #f89406;
}

.label-warning.arrowed-in-right:after {
    border-color: #f89406;
}

.label-important.arrowed:before {
    border-right-color: #d15b47;
}

.label-important.arrowed-in:before {
    border-color: #d15b47;
}

.label-important.arrowed-right:after {
    border-left-color: #d15b47;
}

.label-important.arrowed-in-right:after {
    border-color: #d15b47;
}

.label-inverse.arrowed:before {
    border-right-color: #333333;
}

.label-inverse.arrowed-in:before {
    border-color: #333333;
}

.label-inverse.arrowed-right:after {
    border-left-color: #333333;
}

.label-inverse.arrowed-in-right:after {
    border-color: #333333;
}

.label-pink.arrowed:before {
    border-right-color: #d6487e;
}

.label-pink.arrowed-in:before {
    border-color: #d6487e;
}

.label-pink.arrowed-right:after {
    border-left-color: #d6487e;
}

.label-pink.arrowed-in-right:after {
    border-color: #d6487e;
}

.label-purple.arrowed:before {
    border-right-color: #9585bf;
}

.label-purple.arrowed-in:before {
    border-color: #9585bf;
}

.label-purple.arrowed-right:after {
    border-left-color: #9585bf;
}

.label-purple.arrowed-in-right:after {
    border-color: #9585bf;
}

.label-yellow.arrowed:before {
    border-right-color: #fee188;
}

.label-yellow.arrowed-in:before {
    border-color: #fee188;
}

.label-yellow.arrowed-right:after {
    border-left-color: #fee188;
}

.label-yellow.arrowed-in-right:after {
    border-color: #fee188;
}

.label-light.arrowed:before {
    border-right-color: #e7e7e7;
}

.label-light.arrowed-in:before {
    border-color: #e7e7e7;
}

.label-light.arrowed-right:after {
    border-left-color: #e7e7e7;
}

.label-light.arrowed-in-right:after {
    border-color: #e7e7e7;
}

.label-grey.arrowed:before {
    border-right-color: #a0a0a0;
}

.label-grey.arrowed-in:before {
    border-color: #a0a0a0;
}

.label-grey.arrowed-right:after {
    border-left-color: #a0a0a0;
}

.label-grey.arrowed-in-right:after {
    border-color: #a0a0a0;
}

.label-large {
    font-size: 13px;
    padding: 3px 8px 5px;
}

.label-large.arrowed,
.label-large.arrowed-in {
    margin-left: 12px;
}

.label-large.arrowed:before,
.label-large.arrowed-in:before {
    left: -16px;
    border-width: 11px 8px;
}

.label-large.arrowed-in:before {
    left: -12px;
}

.label-large.arrowed-right,
.label-large.arrowed-in-right {
    margin-right: 11px;
}

.label-large.arrowed-right:after,
.label-large.arrowed-in-right:after {
    right: -16px;
    border-width: 11px 8px;
}

.label-large.arrowed-in-right:after {
    right: -12px;
}

.icon-white,
.nav-pills > .active > a > [class^="icon-"],
.nav-pills > .active > a > [class*=" icon-"],
.nav-list > .active > a > [class^="icon-"],
.nav-list > .active > a > [class*=" icon-"],
.navbar-inverse .nav > .active > a > [class^="icon-"],
.navbar-inverse .nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:focus > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > li > a:focus > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"],
.dropdown-submenu:hover > a > [class^="icon-"],
.dropdown-submenu:focus > a > [class^="icon-"],
.dropdown-submenu:hover > a > [class*=" icon-"],
.dropdown-submenu:focus > a > [class*=" icon-"] {
    background-image: none;
}

.dropdown-menu {
    background-color: #ffffff;
    border-radius: 4px !important;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,0.07) !important;
    border:1px solid #efefef !important;
    padding: 0px !important;
}

.dropdown-menu > li.dropdown-menu-header-title {
    border-bottom: 1px solid #EFEFEF;
    padding: 10px 20px 10px 20px !important;
    color: #595959;
    font-weight: bold;
    letter-spacing: .4px;
    background: #ffffff !important;
    border-radius: 4px 4px 0px 0px;
}

.dropdown-menu>li.dropdown-menu-item>a{
    padding: 0;
    text-align: left;
}

.dropdown-menu > li.dropdown-menu-item {
    background: #fafafa !important;
    padding: 10px 20px 10px 0px !important;
    border-bottom: 1px solid #efefef;
}

.dropdown-menu > li.dropdown-menu-item:last-child {
    border-radius: 0px 0px 4px 4px;
}

.dropdown-menu > li.dropdown-menu-item-content {
    background: #fafafa !important;
    padding: 10px 20px 10px 10px !important;
    border-bottom: 1px solid #efefef;
}

.dropdown-menu > li.dropdown-menu-item-content:last-child {
    border-radius: 0px 0px 4px 4px;
}

.dropdown-menu>li.dropdown-menu-item>a:hover, .dropdown-menu>li.dropdown-menu-item>a:focus{
    background: #fafafa;
    color: #2876fd !important;
}

.dropdown-navbar.dropdown-menu > li.dropdown-menu-item-content:hover{
    background: #fafafa !important;
}

.widget-toolbar > .dropdown > .dropdown-menu:after, .dropdown-menu.dropdown-caret:after,
.widget-toolbar > .dropdown > .dropdown-menu:before, .dropdown-menu.dropdown-caret:before{
    display: none;
}

.dropdown-menu > li > a {
    font-size: 13px;
    padding-left: 11px;
    padding-right: 11px;
    margin-bottom: 1px;
    margin-top: 1px;
}

.dropdown-menu.dropdown-icon-only {
    min-width: 0;
}

.dropdown-menu.dropdown-icon-only > li > a [class*="icon-"] {
    width: 18px;
    display: inline-block;
}

.dropdown-menu.dropdown-icon-only > li > a .icon-2x {
    width: 36px;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active,
.dropdown-menu li.active a,
.dropdown-menu li.active a:hover,
.dropdown-menu .dropdown-submenu:hover > a,
.nav-tabs .dropdown-menu li > a:focus {
    background: #4f99c6;
    color: #ffffff;
}

.dropdown-default li a:hover,
.dropdown-default li a:focus,
.dropdown-default li a:active,
.dropdown-default li.active a,
.dropdown-default li.active a:hover,
.dropdown-default .dropdown-submenu:hover > a,
.nav-tabs .dropdown-default li > a:focus {
    background: #abbac3;
    color: #ffffff;
}

.dropdown-info li a:hover,
.dropdown-info li a:focus,
.dropdown-info li a:active,
.dropdown-info li.active a,
.dropdown-info li.active a:hover,
.dropdown-info .dropdown-submenu:hover > a,
.nav-tabs .dropdown-info li > a:focus {
    background: #6fb3e0;
    color: #ffffff;
}

.dropdown-primary li a:hover,
.dropdown-primary li a:focus,
.dropdown-primary li a:active,
.dropdown-primary li.active a,
.dropdown-primary li.active a:hover,
.dropdown-primary .dropdown-submenu:hover > a,
.nav-tabs .dropdown-primary li > a:focus {
    background: #2283c5;
    color: #ffffff;
}

.dropdown-success li a:hover,
.dropdown-success li a:focus,
.dropdown-success li a:active,
.dropdown-success li.active a,
.dropdown-success li.active a:hover,
.dropdown-success .dropdown-submenu:hover > a,
.nav-tabs .dropdown-success li > a:focus {
    background: #87b87f;
    color: #ffffff;
}

.dropdown-warning li a:hover,
.dropdown-warning li a:focus,
.dropdown-warning li a:active,
.dropdown-warning li.active a,
.dropdown-warning li.active a:hover,
.dropdown-warning .dropdown-submenu:hover > a,
.nav-tabs .dropdown-warning li > a:focus {
    background: #ffa24d;
    color: #ffffff;
}

.dropdown-danger li a:hover,
.dropdown-danger li a:focus,
.dropdown-danger li a:active,
.dropdown-danger li.active a,
.dropdown-danger li.active a:hover,
.dropdown-danger .dropdown-submenu:hover > a,
.nav-tabs .dropdown-danger li > a:focus {
    background: #d15b47;
    color: #ffffff;
}

.dropdown-inverse li a:hover,
.dropdown-inverse li a:focus,
.dropdown-inverse li a:active,
.dropdown-inverse li.active a,
.dropdown-inverse li.active a:hover,
.dropdown-inverse .dropdown-submenu:hover > a,
.nav-tabs .dropdown-inverse li > a:focus {
    background: #555555;
    color: #ffffff;
}

.dropdown-purple li a:hover,
.dropdown-purple li a:focus,
.dropdown-purple li a:active,
.dropdown-purple li.active a,
.dropdown-purple li.active a:hover,
.dropdown-purple .dropdown-submenu:hover > a,
.nav-tabs .dropdown-purple li > a:focus {
    background: #9585bf;
    color: #ffffff;
}

.dropdown-pink li a:hover,
.dropdown-pink li a:focus,
.dropdown-pink li a:active,
.dropdown-pink li.active a,
.dropdown-pink li.active a:hover,
.dropdown-pink .dropdown-submenu:hover > a,
.nav-tabs .dropdown-pink li > a:focus {
    background: #d6487e;
    color: #ffffff;
}

.dropdown-grey li a:hover,
.dropdown-grey li a:focus,
.dropdown-grey li a:active,
.dropdown-grey li.active a,
.dropdown-grey li.active a:hover,
.dropdown-grey .dropdown-submenu:hover > a,
.nav-tabs .dropdown-grey li > a:focus {
    background: #a0a0a0;
    color: #ffffff;
}

.dropdown-light li a:hover,
.dropdown-light li a:focus,
.dropdown-light li a:active,
.dropdown-light li.active a,
.dropdown-light li.active a:hover,
.dropdown-light .dropdown-submenu:hover > a,
.nav-tabs .dropdown-light li > a:focus {
    background: #e7e7e7;
    color: #333333;
}

.dropdown-lighter li a:hover,
.dropdown-lighter li a:focus,
.dropdown-lighter li a:active,
.dropdown-lighter li.active a,
.dropdown-lighter li.active a:hover,
.dropdown-lighter .dropdown-submenu:hover > a,
.nav-tabs .dropdown-lighter li > a:focus {
    background: #f3f3f3;
    color: #444444;
}

.dropdown-yellow li a:hover,
.dropdown-yellow li a:focus,
.dropdown-yellow li a:active,
.dropdown-yellow li.active a,
.dropdown-yellow li.active a:hover,
.dropdown-yellow .dropdown-submenu:hover > a,
.nav-tabs .dropdown-yellow li > a:focus {
    background: #fee188;
    color: #444444;
}

.dropdown-light .dropdown-submenu:hover > a:after,
.dropdown-lighter .dropdown-submenu:hover > a:after {
    border-left-color: #444444;
}

.dropdown-menu.dropdown-close {
    top: 92%;
    left: -5px;
}

.dropdown-menu.dropdown-close.pull-right {
    left: auto;
    right: -5px;
}

.dropdown-menu.dropdown-closer {
    top: 80%;
    left: -10px;
}

.dropdown-menu.dropdown-closer.pull-right {
    right: -10px;
    left: auto;
}

.dropdown-submenu > .dropdown-menu {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.dropdown-submenu > a:after {
    margin-right: -5px;
}

.dropdown-colorpicker > .dropdown-menu {
    top: 80%;
    left: -7px;
    padding: 4px;
    min-width: 120px;
    max-width: 120px;
}

.dropdown-colorpicker > .dropdown-menu.pull-right {
    right: -7px;
    left: auto;
}

.dropdown-colorpicker > .dropdown-menu > li {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
}

.dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    position: relative;
    -webkit-transition: all ease .1s;
    -moz-transition: all ease .1s;
    -o-transition: all ease .1s;
    transition: all ease 0.1s;
}

.dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn:hover {
    text-decoration: none;
    opacity: .8;
    filter: alpha(opacity=80);
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
    -o-transform: scale(1.08);
    transform: scale(1.08);
}

.dropdown-colorpicker > .dropdown-menu > li > .colorpick-btn.selected:after {
    content: "\f00c";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 11px;
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px;
}

.btn-colorpicker {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #dddddd;
    vertical-align: middle;
    border-radius: 0;
}

.dropdown-navbar {
    padding: 0;
    width: 240px;
    -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    -moz-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
    border-color: #bcd4e5;
}

.dropdown-navbar > li {
    padding: 0 8px;
    background-color: #ffffff;
}

.dropdown-navbar > li.nav-header {
    text-shadow: none;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
    border-bottom: 1px solid;
}

.dropdown-navbar > li > [class*="icon-"],
.dropdown-navbar > li > a > [class*="icon-"],
.dropdown-navbar > li > span > [class*="icon-"] {
    margin-right: 5px !important;
    color: #555555;
    font-size: 14px;
}

.dropdown-navbar > li > a,
.dropdown-navbar > li > span {
    padding: 10px 2px;
    margin: 0;
    border-bottom: 1px solid;
    font-size: 12px;
    line-height: 16px;
    color: #555555;
}

.dropdown-navbar > li > a:active,
.dropdown-navbar > li > a:hover,
.dropdown-navbar > li > a:focus,
.dropdown-navbar > li > span:active,
.dropdown-navbar > li > span:hover,
.dropdown-navbar > li > span:focus {
    background-color: transparent !important;
    color: #555555;
}

.dropdown-navbar > li > a .progress {
    margin-bottom: 0;
    margin-top: 4px;
}

.dropdown-navbar > li > a .badge {
    line-height: 16px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
}

.dropdown-navbar > li:last-child > a {
    border-bottom: 0 solid #dddddd;
    border-top: 1px dotted transparent;
    color: #4f99c6;
    text-align: center;
    font-size: 13px;
}

.dropdown-navbar > li:last-child > a:hover {
    background-color: #ffffff;
    color: #4f99c6;
    text-decoration: underline;
}

.dropdown-navbar > li:last-child > a:hover > [class*="icon-"] {
    text-decoration: none;
}

.dropdown-navbar > li:hover {
    background-color: #f4f9fc !important;
}

.dropdown-navbar > li.nav-header {
    background-color: #ecf2f700 !important;
    color: #555555 !important;
    border-bottom-color: #bcd4e5;
    border-bottom: 1px solid #dddddd;
}

.dropdown-navbar > li.nav-header > [class*="icon-"] {
    color: #555555;
}

.dropdown-navbar > li > a,
.dropdown-navbar > li > span {
    border-bottom-color: #e4ecf3;
}

.dropdown-navbar.navbar-pink {
    border-color: #e5bcd4;
}

.dropdown-navbar.navbar-pink > li:hover {
    background-color: #fcf4f9 !important;
}

.dropdown-navbar.navbar-pink > li.nav-header {
    background-color: #f7ecf2 !important;
    color: #b471a0;
    border-bottom-color: #e5bcd4;
}

.dropdown-navbar.navbar-pink > li.nav-header > [class*="icon-"] {
    color: #c06090;
}

.dropdown-navbar.navbar-pink > li > a {
    border-bottom-color: #f3e4ec;
}

.dropdown-navbar.navbar-grey {
    border-color: #e5e5e5;
}

.dropdown-navbar.navbar-grey > li:hover {
    background-color: #f8f8f8 !important;
}

.dropdown-navbar.navbar-grey > li.nav-header {
    background-color: #f2f2f2 !important;
    color: #3a87ad;
    border-bottom-color: #e5e5e5;
}

.dropdown-navbar.navbar-grey > li.nav-header > [class*="icon-"] {
    color: #3a87ad;
}

.dropdown-navbar.navbar-grey > li > a {
    border-bottom-color: #eeeeee;
}

.dropdown-navbar.navbar-green {
    border-color: #b4d5ac;
}

.dropdown-navbar.navbar-green > li:hover {
    background-color: #f4f9ef !important;
}

.dropdown-navbar.navbar-green > li.nav-header {
    background-color: #ebf7e4 !important;
    color: #88aa66;
    border-bottom-color: #b4d5ac;
}

.dropdown-navbar.navbar-green > li.nav-header > [class*="icon-"] {
    color: #90c060;
}

.dropdown-navbar.navbar-green > li > a {
    border-bottom-color: #ecf3e4;
}

.dropdown-navbar [class*="btn"][class*="icon-"] {
    display: inline-block;
    border: 0;
    margin: 0 5px 0 0;
    width: 24px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.dropdown-navbar .msg-photo {
    margin-right: 6px;
    max-width: 42px;
}

.dropdown-navbar .msg-body {
    display: inline-block;
    line-height: 20px;
    white-space: normal;
    vertical-align: middle;
    max-width: 410px;
}

.dropdown-navbar .msg-title {
    display: inline-block;
    line-height: 14px;
}

.dropdown-navbar .msg-time {
    display: block;
    font-size: 11px;
    color: #777777;
}

.dropdown-navbar .msg-time > [class*="icon-"] {
    font-size: 14px;
    color: #555555;
}

.dropdown-menu li > a,
.dropdown-submenu > a {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}

.dropdown-preview {
    margin: 0 5px;
    display: inline-block;
}

.dropdown-preview > .dropdown-menu {
    display: block;
    position: static;
    margin-bottom: 5px;
}

.nav-list > li > a > [class*="icon-"]:first-child {
    display: inline !important;
}

.sidebar-collapse > [class*="icon-"] {
    display: inline-table;
}

.wizard-steps {
    list-style: none;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}

.wizard-steps li {
    display: block;
    text-align: center;
    float: left;
}

.wizard-steps li .step {
    border: 5px solid #ced1d6;
    color: #546474;
    font-size: 15px;
    border-radius: 100%;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.wizard-steps li.hide-progress-bar:before {
    display: none;
}

.wizard-steps li:before {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    font-size: 0;
    overflow: hidden;
    border-top: 4px solid #ced1d6;
    position: relative;
    top: 21px;
    z-index: 1;
}

.wizard-steps li:last-child:before {
    max-width: 50%;
    width: 50%;
}

.wizard-steps li:first-child:before {
    max-width: 51%;
    left: 50%;
}

.wizard-steps li.active:before,
.wizard-steps li.complete:before,
.wizard-steps li.active .step,
.wizard-steps li.complete .step {
    border-color: #5293c4;
}

.wizard-steps li.complete .step {
    cursor: default;
    color: #ffffff;
    -webkit-transition: transform ease .1s;
    -moz-transition: transform ease .1s;
    -o-transition: transform ease .1s;
    transition: transform ease 0.1s;
}

.wizard-steps li.complete .step:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    line-height: 30px;
    text-align: center;
    border-radius: 100%;
    content: "\f00c";
    background-color: #ffffff;
    z-index: 3;
    font-family: FontAwesome;
    font-size: 17px;
    color: #87ba21;
}

.wizard-steps li.complete:hover .step {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    border-color: #80afd4;
}

.wizard-steps li.complete:hover:before {
    border-color: #80afd4;
}

.wizard-steps li .title {
    display: block;
    margin-top: 4px;
    max-width: 100%;
    color: #949ea7;
    font-size: 14px;
    z-index: 104;
    text-align: center;
    table-layout: fixed;
    word-wrap: break-word;
}

.wizard-steps li.complete .title,
.wizard-steps li.active .title {
    color: #2b3d53;
}

.step-content .step-pane {
    display: none;
    min-height: 200px;
    padding: 4px 8px 12px;
}

.step-content .active {
    display: block;
}

.wizard-actions {
    margin-top: 10px;
    text-align: right;
}

.dialogs {
    padding: 9px 9px 0;
    position: relative;
}

.itemdiv {
    padding-right: 3px;
    min-height: 84px;
    position: relative;
}

.itemdiv > .user {
    display: inline-block;
    width: 38px;
    height: 38px;
    position: absolute;
    left: 0;
    border-radius: 100%;
}

.standalone-user {
    display: inline-block;
    border-radius: 100%;
    border: 2px solid #c9d6e5;
    max-width: 32px;
    text-align: center;
    overflow: hidden;
}

.itemdiv > .user > img,
.itemdiv > .user > a > img {
    border-radius: 100%;
    position: relative;
}

.itemdiv > .body {
    width: auto;
    margin-left: 50px;
    margin-right: 12px;
    position: relative;
}

.itemdiv > .body > .time {
    display: block;
    font-size: 11px;
    font-weight: bold;
    color: #666666;
    position: absolute;
    right: 9px;
    top: 0;
}

.itemdiv > .body > .time [class*="icon-"] {
    font-size: 14px;
    font-weight: normal;
}

.itemdiv > .body > .name {
    display: block;
    color: #999999;
}

.itemdiv > .body > .name > b {
    color: #777777;
}

.itemdiv > .body > .text {
    display: block;
    padding-bottom: 19px;
    padding-left: 7px;
    margin-top: 2px;
    font-size: 13px;
    position: relative;
}

.itemdiv > .body > .text:after {
    display: block;
    content: "";
    height: 1px;
    font-size: 0;
    overflow: hidden;
    position: absolute;
    left: 16px;
    right: -12px;
    margin-top: 9px;
    border-top: 1px solid #e4ecf3;
}

.itemdiv > .body > .text > [class*="icon-quote-"]:first-child {
    color: #dce3ed;
    margin-right: 4px;
}

.itemdiv:last-child > .body > .text {
    border-bottom: 0;
}

.itemdiv:last-child > .body > .text:after {
    display: none;
}

.itemdiv.dialogdiv {
    padding-bottom: 14px;
}

.itemdiv.dialogdiv:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    bottom: 0;
    left: 19px;
    width: 1px;
    max-width: 1px;
    background-color: #e1e6ed;
    border: 1px solid #d7dbdd;
    border-width: 0 1px;
}

.itemdiv.dialogdiv:last-child:before {
    display: none;
}

.itemdiv.dialogdiv > .user > img {
    border-color: #c9d6e5;
}

.itemdiv.dialogdiv > .body {
    border: 1px solid #dde4ed;
    padding: 3px 7px 7px;
    border-left-width: 2px;
    margin-right: 1px;
}

.itemdiv.dialogdiv > .body:before {
    content: "";
    display: block;
    position: absolute;
    left: -7px;
    top: 11px;
    width: 8px;
    height: 8px;
    border: 2px solid #dde4ed;
    border-width: 2px 0 0 2px;
    background-color: #ffffff;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.itemdiv.dialogdiv > .body > .time {
    position: static;
    float: right;
}

.itemdiv.dialogdiv > .body > .text {
    padding-left: 0;
    padding-bottom: 0;
}

.itemdiv.dialogdiv > .body > .text:after {
    display: none;
}

.itemdiv.dialogdiv .tooltip > .tooltip-inner {
    word-break: break-all;
}

.itemdiv.memberdiv {
    width: 225px;
    padding: 2px;
    margin: 3px 0;
    float: left;
    border-bottom: 1px solid #e8e8e8;
    font-size: 12px;
}

.itemdiv.memberdiv > .user > img {
    border-color: #dce3ed;
}

.itemdiv.memberdiv > .body > .time {
    position: static;
}

.itemdiv.memberdiv > .body > .name {
    line-height: 18px;
    height: 18px;
    margin-bottom: 0;
}

.itemdiv.memberdiv > .body > .name > a {
    display: inline-block;
    max-width: 100px;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    font-size: 13px;
    cursor: pointer;
}

.itemdiv.memberdiv.disableUser {
    opacity: 0.5;
    background-color: #eee;
    cursor: not-allowed;
}

.itemdiv .tools {
    width: 20px;
    position: absolute;
    right: 4px;
    bottom: 16px;
}

.itemdiv .tools .btn {
    border-radius: 36px;
    margin: 1px 0;
}

.itemdiv .body .tools {
    bottom: 4px;
}

.itemdiv.commentdiv .tools {
    right: 9px;
}

.itemdiv:hover .tools {
    display: inline-block;
}

.item-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.item-list > li {
    border: 1px solid #dddddd;
    border-left-width: 3px;
    padding: 9px;
    background-color: #ffffff;
    margin-top: -1px;
    position: relative;
}

.item-list > li.selected {
    color: #8090a0;
    background-color: #f4f9fc;
}

.item-list > li.selected label,
.item-list > li.selected .lbl {
    text-decoration: line-through;
    color: #8090a0;
}

.item-list > li > .checkbox {
    display: inline-block;
}

.item-list > li > label.inline {
    display: inline-block;
}

.item-list > li label {
    font-size: 13px;
}

.item-list > li .percentage {
    font-size: 11px;
    font-weight: bold;
    color: #777777;
}

.item-list > li.ui-sortable-helper {
    cursor: move;
}

.item-list > li.item-orange {
    border-left-color: #eccb71;
}

.item-list > li.item-red {
    border-left-color: #e05656;
}

.item-list > li.item-green {
    border-left-color: #9ecf6a;
}

.item-list > li.item-blue {
    border-left-color: #569add;
}

.item-list > li.item-pink {
    border-left-color: #dd56c0;
}

.item-list > li.item-black {
    border-left-color: #505050;
}

.item-list > li.item-grey {
    border-left-color: #999999;
}

.item-list > li.item-brown {
    border-left-color: #a52a2a;
}

.item-list > li.item-default {
    border-left-color: #abbac3;
}

.ace-file-input {
    position: relative;
    height: 38px;
    line-height: 38px;
    margin-bottom: 9px;
}

.ace-file-input input[type=file] {
    position: fixed;
    z-index: -2;
    opacity: 0;
}

.ace-file-input label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 28px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    cursor: pointer;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all .15s;
    -moz-transition: all .15s;
    -o-transition: all .15s;
    transition: all 0.15s;
}

.ace-file-input label:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #f59942;
}

.ace-file-input label:before {
    display: inline-block;
    content: attr(data-title);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 8px;
    line-height: 24px;
    text-align: center;
    background-color: #6fb3e0;
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    border: 2px solid #ffffff;
    border-left-width: 4px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all 0.3s;
}

.ace-file-input label span {
    display: inline-block;
    height: 28px;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    line-height: 28px;
    color: #888888;
    font-size: 13px;
    position: static;
    padding-left: 30px;
}

.ace-file-input label span:after {
    display: inline-block;
    content: attr(data-title);
}

.ace-file-input label.selected {
    right: 16px;
}

.ace-file-input label.selected span {
    color: #666666;
}

.ace-file-input label [class*="icon-"] {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    line-height: 22px;
    width: 22px;
    text-align: center;
    font-family: FontAwesome;
    font-size: 13px;
    border: 2px solid #ffffff;
    color: #ffffff;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
    background-color: #d1d1d1;
}

.ace-file-input label.selected [class*="icon-"] {
    background-color: #efad62;
}

.ace-file-input label.selected .icon-picture {
    background-color: #bd7a9d;
}

.ace-file-input label.selected .icon-film {
    background-color: #87b87f;
}

.ace-file-input label.selected .icon-music {
    background-color: #8b7ac9;
}

.ace-file-input label.selected .icon-archive {
    background-color: #efad62;
}

.ace-file-input label.hide-placeholder:before {
    display: none;
}

.ace-file-input a:hover {
    text-decoration: none;
}

.ace-file-input .remove {
    position: absolute;
    right: -8px;
    top: 6px;
    display: none;
    width: 17px;
    text-align: center;
    height: 17px;
    line-height: 15px;
    font-size: 11px;
    font-weight: normal;
    background-color: #fb7142;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #ffffff;
    text-decoration: none;
}

.ace-file-input label.selected + .remove {
    display: inline-block;
}

.ace-file-multiple {
    height: auto;
}

.ace-file-multiple label {
    position: relative;
    height: auto;
    border: 1px dashed #aaaaaa;
    border-radius: 4px;
    text-align: center;
}

.ace-file-multiple label:before {
    display: inline-block;
    content: attr(data-title);
    position: relative;
    right: 0;
    left: 0;
    margin: 12px;
    line-height: 22px;
    background-color: #ffffff;
    color: #cccccc;
    font-size: 18px;
    font-weight: bold;
    border: 0;
}

.ace-file-multiple label.selected span [class*="icon-"] {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    line-height: 22px;
    width: 22px;
    text-align: center;
    font-family: FontAwesome;
    font-size: 13px;
    border: 2px solid #ffffff;
    color: #ffffff;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all 0.1s;
}

.ace-file-multiple label span {
    position: relative;
    display: block;
    padding: 0;
    height: auto;
    width: auto;
    max-width: 100%;
    margin: 0 4px;
    border-bottom: 1px solid #dddddd;
    text-align: left;
}

.ace-file-multiple label span:first-child {
    margin-top: 1px;
}

.ace-file-multiple label span:last-child {
    border-bottom-width: 0;
    margin-bottom: 1px;
}

.ace-file-multiple label span img {
    padding: 2px;
    border: 1px solid #d7d7d7;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    margin: 4px 8px 4px 1px;
}

.ace-file-multiple label span:after {
    display: none;
}

.ace-file-multiple label.selected span:after {
    display: inline-block;
    white-space: pre;
}

.ace-file-multiple label span img + [class*="icon-"],
.ace-file-multiple label.selected span img + [class*="icon-"] {
    display: none;
}

.ace-file-multiple .remove {
    right: -11px;
    top: -11px;
    border: 3px solid #bbbbbb;
    border-radius: 32px;
    background-color: #ffffff;
    color: #ff0000;
}

.ace-file-multiple label.selected + .remove:hover {
    border-color: #f4c0b1;
}

.ace-file-multiple label span [class*="icon-"] {
    position: relative;
    display: block;
    text-align: center;
    height: auto;
    line-height: 64px;
    width: auto;
    font-size: 64px;
    color: #d5d5d5;
    margin: 4px 0;
    background-color: transparent;
}

.ace-file-multiple label.selected:after {
    display: none;
}

.ace-file-multiple label.selected span [class*="icon-"] {
    position: relative;
    margin-right: 4px;
    margin-left: 2px;
    line-height: 24px;
}

.ace-file-multiple label span.large {
    text-align: center;
    border-bottom: 2px solid #222222;
    margin: 0 1px 3px;
}

.ace-file-multiple label span.large:last-child {
    margin: 0 1px;
    border-bottom-width: 0;
}

.ace-file-multiple label span.large:after {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 4px;
    background-color: #555555;
    color: #ffffff;
    opacity: .8;
    filter: alpha(opacity=80);
}

.ace-file-multiple label span.large img {
    border-width: 0;
    margin: 0;
    padding: 0;
}

.ace-file-input input[type=file].disabled + label,
.ace-file-input input[type=file][disabled] + label,
.ace-file-input input[type=file][readonly] + label {
    cursor: not-allowed;
    background-color: #eeeeee;
}

.ace-file-input input[type=file].disabled + label:hover,
.ace-file-input input[type=file][disabled] + label:hover,
.ace-file-input input[type=file][readonly] + label:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #e3e3e3;
}

.ace-file-input input[type=file].disabled + label:before,
.ace-file-input input[type=file][disabled] + label:before,
.ace-file-input input[type=file][readonly] + label:before {
    border-color: #eeeeee;
    background-color: #a1aaaf;
}

.ace-file-input input[type=file][readonly] + label {
    cursor: default;
}

.ace-file-multiple input[type=file].disabled + label:hover,
.ace-file-multiple input[type=file][disabled] + label:hover,
.ace-file-multiple input[type=file][readonly] + label:hover {
    border-color: #aaaaaa;
}

.ace-file-multiple input[type=file].disabled + label:before,
.ace-file-multiple input[type=file][disabled] + label:before,
.ace-file-multiple input[type=file][readonly] + label:before {
    background-color: transparent;
}

.ace-file-multiple input[type=file].disabled + label [class*="icon-"],
.ace-file-multiple input[type=file][disabled] + label [class*="icon-"],
.ace-file-multiple input[type=file][readonly] + label [class*="icon-"] {
    border-color: #eeeeee;
}

.tab-content {
    border: 1px solid #c5d0dc;
    padding: 16px 12px;
    position: relative;
    z-index: 11;
    background: #ffffff;
}

.tab-content.no-padding {
    padding: 0;
}

.tab-content.no-border {
    border: 0;
    padding: 12px;
}

.tab-content.padding-32 {
    padding: 32px 24px;
}

.tab-content.no-border.padding-32 {
    padding: 32px;
}

.tab-content.padding-30 {
    padding: 30px 23px;
}

.tab-content.no-border.padding-30 {
    padding: 30px;
}

.tab-content.padding-28 {
    padding: 28px 21px;
}

.tab-content.no-border.padding-28 {
    padding: 28px;
}

.tab-content.padding-26 {
    padding: 26px 20px;
}

.tab-content.no-border.padding-26 {
    padding: 26px;
}

.tab-content.padding-24 {
    padding: 24px 18px;
}

.tab-content.no-border.padding-24 {
    padding: 24px;
}

.tab-content.padding-22 {
    padding: 22px 17px;
}

.tab-content.no-border.padding-22 {
    padding: 22px;
}

.tab-content.padding-20 {
    padding: 20px 15px;
}

.tab-content.no-border.padding-20 {
    padding: 20px;
}

.tab-content.padding-18 {
    padding: 18px 14px;
}

.tab-content.no-border.padding-18 {
    padding: 18px;
}

.tab-content.padding-16 {
    padding: 16px 12px;
}

.tab-content.no-border.padding-16 {
    padding: 16px;
}

.tab-content.padding-14 {
    padding: 14px 11px;
}

.tab-content.no-border.padding-14 {
    padding: 14px;
}

.tab-content.padding-12 {
    padding: 12px 9px;
}

.tab-content.no-border.padding-12 {
    padding: 12px;
}

.tab-content.padding-10 {
    padding: 10px 8px;
}

.tab-content.no-border.padding-10 {
    padding: 10px;
}

.tab-content.padding-8 {
    padding: 8px 6px;
}

.tab-content.no-border.padding-8 {
    padding: 8px;
}

.tab-content.padding-6 {
    padding: 6px 5px;
}

.tab-content.no-border.padding-6 {
    padding: 6px;
}

.tab-content.padding-4 {
    padding: 4px 3px;
}

.tab-content.no-border.padding-4 {
    padding: 4px;
}

.tab-content.padding-2 {
    padding: 2px 2px;
}

.tab-content.no-border.padding-2 {
    padding: 2px;
}

.tab-content.padding-0 {
    padding: 0;
}

.tab-content.no-border.padding-0 {
    padding: 0;
}

.nav-tabs.padding-32 {
    padding-left: 32px;
}

.tabs-right > .nav-tabs.padding-32,
.tabs-left > .nav-tabs.padding-32 {
    padding-left: 0;
    padding-top: 32px;
}

.nav-tabs.padding-30 {
    padding-left: 30px;
}

.tabs-right > .nav-tabs.padding-30,
.tabs-left > .nav-tabs.padding-30 {
    padding-left: 0;
    padding-top: 30px;
}

.nav-tabs.padding-28 {
    padding-left: 28px;
}

.tabs-right > .nav-tabs.padding-28,
.tabs-left > .nav-tabs.padding-28 {
    padding-left: 0;
    padding-top: 28px;
}

.nav-tabs.padding-26 {
    padding-left: 26px;
}

.tabs-right > .nav-tabs.padding-26,
.tabs-left > .nav-tabs.padding-26 {
    padding-left: 0;
    padding-top: 26px;
}

.nav-tabs.padding-24 {
    padding-left: 24px;
}

.tabs-right > .nav-tabs.padding-24,
.tabs-left > .nav-tabs.padding-24 {
    padding-left: 0;
    padding-top: 24px;
}

.nav-tabs.padding-22 {
    padding-left: 22px;
}

.tabs-right > .nav-tabs.padding-22,
.tabs-left > .nav-tabs.padding-22 {
    padding-left: 0;
    padding-top: 22px;
}

.nav-tabs.padding-20 {
    padding-left: 20px;
}

.tabs-right > .nav-tabs.padding-20,
.tabs-left > .nav-tabs.padding-20 {
    padding-left: 0;
    padding-top: 20px;
}

.nav-tabs.padding-18 {
    padding-left: 18px;
}

.tabs-right > .nav-tabs.padding-18,
.tabs-left > .nav-tabs.padding-18 {
    padding-left: 0;
    padding-top: 18px;
}

.nav-tabs.padding-16 {
    padding-left: 16px;
}

.tabs-right > .nav-tabs.padding-16,
.tabs-left > .nav-tabs.padding-16 {
    padding-left: 0;
    padding-top: 16px;
}

.nav-tabs.padding-14 {
    padding-left: 14px;
}

.tabs-right > .nav-tabs.padding-14,
.tabs-left > .nav-tabs.padding-14 {
    padding-left: 0;
    padding-top: 14px;
}

.nav-tabs.padding-12 {
    padding-left: 12px;
}

.tabs-right > .nav-tabs.padding-12,
.tabs-left > .nav-tabs.padding-12 {
    padding-left: 0;
    padding-top: 12px;
}

.nav-tabs.padding-10 {
    padding-left: 10px;
}

.tabs-right > .nav-tabs.padding-10,
.tabs-left > .nav-tabs.padding-10 {
    padding-left: 0;
    padding-top: 10px;
}

.nav-tabs.padding-8 {
    padding-left: 8px;
}

.tabs-right > .nav-tabs.padding-8,
.tabs-left > .nav-tabs.padding-8 {
    padding-left: 0;
    padding-top: 8px;
}

.nav-tabs.padding-6 {
    padding-left: 6px;
}

.tabs-right > .nav-tabs.padding-6,
.tabs-left > .nav-tabs.padding-6 {
    padding-left: 0;
    padding-top: 6px;
}

.nav-tabs.padding-4 {
    padding-left: 4px;
}

.tabs-right > .nav-tabs.padding-4,
.tabs-left > .nav-tabs.padding-4 {
    padding-left: 0;
    padding-top: 4px;
}

.nav-tabs.padding-2 {
    padding-left: 2px;
}

.tabs-right > .nav-tabs.padding-2,
.tabs-left > .nav-tabs.padding-2 {
    padding-left: 0;
    padding-top: 2px;
}

.nav-tabs {
    border-color: #c5d0dc;
    margin-bottom: 0;
    position: relative;
    top: 1px;
}

.nav-tabs > li > a,
.nav-tabs > li > a:focus {
    border-radius: 0 !important;
    background-color: #f9f9f9;
    color: #999999;
    margin-right: -1px;
    position: relative;
    z-index: 11;
    border-color: #c5d0dc;
}

.nav-tabs > li > a:hover {
    background-color: #ffffff;
    color: #4c8fbd;
    border-color: #c5d0dc;
}

.nav-tabs > li > a:active,
.nav-tabs > li > a:focus {
    outline: none !important;
}

.nav-tabs > li:first-child > a {
    margin-left: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #576373;
    border-color: #c5d0dc;
    border-top: 2px solid #4c8fbd;
    border-bottom-color: transparent;
    background-color: #ffffff;
    z-index: 12;
    line-height: 16px;
    margin-top: -1px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.tabs-below > .nav-tabs {
    top: auto;
    margin-bottom: 0;
    margin-top: -1px;
    border-color: #c5d0dc;
}

.tabs-below > .nav-tabs > li > a,
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
    border-color: #c5d0dc;
}

.tabs-below > .nav-tabs > li.active > a,
.tabs-below > .nav-tabs > li.active > a:hover,
.tabs-below > .nav-tabs > li.active > a:focus {
    border-color: #c5d0dc;
    border-top-width: 1px;
    border-bottom: 2px solid #4c8fbd;
    border-top-color: transparent;
    margin-top: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    min-width: 60px;
}

.tabs-left > .nav-tabs {
    top: auto;
    margin-bottom: 0;
    margin-right: -1px;
    border-color: #c5d0dc;
}

.tabs-left > .nav-tabs > li > a,
.tabs-left > .nav-tabs > li > a:focus,
.tabs-left > .nav-tabs > li > a:hover {
    border-color: #c5d0dc;
    margin: 0 -1px 0 0;
}

.tabs-left > .nav-tabs > li.active > a,
.tabs-left > .nav-tabs > li.active > a:focus,
.tabs-left > .nav-tabs > li.active > a:hover {
    border-color: #c5d0dc;
    border-top-width: 1px;
    border-left: 2px solid #4c8fbd;
    border-right-color: transparent;
    margin: 0 -1px 0 -1px;
    box-shadow: -2px 0 3px 0 rgba(0, 0, 0, 0.15);
}

.tabs-right > .nav-tabs {
    top: auto;
    margin-bottom: 0;
    margin-left: -1px;
    border-color: #c5d0dc;
}

.tabs-right > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a:focus,
.tabs-right > .nav-tabs > li > a:hover {
    border-color: #c5d0dc;
    margin: 0 0 0 -1px;
}

.tabs-right > .nav-tabs > li.active > a,
.tabs-right > .nav-tabs > li.active > a:focus,
.tabs-right > .nav-tabs > li.active > a:hover {
    border-color: #c5d0dc;
    border-top-width: 1px;
    border-right: 2px solid #4c8fbd;
    border-left-color: transparent;
    margin: 0 -1px 0 -1px;
    box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.15);
}

.nav-tabs > li > a > .badge {
    padding: 0 4px;
    line-height: 15px;
    opacity: 0.75;
}

.nav-tabs > li > a > [class*="icon-"] {
    opacity: 0.75;
}

.nav-tabs > li.active > a > .badge,
.nav-tabs > li.active > a > [class*="icon-"] {
    opacity: 1;
}

.nav-tabs li [class*=" icon-"],
.nav-tabs li [class^="icon-"] {
    width: 1.25em;
    display: inline-block;
    text-align: center;
    margin: 0;
}

.nav-tabs > li.open .dropdown-toggle {
    background-color: #4f99c6;
    border-color: #4f99c6;
    color: #ffffff;
}

.nav-tabs > li.open .dropdown-toggle .caret {
    margin-top: 7px;
}

.nav-tabs .dropdown-toggle .caret {
    margin-top: 7px;
}

.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover,
.nav > li.dropdown.open.active > a:focus {
    background-color: #4f99c6;
    border-color: #4f99c6;
    color: #ffffff;
}

.nav-tabs li {
    margin-bottom: -1px !important;
    min-height: 38px;
}

.nav-tabs > li > a {
    border: 1px solid #c5d0dc !important;
    margin-right: -1px !important;
}

.nav-tabs > li.active a {
    border-top: 3px solid #4c8fbd !important;
    border-bottom: none !important;
}

.nav {
    margin-bottom: 0 !important;
}

.tabs-left > .nav-tabs {
    margin-right: -1px !important;
}

.tabs-left > .nav-tabs > li.active > a {
    border-top: 1px solid #c5d0dc !important;
    border-left: 2px solid #4c8fbd !important;
    border-right: 0 !important;
}

.wizard-btns {
    bottom: 20px;
    right: 20px;
}

.dark {
    color: #333333 !important;
}

.white {
    color: #ffffff !important;
}

.red {
    color: #dd5a43 !important;
}

.light-red {
    color: #ff7777 !important;
}

.blue {
    color: #478fca !important;
}

.blue2 {
    color: #4383b4 !important;
}

.light-blue {
    color: #93cbf9 !important;
}

.green {
    color: #69aa46 !important;
}

.light-green {
    color: #b0d877 !important;
}

.orange {
    color: #ff892a !important;
}

.orange2 {
    color: #feb902 !important;
}

.light-orange {
    color: #fcac6f !important;
}

.purple {
    color: #a069c3 !important;
}

.pink {
    color: #c6699f !important;
}

.pink2 {
    color: #d6487e !important;
}

.brown {
    color: #a52a2a !important;
}

.grey {
    color: #777777 !important;
}

.light-grey {
    color: #bbbbbb !important;
}

.lighter {
    font-weight: lighter;
}

.bolder {
    font-weight: bolder;
}

.inline {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.center,
.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.middle {
    vertical-align: middle;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

h1 {
    font-size: 32px;
    font-weight: normal;
}

h1.smaller {
    font-size: 31px;
}

h1.bigger {
    font-size: 33px;
}

h1.block {
    margin-bottom: 16px;
}

h2 {
    font-size: 26px;
    font-weight: normal;
}

h2.smaller {
    font-size: 25px;
}

h2.bigger {
    font-size: 27px;
}

h2.block {
    margin-bottom: 16px;
}

h3 {
    font-size: 22px;
    font-weight: normal;
}

h3.smaller {
    font-size: 21px;
}

h3.bigger {
    font-size: 23px;
}

h3.block {
    margin-bottom: 16px;
}

h4 {
    font-size: 18px;
    font-weight: normal;
}

h4.smaller {
    font-size: 17px;
}

h4.bigger {
    font-size: 19px;
}

h4.block {
    margin-bottom: 16px;
}

h5 {
    font-size: 15px;
    font-weight: normal;
}

h5.smaller {
    font-size: 14px;
}

h5.bigger {
    font-size: 16px;
}

h5.block {
    margin-bottom: 16px;
}

h6 {
    font-size: 13px;
    font-weight: normal;
}

h6.smaller {
    font-size: 12px;
}

h6.bigger {
    font-size: 14px;
}

h6.block {
    margin-bottom: 16px;
}

.no-padding {
    padding: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.no-padding-top {
    padding-top: 0;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.no-margin {
    margin: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-left,
.row-fluid .span12.no-margin-left {
    margin-left: 0;
}

.no-margin-right {
    margin-right: 0;
}

.no-border {
    border: 0;
}

.no-border-bottom {
    border-bottom: 0;
}

.no-border-top {
    border-top: 0;
}

.no-border-left {
    border-left: none;
}

.no-border-right {
    border-right: 0;
}

.widget-toolbar > .nav-tabs > li {
    margin-bottom: -1px !important;
    height: 48px;
}

.profile-user-info {
    margin: 0 12px;
}

.profile-info-row {
    position: relative;
    line-height: 32px;
    display: flex;
}

.profile-info-name {
    padding: 6px 10px 6px 0;
    width: 150px;
    text-align: right;
    font-weight: normal;
    margin: 0;
    color: #667e99;
    background-color: transparent;
    border-top: 1px dotted #d5e4f1;
}

.profile-info-value input.error {
    margin-bottom: 15px !important;
}

.profile-info-value label.error {
    position: absolute;
    bottom: 0;
    margin: 0;
}

.profile-info-value {
    padding: 6px 4px 6px 6px;
    border-top: 1px dotted #d5e4f1;
    width: 100%;
}

.profile-info-value > input, .profile-info-value > select {
    margin: 0 !important;
}

.profile-info-value > span + span:before {
    display: inline;
    content: ",";
    margin-left: 1px;
    margin-right: 3px;
    color: #666666;
    border-bottom: 1px solid #ffffff;
}

.profile-info-value > span + span.editable-container:before {
    display: none;
}

.profile-info-row:first-child .profile-info-name {
    border-top: 0;
}

.profile-info-row:first-child .profile-info-value {
    border-top: 0;
}

.profile-user-info-striped {
    border: 1px solid #dcebf7;
}

.profile-user-info-striped .profile-info-name {
    color: #336199;
    background-color: #edf3f4;
    border-top: 1px solid #f7fbff;
}

.profile-user-info-striped .profile-info-value {
    border-top: 1px dotted #dcebf7;
    padding-left: 12px;
}

.profile-picture {
    border: 1px solid #cccccc;
    background-color: #ffffff;
    padding: 4px;
    display: inline-block;
    max-width: 100%;
    -moz-box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.profile-activity:nth-child(odd) {
    background: #f6f6f6;
}

.profile-activity {
    padding: 10px 4px 10px 14px;
    position: relative;
    font-size: 12px;
    background: #fff;
}

.profile-activity-msg {
    padding: 3px 120px 3px 8px;
}

.profile-activity-time {
    position: absolute;
    top: 12px;
    right: 20px;
    color: #696969;
    font-size: 11px;
}

.profile-activity-msg a {
    color: #2876fd
}

.profile-activity .time {
    display: block;
    color: #777777;
    position: absolute;
    top: 13px;
    right: 10px;
}

.profile-activity a.user {
    font-weight: 500;
    color: #2876fd;
}

.profile-activity .tools {
    position: absolute;
    right: 12px;
    bottom: 8px;
    display: none;
}

.profile-activity:hover .tools {
    display: block;
}

.user-profile .ace-thumbnails li {
    border: 1px solid #cccccc;
    padding: 3px;
    margin: 6px;
}

.user-profile .ace-thumbnails li .tools {
    left: 3px;
    right: 3px;
}

.user-profile .ace-thumbnails li:hover .tools {
    bottom: 3px;
}

.user-profile .user-title-label:hover {
    text-decoration: none;
}

.user-profile .user-title-label + .dropdown-menu {
    margin-left: -12px;
}

.profile-contact-links {
    padding: 4px 2px 5px;
    border: 1px solid #e0e2e5;
    background-color: #f8fafc;
}

.profile-contact-info .btn-link:hover > [class*="icon-"],
.profile-contact-info .btn-link:focus > [class*="icon-"] {
    text-decoration: none;
}

.profile-social-links > a {
    text-decoration: none;
    margin: 0 1px;
}

.profile-social-links > a:hover > [class*="icon-"] {
    text-decoration: none;
}

.profile-skills .progress {
    height: 24px;
    margin-bottom: 2px;
    background-color: transparent;
}

.profile-skills .progress .bar {
    line-height: 24px;
    font-size: 13px;
    font-weight: bold;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    padding: 0 8px;
}

.profile-users .user {
    display: block;
    position: static;
    text-align: center;
    width: auto;
}

.profile-users .user img {
    padding: 2px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 1px solid #aaaaaa;
    max-width: none;
    width: 64px;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all 0.1s;
}

.profile-users .user img:hover {
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.33);
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.33);
}

.profile-users .memberdiv {
    background-color: #ffffff;
    width: 100px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 0;
    text-align: center;
    margin: 0 8px 24px;
}

.profile-users .memberdiv .name a:hover [class*="icon-"] {
    text-decoration: none;
}

.profile-users .memberdiv .body {
    display: inline-block;
    margin: 8px 0 0 0;
}

.profile-users .memberdiv .popover {
    visibility: hidden;
    min-width: 150px;
    margin-left: 0;
    margin-right: 0;
    top: -5%;
    left: auto;
    right: auto;
    z-index: -1;
    opacity: 0;
    display: none;
    -webkit-transition: visibility 0s linear 0.2s, z-index 0s linear 0.2s, opacity 0.2s linear 0s;
    -moz-transition: visibility 0s linear 0.2s, z-index 0s linear 0.2s, opacity 0.2s linear 0s;
    -o-transition: visibility 0s linear 0.2s, z-index 0s linear 0.2s, opacity 0.2s linear 0s;
    transition: visibility 0s linear 0.2s, z-index 0s linear 0.2s, opacity 0.2s linear 0s;
}

.profile-users .memberdiv .popover.right {
    left: 100%;
    right: auto;
    display: block;
}

.profile-users .memberdiv .popover.left {
    left: auto;
    right: 100%;
    display: block;
}

.profile-users .memberdiv > :first-child:hover .popover {
    visibility: visible;
    opacity: 1;
    z-index: 1010;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}

.profile-users .memberdiv .tools {
    position: static;
    display: block;
    width: 100%;
    margin-top: 2px;
}

.profile-users .memberdiv .tools > a {
    margin: 0 2px;
}

.profile-users .memberdiv .tools > a:hover {
    text-decoration: none;
}

.user-status {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #ffffff;
    border: 3px solid #aaaaaa;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 1px;
}

.user-status.status-online {
    border-color: #8ac16c;
}

.user-status.status-busy {
    border-color: #e07f69;
}

.user-status.status-idle {
    border-color: #ffb752;
}

.tab-content.profile-edit-tab-content {
    border: 1px solid #dddddd;
    padding: 8px 32px 32px;
    -webkit-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.user-profile .form-horizontal .control-label {
    width: 125px;
}

.user-profile .form-horizontal .controls {
    margin-left: 140px;
}

.fc-header-title > h2 {
    font-size: 22px;
    color: #65a0ce;
}

.fc-widget-header,
.fc-widget-content {
    border: 1px solid #bcd4e5;
}

.fc-state-highlight {
    background: #ffffcc;
}

.fc-event-skin {
    border: none !important;
    background-color: #abbac3;
    padding: 0 0 1px 2px;
}

.label-yellow .fc-event-skin {
    color: #996633;
}

.label-light .fc-event-skin {
    color: #888888;
}

[class*="label-"] > .fc-event-skin,
[class*="label-"] > .fc-event-skin > .fc-event-skin.fc-event-head {
    background-color: inherit;
}

.fc-event-skin.ui-draggable-dragging {
    cursor: move;
}

.fc-event-skin.fc-event-vert,
.fc-event-vert > .fc-event-skin {
    padding: 0 0 1px;
}

.fc-grid .fc-day-number {
    color: #2e6589;
}

.fc-widget-header {
    background: #ecf2f7;
    color: #8090a0;
}

.fc-event-hori,
.fc-event-vert {
    border-radius: 0 !important;
    border-color: transparent;
}

.fc-event-vert .fc-event-content {
    padding-left: 1px;
    padding-right: 1px;
}

.fc-event-vert .fc-event-time {
    padding: 0;
}

.fc-state-default {
    border: 0;
}

.fc-state-default,
.fc-state-default .fc-button-inner {
    border: 0;
    background-color: #abbac3;
    color: #ffffff;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0 !important;
    margin-left: 2px;
}

.fc-state-default .fc-button-effect {
    display: none;
}

.fc-state-disabled,
.fc-state-disabled .fc-button-inner {
    opacity: .75;
    filter: alpha(opacity=75);
    color: #dddddd;
}

.fc-state-active,
.fc-state-active .fc-button-inner {
    border-color: #4f99c6;
    background-color: #6fb3e0;
}

.fc-state-hover,
.fc-state-hover .fc-button-inner {
    background-color: #8b9aa3;
}

.external-event {
    margin: 6px 0;
    padding: 0;
    cursor: default;
    display: block;
    color: #ffffff;
    background-color: #abbac3;
    font-size: 13px;
    line-height: 28px;
}

.external-event:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.external-event.ui-draggable-dragging {
    cursor: move;
}

.external-event > [class*="icon-"]:first-child {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
    font-size: 15px;
    border-right: 1px solid #ffffff;
}

.tags {
    display: inline-block;
    padding: 4px 6px;
    color: #777777;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #d5d5d5;
    width: 206px;
}

.tags:hover {
    border-color: #b5b5b5;
}

.tags[class*="span"] {
    float: none;
    margin-left: 0;
}

.tags input[type="text"],
.tags input[type="text"]:focus {
    border: 0;
    display: inline;
    outline: 0;
    margin: 0;
    padding: 0;
    line-height: 14px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
}

.tags .tag {
    display: inline-block;
    position: relative;
    font-size: 13px;
    font-weight: normal;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: #91b8d0;
    color: #ffffff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    padding: 4px 22px 5px 9px;
    margin-bottom: 3px;
    margin-right: 3px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all 0.2s;
}

.tags .tag:empty {
    display: none;
}

.tags .tag-important {
    background-color: #d15b47;
}

.tags .tag-warning {
    background-color: #ffb752;
}

.tags .tag-success {
    background-color: #87b87f;
}

.tags .tag-info {
    background-color: #6fb3e0;
}

.tags .tag-inverse {
    background-color: #555555;
}

.tags .tag .close {
    font-size: 15px;
    line-height: 20px;
    opacity: 1;
    color: #ffffff;
    text-shadow: none;
    float: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 18px;
    text-align: center;
}

.tags .tag .close:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.no-border-lr {
    border-right: 0;
    border-left: 0;
}


.login-container {
    width: 375px;
    margin: 0 auto
}

.login-layout:before {
    display: none
}

.login-layout .main-content {
    margin-left: 0;
    min-height: 100%
}

#login > fieldset > label > span > i{
    font-size: 22px;
    line-height: 40px;
}

.login-layout label {
    margin-bottom: 11px
}

.login-layout .widget-box {
    visibility: hidden;
    position: absolute;
    width: 100%;
    border-bottom: 0;
    box-shadow: none;
    padding: 2px;
    -moz-transform: scale(0, 1) translate(-150px);
    -webkit-transform: scale(0, 1) translate(-150px);
    -o-transform: scale(0, 1) translate(-150px);
    -ms-transform: scale(0, 1) translate(-150px);
    transform: scale(0, 1) translate(-150px);
    -webkit-transition:-webkit-transform 0.3s;
}

.login-layout .widget-box.visible {
    visibility: visible;
    -moz-transform: scale(1, 1) translate(0);
    -webkit-transform: scale(1, 1) translate(0);
    -o-transform: scale(1, 1) translate(0);
    -ms-transform: scale(1, 1) translate(0);
    transform: scale(1, 1) translate(0);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    -o-transition: none;
    -webkit-transition: none;
    -webkit-transition:-webkit-transform 0.3s;
}

.login-layout .widget-box .widget-body {
    border-radius: 1px;
    border: 1px solid rgba(0, 0, 0, 0.04) !important;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.02);
}

.login-layout .widget-box .widget-body.tfa {
    background-color: #fff;
}

.btn-login{
    border-radius: 8px !important;
}

.login-layout .widget-box .widget-main {
    padding: 0px;
    background: #ffffff;
}

.login-layout .widget-box form .widget-main{
    padding: 30px 20px;
}

.login-layout .widget-box .widget-main form {
    margin: 0
}

.login-layout .widget-box .widget-body .toolbar>div>a {
    font-size: 15px;
    font-weight: 400;
}

.login-box .headerLogo, .forgot-box .headerLogo{
    background: #ffffff;
}

.login-box .user-signup-link {
    color: #FFFFFF;
}

.login-box .toolbar {
    background: #575BD8;
}

.login-box .toolbar>div {
    width: 50%;
    display: inline-block;
}

.login-box .toolbar>div:first-child {
    float: left;
    text-align: left
}

.login-box .toolbar>div:first-child>a {
    margin-left: 11px
}

.login-box .toolbar>div:first-child+div {
    float: right;
    text-align: right
}

.login-box .toolbar>div:first-child+div>a {
    margin-right: 11px
}

.forgot-box .toolbar {
    background: #575BD8;
    padding: 9px 18px
}

.signup-box .toolbar {
    background: #575BD8;
    padding: 9px 18px
}

.forgot-box .back-to-login-link, .signup-box .back-to-login-link {
    color: #FFFFFF;
    font-size: 14px;
}

.login-box .social-or-login {
    margin-top: 4px;
    position: relative;
    z-index: 1
}

.login-box .social-or-login :first-child {
    display: inline-block;
    background: #f7f7f7;
    padding: 0 8px;
    color: #575BD8;
    font-size: 13px
}

.login-box .social-or-login:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px dotted #a6c4db
}

.login-box .social-login {
    margin-top: 12px
}

.login-box .social-login a {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    line-height: 46px;
    padding: 0;
    margin: 0 1px;
    border: 0
}

.login-box .social-login a>[class*="icon-"] {
    font-size: 24px
}

.login-footer{
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.menu_option{
    width: 358px;
    word-wrap: break-word;
}

.menutd .menu_option{
    word-wrap: break-word;
    max-width: 200px;
}

.open .menutd .menu_option{
    width: 170px;
    margin-right: 15px;
    max-height: 32px;
}

.submenu td.menutd.menutd-text{
    width: 160px;
    padding: 6px 0px 6px 42px;
}

.projectBoxContent {
    padding: 0px 14px 0px 6px;
}

.projects {
    padding-top: 10px;
}

.projectBoxContent .tab-content{
    overflow: hidden;
}

.dropdown-closer li.menubtncreateobjects div.clearfix form a, .dropdown-closer li.menu_divider.menubtnadddata div.clearfix form a, .dropdown-closer li.menu_divider.menubtnviewtutorial div.clearfix form a{
    font-weight: bold !important;
    color: #ffffff !important;
}

.dropdown-closer li.menubtnadddata{
    background: #5bb75b;
}

.dropdown-closer li.menubtnadddata:hover{
    background: #31b733 !important;
}

.dropdown-closer li.menubtncreateobjects{
    background: #E04141;
}

.dropdown-closer li.menubtncreateobjects:hover{
    background: #E0191A !important;
}


.dropdown-closer li.menubtnviewtutorial{
    background: #307ECC;;
}

.dropdown-closer li.menubtnviewtutorial:hover{
    background: #0028cc !important;
}

.icePnlPopBody.dialogBody {
    position: relative;
}

.btnCreateObject:hover{
    background: #EA0B0B !important;
}

.btnAddData:hover{
    background: #008C0B !important;
}