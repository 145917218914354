.databoxorigin {
    text-align: center;
    margin: 10px;
    cursor: pointer
}

.databoxorigin a:hover {
    background: #ffffff !important;
    color: #222222 !important;
}

.databoxorigin a {
    background: #ffffff !important;
    color: #222222 !important;
}

.databoxorigin .databoxoriginimage img {
    height: 40px;
}

.databoxorigin a .databoxoriginimage img {
    filter: grayscale(20%);
}

.databoxorigin a:hover .databoxoriginimage img {
    filter: grayscale(0);
}

.databoxorigin .databoxoriginlabel {
    font-size: 13px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial
}

.databoxorigin .databoxoriginlabelbeta {
    font-size: 9px;
    font-family: -apple-system, "San Francisco", "Helvetica Neue", "Roboto", "Lato", "Open Sans", Helvetica, Arial;
    color: #555555 !important;
}

.wizard-actions .action-blocker {
    display: none;
}

.wizard-actions .action-blocker.processing {
    width: calc(100% - 38px);
    height: 51px;
    position: absolute;
    right: 0;
    display: inline-block;
    z-index: 1;
    bottom: 0;
}